const texts = {
  title: 'Balance',
  firstRowClassName: 'b-dark t-bold',
  balanceDisclaimer: 'Balance subject to currency fluctuations.',
  firstRowText: 'New Earnings',
  secondRowClassName: 't-faded',
  secondRowText: 'AsqMe Fee',
  thirdRowClassName: 'b-dark t-bold',
  thirdRowText: 'Pending Balance',
  fourthRowClassName: 't-blue',
  fourthRowText: 'Available for Withdrawal',
  button: (text) =>
    text === 'loading' || text === 'disable' ? 'Withdraw Funds' : `Withdraw ${text}`,
};

export { texts };
