const styles = (theme) => ({
  outerContainer: {
    height: '100%',
  },
  chatContainer: {
    minHeight: '100%',
    paddingTop: 20,
  },
  asqMeAssistantProfileImg: {
    marginRight: 10,
  },
  connectClipsLink: {
    color: '#495EFF',
  },
  assistantMessageContainer: {
    maxWidth: 629,
    padding: '10px 20px',
    border: '1px solid #CCCCCC',
    borderRadius: 5,
    backgroundColor: 'white',
    '& p': {
      fontFamily: 'Figtree-Regular',
      fontSize: 16,
      lineHeight: '21.79px',
      margin: 0,
      whiteSpace: 'pre-wrap',
    }
  },
  clipsAnswerContainer: {
    maxWidth: 310,
    borderRadius: 10,
    backgroundColor: 'white',
    overflow: 'hidden',
    marginTop: 10,
  },
  clipsAnswerThumbnailContainer: {
    position: 'relative',
  },
  clipsAnswerThumbnail: {
    width: '100%',
  },
  clipsAnswerThumbnailTitle: {
    padding: '10px 20px',
    '& span': {
      fontFamilyt: 'Figtree-Regular',
      fontSize: 16,
      lineHeight: '21.79px',
      color: 'black',
      textDecoration: 'underline',
    },
  },
  clipsPlayButtonContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  copyLink: {
    backgroundColor: 'rgba(73, 94, 255, 0.5)',
    borderRadius: 30,
    margin: '10px 0',
    cursor: 'pointer',
    padding: '5px 10px',
    '& span': {
      fontFamily: 'Figtree-Regular',
      fontSize: 12,
      color: 'white',
      marginLeft: 10,
    }
  },
  messageOuterContainer: {
    marginBottom: 10,
    [theme.breakpoints.down('xs')]: {
      padding: '0 20px',
    }
  },
  creatorMessageContainer: {
    maxWidth: 629,
    padding: '10px 20px',
    marginRight: 10,
    backgroundColor: '#495EFF',
    borderRadius: 5,
    '& p': {
      fontFamily: 'Figtree-Regular',
      fontSize: 16,
      lineHeight: '21.79px',
      margin: 0,
      color: 'white',
      whiteSpace: 'pre-wrap',
    }
  },
  messageProfileImage: {
    width: 45,
    height: 45,
    borderRadius: '50%',
    border: '3px solid white',
  },
  helpText: {
    width: '100%',
    marginBottom: 5,
    '& span': {
      fontFamily: 'Figtree-Light',
      fontSize: 16,
      lineHeight: '19.2px',
      color: '#181818',
      '& a': {
        color: '#181818',
        textDecoration: 'underline',
      }
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0 20px',
    }
  },
  messageInputOverlay: {
    position: 'sticky',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
    }
  },
  messageInputOuterContainer: {
    position: 'sticky',
    bottom: 0,
    backgroundColor: 'white',
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
    boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)',
    padding: '15px 20px',
  },
  messageInputContainer: {
    border: '1.5px solid #C1C1C1',
    borderRadius: 5,
  },
  textFieldContainer: {
    padding: '0 20px',
  },
  textField: {
    marginTop: 26,
    '& .MuiInputBase-root': {
      padding: 0,
      paddingBottom: 5,
    },
    '& textarea': {
      fontFamily: 'Figtree-Regular',
      fontSize: 16,
      lineHeight: '21.79px',
    },
    '& .MuiInputBase-input::placeholder': {
      color: '#898A8D'
    }
  },
  controlsContainer: {
    marginRight: 10,
    marginBottom: 10,
  },
  firstDraftIcon: {
    marginRight: 7,
    '& img': {
      width: 20,
      height: 20,
    },
  },
  firstDraftQuotaText: {
    marginRight: 20,
    '& span': {
      fontFamily: 'Figtree-Bold',
      fontSize: 16,
    }
  },
  upgradeButton: {
    backgroundColor: '#FFD058',
    boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.1)',
    padding: '0 20px',
    borderRadius: 5,
    fontFamily: 'Figtree-Bold',
    fontSize: 16,
    '&:hover': {
      color: 'black',
      backgroundColor: '#FFD058 !important',
      opacity: 0.8,
    },
  }
});

export default styles;