import { makeStyles } from "@material-ui/core/styles";
import { ShootingStarsIcon, StarAltIcon, StarFilledIcon } from "components/Icons";
import ThankBankButton from "./thank-bank-button";
import { styles } from "./rate-creator.styles";

const useStyles = makeStyles(styles);

const RateCreator = (props) => {
  const { onRatingSubmit, onChange, rating, isSubmitted } = props;

  const classes = useStyles();

  const onRatingChanged = (newRating) => {
    onChange(newRating);
  }

  const onSubmit = () => {
    onRatingSubmit(rating);
  }

  return (
    <div className={classes.container}>
      <h2 className={classes.headingText}>Feeling star-struck? Pass it on with a stellar rating! 🌟</h2>
      <p className={classes.ratingText}>{rating ?? 0} of 5 stars</p>
      <RatingStars
        value={rating}
        onChange={onRatingChanged}
        readOnly={isSubmitted}
      />
      <ThankBankButton 
        onClick={onSubmit}
        isSubmitted={isSubmitted}
        isDisabled={!rating}
        Icon={ShootingStarsIcon}
      />
    </div>
  )
}

const RatingStars = (props) => {
  const classes = useStyles();

  const { value, onChange, readOnly } = props;

  const setRating = (newRating) => {
    if (!readOnly) {
      onChange(newRating)
    }
  }

  return (
    <div className={classes.ratingContainer}>
      {[...Array(5)].map((_, i) => (
        <span 
          key={`rating-${i}`} 
          onClick={() => setRating(i + 1)} 
          className={classes.starContainer}
        >
          <StarAltIcon />
          <StarFilledIcon className={`${value >= i + 1 ? classes.starActive : ''}`} />
        </span>
      ))}
    </div>
  )
}

export default RateCreator;