const StarAlt = ({
  width = 34,
  height = 33,
  fillColor='#CCCCCC',
  ...rest
}) => (
  <svg
    {...rest}
    width={width} 
    height={height} 
    viewBox="0 0 34 33" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M27.5033 32.3343L17 26.0368L6.49675 32.3398L9.24154 20.3978L0 12.3516L12.2021 11.278L17 0L21.7979 11.278L34 12.3516L24.7585 20.3978L27.5033 32.3343Z" fill={fillColor}/>
  </svg>
);

export default StarAlt;