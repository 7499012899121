import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useAlert } from 'react-alert';
import { useQuery } from '@tanstack/react-query';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import TipFlow from './TipFlow';
import NotFound from 'components/NotFound';
import { fetchCreatorDataForTip, queryCustomerTypes } from 'store/customer';
import { defaultAlertError } from 'utils/common/constants';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.common.white,
    color: theme.common.black,
  },
}));

const Tipjar = () => {
  const alert = useAlert();
  const classes = useStyles();

  const navigate = useNavigate();
  const { tag } = useParams();
  const asqMeTagFound = tag.startsWith('@');

  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    if (!asqMeTagFound) setInitialLoad(false);
  }, []);

  const { data, isFetching, error } = useQuery({
    queryKey: [queryCustomerTypes.creatorDataForTip, tag],
    queryFn: fetchCreatorDataForTip,
    enabled: asqMeTagFound,
    retry: 1,
    onSuccess: () => {
      setInitialLoad(false);
    },
    onError: (err) => {
      if (err.status === 400) navigate(`/${tag}`, { replace: true });
      else if (err.status === 404) setInitialLoad(false);
      else
        alert.error(null, defaultAlertError);
    },
  });

  const tipSessionData = useMemo(
    () => (data ? { creator: data, subscriptionTipFlow: true } : null),
    [data],
  );

  const isLoading = isFetching || initialLoad;

  return (
    <>
      {isLoading ? (
        <Backdrop open className={classes.backdrop}>
          <CircularProgress color='inherit' thickness={3} size={70} />
        </Backdrop>
      ) : tipSessionData ? (
        <TipFlow tipSessionData={tipSessionData} />
      ) : !asqMeTagFound || error?.status === 404 ? (
        <NotFound />
      ) : null}
    </>
  );
};

export default Tipjar;
