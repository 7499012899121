import { useCallback, useMemo, useRef, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { queryProfileTypes, updateTranslatedLanguage } from 'store/auth/profile';
import { updateTipJarFeature } from 'store/creator';
import { languagesLookup } from 'utils/lookups/supportedTranslationLanguages';
import { defaultAlertError } from 'utils/common/constants';

/**
 * Hook dealing with translations
 * @param {string} preferred_language - user's selected language
 * @param {object} queryClient - react-query queryClient
 * @param {object} alert - react-alert
 *
 * @returns {object} - object containing:
 * - isLoading - boolean indicating if mutation is in progress
 * - handleChangeTranslationSettings - function for updating translation settings
 * - selectedLanguageOption - selected option from language dropdown
 */
const useTranslation = ({ preferred_language, queryClient, alert }) => {
  // mutation for updating translation settings
  const { mutate: onUpdateTranslatedLanguage, isLoading } = useMutation({
    mutationFn: updateTranslatedLanguage,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryProfileTypes.profile] });
    },
    onError: (err) => {
      alert.error(null, {...defaultAlertError, offsetError: true });
    },
  });

  // handler for udpating translation settings
  const handleChangeTranslationSettings = useCallback((isActive, lang = '') => {
    onUpdateTranslatedLanguage({ languageTranslationEnabled: isActive, preferred_language: lang });
  }, []);

  // selected option for language dropdown
  const selectedLanguageOption = useMemo(
    () =>
      preferred_language ? languagesLookup.find((lang) => lang.value === preferred_language) : null,
    [preferred_language],
  );

  return {
    isLoading,
    handleChangeTranslationSettings,
    selectedLanguageOption,
  };
};

/**
 * Hook dealing with tip jar feature toggle
 * @param {boolean} is_subscribed_and_profile_completed - boolean indicating if user is subscribed and required profile info are completed
 * @param {function} setFeatureInfoDrawer - function for setting drawer state
 *  @param {object} queryClient - react-query queryClient
 * @param {object} alert - react-alert
 *
 * @returns {object} - object containing:
 * - isLoading - boolean indicating if mutation is in progress
 * - handleTipJarChange - function for updating tip jar feature
 */

const useTipJar = ({
  is_subscribed_and_profile_completed,
  setFeatureInfoDrawer,
  queryClient,
  alert,
}) => {
  // mutation for updating tip jar feature
  const { mutate: onUpdateTipJar, isLoading } = useMutation({
    mutationFn: updateTipJarFeature,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryProfileTypes.profile] });
    },
    onError: (err) => {
      alert.error(null, {...defaultAlertError, offsetError: true });
    },
  });

  // handler for udpating tip jar feature
  const handleTipJarChange = useCallback(
    (e) => {
      if (!is_subscribed_and_profile_completed) {
        setFeatureInfoDrawer('tip');
        return;
      }
      onUpdateTipJar({ tip_jar_enabled: e.target.checked });
    },
    [is_subscribed_and_profile_completed],
  );

  return {
    isLoading,
    handleTipJarChange,
  };
};

export { useTranslation, useTipJar };
