const SpeechBubble = ({
  fillColor="white",
  ...rest
}) => (
  <svg
    {...rest}
    width="18" 
    height="18" 
    viewBox="0 0 18 18" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9.00012 0C4.10258 0 0.000116833 3.51562 0.000116833 7.96154C0.000116833 10.0601 0.941222 11.9423 2.42319 13.3486C2.29339 13.692 2.06352 14.1626 1.66598 14.6466C1.19002 15.2281 0.635634 15.7635 0.259732 16.0745C0.0298645 16.2584 -0.0593784 16.5667 0.0379774 16.8453C0.135334 17.1211 0.397653 17.3077 0.692425 17.3077C2.22578 17.3077 3.32914 17.2915 4.32704 17.0264C5.21947 16.7912 5.9875 16.2936 6.77175 15.5986C7.48569 15.7689 8.22127 15.9231 9.00012 15.9231C13.8977 15.9231 18.0001 12.4075 18.0001 7.96154C18.0001 3.51562 13.8977 0 9.00012 0ZM9.00012 1.38462C13.2405 1.38462 16.6155 4.3756 16.6155 7.96154C16.6155 11.5475 13.2405 14.5385 9.00012 14.5385C8.24561 14.5385 7.46406 14.4087 6.75012 14.2139C6.51754 14.1544 6.27415 14.2221 6.10108 14.387C5.32223 15.1334 4.74891 15.4823 3.98089 15.6851C3.62662 15.7797 2.94783 15.7554 2.46646 15.7933C2.56382 15.6851 2.65306 15.6256 2.74771 15.512C3.29128 14.8468 3.78888 14.1436 3.93762 13.3486C3.98089 13.1052 3.88894 12.8591 3.69964 12.6995C2.25012 11.5015 1.38473 9.82211 1.38473 7.96154C1.38473 4.3756 4.75973 1.38462 9.00012 1.38462ZM7.6804 4.08894C7.55871 4.11599 7.44513 4.17548 7.35589 4.26202L6.31742 5.23558L7.24771 6.2524L8.06983 5.47356H9.73569L10.3847 6.20913V7.22596L8.61069 8.41586C8.41869 8.54838 8.3051 8.76743 8.30781 9V10.3846H9.69242V9.38942L11.4665 8.19952C11.6585 8.06701 11.7721 7.84796 11.7693 7.61538V5.94952C11.7693 5.78185 11.7071 5.61959 11.5963 5.49519L10.5578 4.32692C10.428 4.17548 10.2387 4.08894 10.0386 4.08894H7.83185C7.78046 4.08353 7.73179 4.08353 7.6804 4.08894ZM8.30781 11.0769V12.4615H9.69242V11.0769H8.30781Z" fill={fillColor} />
  </svg>
);

export default SpeechBubble; 