import { number, object, string } from 'prop-types';
import common from '@material-ui/core/colors/common';

const CircleSuccessIcon = ({
  className = '',
  style = {},
  width = 25,
  height = 25,
  fillColor = common.black,
  strokeColor = common.white,
  ...rest
}) => (
  <svg
    {...rest}
    style={style}
    className={className}
    height={height}
    width={width}
    viewBox='0 0 25 25'
    fill='none'
  >
    <g clipPath='url(#clip0_5850_15025)'>
      <path
        d='M12.5 25C19.4036 25 25 19.4036 25 12.5C25 5.59644 19.4036 0 12.5 0C5.59644 0 0 5.59644 0 12.5C0 19.4036 5.59644 25 12.5 25Z'
        fill={fillColor}
      />
      <path
        d='M11.4976 19.149L5.5874 14.5419L7.26807 12.3837L10.8975 15.2128L16.8872 6.56396L19.1348 8.12164L11.4976 19.149Z'
        fill={strokeColor}
      />
    </g>
    <defs>
      <clipPath id='clip0_5850_15025'>
        <rect width='25' height='25' fill={strokeColor} />
      </clipPath>
    </defs>
  </svg>
);

CircleSuccessIcon.propTypes = {
  style: object,
  className: string,
  height: number,
  width: number,
  fillColor: string,
  strokeColor: string,
};

export default CircleSuccessIcon;
