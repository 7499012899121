import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';
import useAppData from 'hooks/context';

const useStyles = makeStyles((theme) => ({
  upgradeToProButton: {
    width: '100%',
    height: 44,
    backgroundColor: '#FFD058',
    borderRadius: 5,
    '&:hover': {
      backgroundColor: '#FFD058 !important',
      opacity: 0.8,
    }
  },
  upgradeToProButtonText: {
    color: 'black',
    fontFamily: 'Figtree-Bold',
    fontSize: 18,
    textTransform: 'none',
  },
}));

const UpgradeToProButton = (props) => {
  const { children, isInNav, ...rest } = props;

  const location = useLocation();

  const { user } = useAppData();

  const classes = useStyles();

  return (
    <>
      {(!isInNav || (!user?.is_subscribed && !location.pathname.includes("upgrade"))) && 
      <Button
        classes={{ root: classes.upgradeToProButton, label: classes.upgradeToProButtonText }}
        to='/upgrade'
        component={Link}
        {...rest}
      >
        {children ?? 'Go Pro!'}
      </Button>}
    </>
  )
}

export default UpgradeToProButton;