import { DateTime } from 'luxon';

/**
 * e.g. January 11, 2024
 */
export function formatDate(dateStr) {
  if (!dateStr) return '';
  return DateTime.fromISO(dateStr).toFormat('MMMM d, yyyy');
}

/**
 * e.g. Jan 2024
 */
export function formatMonth(dateStr) {
  if (!dateStr) return '';
  return DateTime.fromISO(dateStr).toFormat('MMM yyyy');
}
