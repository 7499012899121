import { ButtonBase, makeStyles } from "@material-ui/core"
import closeIcon from 'assets/images/white-x.svg';
import playIcon from 'assets/images/white-play.svg';

const useStyles = makeStyles((theme) => ({
  buttonRoot: {
    borderRadius: '50%',
    // Set the background color to #454545 with 80 alpha
    backgroundColor: 'rgba(69, 69, 69, 0.8)',
    border: '2px solid white',
  },
  closeButton: {
    width: 20,
    height: 20,
    '&.large': {
      width: 48,
      height: 48,
      '& img': {
        width: 24,
        height: 24,
      },
      [theme.breakpoints.down('xs')]: {
        width: 24,
        height: 24,
        '& img': {
          width: 11,
          height: 11,
        }
      },
    }
  },
  playButton: {
    width: 40,
    height: 40,
    '& img': {
      marginLeft: 3
    },
    '&.large': {
      width: 65,
      height: 65,
      '& img': {
        marginLeft: 5,
        width: 26,
        height: 26,
      },
    }
  },
}));

export const ClipsCloseButton = (props) => {
  const { onClick, isLarge } = props;

  const classes = useStyles();

  return (
    <ButtonBase 
      classes={{root: classes.buttonRoot }} 
      className={`${classes.closeButton} ${isLarge ? 'large' : ''} ${props.extraClass}`}
      onClick={onClick}
    >
      <img src={closeIcon} alt="Close" />
    </ButtonBase>
  )
}

export const ClipsPlayButton = (props) => {
  const { onClick, isLarge } = props;

  const classes = useStyles();

  return (
    <ButtonBase 
      classes={{root: classes.buttonRoot }}
      className={`${classes.playButton} ${isLarge ? 'large' : ''}`}
      onClick={onClick}
    >
      <img src={playIcon} alt="Play" />
    </ButtonBase>
  )
}