import { useMemo, useState, } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ThankBankButton from "./thank-bank-button";
import { CloseIconAlt, PaperPlaneIconAlt } from 'components/Icons';
import AsqButton from 'routes/Subscribe/asq-button';

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      borderRadius: 20,
    }
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    padding: 20,
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: 20,
    '& h2': {
      fontFamily: 'Figtree-Bold',
      fontSize: 16,
      lineHeight: '19px',
      letterSpacing: '0em',
      textAlign: 'left',
      display: 'inline-flex',
      alignItems: 'center',
      marginBottom: 0,
    },
    '& button': {
      minWidth: 'auto',
      minHeight: 'auto',
      padding: 0,
      alignItems: 'start',
    },
  },
  discardMessageHeaderMargin: {
    marginBottom: 10,
  },
  discardMessage: {
    fontFamily: 'Figtree-Regular',
    fontSize: 14,
    lineHeight: '19.07px',
    textAlign: 'left',
    width: '100%',
    marginBottom: 20,
  },
  customTextarea: {
    width: '100%',
    border: 0,
    backgroundColor: '#EEEEEE',
    padding: 20,
    height: 272,
    fontFamily: 'Figtree-Regular',
    fontSize: 16,
    lineHeight: '19.2px',
  },
  remainingCharacters: {
    width: '100%',
    textAlign: 'end',
    fontFamily: 'Figtree-SemiBold',
    fontSize: 12,
    lineHeight: '14.4px',
  },
  errorText: {
    color: '#D44444',
  },
  submitButton: {
    marginTop: 20,
  }
}));

const CustomMessageDialog = (props) => {
  const { open, readOnly, displayName, isSubmitted, message, onMessageChange, handleClose, handleSubmit } = props;

  const classes = useStyles();

  const maxChars = 300;
  
  const remainingChars = useMemo(() => {
    return maxChars - (message?.length ?? 0);
  }, [message])

  const [showDiscardDialog, setShowDiscardDialog] = useState(false);

  const discardMessage = () => {
    if (isSubmitted || remainingChars == maxChars) {
      handleClose();
    } else {
      setShowDiscardDialog(true);
    }
  }

  const closeDialogs = () => {
    setShowDiscardDialog(false);
    handleClose();
  }

  return (
    <Dialog 
      open={open} 
      onClose={handleClose} 
      className={classes.dialog}
      fullWidth={true}
    >
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <h2>Message to {displayName}</h2>
          <Button onClick={discardMessage}><CloseIconAlt /></Button>
        </div>
        <textarea
          value={message}
          onChange={(e) => onMessageChange(e.target.value)}
          className={classes.customTextarea}
          name="customMessage"
          maxLength={maxChars}
          readOnly={isSubmitted}
        />
        {!readOnly && 
          <p className={`${classes.remainingCharacters} ${remainingChars === 0 ? classes.errorText : ''}`}>{remainingChars} characters remaining</p>
        }
        <ThankBankButton 
          onClick={handleSubmit}
          isSubmitted={isSubmitted}
          isDisabled={remainingChars === maxChars}
          Icon={PaperPlaneIconAlt}
          className={classes.submitButton}
        />
      </div>
      <DiscardMessageDialog 
        open={showDiscardDialog} 
        handleClose={() => setShowDiscardDialog(false)}
        onDiscardMessage={closeDialogs}
      />
    </Dialog>
  )
}

const DiscardMessageDialog = (props) => {
  const { open, handleClose, onDiscardMessage } = props;

  const classes = useStyles();

  return (
    <Dialog 
      open={open} 
      onClose={handleClose} 
      className={classes.dialog}
      fullWidth={true}
    >
      <div className={classes.container}>
        <div className={`${classes.headerContainer} ${classes.discardMessageHeaderMargin}`}>
          <h2>Are you sure you want to exit?</h2>
          <Button onClick={handleClose}><CloseIconAlt /></Button>
        </div>
        <p className={classes.discardMessage}>Closing this will erase what you have written. Are you sure you want to continue?</p>
        <AsqButton 
          fullWidth={true}
          onClick={onDiscardMessage}
        >
          Discard Message
        </AsqButton>
      </div>
    </Dialog>
  )
}

export default CustomMessageDialog;