import { makeStyles } from '@material-ui/core';
import { AsqDrawer } from 'components/Shared/AsqDrawer';

import iconTime from 'assets/images/icon-time.svg';
import iconGraphUp from 'assets/images/icon-graph-up.svg';
import iconYoutube from 'assets/images/icon-youtube.svg';
import AsqButton from 'routes/Subscribe/asq-button';

const useStyles = makeStyles((theme) => ({
  header: {
    fontSize: 20,
  },
  iconRow: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: 40,
    paddingRight: 40,
    marginBottom: 40,
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      marginBottom: 30,
    },
  },
  iconEl: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  iconElImg: {
    alignSelf: 'center',
    marginBottom: 8,
  },
  iconElText: {
    textAlign: 'center',
  },
  infoBox: {
    border: '2px solid #B1B1B1',
    borderRadius: 10,
    padding: 20,
    fontSize: 16,
    '& :last-child': {
      marginBottom: 0,
    },
  },
  infoItem: {
    marginBottom: 15,
  },
  infoTitle: {
    fontSize: 20,
    marginBottom: 10,
  },
  infoLabel: {
    fontWeight: 'bold',
  },
  btn: {
    marginTop: 40,
    width: '100%',
    maxWidth: 416,
    [theme.breakpoints.down('xs')]: {
      '& .MuiButton-label': {
        fontSize: 14,
      },
    },
  },
}));

export function ClipsInfoDrawer({ onConnect, ...props }) {
  const classes = useStyles();

  function Header() {
    return (
      <div className={classes.header}>
        <strong>FirstDraft</strong> is your personal AI assistant that crafts answers to audience
        questions using your YouTube content
      </div>
    );
  }

  const iconEls = [
    { icon: iconTime, text: 'Save Time' },
    { icon: iconGraphUp, text: 'Boost Engagement' },
    { icon: iconGraphUp, text: 'Effortless Management' },
  ];

  const infoSections = [
    {
      title: 'How it Works:',
      items: [
        { label: 'Connect Your YouTube', value: 'Link your channel to AsqMe' },
        { label: 'AI Analysis:', value: 'FirstDraft understands your content' },
        {
          label: 'Generate & Review:',
          value: 'Get video clips and answers based on your content, ready for your approval',
        },
      ],
    },
    {
      title: 'Why FirstDraft?',
      items: [
        {
          label: 'Unique to You:',
          value: 'Uses your proprietary content, ensuring personalized answers',
        },
        { label: 'Privacy First:', value: 'Your data is secure and never shared' },
      ],
    },
  ];

  return (
    <AsqDrawer header={<Header />} {...props}>
      <div className={classes.iconRow}>
        {iconEls.map((x, idx) => (
          <div key={`icon-${idx}`} className={classes.iconEl}>
            <img src={x.icon} alt={x.text} className={classes.iconElImg} />
            <div className={classes.iconElText}>
              {x.text.split(' ').map((x, textIdx) => (
                <div key={`split-text-${idx}-${textIdx}`}>{x}</div>
              ))}
            </div>
          </div>
        ))}
      </div>

      {infoSections.map((x, idx) => (
        <div key={`info-section-${idx}`} className={classes.infoBox} style={{ marginTop: idx > 0 ? 20 : 0 }}>
          <div className={classes.infoTitle}>{x.title}</div>
          {x.items.map((y, itemIdx) => (
            <div key={`item-${idx}-${itemIdx}`} className={classes.infoItem}>
              <div className={classes.infoLabel}>{y.label}</div>
              <div className={classes.infoValue}>{y.value}</div>
            </div>
          ))}
        </div>
      ))}

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <AsqButton extraClassName={classes.btn} onClick={onConnect}>
          <img src={iconYoutube} alt='YouTube Logo' style={{ marginRight: 10 }} /> Connect Your
          YouTube Account
        </AsqButton>
      </div>
    </AsqDrawer>
  );
}
