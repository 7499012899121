import { number, object, string } from 'prop-types';

const InboxIcon = ({
  className = '',
  style = {},
  width = 46,
  height = 46,
  fillColor = '#B1B1B1',
  ...rest
}) => (
  <svg
    {...rest}
    className={className}
    style={style}
    width={width}
    height={height}
    viewBox="0 0 26 26"
  >
    <path d="M5.40625 3C4.10547 3 3.01172 3.87109 2.5625 5.0625V5.09375L0.0625 12.6875L0 12.8438V19C0 21.1992 1.80078 23 4 23H22C24.1992 23 26 21.1992 26 19V12.8438L25.9375 12.6875L23.3438 5.0625C22.9219 3.9375 21.8438 3 20.5 3H5.40625ZM5.40625 5H20.5C20.957 5 21.2891 5.27344 21.4688 5.75L23.9688 13H16C16 14.6992 14.6992 16 13 16C11.3008 16 10 14.6992 10 13H2.03125L4.4375 5.75C4.58984 5.34375 5.10547 5 5.40625 5Z" fill={fillColor} />
  </svg>
);

InboxIcon.propTypes = {
  style: object,
  className: string,
  height: number,
  width: number,
  fillColor: string,
  strokeColor: string,
};

export default InboxIcon;
