import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useAlert } from 'react-alert';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import RevealAnswer from './RevealAnswer';
import { queryCustomerTypes, fetchWholeAnswerSet } from 'store/customer';
import { defaultAlertError } from 'utils/common/constants';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.common.white,
    color: theme.common.black,
  },
}));

const AnswerStep = ({ answerStepData, questionTrackingCode }) => {
  const alert = useAlert();
  const classes = useStyles();

  const [initialLoad, setInitialLoad] = useState(true);

  const { customer_payment_mode, hasPaymentMethod, skip_payment } =
    answerStepData;

  // all cases resolver
  // paid answer: payment card is set - procceed with the answer
  // $0: procceed with the answer
  // answer by tip: procceed with the answer - open payment card if asnwer is accepted
  const { data: wholeAnswerSetData } = useQuery({
    queryKey: [queryCustomerTypes.wholeAnswerSet, questionTrackingCode],
    queryFn: fetchWholeAnswerSet,
    retry: 1,
    onSuccess: () => {
      setInitialLoad(false);
    },
    onError: (err) => {
      alert.error(null, defaultAlertError);
    },
  });

  const answerStepProps = {
    customerPaymentMethod: customer_payment_mode,
    hasPaymentMethod: hasPaymentMethod,
    skipPayment: skip_payment,
  };

  return initialLoad ? (
    <Backdrop open className={classes.backdrop}>
      <CircularProgress color='inherit' thickness={3} size={70} />
    </Backdrop>
  ) : wholeAnswerSetData ? (
    <RevealAnswer
      {...answerStepProps}
      answerData={wholeAnswerSetData}
    />
  ) : null;
};

export default AnswerStep;
