import { number, object, string } from 'prop-types';

const FirstDraftActionIcon = ({
  className = '',
  style = {},
  width = 31,
  height = 30,
  fillColor = '#666666',
  ...rest
}) => (
  <svg {...rest} className={className} style={style} width={width} height={height}>
    <g filter='url(#filter0_d_6642_8036)'>
      <g clipPath='url(#clip0_6642_8036)'>
        <path
          d='M2 14.6998V17.3514C2 18.0832 2.6024 18.6772 3.34568 18.6772H3.79406V13.374H3.34568C2.6024 13.374 2 13.9675 2 14.6998Z'
          fill={fillColor}
        />
        <path
          d='M27.5679 13.374H27.1195V18.6772H27.5679C28.3112 18.6772 28.9136 18.0837 28.9136 17.3514V14.6998C28.9136 13.9675 28.3112 13.374 27.5679 13.374Z'
          fill={fillColor}
        />
        <path
          d='M17.6998 3.20944C17.6971 4.25867 16.9481 5.16238 15.9057 5.37471V7.62855H15.0084V5.37471C13.852 5.14218 13.0766 4.06654 13.2343 2.91424C13.392 1.76195 14.4291 0.927115 15.6071 1.00532C16.7846 1.083 17.6998 2.04678 17.6998 3.20944Z'
          fill={fillColor}
        />
        <path
          d='M20.8395 16.0256C21.5827 16.0256 22.1852 15.432 22.1852 14.6998C22.1852 13.9676 21.5827 13.374 20.8395 13.374C20.0963 13.374 19.4938 13.9676 19.4938 14.6998C19.4938 15.432 20.0963 16.0256 20.8395 16.0256Z'
          fill={fillColor}
        />
        <path
          d='M10.0741 16.0256C10.8173 16.0256 11.4197 15.432 11.4197 14.6998C11.4197 13.9676 10.8173 13.374 10.0741 13.374C9.33087 13.374 8.72839 13.9676 8.72839 14.6998C8.72839 15.432 9.33087 16.0256 10.0741 16.0256Z'
          fill={fillColor}
        />
        <path
          d='M23.5309 8.51245H7.38272C5.89616 8.51245 4.69136 9.69944 4.69136 11.164V20.8863C4.69136 22.3472 5.89038 23.5327 7.37326 23.5379C7.49363 23.5363 7.6098 23.5819 7.69548 23.6653C7.78116 23.7486 7.83058 23.8615 7.8311 23.9801V26.248C7.8311 26.4111 7.92204 26.5613 8.06765 26.6379C8.21325 26.7146 8.39092 26.7063 8.52812 26.6162L12.9888 23.687C13.1355 23.5907 13.3079 23.5389 13.4845 23.5384H23.5298C25.0164 23.5384 26.2212 22.3514 26.2212 20.8868V11.164C26.2212 9.69944 25.0164 8.51245 23.5298 8.51245H23.5309ZM10.0741 16.9089C8.83563 16.9089 7.8311 15.9198 7.8311 14.6991C7.8311 13.4785 8.8351 12.4893 10.0741 12.4893C11.313 12.4893 12.317 13.4785 12.317 14.6991C12.3155 15.9187 11.312 16.9074 10.0741 16.9089ZM18.0798 18.4688C18.0514 18.5133 17.3654 19.5605 15.4568 19.5605C13.5481 19.5605 12.8616 18.5139 12.8338 18.4688C12.7066 18.2632 12.7702 17.9949 12.9762 17.866C13.1823 17.737 13.4556 17.794 13.5907 17.9949C13.6175 18.0327 14.0869 18.6765 15.4568 18.6765C16.8266 18.6765 17.3045 18.0219 17.3234 17.9944C17.4606 17.794 17.735 17.7375 17.9421 17.8675C18.1476 17.997 18.2091 18.2653 18.0803 18.4688H18.0798ZM20.8395 16.9089C19.6011 16.9089 18.5965 15.9198 18.5965 14.6991C18.5965 13.4785 19.6005 12.4893 20.8395 12.4893C22.0785 12.4893 23.0825 13.4785 23.0825 14.6991C23.0809 15.9187 22.0779 16.9074 20.8395 16.9089Z'
          fill={fillColor}
        />
      </g>
    </g>
    <defs>
      <filter
        id='filter0_d_6642_8036'
        x='0'
        y='0'
        width='30.9136'
        height='29.6902'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset dy='1' />
        <feGaussianBlur stdDeviation='1' />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
        <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_6642_8036' />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect1_dropShadow_6642_8036'
          result='shape'
        />
      </filter>
      <clipPath id='clip0_6642_8036'>
        <rect width='26.9136' height='25.6902' fill='white' transform='translate(2 1)' />
      </clipPath>
    </defs>
  </svg>
);

FirstDraftActionIcon.propTypes = {
  style: object,
  className: string,
  height: number,
  width: number,
  fillColor: string,
};

export default FirstDraftActionIcon;
