import { number, object, string } from 'prop-types';

const InfoIcon = ({
  className = '',
  style = {},
  width = 20,
  height = 20,
  fillColor = '#666666',
  strokeColor = '#fff',
  ...restProps
}) => (
  <svg
    {...restProps}
    style={style}
    className={className}
    height={height}
    width={width}
    viewBox='0 0 20 20'
    fill='none'
  >
    <g clipPath='url(#clip0_212_2051)'>
      <path
        d='M17.0698 2.93062C13.1654 -0.973724 6.83497 -0.978072 2.93062 2.93062C-0.978072 6.83497 -0.973724 13.1654 2.93062 17.0698C6.83497 20.9741 13.1698 20.9741 17.0741 17.0698C20.9785 13.1654 20.9785 6.83062 17.0741 2.92628L17.0698 2.93062ZM11.3045 14.348C11.3045 15.0698 10.7219 15.6524 10.0002 15.6524C9.27845 15.6524 8.69584 15.0698 8.69584 14.348V9.13062C8.69584 8.40889 9.27845 7.82628 10.0002 7.82628C10.7219 7.82628 11.3045 8.40889 11.3045 9.13062V14.348ZM9.97845 6.90019C9.22628 6.90019 8.72628 6.36975 8.74367 5.71323C8.72628 5.02628 9.23062 4.50889 9.99584 4.50889C10.7611 4.50889 11.248 5.02628 11.2654 5.71323C11.2654 6.36975 10.7654 6.90019 9.9828 6.90019H9.97845Z'
        fill={fillColor}
      />
    </g>
    <defs>
      <clipPath id='clip0_212_2051'>
        <rect height={height} width={width} fill={strokeColor} />
      </clipPath>
    </defs>
  </svg>
);

InfoIcon.propTypes = {
  style: object,
  className: string,
  height: number,
  width: number,
};

export default InfoIcon;
