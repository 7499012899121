const AskQuestion = ({
  ...rest
}) => (
  <svg
    width="18" 
    height="18" 
    viewBox="0 0 18 18" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <g clipPath="url(#clip0_6621_9683)">
      <path d="M8.99999 0C4.10246 0 -5.23683e-06 3.51562 -5.23683e-06 7.96154C-5.23683e-06 10.0601 0.9411 11.9423 2.42307 13.3486C2.29326 13.692 2.0634 14.1626 1.66586 14.6466C1.1899 15.2281 0.635512 15.7635 0.25961 16.0745C0.0297424 16.2584 -0.0595004 16.5667 0.0378553 16.8453C0.135211 17.1211 0.397531 17.3077 0.692302 17.3077C2.22566 17.3077 3.32902 17.2915 4.32692 17.0264C5.21935 16.7912 5.98737 16.2936 6.77163 15.5986C7.48557 15.7689 8.22115 15.9231 8.99999 15.9231C13.8975 15.9231 18 12.4075 18 7.96154C18 3.51562 13.8975 0 8.99999 0ZM8.99999 1.38462C13.2404 1.38462 16.6154 4.3756 16.6154 7.96154C16.6154 11.5475 13.2404 14.5385 8.99999 14.5385C8.24549 14.5385 7.46394 14.4087 6.74999 14.2139C6.51742 14.1544 6.27403 14.2221 6.10096 14.387C5.32211 15.1334 4.74879 15.4823 3.98076 15.6851C3.6265 15.7797 2.94771 15.7554 2.46634 15.7933C2.5637 15.6851 2.65294 15.6256 2.74759 15.512C3.29116 14.8468 3.78876 14.1436 3.93749 13.3486C3.98076 13.1052 3.88882 12.8591 3.69951 12.6995C2.24999 11.5015 1.38461 9.82211 1.38461 7.96154C1.38461 4.3756 4.75961 1.38462 8.99999 1.38462ZM7.68028 4.08894C7.55859 4.11599 7.44501 4.17548 7.35576 4.26202L6.3173 5.23558L7.24759 6.2524L8.06971 5.47356H9.73557L10.3846 6.20913V7.22596L8.61057 8.41586C8.41856 8.54838 8.30498 8.76743 8.30769 9V10.3846H9.6923V9.38942L11.4663 8.19952C11.6583 8.06701 11.7719 7.84796 11.7692 7.61538V5.94952C11.7692 5.78185 11.707 5.61959 11.5961 5.49519L10.5577 4.32692C10.4279 4.17548 10.2386 4.08894 10.0385 4.08894H7.83173C7.78034 4.08353 7.73167 4.08353 7.68028 4.08894ZM8.30769 11.0769V12.4615H9.6923V11.0769H8.30769Z" fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0_6621_9683">
        <rect width="18" height="18" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);

export default AskQuestion;