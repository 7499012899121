import { forwardRef } from 'react';
import { IMaskInput } from 'react-imask';

const CustomInput = (props, ref) => {
  const { onChange, ...restProps } = props;

  return (
    <IMaskInput
      {...restProps}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
};

const MaskedInput = forwardRef(CustomInput);

export { MaskedInput };
