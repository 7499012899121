import { number, object, string } from 'prop-types';

const RevertIcon = ({
  className = '',
  style = {},
  width = 28,
  height = 28,
  fillColor = '#666666',
  ...rest
}) => (
  <svg
    {...rest}
    className={className}
    style={style}
    width={width}
    height={height}
    viewBox='0 0 37 37'
  >
    <rect
      x='36.7695'
      y='18.3848'
      width='26'
      height='26'
      rx='13'
      transform='rotate(135 36.7695 18.3848)'
      fill={fillColor}
    />
    <g clipath='url(#clip0_7137_8617)'>
      <path
        d='M21.2029 12H19.4838C18.8503 12 18.3162 12.5345 18.2949 13.1918C18.2717 13.8742 18.7756 14.4475 19.4204 14.4721C19.4343 14.4727 19.4485 14.473 19.4625 14.473H21.2521C22.5854 14.4747 23.6649 15.6199 23.6633 17.0308C23.6633 17.0421 23.6633 17.0534 23.663 17.0647C23.6431 18.4501 22.5419 19.577 21.2114 19.577H15.5239L16.2233 18.4397C16.5701 17.8724 16.423 17.1159 15.893 16.7394L15.8859 16.7342C15.3503 16.3559 14.6259 16.5087 14.2685 17.0757C14.2674 17.0777 14.2661 17.0794 14.265 17.0815L12.0447 20.6534C11.984 20.7509 11.9848 20.8774 12.0463 20.974L14.2647 24.4579C14.6259 25.0223 15.3511 25.1699 15.8846 24.7876C16.4178 24.4053 16.5572 23.6377 16.196 23.0731L15.5378 22.05H21.2527C23.8777 22.0469 26.0031 19.7918 25.9998 17.0134C25.9998 17.0077 25.9998 17.0016 25.9998 16.9958C25.9848 14.2412 23.8328 12 21.2029 12Z'
        fill='white'
      />
    </g>
    <defs>
      <clipPath id='clip0_7137_8617'>
        <rect width='14' height='13' fill='white' transform='translate(12 12)' />
      </clipPath>
    </defs>
  </svg>
);

RevertIcon.propTypes = {
  style: object,
  className: string,
  height: number,
  width: number,
  fillColor: string,
};

export default RevertIcon;
