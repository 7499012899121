import { Drawer, Grid, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';

import closeIcon from 'assets/images/icon-close.svg';
const useStyles = makeStyles((theme) => ({
  drawerContainer: {
    backgroundColor: theme.modalDarkBackground,
    flexGrow: 1,
  },
  divider: {
    backgroundColor: theme.grey[800],
    height: 2,
    marginLeft: 40,
    marginRight: 40,
  },
  closeBtn: {
    position: 'absolute',
    right: 20,
    top: 20,
    cursor: 'pointer',
  },
  childrenWrap: {
    padding: 40,
  },
  headerWrap: {
    padding: 40,
    paddingTop: 30,
    paddingBottom: 10,
    paddingRight: 60,
  },
  drawer: {
    maxWidth: 600,
    color: '#FFF',
  },
}));

export const AsqDrawer = ({ isOpen, setIsOpen, header, children }) => {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('xs'));

  const classes = useStyles();

  return (
    <Drawer
      open={isOpen}
      onClose={() => setIsOpen(false)}
      anchor={isMobile ? 'top' : 'right'}
      transitionDuration={400}
      className={classes.drawer}
      classes={{
        paper: classes.drawer,
      }}
    >
      <Grid className={classes.drawerContainer}>
        <div className={classes.closeBtn}>
          <img src={closeIcon} alt='close' onClick={() => setIsOpen(false)} />
        </div>
        <div className={classes.headerWrap}>{header}</div>
        <div className={classes.divider}></div>
        <div className={classes.childrenWrap}>{children}</div>
      </Grid>
    </Drawer>
  );
};
