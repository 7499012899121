const PaperPlaneAlt = ({
  fillColor = '#666666',
  ...rest
}) => (
  <svg
    {...rest}
    width="16" 
    height="18" 
    viewBox="0 0 16 18" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_6358_2080)">
      <path d="M15.3204 -3.02121e-06C15.2402 0.0108143 15.1647 0.0405619 15.094 0.0865354L0.601958 9.08654C0.392032 9.21905 0.271737 9.48407 0.297683 9.75721C0.32127 10.0303 0.486381 10.2602 0.715177 10.3413L4.56462 11.6611C4.65425 12.4778 5.05523 16.0123 5.13071 16.7019C5.20619 17.3888 5.61189 17.5078 6.01759 16.875C6.2912 16.4477 7.90457 13.8462 7.90457 13.8462L11.3389 17.7837C11.4898 17.9567 11.7092 18.0243 11.9144 17.9621C12.122 17.8999 12.2824 17.716 12.339 17.4808L15.962 0.865382C16.0162 0.646331 15.9691 0.411055 15.8417 0.237978C15.712 0.0649008 15.5185 -0.024342 15.3204 -3.02121e-06ZM14.4524 2.03365L11.4144 15.9663L7.99892 12.0505L12.9617 4.15384L5.24393 10.4712L2.43233 9.51923L14.4524 2.03365Z" fill={fillColor}/>
    </g>
      <defs>
      <clipPath id="clip0_6358_2080">
        <rect width="15.6997" height="18" fill="white" transform="translate(0.281128)"/>
      </clipPath>
    </defs>
  </svg>
);

export default PaperPlaneAlt;