import { number, object, string } from 'prop-types';

const StarIcon = ({
  className = '',
  style = {},
  width = 23,
  height = 23,
  strokeColor = '#C1C1C1',
  fillColor = '#C1C1C1',
  ...rest
}) => (
  <svg
    {...rest}
    className={className}
    style={style}
    width={width}
    height={height}
    viewBox='0 0 23 23'
    fill='none'
  >
    <g clipPath='url(#clip0_5876_5789)'>
      <path
        d='M11.4998 2.23577L13.9602 7.22941L14.3784 8.07803L15.3133 8.21392L20.8153 9.01484L16.8343 12.902L16.1578 13.5626L16.3177 14.4953L17.2579 19.9839L12.3367 17.3926L11.5007 16.9521L10.6647 17.3926L5.74355 19.9839L6.68331 14.4949L6.84278 13.5621L6.16626 12.9016L2.18529 9.01439L7.68732 8.21347L8.62214 8.07758L9.04036 7.22896L11.5007 2.23532M11.5007 -0.000526604C10.9275 -0.000526604 10.4073 0.387788 10.0736 1.06453L7.42902 6.43208L1.51505 7.29285C0.769348 7.40129 0.239719 7.77701 0.0627266 8.32326C-0.114266 8.86951 0.0932735 9.48505 0.633234 10.012L4.91249 14.1903L3.9022 20.0901C3.76923 20.8663 3.99519 21.3302 4.20767 21.5831C4.45653 21.8787 4.8195 22.0416 5.22964 22.0416C5.5396 22.0416 5.87023 21.9507 6.21118 21.7707L11.5007 18.985L16.7903 21.7707C17.1317 21.9502 17.4619 22.0416 17.7718 22.0416C18.182 22.0416 18.5449 21.8787 18.7938 21.5831C19.0063 21.3307 19.2322 20.8663 19.0993 20.0901L18.089 14.1903L22.3682 10.012C22.9077 9.48505 23.1157 8.86951 22.9387 8.32326C22.7617 7.77701 22.2321 7.40129 21.4864 7.29285L15.5725 6.43208L12.9275 1.06408C12.5937 0.387338 12.0739 -0.000976562 11.5003 -0.000976562L11.5007 -0.000526604Z'
        fill={strokeColor}
      />
    </g>
    <defs>
      <clipPath id='clip0_5876_5789'>
        <rect width='23' height='22.0417' fill={fillColor} />
      </clipPath>
    </defs>
  </svg>
);

StarIcon.propTypes = {
  style: object,
  className: string,
  height: number,
  width: number,
  fillColor: string,
  strokeColor: string,
};

export default StarIcon;
