const ReceiveAnswer = ({
  ...rest
}) => (
  <svg 
    width="50" 
    height="50" 
    viewBox="0 0 50 50" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <rect x="1.17188" y="1.17188" width="47.6562" height="47.6562" rx="23.8281" fill="#495EFF" stroke="#495EFF" strokeWidth="2.34375"/>
      <g clipPath="url(#clip0_7252_854)">
      <path d="M25.6525 28.8384C25.4393 28.9719 25.2002 29.0251 24.987 29.0251C24.7738 29.0251 24.5346 28.9719 24.3214 28.8384L13.7255 22.3478V30.9751C13.7255 32.8182 15.2162 34.3138 17.0533 34.3138H32.9467C34.7837 34.3138 36.2745 32.8182 36.2745 30.9751V22.3478L25.652 28.8384H25.6525Z" fill="white"/>
      <path d="M32.9466 17.6471H17.0532C15.4824 17.6471 14.1513 18.7687 13.832 20.2648L25.0135 27.1023L36.1684 20.2648C35.8491 18.7692 34.518 17.6471 32.9472 17.6471H32.9466Z" fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0_7252_854">
        <rect width="22.549" height="16.6667" fill="white" transform="translate(13.7255 17.6471)"/>
      </clipPath>
    </defs>
  </svg>
);

export default ReceiveAnswer;