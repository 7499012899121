import { number, object, string } from 'prop-types';
import common from '@material-ui/core/colors/common';

const TipChargeIcon = ({
  className = '',
  style = {},
  width = 34,
  height = 38,
  fillColor = '#898A8D',
  strokeColor = common.black,
  ...restProps
}) => (
  <svg
    {...restProps}
    style={style}
    className={className}
    width={width}
    height={height}
    fill='none'
    viewBox='0 0 34 38'
  >
    <g clipPath='url(#clip0_2969_2007)'>
      <path
        d='M23.76 12.2816C26.9012 7.32196 23.58 0.561152 17.72 0.032437C11.26 -0.547525 6.74998 6.77074 10.2412 12.2816H14.0512C13.6312 11.9217 13.3225 11.4617 13.1525 10.9417C12.7525 9.7118 14.6525 9.09059 15.0512 10.3105C15.18 10.7217 15.57 11.0017 16 11.0017H18C19.29 11.0017 19.29 9.00185 18 9.00185H16C12.13 9.00185 12.13 3.00224 16 3.00224C16 1.71233 18 1.71233 18 3.00224C19.3 3.00224 20.45 3.83219 20.85 5.06211C21.25 6.29203 19.35 6.91323 18.9512 5.69331C18.82 5.28209 18.43 5.00211 18 5.00211H16C14.71 5.00211 14.71 7.00198 16 7.00198H18C20.73 7.00198 22.02 10.5117 19.95 12.2816H23.76Z'
        fill={fillColor}
      />
      <path
        d='M0 15.2814V18.2812H34V15.2814C34 14.7315 33.55 14.2815 33 14.2815H1C0.45 14.2815 0 14.7315 0 15.2814Z'
        fill={fillColor}
      />
      <path
        d='M32 35.0003V20.2812H2V35.0003C2 36.6602 3.34 38.0001 5 38.0001H29C30.66 38.0001 32 36.6602 32 35.0003Z'
        fill={fillColor}
      />
    </g>
    <defs>
      <clipPath id='clip0_2969_2007'>
        <rect width={width} height={height} fill='none' />
      </clipPath>
    </defs>
  </svg>
);

TipChargeIcon.propTypes = {
  style: object,
  className: string,
  height: number,
  width: number,
  strokeColor: string,
  fillColor: string,
};

export default TipChargeIcon;
