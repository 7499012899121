import { number, object, string } from 'prop-types';
import common from '@material-ui/core/colors/common';

const AmountChargeIcon = ({
  className = '',
  style = {},
  width = 46,
  height = 39,
  fillColor = '#898A8D',
  strokeColor = common.black,
  ...restProps
}) => (
  <svg
    {...restProps}
    style={style}
    className={className}
    width={width}
    height={height}
    fill='none'
    viewBox='0 0 46 39'
  >
    <g clipPath='url(#clip0_2941_5555)'>
      <path
        d='M38.9036 21.736L31.949 28.5893C31.0949 29.43 29.9608 29.8929 28.7549 29.8929H23.5363C22.7618 29.8929 22.0956 29.3063 22.0592 28.5449C22.0199 27.7316 22.6784 27.059 23.4951 27.059H28.152C29.8535 27.059 31.2319 25.6997 31.2319 24.0239C31.2319 22.3481 29.8525 20.9888 28.152 20.9888H18.7741C14.4115 20.9888 13.0302 24.6096 12.8011 25.5419H10.5762V35.964H30.4037C32.1915 35.964 33.8729 35.2773 35.1382 34.0313L45.5773 23.7433C46.1237 23.2049 46.1572 22.3094 45.5706 21.7322C43.6591 19.8534 40.6913 19.9724 38.9026 21.7351L38.9036 21.736Z'
        fill={fillColor}
      />
      <path
        d='M6.26249 22.5068H1.43789C0.644175 22.5068 0 23.1416 0 23.9238V37.5831C0 38.3653 0.644175 39.0001 1.43789 39.0001H6.26249C7.0562 39.0001 7.70038 38.3653 7.70038 37.5831V23.9238C7.70038 23.1416 7.0562 22.5068 6.26249 22.5068Z'
        fill={fillColor}
      />
      <path
        d='M38.3072 17.932C42.9603 10.6905 38.0408 0.820027 29.3588 0.0473193C19.7882 -0.799069 13.1068 9.88564 18.2784 17.932H23.9226C23.3005 17.4068 22.8423 16.7352 22.5911 15.9757C21.9987 14.1799 24.8131 13.2731 25.4046 15.0547C25.5953 15.6545 26.1734 16.0635 26.8108 16.0635H29.7738C31.6853 16.0635 31.6853 13.1437 29.7738 13.1437H26.8108C21.0775 13.1437 21.0775 4.38412 26.8108 4.38412C26.8108 2.50052 29.7738 2.50052 29.7738 4.38412C31.6997 4.38412 33.404 5.59608 33.9965 7.39182C34.5889 9.18756 31.7744 10.0944 31.183 8.31283C30.9893 7.71299 30.4113 7.30397 29.7738 7.30397H26.8108C24.8994 7.30397 24.8994 10.2238 26.8108 10.2238H29.7738C33.8182 10.2238 35.7296 15.3484 32.663 17.932H38.3072Z'
        fill={fillColor}
      />
    </g>
    <defs>
      <clipPath id='clip0_2941_5555'>
        <rect width={width} height={height} fill='none' />
      </clipPath>
    </defs>
  </svg>
);

AmountChargeIcon.propTypes = {
  style: object,
  className: string,
  height: number,
  width: number,
  strokeColor: string,
  fillColor: string,
};

export default AmountChargeIcon;
