import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

const ProfileFooter = ({
  classes,
  dirty,
  logoUploadIsLoading,
  saveProfileIsLoading,
  setDiscardChangesModal,
  setPreviewProfileDrawer,
}) => {
  return (
    <>
      <Hidden smDown>
        <Grid container direction='column' className={classes.spacing}>
          <Grid item>
            <hr className={classes.footerHr} />
          </Grid>
          <Grid item container alignItems='center' className={classes.spacing}>
            <Grid item xs={4}>
              <Button
                disabled={logoUploadIsLoading}
                className={classes.previewBtn}
                variant='outlined'
                fullWidth
                size='large'
                onClick={() => setPreviewProfileDrawer(true)}
              >
                Preview Profile
              </Button>
            </Grid>
            <Grid item xs />
            <Grid item xs>
              <Button
                disabled={logoUploadIsLoading}
                className={classes.discardBtn}
                fullWidth
                size='medium'
                onClick={() => {
                  if (dirty) setDiscardChangesModal(true);
                }}
              >
                Discard Changes
              </Button>
            </Grid>
            <Grid item xs />
            <Grid item xs={4}>
              <Button
                disabled={logoUploadIsLoading || saveProfileIsLoading}
                className={classes.saveBtn}
                type='submit'
                variant='outlined'
                fullWidth
                size='large'
              >
                Save Profile
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <Grid item container className={classes.spacing}>
          <Grid item xs />
          <Grid item xs={10}>
            <Grid container direction='column' className={classes.btnContainer}>
              <Grid item>
                <Button
                  disabled={logoUploadIsLoading}
                  className={classes.previewBtn}
                  variant='outlined'
                  fullWidth
                  size='large'
                  onClick={() => setPreviewProfileDrawer(true)}
                >
                  Preview Profile
                </Button>
              </Grid>
              <Grid item className={classes.smallFooterSpacing}>
                <Button
                  disabled={logoUploadIsLoading || saveProfileIsLoading}
                  className={classes.saveBtn}
                  type='submit'
                  variant='outlined'
                  fullWidth
                  size='large'
                >
                  Save Profile
                </Button>
              </Grid>
              <Grid item className={classes.smallFooterSpacing}>
                <Button
                  disabled={logoUploadIsLoading}
                  className={classes.discardBtn}
                  fullWidth
                  size='medium'
                  onClick={() => {
                    if (dirty) setDiscardChangesModal(true);
                  }}
                >
                  Discard Changes
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs />
        </Grid>
      </Hidden>
    </>
  );
};

export default ProfileFooter;
