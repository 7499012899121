import { number, object, string } from 'prop-types';
import common from '@material-ui/core/colors/common';

const ZeroChargeIcon = ({
  className = '',
  style = {},
  width = 34,
  height = 34,
  fillColor = '#898A8D',
  strokeColor = common.black,
  ...restProps
}) => (
  <svg
    {...restProps}
    style={style}
    className={className}
    width={width}
    height={height}
    fill='none'
    viewBox='0 0 34 34'
  >
    <g clipPath='url(#clip0_2969_2014)'>
      <path
        d='M17 0C7.61098 0 0 7.61098 0 17C0 26.389 7.61098 34 17 34C26.389 34 34 26.389 34 17C34 7.61098 26.3879 0 17 0ZM17 30.0509C9.79214 30.0509 3.94906 24.2079 3.94906 17C3.94906 9.79214 9.79214 3.94906 17 3.94906C24.2079 3.94906 30.0509 9.79214 30.0509 17C30.0509 24.2079 24.2079 30.0509 17 30.0509Z'
        fill={fillColor}
      />
      <path
        d='M15.2547 14.8505H18.7353C19.2071 14.8505 19.6451 14.9034 20.0516 14.9991C21.2982 15.3223 22.2992 16.0981 22.9704 17.0936C24.1854 19.0585 24.0435 21.868 22.5447 23.6348C22.4175 23.7688 22.2823 23.8983 22.1371 24.0233H22.1754C21.3477 24.7969 20.2014 25.2924 18.7353 25.2924C18.7353 27.5377 15.2547 27.5377 15.2547 25.2924C13.9653 25.2924 12.7616 24.8228 11.8348 24.0233H11.8709C11.1401 23.3972 10.6018 22.5966 10.3068 21.6912C10.112 21.0933 10.2302 20.5787 10.5162 20.1936C11.2617 19.2128 13.1017 19.0878 13.5994 20.6091C13.8268 21.3241 14.5058 21.8117 15.2547 21.8117H18.7353C19.4436 21.8117 19.9278 21.4649 20.189 20.992C20.7712 19.9639 20.2892 18.3153 18.7443 18.3153H15.2637C14.7919 18.3153 14.3538 18.2624 13.9473 18.1667C12.7008 17.8435 11.6997 17.0677 11.0286 16.0722C9.81358 14.1073 9.95546 11.2978 11.4542 9.53101C11.5815 9.39701 11.7166 9.26752 11.8619 9.14252H11.8236C12.6512 8.36893 13.7976 7.87346 15.2637 7.87346C15.2637 5.62812 18.7443 5.62812 18.7443 7.87346C20.0336 7.87346 21.2374 8.34303 22.1641 9.14252H22.1281C22.8589 9.76861 23.3971 10.5692 23.6922 11.4746C23.887 12.0725 23.7687 12.5871 23.4827 12.9722C22.7373 13.953 20.8973 14.078 20.3996 12.5567C20.1721 11.8417 19.4931 11.3541 18.7443 11.3541H15.2637C14.5554 11.3541 14.0712 11.7009 13.8099 12.1739C13.2278 13.2019 13.7097 14.8505 15.2547 14.8505Z'
        fill={fillColor}
      />
      <path
        d='M8.32682 4.03561L5.45081 6.91162L27.8554 29.3162L30.7314 26.4402L8.32682 4.03561Z'
        fill={fillColor}
      />
    </g>
    <defs>
      <clipPath id='clip0_2969_2014'>
        <rect width={width} height={height} fill='none' />
      </clipPath>
    </defs>
  </svg>
);

ZeroChargeIcon.propTypes = {
  style: object,
  className: string,
  height: number,
  width: number,
  strokeColor: string,
  fillColor: string,
};

export default ZeroChargeIcon;
