import { Button, CircularProgress, makeStyles } from '@material-ui/core';
import { commonBtnStyles } from 'assets/styles/common';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  asqBtn: {
    padding: '7px 14px',
    ...commonBtnStyles,
    backgroundColor: theme.blue,
    '& *': {
      fontFamily: 'FigTree-Regular',
    },
    '&.Mui-disabled': {
      opacity: 0.7,
    },
    '& .MuiButton-label': {
      fontFamily: 'Figtree-Regular',
      color: theme.common.white,
      fontSize: '1.1rem',
    },
    '&:hover': {
      backgroundColor: theme.blue,
      opacity: 0.85,
      '&.Mui-disabled': {
        backgroundColor: theme.blue,
        opacity: 0.7,
      },
    },
  },
}));

export default function AsqButton(props) {
  const classes = useStyles();
  const { extraClassName, isLoading, children, ...otherProps } = props;
  return (
    <Button className={`${classes.asqBtn} ${extraClassName}`} disabled={isLoading} {...otherProps} component={props.to ? Link : undefined}>
      {isLoading ? <CircularProgress color='inherit' size={31} /> : children}
    </Button>
  );
}
