import { popUpFeatures } from 'utils/common/constants';

// Features per price selection at informational popups
const featuresPerPriceTypes = {
  features: [
    popUpFeatures.tipJar,
    popUpFeatures.complimentaryAnswers,
    popUpFeatures.noRevshare,
    popUpFeatures.zeroFees,
    popUpFeatures.translation,
    popUpFeatures.qna,
    popUpFeatures.clips,
  ],
};

export { featuresPerPriceTypes };
