import { commonBtnStyles } from 'assets/styles/common';

const styles = (theme) => ({
  previewContainer: {
    height: '100%',
    backgroundColor: '#FAFAFA',
    position: 'relative',
  },
  innerContainer: {
    maxWidth: 454,
    [theme.breakpoints.down('xs')]: {
      padding: '0 20px',
    },
  },
  formContainer: {
    height: '100%',
    width: '100%',
  },
  drawerContainer: {
    height: '100%',
  },
  headerContainer: {
    height: 100,
    marginBottom: 60,
  },
  header: {
    backgroundColor: theme.blue,
    paddingBottom: 0,
  },
  previewHeaderText: {
    backgroundColor: theme.common.black,
    color: theme.common.white,
    fontFamily: 'Figtree-Bold',
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1.5)}px`,
    borderRadius: 10,
  },
  headerLogosContainer: {
    backgroundColor: theme.blue,
    width: '100%',
    height: 80,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  introSectionContainer: {
    paddingBottom: 40,
  },
  ratingContainer: {
    marginBottom: 10,
    '& span': {
      fontFamily: 'Figtree-Regular',
      fontSize: 16,
      lineHeight: '19.2px',
      color: '#161616'
    },
    '& svg': {
      width: 18,
      height: 18,
      marginBottom: 4,
      marginRight: 5,
    }
  },
  creatorDescription: {
    fontFamily: 'Figtree-Regular',
    fontSize: 14,
    lineHeight: '14.4px',
    marginBottom: 20,
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    }
  },
  hr: {
    backgroundColor: '#B1B1B1',
    height: 1,
    border: 0,
    maxWidth: 314,
    width: '100%',
  },
  previewFooter: {
    backgroundColor: theme.common.black,
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(0.5)}px ${theme.spacing(
      2,
    )}px`,
  },
  customerFooter: {
    margin: '20px 0',
  },
  saveBtn: {
    ...commonBtnStyles,
    height: 44,
    backgroundColor: theme.blue,
    '&.Mui-disabled': {
      opacity: 0.7,
    },
    '&:hover': {
      backgroundColor: theme.blue,
      opacity: 0.85,
      '&.Mui-disabled': {
        backgroundColor: theme.blue,
        opacity: 0.7,
      },
    },
  },
  saveBtnLabel: {
    '& span:nth-child(1)': {
      position: 'relative',
      marginRight: 10,
      top: 4,
    },
    '& span:nth-child(2)': {
      fontFamily: 'Figtree-Bold',
      color: theme.common.white,
      fontSize: 16,
      lineHeight: '19.2px',
    },
  },
  joinAsqContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 40,
  },
  joinAsqBtn: {
    ...commonBtnStyles,
    border: `1px solid #495EFF`,
    color: '#495EFF',
    fontSize: 12,
    padding: '4px 8px',
    '&:hover': {
      borderColor: '#000543',
      color: '#000543',
      backgroundColor: 'transparent',
    },
  },
  link: {
    marginTop: theme.spacing(0.8),
    color: theme.common.white,
    fontSize: '1.3rem',
  },
  chainlinkIcon: {
    cursor: 'pointer',
  },
  tag: {
    color: theme.blue,
  },
});

export default styles;
