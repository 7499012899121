import { number, object, string } from 'prop-types';
import common from '@material-ui/core/colors/common';

const CoffeIcon = ({
  className = '',
  style = {},
  width = 80,
  height = 71,
  fillColor = common.white,
  strokeColor = common.black,
  ...restProps
}) => (
  <svg
    {...restProps}
    style={style}
    className={className}
    width={width}
    height={height}
    viewBox='0 0 80 71'
    fill='none'
  >
    <rect
      x='6.5'
      y='1.5'
      width='68'
      height='68'
      rx='34'
      fill={fillColor}
      stroke={strokeColor}
      strokeWidth='3'
    />
    <g clipPath='url(#clip0_1805_7364)'>
      <path
        d='M25 21.2571V23.471C25 24.2065 25.6157 24.8076 26.3728 24.8076H54.6272C55.3843 24.8076 56 24.2065 56 23.471V21.2571C56 20.5217 55.3843 19.9205 54.6272 19.9205H26.3728C25.6157 19.9205 25 20.5217 25 21.2571Z'
        fill={strokeColor}
      />
      <path
        d='M29.223 19.108H51.781L50.5083 17.0848C50.0879 16.4143 49.3308 16 48.5276 16H32.4764C31.6732 16 30.9122 16.4143 30.4919 17.0848L29.223 19.108Z'
        fill={strokeColor}
      />
      <path
        d='M27.2298 25.6201C31.2895 59.2951 30.398 51.9011 30.5292 52.989C30.6668 54.1349 31.6606 55 32.8427 55H48.1611C49.3385 55 50.3331 54.1349 50.4698 52.989C50.6011 51.9004 49.7096 59.2951 53.7693 25.6201H27.229H27.2298Z'
        fill={strokeColor}
      />
    </g>
    <defs>
      <clipPath id='clip0_1805_7364'>
        <rect width='31' height='39' fill='white' transform='matrix(-1 0 0 1 56 16)' />
      </clipPath>
    </defs>
  </svg>
);

CoffeIcon.propTypes = {
  style: object,
  className: string,
  height: number,
  width: number,
  strokeColor: string,
  fillColor: string,
};

export default CoffeIcon;
