import { number, object, string } from 'prop-types';
import common from '@material-ui/core/colors/common';

const PizzaSliceIcon = ({
  className = '',
  style = {},
  width = 80,
  height = 71,
  fillColor = common.white,
  strokeColor = common.black,
  ...restProps
}) => (
  <svg
    {...restProps}
    style={style}
    className={className}
    width={width}
    height={height}
    viewBox='0 0 80 71'
    fill={fillColor}
  >
    <rect x='6.5' y='1.5' width='68' height='68' rx='34' stroke={strokeColor} strokeWidth='3' />
    <g clipPath='url(#clip0_1805_7372)'>
      <path
        d='M43.2622 42.4501C43.2622 43.6727 43.9237 44.7777 44.9695 45.3939L48.5594 39.4306C48.0355 39.1511 47.4428 39.0016 46.8336 39.0016C44.8643 39.0016 43.2622 40.5489 43.2622 42.4508V42.4501Z'
        fill={strokeColor}
      />
      <path
        d='M34.1158 40.0973C36.0878 40.0973 37.6865 38.5534 37.6865 36.6489C37.6865 34.7443 36.0878 33.2004 34.1158 33.2004C32.1438 33.2004 30.5452 34.7443 30.5452 36.6489C30.5452 38.5534 32.1438 40.0973 34.1158 40.0973Z'
        fill={strokeColor}
      />
      <path
        d='M57.9583 24.0987C57.8174 23.3085 57.3306 22.6186 56.6223 22.2055C51.902 19.4543 46.5003 18 41.0003 18C35.5002 18 30.0985 19.4543 25.3782 22.2055C24.67 22.6186 24.1825 23.3085 24.0422 24.0987C23.9006 24.895 24.1233 25.7098 24.6535 26.334C25.5652 27.4071 27.1336 27.6595 28.3837 26.933C32.1963 24.7183 36.5591 23.5476 41.0003 23.5476C45.4414 23.5476 49.8042 24.7183 53.6169 26.933C54.8669 27.6588 56.4353 27.4071 57.3471 26.334C57.8772 25.7098 58.1 24.895 57.9583 24.0987Z'
        fill={strokeColor}
      />
      <path
        d='M51.9334 28.285C51.6618 31.0382 49.2538 33.1991 46.333 33.1991C43.2305 33.1991 40.7064 30.7613 40.7064 27.765C40.7064 26.9828 40.8776 26.2264 41.2008 25.5345C41.1341 25.5345 41.0667 25.5325 41 25.5325C36.9322 25.5325 32.9353 26.6049 29.4423 28.6336C28.7925 29.0108 28.0671 29.2379 27.3252 29.3043L29.6816 33.314C30.7123 32.037 32.3178 31.2135 34.1207 31.2135C37.2231 31.2135 39.7472 33.6513 39.7472 36.6475C39.7472 39.3921 37.6294 41.6659 34.8901 42.0298L40.9931 51.9993L43.9367 47.1092C42.2665 46.143 41.207 44.3905 41.207 42.4495C41.207 39.4532 43.7311 37.0154 46.8335 37.0154C47.8092 37.0154 48.7567 37.2591 49.5921 37.7134L54.6549 29.303C53.9198 29.234 53.202 29.0075 52.5577 28.6336C52.3514 28.5141 52.1438 28.3979 51.9341 28.2843L51.9334 28.285Z'
        fill={strokeColor}
      />
      <path
        d='M49.9044 27.765C49.9044 27.6096 49.8927 27.4556 49.8714 27.3022C47.8416 26.4575 45.6936 25.9063 43.4981 25.6686C43.0209 26.2676 42.7617 26.9974 42.7617 27.765C42.7617 29.6669 44.3638 31.2142 46.3331 31.2142C48.3023 31.2142 49.9044 29.6669 49.9044 27.765Z'
        fill={strokeColor}
      />
    </g>
    <defs>
      <clipPath id='clip0_1805_7372'>
        <rect width='34' height='34' fill='white' transform='translate(24 18)' />
      </clipPath>
    </defs>
  </svg>
);

PizzaSliceIcon.propTypes = {
  style: object,
  className: string,
  height: number,
  width: number,
  strokeColor: string,
  fillColor: string,
};

export default PizzaSliceIcon;
