import { useMemo } from "react";
import { AppLogoIcon } from "components/Icons";
import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core';
import {
  SuccessIcon,
} from 'components/Icons';
import EditBtnImg from 'assets/images/edit-new-button.svg';

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    position: 'absolute',
    display: 'flex',
    height: 100,
    flexDirection: 'column',
    width: '100%',
    '&:before': {
      content: '""',
      position: 'absolute',
      backgroundColor: theme.blue,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderRadius: '50% / 100%',
      width: '115%',
      transform: 'translateX(-6.5%)',
      height: 100,
      zIndex: 1,
    },
    '& *': {
      zIndex: 2
    },
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 10,
    '& span:nth-child(1)': {
      flex: 1,
      display: 'inline-flex',
      alignItems: 'center',
      width: 37,
      height: 37,
      '& svg': {
        width: 37,
        height: 37,
      }
    },
    // Title
    '& span:nth-child(2)': {
      fontFamily: 'Figtree-Bold',
      fontSize: 16,
      lineHeight: '21.79px',
      color: 'white'
    },
    // Beta Icon
    '& span:nth-child(3)': {
      position: 'relative',
      fontFamily: 'Figtree-Light',
      fontSize: 16,
      lineHeight: 1,
      color: 'white',
      padding: '2px 10px',
      border: '1px solid #FFFFFF',
      borderRadius: 3,
      marginLeft: 10,
      bottom: 2,
    },
    '&:after': {
      content: '""',
      flex: 1,
    },
  },
  creatorProfilePicContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    //marginTop: 20,
    '& div': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 50,
      width: 88,
      height: 88,
      backgroundColor: theme.common.white,
      border: `3px solid ${theme.common.white}`,
      position: 'relative',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      '& svg': {
        width: '100%',
        height: '100%',
      },
    },
  },
  editBtnContainer: {
    justifyContent: 'end !important',
    alignItems: 'start !important',
    backgroundColor: 'black !important',
    '& img': {
      position: 'relative',
      bottom: 10
    },
  },
  editBtnImg: {
    cursor: 'pointer',
  },
  circularProgress: {
    position: 'absolute !important',
    zIndex: 4,
    color: theme.common.white,
    backgroundColor: 'transparent !important',
    border: '0 !important',
  },
}));

const AsqMeCreatorHeader = (props) => {
  const { 
    title,
    useLargeTitle,
    iconUrl, 
    showLogo, 
    showSuccessIcon,
    showBetaIcon,
    rootLogoProps, 
    inputLogoProps, 
    isPhotoUploadLoading 
  } = props;

  const classes = useStyles();

  const showEditButton = useMemo(() => {
    return inputLogoProps != null && inputLogoProps != null;
  }, [rootLogoProps, inputLogoProps]);

  return (
    <div className={classes.headerContainer}>
      <div className={classes.logoContainer}>
        <span>
          {showLogo && <AppLogoIcon strokeColor='white' />}
        </span>
        <span style={{ fontSize: useLargeTitle ? 24 : 16 }}>{title}</span>
        {showBetaIcon && <span>Beta</span>}
      </div>
      <div className={classes.creatorProfilePicContainer}>
        <div
          className={`${showEditButton ? classes.editBtnContainer : ''}`}
          style={{
            backgroundImage: `url(${iconUrl})`,
          }}
          {...rootLogoProps}
        >
          {showSuccessIcon && <SuccessIcon />}
          {showEditButton && (
            <>
              <img
                src={EditBtnImg}
                className={classes.editBtnImg}
                alt='edit button'
              />
              <input {...inputLogoProps} />
            </>
          )}
        </div>
        {isPhotoUploadLoading && <CircularProgress color='inherit' thickness={3} size={30} className={classes.circularProgress} />}
      </div>
    </div>
  )
}

export default AsqMeCreatorHeader;