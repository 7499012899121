import { number, object, string } from 'prop-types';
import common from '@material-ui/core/colors/common';

const CircleErrorIcon = ({
  className = '',
  style = {},
  width = 24,
  height = 24,
  fillColor = common.black,
  strokeColor = common.white,
  ...rest
}) => (
  <svg
    {...rest}
    style={style}
    className={className}
    height={height}
    width={width}
    viewBox='0 0 24 24'
    fill='none'
  >
    <g clipPath='url(#clip0_5876_3309)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.0005 0C5.39725 0 0 5.39725 0 12.0005C0 18.6038 5.39725 24.001 12.0005 24.001C18.6038 24.001 24.001 18.6038 24.001 12.0005C24.001 5.39725 18.6038 0 12.0005 0ZM17.433 15.2931C18.0234 15.8835 18.0234 16.8416 17.433 17.432C16.8426 18.0224 15.8845 18.0224 15.2941 17.432L12.0005 14.1384L8.70694 17.432C8.11654 18.0224 7.15839 18.0224 6.56799 17.432C5.97758 16.8416 5.97758 15.8835 6.56799 15.2931L9.86155 11.9995L6.56799 8.70593C5.97758 8.11553 5.97758 7.15738 6.56799 6.56698C7.15839 5.97658 8.11654 5.97658 8.70694 6.56698L12.0005 9.86054L15.2941 6.56698C15.8845 5.97658 16.8426 5.97658 17.433 6.56698C18.0234 7.15738 18.0234 8.11553 17.433 8.70593L14.1395 11.9995L17.433 15.2931Z'
        fill={fillColor}
      />
    </g>
    <defs>
      <clipPath id='clip0_5876_3309'>
        <rect width='24' height='24' fill={strokeColor} />
      </clipPath>
    </defs>
  </svg>
);

CircleErrorIcon.propTypes = {
  style: object,
  className: string,
  height: number,
  width: number,
  fillColor: string,
  strokeColor: string,
};

export default CircleErrorIcon;
