export const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  headingText: {
    fontFamily: 'Figtree-Bold',
    fontSize: 16,
    lineHeight: '19px',
    letterSpacing: '0em',
    textAlign: 'left',
    marginBottom: 40,
  },
  ratingText: {
    fontFamily: 'Figtree-Regular',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '19px',
    letterSpacing: '0em',
    textAlign: 'center',
    marginBottom: 20,
  },
  ratingContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    width: '100%',
    gap: 25,
  },
  starContainer: {
    position: 'relative',
    width: 34,
    height: 33,
    marginBottom: 60,
    '& svg': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: 34,
      height: 33,
    },
    '& svg:nth-child(2)': {
      transition: 'transform 0.3s ease',
      transform: 'scale(0)',
    },
  },
  starActive: {
    transform: 'scale(1) !important',
  }
});