const QuestionAnswered = ({
  ...rest
}) => (
  <svg 
    width="50" 
    height="50" 
    viewBox="0 0 50 50" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <rect x="1.17188" y="1.17188" width="47.6562" height="47.6562" rx="23.8281" fill="white" stroke="#495EFF" strokeWidth="2.34375"/>
    <path d="M31.5426 13H19.1809C15.7731 13 13 15.7731 13 19.1809V29.0702C13 32.478 15.7731 35.2511 19.1809 35.2511H20.417V39.042C20.417 39.5415 20.7178 39.9931 21.1802 40.1843C21.6359 40.3746 22.1708 40.2733 22.5276 39.9164L27.1105 35.2519H31.5434C34.9511 35.2519 37.8067 32.4788 37.8067 29.0711V19.1817C37.8067 15.774 34.9503 13 31.5426 13Z" fill="#495EFF"/>
    <path d="M31.5191 19.2291L30.4847 18.526C30.1985 18.3322 29.8063 18.4066 29.6148 18.6905L24.5442 26.168L22.214 23.8378C21.9706 23.5944 21.5739 23.5944 21.3305 23.8378L20.4449 24.7234C20.2015 24.9668 20.2015 25.3635 20.4449 25.6091L24.0281 29.1923C24.2287 29.3929 24.5442 29.5462 24.8281 29.5462C25.1121 29.5462 25.3983 29.3681 25.5831 29.0999L31.6859 20.0968C31.8797 19.8128 31.8053 19.423 31.5191 19.2291Z" fill="white"/>
  </svg>
);

export default QuestionAnswered;