import React, { useRef, useState, useEffect, useMemo } from "react";
import Slider from "react-slick";
import { useAlert } from "react-alert";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AsqMeCreatorHeader from "components/Header/AsqMeCreatorHeader";
import RateCreator from "./rate-creator";
import ThankCreator from "./thank-creator";
import TipCreator from "./tip-creator";
import GetSummary from "./get-summary";
import StarRating from "./star-rating";
import { ArrowLeftIcon, ArrowRightIcon, SpeechBubbleIcon, } from "components/Icons";
import AsqButton from "routes/Subscribe/asq-button";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useQuery, useMutation } from '@tanstack/react-query';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { completeThankBankSession, getThankBankSession, saveThankBankSession, sendTrackCallTB7 } from 'store/customer';
import { createThankBankPaymentIntent } from "store/payment";
import LogoBuyMeACoffee from "assets/images/logo-buy-me-coffee.png";
import LogoPayPal from "assets/images/logo-paypal.png";
import LogoCashApp from "assets/images/logo-cash-app.png";
import LogoVenmo from "assets/images/logo-venmo.png";
import { thankBankSessionCompleteStatus, thankBankPaymentMethods, thankBankSessionUpdateAction, defaultAlertError } from "utils/common/constants";
import { styles } from "./index.styles";
import ReturnToThankBankCard from "./return-to-thank-bank-card";

const useStyles = makeStyles(styles);

const ThankBank = (props) => {
  const { previewMode, user } = props;

  const classes = useStyles();
  const { breakpoints }  = useTheme();
  const mobileView = useMediaQuery(breakpoints.down('xs'));

  const alert = useAlert();

  const { code } = useParams();
  const [searchParams] = useSearchParams();

  const redirected_from = searchParams.get('redirected_from');

  let sliderRef = useRef(null)
  let scrollTimeoutRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0)
  const [carouselBreakPointsState, setCarouselBreakPointsState] = useState(null); // For Desktop only
  const [verticalOffset, setVerticalOffset] = useState(0) // For Desktop only
  const [isScrollingState, setIsScrollingState] = useState(false) // For Desktop only
  const [isScrollingDown, setIsScrollingDown] = useState(false) // For Desktop only
  const [showSummary, setShowSummary] = useState(false)
  const [returnToThankBankSlide, setReturnToThankBankSlide] = useState(null)

  const [isThankBankLoaded, setIsThankBankLoaded] = useState(false)
  const [thankBankResponses, setThankBankResponses] = useState(null)
  const [isRatingSubmitted, setIsRatingSubmitted] = useState(false)
  const [isThankYouSubmitted, setIsThankYouSubmitted] = useState(false)
  const [creatorData, setCreatorData] = useState(null)

  const [questionSession, setQuestionSession] = useState(null)

  const [tipPrice, setTipPrice] = useState(0)
  const [isTipPaid, setIsTipPaid] = useState(false)
  const [tipPaymentMethod, setTipPaymentMethod] = useState(null)
  const [customerPaymentMethod, setCustomerPaymentMethod] = useState(false)

  useEffect(() => {
    if (previewMode) {
      setCreatorData(user)

      setThankBankResponses({
        rating: null,
        is_rating_submitted: false,
        comment: null,
        is_comment_submitted: false,
      });

      setIsThankBankLoaded(true);
    }
  }, [previewMode])

  useQuery({
    queryKey: ['thank-bank-creator', code, redirected_from],
    queryFn: getThankBankSession,
    enabled: !previewMode,
    retry: 1,
    onSuccess: (data) => {
      setThankBankResponses(data.thankBankSession);
      setCreatorData(data.creator);
      setCustomerPaymentMethod(data.customer.payment_method);
      setQuestionSession(data.questionSession);
      setIsRatingSubmitted(data.thankBankSession.is_rating_submitted);
      setIsThankYouSubmitted(data.thankBankSession.is_comment_submitted);

      if (data.thankBankSession.is_tip_paid || data.thankBankSession.tip_payment_method) {
        if (data.thankBankSession.tip_price) {
          setTipPrice(Number(data.thankBankSession.tip_price).toFixed(0));
        }

        setIsTipPaid(data.thankBankSession.is_tip_paid);
        setTipPaymentMethod(data.thankBankSession.tip_payment_method);
      }

      if ([thankBankSessionCompleteStatus.allDoneCta, 
          thankBankSessionCompleteStatus.internalTip, 
          thankBankSessionCompleteStatus.externalTip,
          thankBankSessionCompleteStatus.allActionsComplete]
          .includes(data.thankBankSession.complete_status)) {
        setShowSummary(true);
      }

      setIsThankBankLoaded(true)
    },
    onError: (err) => {
      alert.error(null, defaultAlertError);
    },
  });

  const {
    mutate: onSaveSession,
  } = useMutation({
    mutationFn: saveThankBankSession,
    onError: (err) => {
      alert.error(null, defaultAlertError);
    },
  });

  const {
    mutate: onSendCompleteSession,
  } = useMutation({
    mutationFn: completeThankBankSession,
    onError: (err) => {
      alert.error(null, defaultAlertError);
    },
  });

  const {
    mutate: onTipPayment,
    isLoading: isTipPaymentLoading,
  } = useMutation({
    mutationFn: createThankBankPaymentIntent,
    onSuccess: (data) => {
      setIsTipPaid(true);
      updateResponsesSubmitStatus();
      setShowSummary(true);
    },
    onError: (err) => {
      alert.error(null, defaultAlertError);
    },
  });

  const maxSlides = useMemo(() => {
    return creatorData?.tip_jar_enabled ? 4 : 3;
  }, [creatorData])

  useEffect(() => {
    if ((!creatorData?.tip_jar_enabled || isTipPaid) && isRatingSubmitted && isThankYouSubmitted) {
      setShowSummary(true);
    }
  }, [isTipPaid, isRatingSubmitted, isThankYouSubmitted, creatorData])

  const ratingSubmitted = () => {
    const newResponses = { ...thankBankResponses, is_rating_submitted: true };
    setThankBankResponses(newResponses);
    setIsRatingSubmitted(true);

    onSaveSession({ code, data: { ...newResponses, action: thankBankSessionUpdateAction.submitRating }});

    // Let button animation play out before moving to next slide
    setTimeout(() => {
      nextSlide();
    }, 500)
  }

  const ratingChanged = (newRating) => {
    const newResponses = { ...thankBankResponses, rating: newRating };
    setThankBankResponses(newResponses);
    
    onSaveSession({ code, data: { ...newResponses, action: thankBankSessionUpdateAction.updateRating }});
  }

  const thankYouSubmitted = (goToNextSlide, title, customMessage) => {
    let newResponses = { ...thankBankResponses, is_comment_submitted: true };

    if (customMessage) {
      newResponses.comment = customMessage;
    }

    setThankBankResponses(newResponses);
    setIsThankYouSubmitted(true);

    onSaveSession({ code, data: { ...newResponses, comment_title: title, action: thankBankSessionUpdateAction.submitComment }});

    if (goToNextSlide) {
      // Let button animation play out before moving to next slide
      setTimeout(() => {
        nextSlide();
      }, 500)
    }
  }

  // The submit button animation plays out before the modal closes. This gets called after
  // the model is closed
  const onThankYouModalClosedAfterSubmit = () => {
    nextSlide();          
  }

  const thankYouChanged = (newThankYouMessage, title) => {
    const newResponses = { ...thankBankResponses, comment_title: title, comment: newThankYouMessage };
    setThankBankResponses(newResponses);

    onSaveSession({ code, data: newResponses });
  }

  const tipSubmitted = (method, tip_price, payment_type, card_prepopulated) => {
    if (method !== thankBankPaymentMethods.card) {
      onCompleteSession({ tip_payment_method: method, complete_status: thankBankSessionCompleteStatus.externalTip });
    }

    if (tip_price > 0) { 
      setTipPrice(tip_price)
      onTipPayment({ session_tracking_code: code, tip_price, payment_type, card_prepopulated });
    } else {
      setShowSummary(true);
    }

    setTipPaymentMethod(method);
  }

  const updateResponsesSubmitStatus = () => {
    if (thankBankResponses.rating > 0) {
      setIsRatingSubmitted(true);
    }

    if (thankBankResponses.comment) {
      setIsThankYouSubmitted(true);
    }
  }

  const onCompleteSession = (data) => {
    updateResponsesSubmitStatus();
    onSendCompleteSession({ code, data });
  }

  const onReturnToThankBank = (slide) => {
    setShowSummary(false);
    setReturnToThankBankSlide(slide)
  }

  // Slick carousel functions /////////////////////////////////////////////////

  // Vertical carousel scrolling does not work with variable card heights.
  // These functions manually scroll the carousel to the next or previous slide taking
  // into account the height of the cards. Use these functions instead of slickNext()
  // and slickPrev() when the carousel is vertical.
  const slickNextDesktop = () => {
    slickNextPrevDesktop('next')
  }

  const slickPrevDesktop = () => {
    slickNextPrevDesktop('prev')
  }

  const slickNextPrevDesktop = (destination) => {
    const track = document.querySelector('.slick-track'); 

    const slideIndex = destination === 'next'
      ? currentSlide
      : currentSlide - 1;

    const slideSelector = `.slick-slide[data-index="${slideIndex}"]`;
    const slideElement = document.querySelector(slideSelector);

    const height = slideElement.offsetHeight;
    const newOffset = destination === 'next'
      ? verticalOffset + height 
      : verticalOffset - height;

    track.style.transition = 'transform 500ms ease';
    track.style.transform = `translate3d(0px, -${newOffset}px, 0px)`;

    setVerticalOffset(newOffset);

    const newSlideIndex = destination === 'next'
      ? currentSlide + 1
      : currentSlide - 1;

    setCurrentSlide(newSlideIndex);
  }

  const slickGoToDesktop = (index) => {
    // setTimeout(() => {
      // Check if carousel slides are loaded
      const track = document.querySelector('.slick-track');
      if (!track) return;
  
      let offset = 0;
      for (let i = 0; i <= index - 1; i++) {
        const slideSelector = `.slick-slide[data-index="${i}"]`;
        const slideElement = document.querySelector(slideSelector);
        offset += slideElement.offsetHeight;
      }
  
      track.style.transition = 'transform 0ms ease';
      track.style.transform = `translate3d(0px, -${offset}px, 0px)`;
  
      setVerticalOffset(offset);
      setCurrentSlide(index);
  }

  // Cleanup for scroll timeout
  useEffect(() => {
    return clearTimeout(scrollTimeoutRef.current);
  }, [])

  // Reset carousel state when changing between mobile and desktop view
  useEffect(() => {
    if (mobileView) {
      document.documentElement.style.overscrollBehaviorY = 'auto';
    } else {
      // The overscroll behavior causes issues when scrolling through the carousel on desktop
      document.documentElement.style.overscrollBehaviorY = 'none';
    }

    const track = document.querySelector('.slick-track');

    if (!track) return;

    track.style.transition = 'transform 0ms ease';
    track.style.transform = 'translate3d(0px, 0px, 0px)';

    setVerticalOffset(0);
    setCurrentSlide(0);
    sliderRef.current.slickGoTo(0, true);

  }, [mobileView])

  // Add touch support for scrolling vertically on tablets, and add support for vertically scrolling via 
  // the up and down arrow keys.
  useEffect(() => {
    if (!isThankBankLoaded || mobileView) return;

    const carouselElement = document.querySelector('.slick-slider');

    if (!carouselElement) return;

    let touchStartY = 0;
    let touchEndY = 0;
  
    function handleTouchStart(event) {
      touchStartY = event.touches[0].clientY;
    }
  
    function handleTouchMove(event) {
      touchEndY = event.touches[0].clientY;
    }

    function resetTouch() {
      touchStartY = 0;
      touchEndY = 0;
    }
  
    function handleTouchEnd() {
      // User didn't swipe
      if (touchEndY === 0) {
        resetTouch();
        return;
      }

      if (touchStartY - touchEndY > 25 && currentSlide < maxSlides - 1) { // Swipe up
        slickNextDesktop();
      } else if (touchEndY - touchStartY > 25 && currentSlide > 0) { // Swipe down
        slickPrevDesktop();
      }

      resetTouch();
    }

    function handleKeyDown(event) {
      if (event.key === 'ArrowUp' && currentSlide > 0) {
        slickPrevDesktop();
      } else if (event.key === 'ArrowDown' && currentSlide < maxSlides - 1) {
        slickNextDesktop();
      }
    }
  
    document.addEventListener('keydown', handleKeyDown);
    carouselElement.addEventListener('touchstart', handleTouchStart);
    carouselElement.addEventListener('touchmove', handleTouchMove);
    carouselElement.addEventListener('touchend', handleTouchEnd);
  
    // Remove event listeners on cleanup
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      carouselElement.removeEventListener('touchstart', handleTouchStart);
      carouselElement.removeEventListener('touchmove', handleTouchMove);
      carouselElement.removeEventListener('touchend', handleTouchEnd);
    };
  }, [isThankBankLoaded, currentSlide, mobileView]);

  const handleWheel = (e) => {
    if (mobileView || !isThankBankLoaded || showSummary) return;

    // Check if carousel slides are loaded
    const track = document.querySelector('.slick-track');
    if (!track) return;

    // Calculates the breakpoints for the carousel based on the height of the cards
    // These breakpoints are used for snapping to the nearest card when scrolling on desktop
    let carouselBreakPoints = carouselBreakPointsState;
    if (carouselBreakPoints === null) {
      let currentOffset = 0;
      carouselBreakPoints = [currentOffset];
  
      for (let i = 0; i < maxSlides - 1; i++) {
        const slideSelector = `.slick-slide[data-index="${i}"]`;
        const slideElement = document.querySelector(slideSelector);
        const height = slideElement.offsetHeight;
  
        currentOffset += height;
        carouselBreakPoints.push(currentOffset);
      }
  
      setCarouselBreakPointsState(carouselBreakPoints);
    }

    let isScrolling = isScrollingState;

    if (!isScrolling) {
      isScrolling = true;
      setIsScrollingState(isScrolling);
    }

    clearTimeout(scrollTimeoutRef.current);

    const { deltaY } = e;

    setIsScrollingDown(deltaY > 0);

    let newOffset = verticalOffset + deltaY;

    if (newOffset < 0) {
      newOffset = 0;
    } else if (newOffset > carouselBreakPoints[carouselBreakPoints.length - 1]) {
      newOffset = carouselBreakPoints[carouselBreakPoints.length - 1];
    }

    if (isScrolling) {
      track.style.transition = 'transform 0ms ease';
      track.style.transform = `translate3d(0px, -${newOffset}px, 0px)`;

      setVerticalOffset(newOffset);
    }
    
    scrollTimeoutRef.current = setTimeout(() => {
      setIsScrollingState(false);

      const offset = isScrollingDown ? 150 : -150;

      let breakpoint = carouselBreakPoints[0];
      let breakpointIndex = 0;
      let smallestDifference = Math.abs(verticalOffset - breakpoint);

      for (let i = 1; i < carouselBreakPoints.length; i++) {
        const difference = Math.abs((verticalOffset + offset) - carouselBreakPoints[i]);
        
        if (difference < smallestDifference) {
          breakpoint = carouselBreakPoints[i];
          breakpointIndex = i;
          smallestDifference = difference;
        }
      }

      track.style.transition = 'transform 500ms ease';
      track.style.transform = `translate3d(0px, -${breakpoint}px, 0px)`;

      setCurrentSlide(breakpointIndex);
      setVerticalOffset(breakpoint);
    }, 500);
  };

  const nextSlide = () => {
    if (currentSlide < maxSlides - 1 && sliderRef.current) {
      if (mobileView) {
        sliderRef.current.slickNext()
      } else {
        slickNextDesktop()
      }
    }
  }

  const previousSlide = () => {
    if (currentSlide > 0 && sliderRef.current) {
      if (mobileView) {
        sliderRef.current.slickPrev()
      } else {
        slickPrevDesktop()
      }
    }
  }

  const goToSlide = (index) => {
    if (mobileView) {
      sliderRef.current.slickGoTo(index, true);
    } else {
      slickGoToDesktop(index);
    }
  }

  // Slick carousel functions - End /////////////////////////////////////////////////

  return (
    <div onWheel={handleWheel} className={`${classes.mainContainer} ${showSummary ? classes.mainContainerSummaryScroll : ''}`}>
      <AsqMeCreatorHeader 
        title={creatorData?.display_name}
        iconUrl={creatorData?.logo_image}
        showLogo={!previewMode}
      />
      {!isThankBankLoaded ? (
        <Backdrop open className={classes.backdrop}>
          <CircularProgress color='inherit' thickness={3} size={70} />
        </Backdrop>
      ) : (
        showSummary ? (
          <ThankBankSummary 
            responses={thankBankResponses}
            isRatingSubmitted={isRatingSubmitted}
            isThankYouSubmitted={isThankYouSubmitted}
            tipPaymentMethod={tipPaymentMethod}
            tipPrice={tipPrice}
            paymentMethod={customerPaymentMethod}
            creator={creatorData}
            questionSession={questionSession}
            onReturnToThankBank={onReturnToThankBank}
          />
        ) : (
          <div className={classes.carouselContainer}>
            <Slider
              ref={sliderRef}
              onReInit={() => {
                if (returnToThankBankSlide) {
                  goToSlide(returnToThankBankSlide)
                  setReturnToThankBankSlide(null);
                }
              }}
              arrows={false}
              accessibility={false} // Disable built in arrow key navigation since we are handling it manually
              className={classes.carousel}
              dots={mobileView}
              infinite={false}
              slidesToShow={1}
              slidesToScroll={1}
              centerMode={mobileView}
              centerPadding={mobileView ? '40px' : '0px'}
              vertical={!mobileView}
              swipeToSlide={mobileView}
              touchMove={mobileView}
              beforeChange={(current, next) => setCurrentSlide(next)}
              appendDots={dots => {
                return (
                  <>
                    <div className={classes.carouselControls} >
                      <button 
                        onClick={previousSlide}
                        disabled={currentSlide === 0}
                        className={classes.carouselButton}
                      >
                        <ArrowLeftIcon />
                      </button>
                      <div className={`slick-dots ${classes.carouselDots}`}>
                        {dots}
                      </div>
                      <button 
                        onClick={nextSlide} 
                        disabled={currentSlide === maxSlides - 1}
                        className={classes.carouselButton}
                      >
                        <ArrowRightIcon />
                      </button>
                    </div>
                  </>
                );
              }}
            >
              <div key={1} className={`${classes.slideContainer} ${previewMode ? classes.disabledSlide : ''}`}>
                <div className={classes.card}>
                  <RateCreator
                    rating={thankBankResponses.rating}
                    isSubmitted={isRatingSubmitted}
                    onRatingSubmit={ratingSubmitted}
                    onChange={ratingChanged}
                  />
                </div>
              </div>
              <div key={2} className={`${classes.slideContainer} ${previewMode ? classes.disabledSlide : ''}`}>
                <div className={classes.card}>
                  <ThankCreator 
                    onThankYouSubmit={thankYouSubmitted}
                    displayName={creatorData.display_name}
                    comment={thankBankResponses.comment}
                    onModalClosedAfterSubmit={onThankYouModalClosedAfterSubmit}
                    onChange={thankYouChanged}
                    isSubmitted={isThankYouSubmitted}
                  />
                </div>
              </div>
              {creatorData.tip_jar_enabled && <div key={3} className={`${classes.slideContainer} ${previewMode ? classes.disabledSlide : ''}`}>
                <div className={`${classes.card} ${classes.cardDark}`}>
                  <TipCreator 
                    onTipSubmitted={tipSubmitted}
                    isTipPaymentLoading={isTipPaymentLoading}
                    isTipPaid={isTipPaid}
                    tipAmountPaid={tipPrice}
                    code={code}
                    customerPaymentMethod={customerPaymentMethod}
                    onPaymentMethodCreated={(paymentMethod) => setCustomerPaymentMethod(paymentMethod)}
                    creatorData={creatorData}
                  />
                </div>
              </div>}
              <div key={4} className={`${classes.slideContainer} ${previewMode ? classes.disabledSlide : ''}`}>
                <div className={`${classes.card} ${classes.cardBlue}`}>
                  <GetSummary onGetSummary={() => {
                    onCompleteSession({ complete_status: thankBankSessionCompleteStatus.allDoneCta });
                    setShowSummary(true)
                    sendTrackCallTB7({ code, data: {
                      url: window.location.href,
                      page_name: 'ThankBank',
                      cta_verbiage: 'Done',
                      destination_URL: window.location.href,
                      destination_page_name: 'ThankBank'
                    }})
                  }}/>
                </div>
              </div>
            </Slider>
          </div>
        )
      )}
    </div>
  )
}

const ThankBankSummary = (props) => {
  const { responses, isRatingSubmitted, isThankYouSubmitted, tipPaymentMethod, tipPrice, paymentMethod, creator, questionSession, onReturnToThankBank } = props;

  const classes = useStyles();

  return (
    <div className={classes.summaryContainer}>
      <div className={classes.summaryContainerInner}>
        <h1>ThankBank<span>TM</span> Summary</h1>
        {isRatingSubmitted && responses.rating > 0 ? (
          <div className={classes.summaryItem}>
            <h2>Star Rating</h2>
            <StarRating rating={responses.rating} />
            <span>{responses.rating} of 5 stars</span>
          </div>
        ) : (
          <ReturnToThankBankCard
            title='Star Rating'
            body={`It’s not too late to rate ${creator.display_name}!`}
            showStars={true}
            onCardClick={() => onReturnToThankBank(0)}
          />
        )}
        {isThankYouSubmitted && responses.comment != null ? (
          <div className={classes.summaryItem}>
            <h2>Thank You Note</h2>
            <p className={classes.thankYouText}>{responses.comment}</p>
          </div>
        ) : (
          <ReturnToThankBankCard
            title='Thank You Note'
            body={`You can still say thanks to ${creator.display_name}.`}
            onCardClick={() => onReturnToThankBank(1)}
          />
        )}
        {tipPaymentMethod != null ? (
          <>
            {tipPrice > 0 && (
              <div className={classes.summaryItem}>
                <h2>Tip</h2>
                <div className={classes.tipItemText}>
                  <span>${tipPrice}</span>
                  <span>charged to card ending in {paymentMethod?.last4 ?? '_'}</span>
                </div>
              </div>
            )}
            {tipPaymentMethod !== thankBankPaymentMethods.card && (
              <div className={classes.summaryItem}>
                <h2>Tip</h2>
                <div className={classes.tipItem}>
                  {tipPaymentMethod === thankBankPaymentMethods.payPal && (
                    <img src={LogoPayPal} alt="PayPal" />
                  )}
                  {tipPaymentMethod === thankBankPaymentMethods.cashApp && (
                    <img src={LogoCashApp} alt="Cash App" />
                  )}
                  {tipPaymentMethod === thankBankPaymentMethods.buyMeACoffee && (
                    <img src={LogoBuyMeACoffee} alt="Buy Me A Coffee" />
                  )}
                  {tipPaymentMethod === thankBankPaymentMethods.venmo && (
                    <img src={LogoVenmo} alt="Venmo" />
                  )}
                </div>
              </div>
            )}
          </>
        ) : (creator.tip_jar_enabled &&
          <ReturnToThankBankCard
            title='Tip'
            body='Do you want to leave a tip?'
            onCardClick={() => onReturnToThankBank(2)}
          />
        )}

        {questionSession && (<div className={classes.summaryItem}>
            <h2>Question & Answer</h2>
            {questionSession.price && questionSession.price > 0 && (<div className={classes.tipItemText}>
              <span>${questionSession.price}</span>
              <span>charged to card ending in {paymentMethod?.last4 ?? '_'}</span>
            </div>
            )}
            <Link 
              to={`/${questionSession.question_tracking_code}`} 
              target='_blank' 
              className={`${classes.answerLink} ${questionSession.price && questionSession.price > 0 ? '' : classes.answerLinkNoMargin}`}
            >
              View our conversation
            </Link>
          </div>
        )}
      </div>
      <div className={classes.askAnotherQuestionContainer}>
        <AsqButton 
          fullWidth={true}
          to={`/${creator.asqMe_tag}`}
        >
          <SpeechBubbleIcon />Ask Another Question
        </AsqButton>
      </div>
    </div>
  )
}

export default ThankBank;