class CustomError extends Error {
  constructor({ code, config, message, name, request, response }) {
    super(message);
    this.code = code;
    this.config = config;
    this.name = name;
    this.request = request;
    this.response = response;
    this.status = response?.status;
    this.message = this.deconstructMessage(
      response?.data?.message || response?.data?.error || message || 'Generic Error',
    );

    Object.setPrototypeOf(this, new.target.prototype);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, CustomError);
    }
  }
  deconstructMessage(message) {
    return Array.isArray(message) ? message[0] : message;
  }
}

export default CustomError;
