import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import NavigationMenu from 'components/NavigationMenu';

const useStyles = makeStyles((theme) => ({
  navContainer: {
    maxWidth: 176,
  },
  contentContainer: { // Enables the content to scroll independently of the left nav bar
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    [theme.breakpoints.down('xs')]: {
      overflowY: 'initial',
      overflowX: 'clip'
    }
  }
}));

const GridWrapper = ({ children, isFullWidthChild }) => {
  const { breakpoints } = useTheme();
  const classes = useStyles();
  const mobileView = useMediaQuery(breakpoints.down('xs'));

  return isFullWidthChild ? (
    <>
      {!mobileView && (
        <Grid item xs className={classes.navContainer}>
          <NavigationMenu />
        </Grid>
      )}
      <Grid item md sm xs={12} className={classes.contentContainer}>
        {children}
      </Grid>
    </>
  ) : (
    <>
      {!mobileView && (
        <Grid item xs className={classes.navContainer}>
          <NavigationMenu />
        </Grid>
      )}
        <Grid container item sm xs={12} className={classes.contentContainer}>
          <Grid item md sm xs />
          <Grid item md={7} sm={8} xs={12}>
            {children}
          </Grid>
          <Grid item md sm xs />
        </Grid>
    </>
  );
};

export default GridWrapper;
