import { Button, Grid, makeStyles, useTheme } from '@material-ui/core';
import { NewLogoIcon } from 'components/Icons';

const useStyles = makeStyles((theme) => ({
  appName: {
    display: 'flex',
    alignItems: 'end',
    fontFamily: 'Figtree-Bold',
    color: theme.common.white,
    fontSize: 32,
    lineHeight: 1,
    '& svg': {
      position: 'relative',
      width: 26,
      height: 26,
      bottom: 2,
      marginRight: 4,
    },
    '& span': {
      fontFamily: 'inherit',
      color: theme.blue,
    },
    '&.premium span': {
      fontFamily: 'inherit',
      color: theme.premiumYellow,
    },
  },
}));

const AppLogoName = (props) => {
  const { isPro, className } = props;

  const classes = useStyles();
  const {
    premiumYellow,
    common: { white },
  } = useTheme();

  const newLogoColorProps = isPro
  ? { strokeColor: premiumYellow, fillColor: white }
  : {};

  return (
    <div
      className={`${classes.appName} ${
        isPro ? 'premium' : ''
      } ${className}`}
    >
      <NewLogoIcon
        {...newLogoColorProps}
        className={classes.logo}
      />
      Asq<span>Me</span>
    </div>
  )
}

export default AppLogoName;