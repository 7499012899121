const FollowUp = ({
  ...rest
}) => (
  <svg 
    width="50" 
    height="50" 
    viewBox="0 0 50 50" 
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <rect x="1.17188" y="1.17188" width="47.6562" height="47.6562" rx="23.8281" fill="white" stroke="#495EFF" strokeWidth="2.34375"/>
    <g clipPath="url(#clip0_7252_867)">
      <path d="M29.1893 23.1647L29.1702 13.9801C29.1677 12.7334 28.151 11.7188 26.9043 11.7188H14.7659C13.5166 11.7188 12.5 12.7354 12.5 13.9847V23.1486C12.5 24.3979 13.5166 25.4145 14.7659 25.4145H16.3269V26.9252C16.3269 27.546 17.0379 27.9025 17.5354 27.5294L20.3542 25.4155L26.9163 25.4352C28.1903 25.4352 29.1918 24.4085 29.1893 23.1647ZM20.8084 22.3429C20.3915 22.3429 20.0531 22.0046 20.0531 21.5876C20.0531 21.1707 20.3915 20.8323 20.8084 20.8323C21.2253 20.8323 21.5637 21.1707 21.5637 21.5876C21.5637 22.0046 21.2253 22.3429 20.8084 22.3429ZM21.6141 19.1923V19.2713C21.6141 19.6883 21.2757 20.0267 20.8588 20.0267C20.4418 20.0267 20.1035 19.6883 20.1035 19.2713V18.8C20.1035 18.301 20.4741 17.873 20.966 17.8035C21.3356 17.7517 21.6141 17.4299 21.6141 17.0558C21.6141 16.5054 21.0073 16.0336 20.3134 16.4772C19.962 16.7018 19.4947 16.5996 19.2701 16.2481C19.0455 15.8966 19.1477 15.4298 19.4992 15.2048C20.2782 14.7063 21.1926 14.6549 21.9454 15.0673C22.6725 15.4656 23.1242 16.2274 23.1242 17.0558C23.1242 18.0256 22.4993 18.8771 21.6136 19.1923H21.6141Z" fill="#495EFF"/>
      <path d="M36.0154 22.343H30.698L30.6995 23.1612C30.7035 25.0263 29.3676 26.5395 27.6566 26.8743H34.4544C34.8713 26.8743 35.2097 27.2127 35.2097 27.6296C35.2097 28.0466 34.8713 28.3849 34.4544 28.3849H25.3907C24.9738 28.3849 24.6354 28.0466 24.6354 27.6296C24.6354 27.322 24.8197 27.0571 25.084 26.9398L21.6141 26.9292V33.7225C21.6141 34.9717 22.6308 35.9884 23.8801 35.9884H28.989L31.8506 37.4194C32.3511 37.6697 32.9438 37.3051 32.9438 36.7437V35.9884H36.0154C37.2647 35.9884 38.2813 34.9717 38.2813 33.7225V24.6089C38.2813 23.3596 37.2647 22.343 36.0154 22.343ZM34.4544 31.4062H25.3907C24.9738 31.4062 24.6354 31.0678 24.6354 30.6509C24.6354 30.2339 24.9738 29.8956 25.3907 29.8956H34.4544C34.8713 29.8956 35.2097 30.2339 35.2097 30.6509C35.2097 31.0678 34.8713 31.4062 34.4544 31.4062Z" fill="#495EFF"/>
    </g>
    <defs>
      <clipPath id="clip0_7252_867">
        <rect width="25.7812" height="25.7812" fill="white" transform="translate(12.5 11.7188)"/>
      </clipPath>
    </defs>
  </svg>
);

export default FollowUp;