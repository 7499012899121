import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';
import closeImg from 'assets/images/close.svg';
import goldLogoImg from 'assets/images/gold-logo.svg';
import { AmountChargeIcon, TipChargeIcon, ZeroChargeIcon } from 'components/Icons';

const useStyles = makeStyles((theme) => ({
  menuIcon: {
    cursor: 'pointer',
  },
  drawer: {
    overflow: 'hidden',
  },
  paper: {
    overflowX: 'hidden',
    height: '100%',
    '&.maxWidth': {
      maxWidth: 600,
    },
  },
  drawerContainer: {
    height: '100%',
    backgroundColor: theme.modalDarkBackground,
    '@media (max-height: 500px)': {
      height: 'unset',
    },
  },
  contentContainer: { flexGrow: 1 },
  header: {
    backgroundColor: theme.modalDarkBackground,
    padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px ${theme.spacing(1)}px`,
  },
  headerContainer: {
    marginTop: theme.spacing(4),
    '&.spaceTop': {
      '@media (max-height: 780px)': {
        marginTop: theme.spacing(3),
      },
      '@media (max-height: 630px)': {
        marginTop: theme.spacing(1),
      },
    },
  },
  text: {
    color: theme.common.white,
    fontFamily: 'Figtree-SemiBold',
    fontSize: '1.55rem',
    textAlign: 'center',
    padding: `0 ${theme.spacing(1)}px`,
    lineHeight: 1.2,
    '&.spaceTop': {
      '@media (max-height: 780px)': {
        fontSize: '1.4rem',
      },
      '@media (max-height: 630px)': {
        fontSize: '1.25rem',
      },
    },
  },
  iconContainer: {
    '&.spaceTop': {
      marginTop: theme.spacing(5),
      '@media (max-height: 780px)': {
        marginTop: theme.spacing(3),
        '&.premium': {
          padding: `${theme.spacing(1)}px 0px`,
        },
      },
      '@media (max-height: 630px)': {
        marginTop: theme.spacing(2),
        '&.premium': {
          padding: `${theme.spacing(1)}px 0px`,
        },
      },
    },
    '&.premium': {
      padding: `${theme.spacing(2)}px 0px`,
      border: `1px solid ${theme.premiumYellow}`,
      borderRadius: 5,
    },
  },
  icon: {},
  iconTitle: {
    color: theme.common.white,
    fontFamily: 'Figtree-SemiBold',
    fontSize: '1.2rem',
    '&.spaceTop': {
      '@media (max-height: 780px)': {
        fontSize: '1.1rem',
      },
      '@media (max-height: 630px)': {
        fontSize: '1rem',
      },
    },
  },
  iconDescription: {
    color: theme.grey[500],
    fontSize: '1rem',
    lineHeight: 1.3,
    '&.shrink': {
      //   margin: 'auto',
      '@media (max-height: 780px)': {
        fontSize: '0.9rem',
      },
      '@media (max-height: 630px)': {
        fontSize: '0.85rem',
      },
    },
  },
  svgs: {
    width: 45,
    height: 45,
    '@media (max-height: 780px)': {
      width: 40,
      height: 40,
    },
    '@media (max-height: 630px)': {
      width: 27,
      height: 27,
    },
  },
  footerContainer: {
    padding: `${theme.spacing(3)}px 0`,
    borderTop: `1px solid ${theme.grey[800]}`,
  },
  footerText: {
    cursor: 'pointer',
    color: theme.grey[200],
    fontSize: '1rem',
    textDecoration: 'underline',
  },
}));

const PriceInfoDrawer = ({ mobileType, open, onClose, onLinkSelect }) => {
  const classes = useStyles();
  const {
    common: { white },
  } = useTheme();

  return (
    <Drawer
      classes={{
        root: classes.drawer,
        paper: `${classes.paper} ${!mobileType ? 'maxWidth' : ''}`,
        paperAnchorTop: classes.paper,
      }}
      transitionDuration={400}
      anchor={mobileType ? 'top' : 'right'}
      open={open}
      onClose={onClose}
    >
      <Grid container direction='column' className={classes.drawerContainer}>
        {/* close icon */}
        <Grid
          item
          container
          justifyContent='flex-end'
          alignItems='center'
          className={classes.header}
        >
          <Grid item>
            <img className={classes.menuIcon} src={closeImg} onClick={onClose} />
          </Grid>
        </Grid>
        {/* content */}
        <Grid item container className={classes.contentContainer}>
          <Grid item xs />
          <Grid item xs={mobileType ? 10 : 8}>
            <Grid container direction='column'>
              {/* header */}
              <Grid
                item
                container
                className={`${classes.headerContainer} ${!mobileType ? 'spaceTop' : ''}`}
              >
                <Grid item>
                  <div className={`${classes.text} ${!mobileType ? 'spaceTop' : ''}`}>
                    How do you want to charge for answers?
                  </div>
                </Grid>
              </Grid>
              {/* 1st icon */}
              <Grid
                item
                container
                alignItems='center'
                className={`${classes.iconContainer} spaceTop`}
                spacing={2}
              >
                <Grid item>
                  <AmountChargeIcon fillColor={white} className={classes.svgs} />
                </Grid>
                <Grid item>
                  <Grid container direction='column' spacing={1}>
                    <Grid item>
                      <Grid container alignItems='center'>
                        <Grid item>
                          <div className={`${classes.iconTitle} ${!mobileType ? 'spaceTop' : ''}`}>
                            Set a Price
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <div className={`${classes.iconDescription} ${!mobileType ? 'shrink' : ''}`}>
                        $5, $10, $20 or a custom amount.. <br />
                        you set the price for your expertise.
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/* 2nd icon */}
              <Grid
                item
                container
                alignItems='center'
                className={`${classes.iconContainer} premium spaceTop`}
                spacing={2}
              >
                <Grid item>
                  <TipChargeIcon fillColor={white} className={classes.svgs} />
                </Grid>
                <Grid item>
                  <Grid container direction='column' spacing={1}>
                    <Grid item>
                      <Grid container alignItems='center' spacing={1}>
                        <Grid item>
                          <div className={`${classes.iconTitle} ${!mobileType ? 'spaceTop' : ''}`}>
                            Tips Only
                          </div>
                        </Grid>
                        <Grid item>
                          <img src={goldLogoImg} alt='' />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <div className={`${classes.iconDescription} ${!mobileType ? 'shrink' : ''}`}>
                        After you answer a question, we'll <br /> suggest they say thanks with a
                        tip.
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/* 3rd icon */}
              <Grid
                item
                container
                alignItems='center'
                className={`${classes.iconContainer} premium spaceTop`}
                spacing={2}
              >
                <Grid item>
                  <ZeroChargeIcon fillColor={white} className={classes.svgs} />
                </Grid>
                <Grid item>
                  <Grid container direction='column' spacing={1}>
                    <Grid item>
                      <Grid container alignItems='center' spacing={1}>
                        <Grid item>
                          <div className={`${classes.iconTitle} ${!mobileType ? 'spaceTop' : ''}`}>
                            Don't Charge
                          </div>
                        </Grid>
                        <Grid item>
                          <img src={goldLogoImg} alt='' />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <div className={`${classes.iconDescription} ${!mobileType ? 'shrink' : ''}`}>
                        Perfect if you already monetize <br /> answers through a membership.
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs />
        </Grid>
        {/* footer */}
        <Grid item className={classes.footerContainer}>
          <Grid container justifyContent='center' spacing={1} onClick={onLinkSelect}>
            <Grid item>
              <img src={goldLogoImg} alt='' />
            </Grid>
            <Grid item>
              <div className={classes.footerText}>Included in Pro Plan</div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default PriceInfoDrawer;
