const ArrowRight = ({
  width = 20,
  height = 18,
  ...rest
}) => (
  <svg 
    {...rest} 
    width={width} 
    height={height} 
    viewBox="0 0 20 18" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M11.6992 0.999985C15.8984 3.80077 19.6992 8.1992 19.8008 8.39842C20 8.60155 20 8.80077 20 9.10155C20 9.30077 19.8984 9.60155 19.8008 9.80077C19.6992 9.99999 15.8008 14.3984 11.6992 17.1992C11.3984 17.3984 11 17.3984 10.6016 17.3008C10.3008 17.1015 10 16.8008 10 16.3984V12.6992C10 12.6992 1.30078 12.1992 0.898438 11.8984C0.398438 11.3008 0 10.1015 0 8.99999C0 7.89842 0.398438 6.80077 0.898438 6.39842C1.30078 6.1992 10 5.49999 10 5.49999V1.80077C10 1.39842 10.1992 1.10155 10.6016 0.898423C10.8984 0.699204 11.3008 0.800766 11.6992 0.999985Z" fill="#454545"/>
  </svg>
)

export default ArrowRight;