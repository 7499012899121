const ArrowLeft = ({
  width = 20,
  height = 18,
  ...rest
}) => (
  <svg 
    {...rest}
    width={width} 
    height={height}
    viewBox="0 0 20 18" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8.30078 0.999985C4.10156 3.80077 0.300781 8.1992 0.199219 8.39842C0 8.60155 0 8.80077 0 9.10155C0 9.30077 0.101562 9.60155 0.199219 9.80077C0.300781 9.99999 4.19922 14.3984 8.30078 17.1992C8.60156 17.3984 9 17.3984 9.39844 17.3008C9.69922 17.1015 10 16.8008 10 16.3984V12.6992C10 12.6992 18.6992 12.1992 19.1016 11.8984C19.6016 11.3008 20 10.1015 20 8.99999C20 7.89842 19.6016 6.80077 19.1016 6.39842C18.6992 6.1992 10 5.49999 10 5.49999V1.80077C10 1.39842 9.80078 1.10155 9.39844 0.898423C9.10156 0.699204 8.69922 0.800766 8.30078 0.999985Z" fill="#454545"/>
  </svg>
)

export default ArrowLeft;