import { methods, fetchApi } from 'utils/apiConfig';
const { POST } = methods;

const oktaAuth = ({ token, ...data }) =>
  fetchApi('/AuthorizationOcta/authenticate', POST, {
    headers: {
      chinawall: process.env.REACT_APP_CHINA_WALL,
      Authorization: `Bearer ${token}`,
    },
    data,
  });

const resetPassword = ({ email }) =>
  fetchApi(`https://${process.env.REACT_APP_AUTH0_DOMAIN}/dbconnections/change_password`, POST, {
    data: {
      client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
      email,
      connection: 'Username-Password-Authentication',
    },
  });

export { oktaAuth, resetPassword };
