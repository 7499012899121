import { useState, useEffect, useRef } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { commonBtnStyles } from 'assets/styles/common';

const useStyles = makeStyles((theme) => ({
  asqBtn: {
    padding: '7px 14px',
    height: 44,
    width: '100%',
    ...commonBtnStyles,
    overflow: 'hidden',
    backgroundColor: theme.blue,
    '& *': {
      fontFamily: 'FigTree-Regular',
    },
    '&.Mui-disabled': {
      backgroundColor: '#B1B1B1',
      opacity: 1,
      '& .MuiButton-label': {
        color: "#454545",
      },
    },
    '& .MuiButton-label': {
      fontFamily: 'Figtree-Regular',
      color: theme.common.white,
      fontSize: 16,
      lineHeight: '19.2px',
    },
    '&:hover': {
      backgroundColor: theme.blue,
      '& $icon': {
        transform: 'translateY(5px) translateX(-5px)',
      },
      '&.Mui-disabled': {
        backgroundColor: theme.blue,
        opacity: 1,
      },
      '& $iconFlyOff': {
        transform: 'translateY(-30px) translateX(30px)',
      },
    },
    [theme.breakpoints.down('xs')]: {
      height: 44,
      '& .MuiButton-label': {
        fontSize: 16,
        lineHeight: '19.2px',
      }
    }
  },
  submitted: {
    backgroundColor: '#2B8F59 !important',
    transition: 'background-color 0.0s',
    '& .MuiButton-label': {
      color: `${theme.common.white} !important`,
    },
  },
  icon: {
    marginRight: 11,
    transition: 'transform 0.3s ease-in-out',
    width: 22,
    height: 22,
    [theme.breakpoints.down('xs')]: {
      width: 18,
      height: 18,
    }
  },
  iconFlyOff: {
    transform: 'translateY(-40px) translateX(30px)',
    transition: 'transform 0.3s ease',
    [theme.breakpoints.down('xs')]: {
      transform: 'translateY(-30px) translateX(30px)',
    }
  },
  iconHidden: {
    transition: 'width 0.3s ease, margin-right 0.3s ease',
    width: 0,
    marginRight: 0,
  }
}));

export default function ThankBankButton(props) {
  const { isDisabled, isSubmitted, Icon, className, ...otherProps } = props;

  const classes = useStyles();

  const [buttonText, setButtonText] = useState('Send');
  const [showIcon, setShowIcon] = useState(true);

  const [showStaticButton, setShowStaticButton] = useState(false);

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isSubmitted && isFirstRender.current) {
      setButtonText('Sent');
      setShowStaticButton(true);
    } else if (isSubmitted) {
      setButtonText('Sent');
      setTimeout(() => {
        setShowIcon(false);
      }, 150);
    }

    isFirstRender.current = false;
  }, [isSubmitted]);

  return (
    <Button 
      className={`${classes.asqBtn} ${isSubmitted ? classes.submitted : ''} ${className}`}
      disabled={isSubmitted || isDisabled}
      {...otherProps} 
    >
      {!showStaticButton && <Icon 
        fillColor={`${isDisabled ? '#666666' : '#FFFFFF'}`} 
        className={`${classes.icon} ${isSubmitted ? classes.iconFlyOff : ''} ${showIcon ? '' : classes.iconHidden}`}
      />}
      <span>{buttonText}</span>
    </Button>
  );
}