import { useState, useMemo, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import AsqButton from "routes/Subscribe/asq-button";
import InputBase from '@material-ui/core/InputBase';
import { Dialog } from '@material-ui/core';
import { ArrowLeftIcon, CloseIconAlt, CreditCardIcon, } from "components/Icons";
import LogoBuyMeACoffee from "assets/images/logo-buy-me-coffee.png";
import LogoPayPal from "assets/images/logo-paypal.png";
import LogoCashApp from "assets/images/logo-cash-app.png";
import LogoVenmo from "assets/images/logo-venmo.png";
import { MaskedInput } from 'components/MaskedInput';
import PaymentForm from "../Profile/AnswerStep/PendingPayment/PaymentForm";
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { setupThankBankPaymentIntent } from "store/payment";
import { thankBankPaymentMethods } from "utils/common/constants";
import { styles } from "./tip-creator.styles";
import { sendTrackCallTB5, sendTrackCallTB7 } from "store/customer";

const useStyles = makeStyles(styles);

const TipCreator = (props) => {
  const { onTipSubmitted, onPaymentMethodCreated, isTipPaymentLoading, isTipPaid, tipAmountPaid, customerPaymentMethod, code, creatorData } = props;

  const classes = useStyles();

  const [showTipPaymentDialog, setShowTipPaymentDialog] = useState(false);
  const [showTipDialog, setShowTipDialog] = useState(false);
  const [tipAmount, setTipAmount] = useState('');

  const [showRedirectDialog, setShowRedirectDialog] = useState(false);
  const [redirectInfo, setRedirectInfo] = useState({
    serviceName: '',
    serviceUrl: '',
    paymentMethod: '',
  });

  const isOnlyCardTipEnabled = useMemo(() => {
    return creatorData.card_tip_enabled 
      && !creatorData.buy_me_a_coffee_tip_url 
      && !creatorData.paypal_tip_url 
      && !creatorData.cash_app_tip_url 
      && !creatorData.venmo_tip_url;
  }, [creatorData])

  const handleTipChange = (e) => {
    const rawValue = e.target.value;
    let amount;

    if (rawValue === '$') {
      amount = '';
    } else {
      amount = rawValue.replace(/\$/g, '');
    }

    setTipAmount(amount);
  }

  const onSubmitClicked = () => {
    if (customerPaymentMethod) {
      onTipSubmitted(thankBankPaymentMethods.card, tipAmount, 'card', true)
    } else {
      setShowTipPaymentDialog(true);
    }
  }

  const onStripeSuccess = (paymentMethodDetails) => {
    setShowTipPaymentDialog(false);
    onPaymentMethodCreated(paymentMethodDetails);
    onTipSubmitted(thankBankPaymentMethods.card, tipAmount, paymentMethodDetails.type, false)
  }

  return (
    <div className={classes.container}>
      {showTipDialog || isTipPaid || isOnlyCardTipEnabled ? (
        <>
          <div className={classes.headingContainer}>
            {(!isTipPaid && !isOnlyCardTipEnabled) && <Button onClick={() => setShowTipDialog(false)}>
              <ArrowLeftIcon />
            </Button>}
            <CreditCardIcon className={classes.creditCardIcon} />
            {customerPaymentMethod ? <span>card ending in {customerPaymentMethod.last4}</span> : <span>Credit Card</span>}
          </div>
          <div className={classes.tipInputGroup}>
            <label htmlFor="tipAmount" className={classes.tipInputLabel}>Tip Amount</label>
            <InputBase 
              id="tipAmount"
              className={classes.tipInput}
              placeholder="$0"
              value={isTipPaid ? tipAmountPaid : tipAmount}
              readOnly={isTipPaid}
              onChange={handleTipChange}
              inputComponent={MaskedInput}
              inputProps={{ 
                inputMode: 'numeric',
                mask: '$num',
                blocks: {
                  num: {
                    mask: Number,
                    scale: 0,
                    signed: false,
                  }
                },
              }}
            />
          </div>
          <AsqButton 
            fullWidth={true} 
            extraClassName={`${classes.sendTipButton} ${isTipPaid ? classes.sentTipButton : ''}`}
            onClick={onSubmitClicked}
            disabled={tipAmount === '' || isTipPaymentLoading || isTipPaid}
            isLoading={isTipPaymentLoading}
          >
            {isTipPaid ? 'Sent' : 'Send Tip'}
          </AsqButton>
          <TipStripePaymentDialog
            code={code}
            open={showTipPaymentDialog}
            handleClose={() => setShowTipPaymentDialog(false)}
            onStripeSuccess={onStripeSuccess}
          />
        </>
      ) : (
        <>
          <h2 className={classes.headingText}>My creativity runs on tips. And caffeine. But mostly tips. ☕️🚀</h2>
          {creatorData.buy_me_a_coffee_tip_url && <TipButton
            onClick={() => {
              setRedirectInfo({
                serviceName: 'Buy Me A Coffee',
                serviceUrl: creatorData.buy_me_a_coffee_tip_url,
                paymentMethod: thankBankPaymentMethods.buyMeACoffee,
              });
              setShowRedirectDialog(true);

              sendTrackCallTB5({ code, data: { tip_option: 'Buy Me A Coffee', tip_type: 'External', card_populated: customerPaymentMethod != null }});
            }}
          >
            <img src={LogoBuyMeACoffee} alt="Buy me a coffee" />
          </TipButton>}
          {creatorData.paypal_tip_url && <TipButton
            onClick={() => {
              setRedirectInfo({
                serviceName: 'PayPal',
                serviceUrl: creatorData.paypal_tip_url,
                paymentMethod: thankBankPaymentMethods.payPal,
              });
              setShowRedirectDialog(true);

              sendTrackCallTB5({ code, data: { tip_option: 'PayPal', tip_type: 'External', card_populated: customerPaymentMethod != null }});
            }}
          >
            <img src={LogoPayPal} alt="Pay Pal" />
          </TipButton>}
          {creatorData.cash_app_tip_url && <TipButton
            onClick={() => {
              setRedirectInfo({
                serviceName: 'Cash App',
                serviceUrl: creatorData.cash_app_tip_url,
                paymentMethod: thankBankPaymentMethods.cashApp,
              });
              setShowRedirectDialog(true);

              sendTrackCallTB5({ code, data: { tip_option: 'Cash App', tip_type: 'External', card_populated: customerPaymentMethod != null }});
            }}
          >
            <img src={LogoCashApp} alt="Cash App" />
          </TipButton>}
          {creatorData.venmo_tip_url && <TipButton
            onClick={() => {
              setRedirectInfo({
                serviceName: 'Venmo',
                serviceUrl: creatorData.venmo_tip_url,
                paymentMethod: thankBankPaymentMethods.venmo,
              });
              setShowRedirectDialog(true);

              sendTrackCallTB5({ code, data: { tip_option: 'Venmo', tip_type: 'External', card_populated: customerPaymentMethod != null }});
            }}
          >
            <img src={LogoVenmo} alt="Cash App" />
          </TipButton>}
          {creatorData.card_tip_enabled && <TipButton onClick={() => {
            setShowTipDialog(true)
            sendTrackCallTB5({ code, data: { tip_option: 'Credit Card', tip_type: 'Internal', card_populated: customerPaymentMethod != null }});
          }}>
            <CreditCardIcon className={classes.creditCardIcon} />
            <span>{customerPaymentMethod ? `card ending in ${customerPaymentMethod.last4}` : 'Credit/Debit Card' }</span>
          </TipButton>}
          <TipRedirectDialog
            open={showRedirectDialog}
            handleClose={() => setShowRedirectDialog(false)}
            onSubmit={onTipSubmitted}
            code={code}
            { ... redirectInfo }
          />
        </>
      )}
    </div>
  )
}

const TipButton = (props) => {
  const { children, ...otherProps } = props;

  const classes = useStyles();

  return (
    <Button
      className={classes.tipButton}
      {...otherProps}
    >
      {children}
    </Button>
  )
}

const TipRedirectDialog = (props) => {
  const { open, handleClose, onSubmit, serviceName, serviceUrl, paymentMethod, code } = props;

  const classes = useStyles();

  return (
    <Dialog 
      open={open} 
      onClose={handleClose} 
      className={classes.dialog}
      fullWidth={true}
    >
      <div className={classes.dialogContainer}>
        <div className={`${classes.dialogHeaderContainer} ${classes.redirectMessageHeaderMargin}`}>
          <h2>You're about to leave AsqMe</h2>
          <Button onClick={handleClose}><CloseIconAlt /></Button>
        </div>
        <p className={classes.redirectMessageParagraphMargin}>By continuing you'll be redirected to {serviceName} where you can continue the tipping process.</p>
        <AsqButton 
          to={serviceUrl} 
          target='_blank'
          fullWidth={true}
          onClick={() => {
            onSubmit(paymentMethod, 0)
            sendTrackCallTB7({ code, data: {
              url: window.location.href,
              page_name: 'ThankBank',
              cta_verbiage: serviceName,
              destination_URL: serviceUrl,
              destination_page_name: serviceName,
            }});
          }}
        >
          Continue to {serviceName}
        </AsqButton>
      </div>
    </Dialog>
  )
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const TipStripePaymentDialog = (props) => {
  const { open, handleClose, onStripeSuccess, code } = props;

  const classes = useStyles();

  const [clientSecret, setClientSecret] = useState(null);

  useEffect(() => {
    if (!clientSecret) {
      setupThankBankPaymentIntent({ session_tracking_code: code }).then((response) => {
        setClientSecret(response)
      });
    }
  }, [clientSecret])

  const stripeProps = useMemo(
    () =>
      clientSecret
        ? {
            options: {
              clientSecret,
              appearance: {
                theme: 'stripe',
                labels: 'floating',
                variables: {
                  colorPrimary: '#495EFF',
                },
                rules: {
                  '.Input': {
                    padding: '7px',
                  },
                },
              },
            },
            stripe: stripePromise,
          }
        : null,
    [clientSecret],
  );

  return (
    <Dialog 
      open={open} 
      onClose={handleClose} 
      className={classes.dialog}
      fullWidth={true}
    >
      <div className={classes.dialogContainer}>
        <div className={classes.dialogHeaderContainer}>
          <h2>Enter your information</h2>
          <Button onClick={handleClose}><CloseIconAlt /></Button>
        </div>
        {stripeProps && (
            <Elements {...stripeProps}>
              <PaymentForm
                classes={classes}
                textPrompt=""
                submitText={`Use Card`}
                useGooglePay={true}
                useApplePay={true}
                fetchPaymentDetailsOnSuccess={true}
                onSetupSuccess={onStripeSuccess}
              />
            </Elements>
          )}
      </div>
    </Dialog>
  )
}

export default TipCreator;