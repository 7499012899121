import { useState, useCallback, useEffect } from 'react';
import AnswerFlow from './AnswerFlow';
import AnswerAcceptedSuccess from 'components/AnswerAcceptedSuccess';
import { useNavigate } from 'react-router-dom';

const answerStatusTypes = {
  pendingOrCompleted: 'pending-or-completed',
  proceedToTipCheckout: 'proceed-to-tip-checkout',
  checkoutIsProcessing: 'checkout-is-processing',
  answerSuccessfullyAccepted: 'answer-successfully-accepted',
};

const RevealAnswer = ({
  answerData,
  customerPaymentMethod,
  hasPaymentMethod,
  skipPayment,
}) => {
  const navigate = useNavigate();

  const [answerStatus, setAnswerStatus] = useState(answerStatusTypes.pendingOrCompleted);
  const [successState, setSuccessState] = useState(null);
  const [followUpSelected, setFollowUpSelected] = useState(false);

  const setStatus = useCallback(() => {
    setFollowUpSelected(true);
  }, []);

  const setAnswerAcceptanceSuccess = useCallback((amount, currencyCode, isTip) => {
    setSuccessState({ amount, currencyCode, isTip });
    setAnswerStatus(answerStatusTypes.answerSuccessfullyAccepted);
  }, []);

  const resetStatus = useCallback(() => {
    setAnswerStatus(answerStatusTypes.pendingOrCompleted);
    setFollowUpSelected(false);
  }, []);

  useEffect(() => {
    // Redirct to thank bank page
    if (answerStatus === answerStatusTypes.answerSuccessfullyAccepted && process.env.REACT_APP_ENABLE_THANK_BANK === 'true') {
      navigate(`/thx/${answerData.thankBankSession.thank_bank_session_tracking_code}?redirected_from=${window.location.pathname}`)
    }
  }, [answerStatus])

  return answerStatus === answerStatusTypes.answerSuccessfullyAccepted ? (
    <AnswerAcceptedSuccess answerData={answerData} successState={successState} />
  ) : answerStatus === answerStatusTypes.pendingOrCompleted ? (
    <AnswerFlow
      answerData={answerData}
      followUpSelected={followUpSelected}
      customerPaymentMethod={customerPaymentMethod}
      hasPaymentMethod={hasPaymentMethod}
      onSetStatus={setStatus}
      onAnswerAcceptanceSuccess={setAnswerAcceptanceSuccess}
      onResetStatus={resetStatus}
      skipPayment={skipPayment}
    />
  ) : null;
};

export default RevealAnswer;
