import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { useLocation } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  navItemContainer: {
    width: '100%',
    height: 42,
    borderRadius: 5,
    padding: '0 10px',
    marginBottom: 20,
    display: 'flex',
    alignItems: 'center',
  },
  selectedNavItem: {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
  },
  navItem: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    '& img': {
      width: 23,
      height: 23,
      marginRight: 10,
    },
    '& span': {
      fontFamily: 'Figtree-Bold',
      fontSize: 18,
      color: 'white',
    }
  },
  link: {
    display: 'block',
    textAlign: 'center',
  },
}));

const NavItem = (props) => {
  const { icon, label, path } = props;

  const classes = useStyles();

  const location = useLocation();

  return (
    <Grid item className={`${classes.navItemContainer} ${location.pathname === path ? classes.selectedNavItem : ''}`}>
      <Link to={path} className={classes.link}>
        <div className={classes.navItem}>
          <img src={icon} alt={label} />
          <span>{label}</span>
        </div>
      </Link>
    </Grid>
  )
}

export default NavItem;