import { commonBtnStyles } from 'assets/styles/common';

const styles = (theme) => ({
  questionPreviewContainer: {
    height: '100%',
    backgroundColor: '#FAFAFA',
    position: 'relative',
  },
  previewContainer: {
    // height: '100%',
    overflow: 'visible', // To prevent box shadow from being cut off
    maxWidth: 454,
    [theme.breakpoints.down('xs')]: {
      padding: '0 20px',
    },
  },
  form: {
    height: '100%',
  },
  headerContainer: {
    height: 100,
    marginBottom: 60,
  },
  header: {
    backgroundColor: theme.blue,
    paddingBottom: 0,
  },
  headerLogosContainer: {
    backgroundColor: theme.blue,
    height: 70,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  logoPlaceholderIcon: {
    transform: 'translateY(50%)',
    borderRadius: 50,
    width: 70,
    height: 70,
    backgroundColor: theme.common.black,
    border: `2px solid ${theme.common.white}`,
    position: 'relative',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  dataSectionContainer: {
    // flexGrow: 1,
  },
  introSectionContainer: {
    paddingTop: theme.spacing(4.5),
  },
  previewContentContainer: {
    //padding: `0 ${theme.spacing(2)}px !important`,
  },
  customerMessage: {
    wordBreak: 'break-word',
    whiteSpace: 'pre-wrap',
  },
  prioritySectionContainer: {
    marginBottom: 20,
    // flexGrow: 1,
  },
  headerTextContainer: {
    marginBottom: 10,
    '& span': {
      fontFamily: 'Figtree-Bold',
      fontSize: 16,
      lineHeight: '19.2px',
      marginBottom: 10,
    },
  },
  paymentMethodHeader: {
    marginBottom: 5,
  },
  paymentMethodInfo: {
    '& p': {
      fontFamily: 'Figtree-Regular',
      fontSize: 14,
      lineHeight: '19.07px',
    },
  },
  propmptToPriorityText: {
    textAlign: 'center',
    fontSize: '1rem',
    color: theme.grey[600],
    '&.firstChild': {
      paddingTop: theme.spacing(1),
    },
    '& span.bold': {
      fontSize: '1rem',
      fontFamily: 'Figtree-Bold',
      color: theme.grey[600],
    },
    '& span.blue': {
      fontSize: '1rem',
      fontFamily: 'Figtree-Bold',
      color: theme.blue,
    },
  },
  paymentInnerSection: {
    display: 'flex',
    flexDirection: 'column',
    // flexGrow: 1,
    paddingBottom: theme.spacing(1),
  },
  previewContentImageContainer: {
    textAlign: 'end',
  },
  mismatchContainer: {
    padding: `0 ${theme.spacing(2)}px`,
    minHeight: theme.spacing(7),
    backgroundColor: theme.grey[700],
  },
  translationIntro: {
    color: theme.common.white,
    paddingLeft: theme.spacing(2),
    fontSize: '0.8rem',
    '& span': {
      fontFamily: 'Figtree-SemiBold',
      fontSize: '1.2rem',
    },
  },
  drawerTranslationIntro: {
    color: theme.common.white,
    fontSize: '1.3rem',
    '& span': {
      fontFamily: 'Figtree-SemiBold',
      fontSize: '2.3rem',
    },
  },
  drawerTranslationText: {
    color: theme.grey[300],
    fontSize: '1rem',
    padding: `0 ${theme.spacing(5)}px`,
    textAlign: 'center',
  },
  infoSvg: {
    marginTop: theme.spacing(0.8),
    cursor: 'pointer',
  },
  paper: {
    overflowX: 'hidden',
    height: '100%',
  },
  drawerSkeletonContainer: {
    height: '100%',
  },
  drawerContainer: {
    height: '100%',
    backgroundColor: theme.common.black,
  },
  closeIconContainer: {
    padding: theme.spacing(1),
  },
  translationIconContainer: {
    marginTop: theme.spacing(7),
  },
  translationIntroContainer: {
    marginTop: theme.spacing(5),
  },
  translationBigImg: {
    width: 65,
  },
  questionHeaderContainer: {
    marginBottom: 5,
  },
  questionHeader: {
    fontFamily: 'Figtree-Bold',
    fontSize: 16,
    lineHeight: '19.2px',
  },
  editPencilButton: {
    minWidth: 0,
    padding: 0,
    bottom: 3,
  },
  questionContentContainer: {
    marginBottom: 20,
    '& p': {
      fontFamily: 'Figtree-Regular',
      fontSize: 14,
      lineHeight: '19.07px',
      margin: 0,
    },
  },
  creatorDisplayName: {
    fontSize: '1rem',
    '& span': {
      fontFamily: 'Figtree-Bold',
    },
  },
  editPreviewText: {
    fontSize: '0.8rem',
    opacity: 0.6,
    lineHeight: 1.4,
  },
  preview: {
    fontFamily: 'Figtree-Bold',
  },
  editBlueBtn: {
    width: 51,
    height: 28,
  },
  attachmentContainer: {
    marginBottom: 10,
  },
  customerAttachment: {
    width: '100%',
    height: '100%',
    // maxHeight: 175, // Disabled because of AM-144
  },
  hr: {
    backgroundColor: theme.greyHorizontalRule,
    height: 1,
    '&.blue': {
      height: 1,
      backgroundColor: theme.blue,
      marginTop: theme.spacing(1),
    },
  },
  textField: {
    '& .MuiInputAdornment-positionEnd': {
      paddingRight: theme.spacing(0.8),
      position: 'absolute',
      right: 5,
      top: '20%',
    },
    '& .MuiFilledInput-input': {
      borderRadius: 0,
      padding: `${theme.spacing(1.9)}px ${theme.spacing(1.5)}px ${theme.spacing(1.9)}px`,
    },
    '& .MuiFilledInput-multiline': {
      borderRadius: 0,
      padding: 'unset',
    },
    '& .MuiFormHelperText-root.Mui-error': {
      color: theme.error.dark,
      fontFamily: 'Figtree-Bold',
      fontSize: theme.spacing(1.6),
    },
    '& .MuiFormHelperText-contained': {
      marginLeft: 0,
    },
  },
  inputLabel: {
    fontSize: '0.9rem',
    fontFamily: 'Figtree-Bold',
  },
  requiredMark: {
    color: theme.error.dark,
  },
  footerInfoContainer: {
    marginTop: theme.spacing(0.8),
    textAlign: 'center',
  },
  tipsDescription: {
    paddingBottom: theme.spacing(1),
    lineHeight: 1.3,
    fontSize: '0.95rem',
    '& span': {
      color: theme.success.light,
    },
  },
  noChargeText: {
    fontSize: '0.85rem',
    fontFamily: 'Figtree-Bold',
    padding: `${theme.spacing(0.7)}px ${theme.spacing(2)}px`,
    backgroundColor: theme.success.light,
    borderRadius: 13,
  },
  footerGeneralText: {
    lineHeight: 1.3,
    '&.underline span': {
      textDecoration: 'underline',
    },
    '&.bold span': {
      fontFamily: 'Figtree-Bold',
    },
  },
  customerFooterContainer: {
    marginBottom: theme.spacing(1),
  },
  saveBtn: {
    ...commonBtnStyles,
    height: 44,
    backgroundColor: theme.blue,
    '&.Mui-disabled': {
      opacity: 0.7,
    },
    '& .MuiButton-label span': {
      fontFamily: 'Figtree-Bold',
      color: theme.common.white,
      fontSize: '1.1rem',
    },
    '&:hover': {
      backgroundColor: theme.blue,
      opacity: 0.8,
      '&.Mui-disabled': {
        backgroundColor: theme.blue,
        opacity: 0.7,
      },
    },
  },
  saveBtnLabel: {
    '& span:nth-child(1)': {
      position: 'relative',
      marginRight: 10,
      top: 4,
    },
    '& span:nth_child(2)': {
      fontFamily: 'Figtree-Bold',
      color: theme.common.white,
      fontSize: 16,
      lineHeight: '19.2px',
    },
  },
  saveBtnIcon: { marginTop: theme.spacing(0.2) },
  progress: { color: theme.common.white },
  paymentInformation: {
    fontSize: '0.93rem',
    textAlign: 'center',
    padding: `${theme.spacing(0.5)}px 0 ${theme.spacing(1)}px`,
    color: theme.grey[600],
    '& span': {
      fontSize: '0.93rem',
      fontFamily: 'Figtree-Bold',
      color: theme.grey[600],
    },
  },
  revealTextPrompt: {
    padding: `${theme.spacing(1)}px 0`,
    fontFamily: 'Figtree-Bold',
  },
  formContainer: {
    height: '100%',
  },
  paymentSetupProcessing: {
    fontSize: '1rem',
  },
  // saveBtnContainer: {
  //   marginTop: theme.spacing(1),
  // },
  priceSectionContainer: {},
  customerFooter: {
    paddingTop: theme.spacing(3),
    position: 'relative',
  },
  errorContainer: {
    height: 150,
    backgroundColor: theme.common.black,
    position: 'fixed',
    left: '25%',
    right: '25%',
    bottom: 0,
    '&.stretch': {
      left: 0,
      right: 0,
    },
  },
  closeIcon: {
    cursor: 'pointer',
    position: 'absolute',
    right: 13,
    top: 10,
  },
  errorInnerContainer: {
    height: 'inherit',
  },
  errorImg: {},
  errorHeader: {
    textAlign: 'center',
    color: theme.common.white,
    fontFamily: 'Figtree-Bold',
    fontSize: '1.1rem',
  },
  errorTempMessage: {
    paddingTop: theme.spacing(0.8),
    lineHeight: 1.3,
    textAlign: 'center',
    color: theme.grey[300],
    fontSize: '0.9rem',
    '& span': {
      color: theme.blue,
      fontFamily: 'Figtree-Bold',
    },
  },
  errorMessage: {
    lineHeight: 1,
    textAlign: 'center',
    color: theme.common.white,
    fontSize: '0.9rem',
  },
});

export default styles;
