import thankBankLogo from 'assets/images/thank-bank-logo-dark.svg';
import percentImg from 'assets/images/percent.svg';
import zeroChageImg from 'assets/images/zero-charge.svg';
import transferImg from 'assets/images/transfer.svg';
import qnaImg from 'assets/images/qna-white.svg';
import translationImg from 'assets/images/translate.svg';
import clipsImg from 'assets/images/clips-sub-icon.svg';

// price types
const zeroValue = '0';
const tipsValue = 'T';
const customValue = 'custom';

// Auth0 origin options
const originTypes = {
  credentials: 'auth0',
  facebook: 'facebook',
  google: 'google-oauth2',
};

// Subscription features
const popUpFeatures = {
  tipJar: {
    icon: thankBankLogo,
    title: 'ThankBank™',
    description: 'Accept tips via your favorite tipping services, including Buy Me a Coffee and Venmo.',
  },
  complimentaryAnswers: {
    icon: zeroChageImg,
    title: 'Complimentary Answers',
    description: "Don't charge for answers. Perfect if you monetize Q&A in a membership.",
  },
  noRevshare: {
    icon: percentImg,
    title: 'No Revenue Share',
    description: 'Keep 100% of what you earn. No commissions or revenue splits.',
  },
  zeroFees: {
    icon: transferImg,
    title: 'Zero Transcation Fees',
    description: 'No credit card processing costs or balance transfer fees.',
  },
  translation: {
    icon: translationImg,
    title: 'AutoTranslate',
    description: 'Detects and translates questions into your preferred language and back.',
  },
  qna: {
    icon: qnaImg,
    title: 'Coming Soon...',
    description: 'Q&A Catalog, Question Crawler, advanced AI features and more.',
  },
  clips: {
    icon: clipsImg,
    title: 'Clips',
    description: 'Unlimited access to AI powered clips from your content.',
  },
};

const thankBankPaymentMethods = {
  card: 'CARD',
  cashApp: 'CASH_APP',
  payPal: 'PAYPAL',
  buyMeACoffee: 'BUY_ME_A_COFFEE',
  venmo: 'VENMO',
}

const thankBankSessionUpdateAction = {
  updateRating: 'UPDATE_RAITING',
  submitRating: 'SUBMIT_RATING',
  submitComment: 'SUBMIT_COMMENT',
}

const thankBankSessionCompleteStatus = {
  notCompleted: 'Not Completed',
  allDoneCta: 'All Done CTA',
  externalTip: 'External Tip',
  internalTip: 'Internal Tip',
  allActionsComplete: 'All Actions Complete',
  timedOut24hr: '24 Hrs Time-out',
  timedOut1hr: '1 Hr Time-out',
}

const defaultAlertError = {
  title: 'Something went wrong',
  body: 'We\'re sorry. Please try again.',
};

const googleError = {
  insufficientPermissions: 'insufficient_permissions',
  noChannel: 'no_channel',
  quotaExceeded: 'quota_exceeded',
  genericAuthError: 'generic_auth_error',
}

export { zeroValue, tipsValue, customValue, originTypes, popUpFeatures, thankBankSessionCompleteStatus, thankBankPaymentMethods, thankBankSessionUpdateAction, defaultAlertError, googleError };
