const EyeAlt = ({
  ...rest
}) => (
  <svg
    {...rest}
    width="18" 
    height="19" 
    viewBox="0 0 18 19" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8.99992 4.76196C5.52486 4.76196 2.27696 6.49003 0.324438 9.37014C-0.0406465 9.911 0.110796 10.6276 0.648957 10.9927C0.851782 11.1307 1.07083 11.2091 1.298 11.2091C1.6766 11.2091 2.06602 11.0252 2.29319 10.6898C2.3662 10.5817 2.45545 10.4897 2.53117 10.387C3.45875 13.0724 5.99812 14.9951 8.99992 14.9951C12.0017 14.9951 14.5411 13.0724 15.4687 10.387C15.5444 10.4897 15.6336 10.5817 15.7066 10.6898C16.069 11.228 16.81 11.3578 17.3509 10.9927C17.889 10.6276 18.0405 9.9083 17.6754 9.37014C15.7256 6.48732 12.475 4.76196 8.99992 4.76196ZM11.7475 7.5961C12.9023 8.00986 13.9326 8.69676 14.798 9.58648C14.1517 12.188 11.7989 14.1298 8.99992 14.1298C6.20094 14.1298 3.84818 12.188 3.20184 9.58648C4.06182 8.70217 5.10569 8.0342 6.25232 7.61773C5.88183 8.15319 5.66819 8.79952 5.66819 9.49994C5.66819 11.3389 7.16098 12.8317 8.99992 12.8317C10.8389 12.8317 12.3316 11.3389 12.3316 9.49994C12.3316 8.79411 12.1234 8.13426 11.7475 7.5961Z" fill="black"/>
  </svg>
);

export default EyeAlt; 