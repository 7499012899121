import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';
import closeImg from 'assets/images/close.svg';
import borderUnderDevImg from 'assets/images/border-under-construction.png';
import logoUnderDevImg from 'assets/images/asqme-under-construction.svg';
import asqmeTextImg from 'assets/images/asqme-text.svg';
import styles from './styles';

const useStyles = makeStyles(styles);

const UnderDevFeatureDrawer = ({ mobileType, open, onClose }) => {
  const classes = useStyles();

  return (
    <Drawer
      classes={{
        root: classes.drawer,
        paper: `${classes.paper} ${!mobileType ? 'maxWidth' : ''}`,
        paperAnchorTop: classes.paper,
      }}
      transitionDuration={400}
      anchor={mobileType ? 'top' : 'right'}
      open={open}
      onClose={onClose}
    >
      <Grid
        container
        direction='column'
        justifyContent='space-between'
        className={classes.drawerContainer}
      >
        {/* border */}
        <Grid item>
          <img src={borderUnderDevImg} className={classes.borderImg} alt='' />
        </Grid>
        {/* close icon */}
        <Grid item container justifyContent='flex-end' className={classes.headerContainer}>
          <Grid item>
            <img className={classes.menuIcon} src={closeImg} onClick={onClose} />
          </Grid>
        </Grid>
        {/* content */}
        <Grid
          item
          container
          direction='column'
          justifyContent='space-around'
          alignItems='center'
          className={classes.contentContainer}
        >
          <Grid item>
            <div className={classes.comingSoonText}>Coming Soon</div>
          </Grid>
          <Grid item container>
            <Grid item xs />
            <Grid item xs={10}>
              <div className={classes.notreadyText}>Sorry,this feature isn’t ready yet.</div>
            </Grid>
            <Grid item xs />
          </Grid>

          <Grid item>
            <img src={logoUnderDevImg} className={classes.underDevImg} alt='' />
          </Grid>
          <Grid item container>
            <Grid item xs />
            <Grid item xs={10}>
              <div className={classes.waitText}>
                We can’t wait for you to try our new features. We’ll let you know when it launches.
              </div>
            </Grid>
            <Grid item xs />
          </Grid>
        </Grid>

        {/* footer */}
        <Grid item container justifyContent='center'>
          <Grid item>
            <img src={asqmeTextImg} className={classes.asqmeLogo} alt='' />
          </Grid>
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default UnderDevFeatureDrawer;
