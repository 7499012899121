import { makeStyles } from '@material-ui/core/styles';
import { Modal } from 'antd';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(() => ({
  modalHeader: {
    fontFamily: 'Figtree-Bold',
    fontSize: '1.1rem',
    textAlign: 'center',
  },
  modalDescription: {
    fontSize: '1rem',
  },
}));

const DiscardChangesModal = ({
  header = 'Warning',
  description = 'Your changes will be lost. Do you want to continue?',
  open,
  onOk,
  onCancel,
}) => {
  const classes = useStyles();

  return (
    <Modal
      title={<div className={classes.modalHeader}>{header}</div>}
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      footer={[
        <Grid key={1} container justifyContent='space-between'>
          <Grid item>
            <Button variant='contained' onClick={onCancel}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button variant='contained' color='primary' onClick={onOk}>
              Ok
            </Button>
          </Grid>
        </Grid>,
      ]}
    >
      <div className={classes.modalDescription}>{description}</div>
    </Modal>
  );
};

export default DiscardChangesModal;
