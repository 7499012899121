export const styles = (theme) => ({
  mainContainer: {
    overflow: 'hidden',
    height: '100%',
    backgroundColor: theme.common.white,
    position: 'relative',
  },
  mainContainerSummaryScroll: {
    '@media (max-height: 800px)': {
      overflowY: 'scroll',
    }
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.common.white,
    color: theme.common.black,
  },
  carouselContainer: {
    '& .slick-slider': {
      paddingTop: 145,
    },
    [theme.breakpoints.up('sm')]: {
      backgroundColor: '#EEEEEE',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      width: '100%',
      '& .slick-slider': {
        width: '65%',
        alignItems: 'center',
        backgroundColor: 'white',
      },
      '& .slick-track': {
        maxWidth: 465,
      },
    }
  },
  slideContainer: {
    padding: 20,
    maxWidth: 465,
  },
  disabledSlide: {
    '& *': {
      pointerEvents: 'none',
    }
  },
  summaryContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      backgroundColor: '#EEEEEE',
    },
    '@media (max-height: 800px)': {
      marginBottom: 100,
    },
    '@media (max-height: 700px)': {
      marginBottom: 200,
    }
  },
  summaryContainerInner: {
    display: 'flex',
    flexDirection: 'column',
    padding: 20,
    backgroundColor: 'white',
    height: '100%',
    paddingTop: 165,
    alignItems: 'center',
    width: '100%',
    '& h1': {
      fontFamily: 'Figtree-Bold',
      fontSize: 24,
      lineHeight: '28.8px',
      marginBottom: 20,
      '& span': {
        fontSize: 8,
        position: 'relative',
        bottom: 11,
      }
    },
    [theme.breakpoints.up('sm')]: {
      width: '65%',
    },
  },
  askAnotherQuestionContainer: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    bottom: 0,
    width: '100%',
    padding: 20,
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    '& .MuiButton-root': {
      maxWidth: 465,
    },
    '& .MuiButton-label': {
      fontFamily: 'Figtree-Bold',
      '& svg': {
        marginRight: 10,
      },
    },
    [theme.breakpoints.up('sm')]: {
      width: '65%',
    }
  },
  carousel: {
    // The default value, pan-y, causes an issue on mobile where click events after
    // swiping to a new slide are not registered. This is a workaround to fix that.
    touchAction: 'auto',
    [theme.breakpoints.up('sm')]: {
      '& .slick-list': {
        height: '100% !important',
      }
    },
  },
  card: {
    padding: 20,
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
    borderRadius: 10,
  },
  cardDark: {
    backgroundColor: '#FAFAFA',
  },
  cardBlue: {
    backgroundColor: '#495EFF',
    border: '2px solid #6C7DFF',
  },
  carouselControls: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 20,
  },
  carouselDots: {
    position: 'static',
    '& li': {
      margin: '0 10px',
    },

    '& li button:before': {
      content: '""',
      background: '#DADADA',
      width: 10,
      height: 10,
      borderRadius: 5,
      opacity: '1 !important',
    },
    '& li.slick-active button:before': {
      background: theme.blue,
    }
  },
  carouselButton: {
    background: 'none',
    color: 'inherit',
    border: 'none',
    padding: 0,
    margin: 0,
    font: 'inherit',
    cursor: 'pointer',
    outline: 'inherit',
    '&:disabled': {
      opacity: 0.50,
    },
  },
  summaryItem: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'start',
    flexDirection: 'column',
    padding: 20,
    backgroundColor: '#EEEEEE',
    marginBottom: 10,
    borderRadius: 5,
    maxWidth: 465,
    width: '100%',
    '& h2': {
      fontFamily: 'Figtree-Bold',
      fontSize: 16,
      lineHeight: '19.2px',
      marginBottom: 10,
    },
    '& span': {
      fontFamily: 'Figtree-Regular',
      fontSize: 16,
      lineHeight: '19.2px',
    },
  },
  thankYouText: {
    fontFamily: 'Figtree-Regular',
    fontSize: 16,
    lineHeight: '19.2px',
    marginBottom: 0,
  },
  tipItemText: {
    display: 'flex',
    justifyContent: 'start',
    '& span': {
      fontFamily: 'Figtree-Regular',
      fontSize: 16,
      lineHeight: '19.2px',
    },
    '& span:nth-child(2)': {
      fontFamily: 'Figtree-Regular',
      fontSize: 12,
      lineHeight: '16.34px',
      alignItems: 'end',
      display: 'inline-flex',
      marginLeft: 5,
    }
  },
  tipItem: {
    display: 'flex',
    justifyContent: 'start',
    '& img': {
      height: 20,
    }
  },
  answerLink: {
    fontFamily: 'Figtree-Regular',
    fontSize: 16,
    lineHeight: '19.2px',
    color: theme.blue,
    marginTop: 10,
    textDecoration: 'underline',
  },
  answerLinkNoMargin: {
    marginTop: 0,
  },
});