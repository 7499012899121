import { number, object, string } from 'prop-types';

const ProgressStatusIcon = ({
  className = '',
  style = {},
  width = 43,
  height = 8,
  firstBulletColor = '#CCCCCC',
  secondBulletColor = '#CCCCCC',
  thirdBulletColor = '#CCCCCC',
  ...rest
}) => (
  <svg
    {...rest}
    className={className}
    style={style}
    width={width}
    height={height}
    viewBox='0 0 43 8'
    fill='none'
  >
    <rect width='20.7037' height='7.96296' rx='3.98148' fill={firstBulletColor} />
    <rect x='23.8889' width='7.96296' height='7.96296' rx='3.98148' fill={secondBulletColor} />
    <rect x='35.037' width='7.96296' height='7.96296' rx='3.98148' fill={thirdBulletColor} />
  </svg>
);

ProgressStatusIcon.propTypes = {
  style: object,
  className: string,
  height: number,
  width: number,
  fillColor: string,
};

export default ProgressStatusIcon;
