import { number, object, string } from 'prop-types';
import common from '@material-ui/core/colors/common';

const PaperPlaneIcon = ({
  className = '',
  style = {},
  width = 25,
  height = 25,
  fillColor = common.white,
  ...rest
}) => (
  <svg
    {...rest}
    className={className}
    style={style}
    width={width}
    height={height}
    viewBox='0 0 25 25'
    fill='none'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17.8838 7.11601L9.63381 12.844L1.0049 9.96708C0.402365 9.76591 -0.00339654 9.20097 2.14288e-05 8.56571C0.0034394 7.93095 0.414084 7.36894 1.01858 7.17509L23.0806 0.0705962C23.605 -0.0978609 24.1807 0.0403228 24.5703 0.429971C24.96 0.81962 25.0982 1.3953 24.9297 1.91972L17.8252 23.9817C17.6309 24.5862 17.0694 24.9969 16.4346 25.0003C15.7998 25.0037 15.2344 24.5979 15.0332 23.9954L12.1426 15.3245L17.8843 7.11601H17.8838Z'
      fill={fillColor}
    />
  </svg>
);

PaperPlaneIcon.propTypes = {
  style: object,
  className: string,
  height: number,
  width: number,
  fillColor: string,
};

export default PaperPlaneIcon;
