import { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Backdrop from '@material-ui/core/Backdrop';
import { AttachmentUploadedIcon, AttachmentIcon } from 'components/Icons';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import useUpload from 'hooks/upload';
import attachmentImg from 'assets/images/attachment.svg';
import attachmentUploadedImg from 'assets/images/attachment-uploaded.svg';
import { commonBtnStyles } from 'assets/styles/common';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    paddingTop: theme.spacing(2),
  },
  textField: {
    '&.MuiFormControl-fullWidth': { height: '100%' },
    '& .MuiInputAdornment-positionEnd': {
      position: 'absolute',
      right: 9,
      top: 25,
    },
    '& .MuiFilledInput-input': {
      height: '100% !important',
      paddingRight: theme.spacing(0.75),
      '&::-webkit-scrollbar': {
        width: 6,
        height: 8,
        display: 'block',
      },
      '&::-webkit-scrollbar-track': {
        background: 'inherit',
      },
      '&::-webkit-scrollbar-thumb': {
        visibility: 'hidden',
      },
      '&:hover::-webkit-scrollbar-thumb': {
        backgroundColor: theme.grey[500],
        borderRadius: 4,
        visibility: 'visible',
      },
    },
    '& .MuiFilledInput-root': {
      borderRadius: 0,
    },
    '& .MuiFilledInput-multiline': {
      borderRadius: 0,
      padding: `${theme.spacing(1.5)}px ${theme.spacing(6.5)}px ${theme.spacing(
        1,
      )}px ${theme.spacing(1.5)}px`,
      height: '100%',
    },
    '& .MuiFormHelperText-root.Mui-error': {
      color: theme.error.dark,
      fontFamily: 'Figtree-Bold',
      fontSize: theme.spacing(1.6),
    },
    '& .MuiFormHelperText-contained': {
      marginLeft: 0,
    },
  },
  attachmentIcon: {
    width: 35,
    height: 35,
    cursor: 'pointer',
  },
  imageBackDrop: {
    borderRadius: 5,
    position: 'absolute',
    zIndex: 5,
    color: theme.common.white,
    backgroundColor: theme.fadedBlack,
  },
  hr: {
    backgroundColor: theme.fadedBlue,
    height: 1,
  },
  saveBtn: {
    ...commonBtnStyles,
    backgroundColor: theme.blue,
    '&.Mui-disabled': {
      opacity: 0.7,
    },
    '& .MuiButton-label': {
      fontFamily: 'Figtree-Bold',
      color: theme.common.white,
      fontSize: '1.1rem',
    },
    '&:hover': {
      backgroundColor: theme.blue,
      opacity: 0.85,
      '&.Mui-disabled': {
        backgroundColor: theme.blue,
        opacity: 0.7,
      },
    },
  },
}));

const QuestionField = ({
  onSendFollowUpQuestion,
  followUpSubmissionIsLoading,
  sessionTrackingCode,
}) => {
  const classes = useStyles();

  const { getFieldProps, errors, touched, handleBlur, handleChange, handleSubmit, values } =
    useFormik({
      initialValues: {
        customer_message: '',
        customer_attachment_image_url: '',
        customer_attachment_image_encoded: '',
        customer_attachment_video_url: '',
        customer_attachment_video_encoded: '',
      },
      validationSchema: Yup.object({
        customer_message: Yup.string()
          .required('Question is required')
          .min(3, 'Question must be at least 3 characters'),
      }),
      onSubmit: (data) => {
        const { customer_message, customer_attachment_image_url, customer_attachment_video_url } =
          data;
        if (onSendFollowUpQuestion)
          onSendFollowUpQuestion({
            customer_message,
            customer_attachment_image_url: customer_attachment_image_url || undefined,
            customer_attachment_video_url: customer_attachment_video_url || undefined,
            session_tracking_code: sessionTrackingCode,
          });
      },
    });

  const handleTrimOnBlur = useCallback(
    (e) => {
      e.target.value = e.target.value.trim();
      handleChange(e);
      handleBlur(e);
    },
    [handleChange, handleBlur],
  );

  const handleFormImageChange = useCallback(
    (formNames) => (formData) => {
      const isVideoUploaded = formData.fileType.split('/')[0] === 'video';
      // image related updates
      handleChange({
        target: {
          name: formNames.image_file,
          value: isVideoUploaded ? '' : formData.encodedFile,
        },
      });
      handleChange({
        target: {
          name: formNames.image_url,
          value: isVideoUploaded ? '' : formData.fileName,
        },
      });
      // video related updates
      handleChange({
        target: {
          name: formNames.video_file,
          value: isVideoUploaded ? formData.encodedFile : '',
        },
      });
      handleChange({
        target: {
          name: formNames.video_url,
          value: isVideoUploaded ? formData.fileName : '',
        },
      });
    },
    [],
  );

  const {
    getRootProps: rootAttachmentProps,
    getInputProps: inputAttachmentProps,
    uploadIsLoading: attachmentUploadIsLoading,
  } = useUpload(
    handleFormImageChange({
      image_url: 'customer_attachment_image_url',
      image_file: 'customer_attachment_image_encoded',
      video_url: 'customer_attachment_video_url',
      video_file: 'customer_attachment_video_encoded',
    }),
    { disabled: false, videoAccept: true },
  );

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction='column' spacing={3} className={classes.formContainer}>
        <Grid item>
          <TextField
            classes={{ root: classes.textField }}
            variant='filled'
            fullWidth
            placeholder='Tap to start typing your question...'
            multiline
            minRows={6}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <div style={{ position: 'relative', height: 34 }} {...rootAttachmentProps()}>
                    {values.customer_attachment_image_encoded ||
                    values.customer_attachment_video_encoded ? (
                      <AttachmentUploadedIcon style={{ cursor: 'pointer' }} />
                    ) : (
                      <AttachmentIcon style={{ cursor: 'pointer' }} />
                    )}
                    <input {...inputAttachmentProps()} />
                    {attachmentUploadIsLoading && (
                      <Backdrop open className={classes.imageBackDrop}>
                        <CircularProgress color='inherit' thickness={3} size={20} />
                      </Backdrop>
                    )}
                  </div>
                </InputAdornment>
              ),
            }}
            {...getFieldProps('customer_message')}
            onBlur={handleTrimOnBlur}
            error={!!touched.customer_message && !!errors.customer_message}
            helperText={touched.customer_message && errors.customer_message}
          />
        </Grid>
        <Grid item container>
          <Grid item xs />
          <Grid item xs={10}>
            <Button
              disabled={followUpSubmissionIsLoading || attachmentUploadIsLoading}
              type='submit'
              className={classes.saveBtn}
              fullWidth
              size='large'
            >
              {followUpSubmissionIsLoading ? (
                <CircularProgress color='inherit' size={31} />
              ) : (
                <>Send Follow-Up Question</>
              )}
            </Button>
          </Grid>
          <Grid item xs />
        </Grid>
      </Grid>
    </form>
  );
};

export default QuestionField;
