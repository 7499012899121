const Attachment = ({ style }) => (
  <svg
    style={style}
    width='34'
    height='34'
    viewBox='0 0 47 47'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect width='47' height='47' rx='10' fill='#495EFF' />
    <g clipPath='url(#clip0_984_5632)'>
      <path
        d='M14.3328 34.0683C12.1983 34.0683 10.2996 32.7169 9.61016 30.7057L9.56329 30.5543C9.40079 30.0217 9.33266 29.5737 9.33266 29.1251V20.1377L6.09822 30.8126C5.68196 32.3827 6.63011 34.0102 8.22076 34.444L28.8387 39.9025C29.0962 39.9686 29.3531 40.0002 29.6068 40.0002C30.935 40.0002 32.1481 39.1289 32.4881 37.8449L33.6894 34.0683H14.3328Z'
        fill='white'
      />
      <path
        d='M17.9997 20.2273C19.4703 20.2273 20.6666 19.0446 20.6666 17.5907C20.6666 16.1368 19.4703 14.9541 17.9997 14.9541C16.529 14.9541 15.3328 16.1368 15.3328 17.5907C15.3328 19.0446 16.529 20.2273 17.9997 20.2273Z'
        fill='white'
      />
      <path
        d='M34.6661 11H14.6657C12.8282 11 11.3325 12.4793 11.3325 14.2953V28.7956C11.3325 30.6122 12.8288 32.0909 14.6657 32.0909H34.6661C36.5036 32.0909 37.9993 30.6116 37.9993 28.7956V14.2953C37.9993 12.4786 36.503 11 34.6661 11ZM14.6657 13.6366H34.6661C35.0342 13.6366 35.333 13.9319 35.333 14.2959V23.654L31.121 18.7955C30.6741 18.2777 30.0279 18.0008 29.3329 17.9848C28.6422 17.9885 27.9941 18.2919 27.5516 18.8165L22.5996 24.6927L20.9865 23.1016C20.0746 22.2001 18.5902 22.2001 17.6795 23.1016L13.9994 26.7386V14.2953C13.9994 13.9313 14.2982 13.636 14.6663 13.636L14.6657 13.6366Z'
        fill='white'
      />
    </g>
    <defs>
      <clipPath id='clip0_984_5632'>
        <rect width='32' height='29' fill='white' transform='translate(6 11)' />
      </clipPath>
    </defs>
  </svg>
);

export default Attachment;
