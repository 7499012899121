// TimeSpan class from backend
export class TimeSpan {
  constructor(input) {
    if (typeof input === "string") {
      this.parseFromString(input);
    } else if (typeof input === "number") {
      this.fromMilliseconds(input);
    } else {
      throw new Error("Constructor requires a string in HH:MM:SS.sss format or a number of milliseconds.");
    }
  }

  parseFromString(timestamp) {
    if (!/^\[?\d+:\d+:\d+(\.\d+)?\]?$/.test(timestamp)) {
      throw new Error(`Invalid time format for provided timestamp ${timestamp}. Please use HH:MM:SS.sss or [HH:MM:SS.sss]`);
    }
    const parts = timestamp
      .replace('[', '')
      .replace(']', '')
      .split(':');

    const secondsAndMs = parts[2].split('.');
    
    this.hours = parseInt(parts[0], 10);
    this.minutes = parseInt(parts[1], 10);
    this.seconds = parseInt(secondsAndMs[0], 10);
    this.milliseconds = parseInt(secondsAndMs[1], 10);

    if (!this.milliseconds || Number.isNaN(this.milliseconds)) {
      this.milliseconds = 0;
    }

    this.totalMilliseconds = this.toMilliseconds();
  }

  fromMilliseconds(milliseconds) {
    const remainingMilliseconds = milliseconds % 1000;
    const totalSeconds = (milliseconds - remainingMilliseconds) / 1000;
    const remainingSeconds = totalSeconds % 60;
    const totalMinutes = (totalSeconds - remainingSeconds) / 60;
    const remainingMinutes = totalMinutes % 60;
    const totalHours = (totalMinutes - remainingMinutes) / 60;

    this.milliseconds = remainingMilliseconds;
    this.seconds = remainingSeconds;
    this.minutes = remainingMinutes;
    this.hours = totalHours;

    this.totalMilliseconds = milliseconds;
  }

  add(other) {
    return new TimeSpan(this.totalMilliseconds + other.totalMilliseconds);
  }

  subtract(other) {
    const result = this.totalMilliseconds - other.totalMilliseconds;
    if (result < 0) {
      throw new Error("Resulting time span cannot be negative.");
    }

    return new TimeSpan(result);
  }

  toMilliseconds() {
    return this.milliseconds +
           this.seconds * 1000 +
           this.minutes * 60000 +
           this.hours * 3600000;
  }

  getTotalMilliseconds() {
    return this.totalMilliseconds;
  }

  getTotalSeconds() {
    return this.totalMilliseconds / 1000;
  }

  toString(includeBrackets) {
    const timestampString = `${this.hours.toString().padStart(2, '0')}:${this.minutes.toString().padStart(2, '0')}:${this.seconds.toString().padStart(2, '0')}`;
    
    if (includeBrackets) {
      return `[${timestampString}]`;
    } else {
      return timestampString;
    }
  }
}