import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  outerContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: 20,
    borderRadius: 10,
    backgroundColor: 'white',
    cursor: 'pointer',
    '& svg': {
      width: 26,
      height: 26,
      marginBottom: 5,
    },
    '& span': {
      fontFamily: 'Figtree-Bold',
      fontSize: 16,
      lineHeight: '21.33px',
      color: '#161616',
    },
    '& span:nth-child(2)': {
      
    },
    '& span:nth-child(3)': {
      fontFamily: 'Figtree-Regular',
      color: '#666666',
      marginBottom: 5,
    },
    '& span:nth-child(4)': {
      fontSize: 32,
      lineHeight: '43.67px',
      color: '#454545',
      '@media (max-width:400px)': {
        fontSize: 28,
      }
    },
  },
  selected: {
    boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.2)',
    border: '1px solid #495EFF',
    '& *': {
      color: '#495EFF !important',
    }
  }
}));

const PriorityOption = (props) => {
  const { Icon, title, subtitle, price, isSelected, onClick, } = props;

  const classes = useStyles();

  return (
    <div 
      className={`${classes.outerContainer} ${isSelected ? classes.selected : ''}`}
      onClick={onClick}
    >
      <Icon />
      <span>{title}</span>
      <span>{subtitle}</span>
      <span>{price}</span>
    </div>
  )
}

export default PriorityOption;