import { number, object, string } from 'prop-types';

const RightArrowIcon = ({
  className = '',
  style = {},
  width = 28,
  height = 28,
  fillColor = '#666666',
  ...rest
}) => (
  <svg
    {...rest}
    className={className}
    style={style}
    width={width}
    height={height}
    viewBox='0 0 37 37'
  >
    <rect
      y='18.3848'
      width='26'
      height='26'
      rx='13'
      transform='rotate(-45 0 18.3848)'
      fill={fillColor}
    />
    <g clipPath='url(#clip0_7137_9272)'>
      <path
        d='M24.9477 17.7558L19.8918 12.9808C19.7475 12.8445 19.5552 12.7695 19.35 12.7695C19.1448 12.7695 18.9525 12.8445 18.8082 12.9808L18.3492 13.4145C18.205 13.5505 18.1256 13.7324 18.1256 13.9262C18.1256 14.12 18.205 14.3078 18.3492 14.4438L21.2989 17.2355L11.9277 17.2355C11.5052 17.2355 11.1714 17.5478 11.1714 17.9472L11.1714 18.5602C11.1714 18.9593 11.5052 19.3033 11.9277 19.3033L21.3322 19.3033L18.3492 22.1106C18.205 22.2468 18.1256 22.4237 18.1256 22.6174C18.1256 22.8112 18.205 22.9904 18.3492 23.1267L18.8082 23.5588C18.9525 23.6951 19.1448 23.7695 19.35 23.7695C19.5552 23.7695 19.7475 23.6943 19.8918 23.558L24.9477 18.783C25.0923 18.6462 25.172 18.4637 25.1714 18.2697C25.172 18.0751 25.0923 17.8923 24.9477 17.7558Z'
        fill='white'
      />
    </g>
    <defs>
      <clipPath id='clip0_7137_9272'>
        <rect width='14' height='11' fill='white' transform='translate(11.1714 12.7695)' />
      </clipPath>
    </defs>
  </svg>
);

RightArrowIcon.propTypes = {
  style: object,
  className: string,
  height: number,
  width: number,
  fillColor: string,
};

export default RightArrowIcon;
