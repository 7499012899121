import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useSearchParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import { useAlert } from 'react-alert';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { AppDataContext } from 'Context';
import { Tooltip } from 'antd';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import { Tabs } from 'antd';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import GridWrapper from 'components/GridWrapper';
import { AppAuthHeader } from 'components/Header';
import {
  displayCustomerName,
  displayRemainingActiveTime,
  displayQnA,
  getFollowupStatusTooltip,
  getProgressBulletsArray,
  useToggleActions,
} from './utils';
import { formatPrice } from 'utils/currency-utils';
import { getInbox, queryCreatorTypes } from 'store/creator';
import { updateMessenger } from 'utils/intercomConfig';
import { socialMediaLinks, statusTabTypes, initDisplayOptions } from './constants';
import blueDotImg from 'assets/images/blue-dot.svg';
import favouriteStarImg from 'assets/images/favourite-star.svg';
import {
  CircleSuccessIcon,
  CircleErrorIcon,
  ClockIcon,
  EyeIcon,
  GoldStarIcon,
  PaperPlaneIcon,
  PaperFolderIcon,
  ProgressStatusIcon,
  StarIcon,
  InboxIcon,
} from 'components/Icons';
import SocialCardItem from './SocialCardItem';
import QuestionSessionDrawer from './QuestionSessionDrawer';
import { tipsValue } from 'utils/common/constants';
import chainlinkBlueImg from 'assets/images/chainlink-blue.svg';
import { inboxStyles as styles } from './styles';
import { slideoutTransitionDelay } from 'config/ui';
import ClipsElement from 'components/Clips/ClipsElement';

const useStyles = makeStyles(styles);

const shortArchiveTimeEnabled =
  process.env.REACT_APP_INBOX_TIME_TO_ARCHIVE_SHORT_ENABLED === 'true';

const Inbox = () => {
  const alert = useAlert();
  const classes = useStyles();
  const {
    breakpoints,
    blue,
    common: { black },
    fadedDarkerBlue,
    grey,
    secondary: { main: red },
  } = useTheme();
  const mobileView = useMediaQuery(breakpoints.down('xs'));
  const tabletView = useMediaQuery('(max-width:1070px)');

  const {
    appData: { user },
    convertPrice,
  } = useContext(AppDataContext);

  const queryClient = useQueryClient();

  const [searchParams, setSearchParams] = useSearchParams();

  const [displayOptions, setDisplayOptions] = useState(initDisplayOptions);
  const [list, setList] = useState([]);

  const [selectedQuestion, setSelectedQuestion] = useState(null);

  const [fixedTabBar, setFixedTabBar] = useState(false);

  // hide intercom launcher when drawer is open
  useEffect(() => {
    if (!!selectedQuestion) updateMessenger({ hide_default_launcher: true });
    else updateMessenger({ hide_default_launcher: false });
  }, [selectedQuestion]);

  // favorite/hide/submit QnA session actions
  const {
    onSendAnswerToAudience,
    onToggleFavoriteQuestion,
    onToggleHiddenQuestion,
    sendAnswerIsLoading,
    drawerClosable,
  } = useToggleActions(list, setList, setSelectedQuestion, alert, queryClient);

  const calculateDisplayPrice = (price, tipPrice) => {
    if (price === '0' || price === tipsValue) {
      price = 0;
    } else {
      price = convertPrice(price, {
        fromCurrency: 'USD',
        toCurrency: user.currency_code,
        roundUp: true,
      });
    }

    let displayDecimals = false;
    if (tipPrice) {
      tipPrice = convertPrice(tipPrice, { fromCurrency: 'USD', toCurrency: user.currency_code });
      displayDecimals = user.currency_code !== 'USD'; // Tips are converted to creator's currency (from USD) without rounding.
    } else {
      tipPrice = 0;
    }

    const total = price + tipPrice;

    return total === 0
      ? null
      : formatPrice(price + tipPrice, {
          currency: user.currency_code,
          hideDecimals: !displayDecimals,
        });
  };

  // initial question list fetching
  const { isFetching } = useQuery({
    queryKey: [queryCreatorTypes.inbox],
    queryFn: getInbox,
    onSuccess: (data) => {
      const formattedList = data.map((d) => ({
        ...d,
        display_price: calculateDisplayPrice(d.display_price, d.tip_price),
        time_to_archive: shortArchiveTimeEnabled
          ? DateTime.fromISO(d.last_submission_time).plus({ minutes: 6 }).toUTC().toISO()
          : DateTime.fromISO(d.last_submission_time).plus({ days: 1 }).toUTC().toISO(),
      }));
      setList(formattedList);
      if (searchParams.get('session')) {
        const foundQuestionSession = formattedList.find(
          (item) => item.session_tracking_code === searchParams.get('session'),
        );
        if (foundQuestionSession) {
          setSelectedQuestion(foundQuestionSession);
          updateMessenger({ hide_default_launcher: true });
        }
        setSearchParams(undefined);
      }
    },
  });

  // list collection per tab with:
  // 1) filtering for hidden questions
  const allTabsCollections = useMemo(
    () =>
      list.reduce(
        (obj, item) => {
          if (item.is_hidden && !displayOptions.showHidden) return obj;

          obj.all.push(item);

          if (item.is_favorite) obj.favorites.push(item);

          if (item.last_question_answered) obj.answered.push(item);
          else obj.unanswered.push(item);

          return obj;
        },
        {
          all: [],
          answered: [],
          unanswered: [],
          favorites: [],
        },
      ),
    [list, displayOptions, selectedQuestion],
  );

  // active/archive list collections per tab with:
  // 1) sorting active and archive lists
  const collectionsPerTab = useMemo(
    () =>
      Object.keys(allTabsCollections).reduce(
        (obj, tabKey) => {
          allTabsCollections[tabKey].forEach((item) => {
            /// question about is_completed
            if (item.is_completed || DateTime.fromISO(item.time_to_archive) <= DateTime.now())
              obj[tabKey].archive.push(item);
            else obj[tabKey].active.push(item);
          });

          // sorting active
          obj[tabKey].active.sort((a, b) => {
            if (a.last_question_answered && !b.last_question_answered) {
              return 1;
            } else if (!a.last_question_answered && b.last_question_answered) {
              return -1;
            } else if (!a.last_question_answered && !b.last_question_answered) {
              return DateTime.fromISO(a.time_to_archive) - DateTime.fromISO(b.time_to_archive);
            } else if (a.last_question_answered && b.last_question_answered) {
              return DateTime.fromISO(b.time_to_archive) - DateTime.fromISO(a.time_to_archive);
            } else {
              return 0;
            }
          });

          // sorting archive
          obj[tabKey].archive.sort(
            (a, b) => DateTime.fromISO(b.time_to_archive) - DateTime.fromISO(a.time_to_archive),
          );

          return obj;
        },
        {
          all: { active: [], archive: [] },
          answered: { active: [], archive: [] },
          unanswered: { active: [], archive: [] },
          favorites: { active: [], archive: [] },
        },
      ),
    [allTabsCollections],
  );

  const statusTabs = useMemo(
    () => [
      {
        key: statusTabTypes.all,
        label: (
          <div className={classes.tabTitle}>
            All <span>({allTabsCollections.all.length})</span>
          </div>
        ),
      },
      {
        key: statusTabTypes.answered,
        label: (
          <div className={classes.tabTitle}>
            Answered <span>({allTabsCollections.answered.length})</span>
          </div>
        ),
      },
      {
        key: statusTabTypes.unanswered,
        label: (
          <Grid
            container
            alignItems='center'
            spacing={1}
            style={{
              minWidth:
                allTabsCollections.unanswered.length < 10
                  ? 120
                  : allTabsCollections.unanswered.length < 100
                  ? 128
                  : allTabsCollections.unanswered.length < 1000
                  ? 136
                  : 144,
            }}
          >
            <Grid item>
              <img src={blueDotImg} alt='dot' style={{ paddingBottom: 5 }} />
            </Grid>
            <Grid item>
              <div className={classes.tabTitle}>
                Unanswered <span>({allTabsCollections.unanswered.length})</span>
              </div>
            </Grid>
          </Grid>
        ),
      },
      {
        key: statusTabTypes.favorites,
        label: (
          <Grid
            container
            alignItems='center'
            spacing={1}
            style={{
              minWidth:
                allTabsCollections.favorites.length < 100
                  ? 115
                  : allTabsCollections.favorites.length < 1000
                  ? 130
                  : 140,
            }}
          >
            <Grid item style={{ padding: '0 0 7px 4px' }}>
              <img src={favouriteStarImg} alt='favorite' style={{ width: '80%' }} />
            </Grid>
            <Grid item>
              <div className={classes.tabTitle}>
                Favorites <span>({allTabsCollections.favorites.length})</span>
              </div>
            </Grid>
          </Grid>
        ),
      },
    ],
    [allTabsCollections],
  );

  const onActiveTabChange = useCallback((activeTab) => {
    setDisplayOptions((prev) => ({ ...prev, page: 1, activeTab }));
  }, []);

  const onToggleHiddenQuestions = useCallback(() => {
    setDisplayOptions((prev) => ({ ...prev, showHidden: !prev.showHidden }));
  }, []);

  // unmoutn cleanup
  useEffect(
    () => () => {
      queryClient.invalidateQueries({ queryKey: [queryCreatorTypes.inbox] });
    },
    [],
  );

  useEffect(() => {
    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  const onScroll = () => {
    const clipsElement = document.getElementById('clips-element');
    const clipsElementHeight = clipsElement.clientHeight;

    const offset = window.scrollY;
    const headerHeight = 140; // Height of black header + blue header
    const totalHeight = headerHeight + clipsElementHeight;

    setFixedTabBar(offset > totalHeight);
  };

  return (
    <>
      <Grid container className={classes.inboxOuterContainer}>
        {isFetching || searchParams.get('session') ? (
          <Backdrop open className={classes.backdrop}>
            <CircularProgress color='inherit' thickness={3} size={70} />
          </Backdrop>
        ) : null}
        <GridWrapper isFullWidthChild>
          <Grid
            container
            direction='column'
            justifyContent='space-between'
            className={classes.inboxInnerContainer}
          >
            {/* black header - navigation menu toggler */}
            <AppAuthHeader />
            {/* Inbox blue header */}
            <Grid item container alignItems='center' className={classes.headerContainer}>
              <Grid item>
                <div className={classes.logoPlaceholder}>
                  <img src={user.logo_image} alt='profile' />
                </div>
              </Grid>
              <Grid item>
                <div className={classes.headerText}>
                  {user.asqMe_tag || ''} <span>Inbox</span>
                </div>
              </Grid>
            </Grid>
            {process.env.REACT_APP_ENABLE_CLIPS === 'true' && (
              <Grid id='clips-element' item container className={classes.clipsElement}>
                <ClipsElement
                  showCloseButton
                  showDoNotShowAgainButton
                  style={{ margin: '20px 0' }}
                />
              </Grid>
            )}
            {!isFetching && (
              <>
                {/* tabs section */}
                <Grid
                  item
                  container
                  className={classes.tabsContainer}
                  style={{ position: fixedTabBar ? 'fixed' : 'initial' }}
                >
                  <Grid item xs={12}>
                    <Tabs
                      defaultActiveKey={displayOptions.activeTab}
                      items={statusTabs}
                      onChange={onActiveTabChange}
                      className={`${classes.tabs}`}
                    />
                  </Grid>
                </Grid>
                {fixedTabBar && (
                  <Grid item container className={classes.tabsContainerPlaceholder} />
                )}
                {/* list section */}
                <Grid item container className={classes.listOuterContainer}>
                  {/* scrollable list */}
                  <div className={classes.listInnerContainer}>
                    {/* active text */}
                    {!!collectionsPerTab[displayOptions.activeTab].active.length && (
                      <div className={classes.activeText}>Active</div>
                    )}
                    {/* active list */}
                    {collectionsPerTab[displayOptions.activeTab].active.map((item, idx, arr) => {
                      const start = DateTime.now();
                      const end = DateTime.fromISO(item.time_to_archive);

                      const { hours, minutes, seconds } = end
                        .diff(start, ['hours', 'minutes', 'seconds'])
                        .toObject();

                      const timeUnit = shortArchiveTimeEnabled ? minutes : hours;

                      const isUrgent = shortArchiveTimeEnabled
                        ? (minutes === 1 && seconds <= 30) || minutes < 1
                        : hours < 6;

                      const unansweredUrgent = isUrgent && !item.last_question_answered;
                      const unansweredWithTime = !isUrgent && !item.last_question_answered;
                      const answered = item.last_question_answered;
                      const isHidden = item.is_hidden;

                      const StatusComponent = answered ? PaperPlaneIcon : ClockIcon;

                      const progressBulletsProps = getProgressBulletsArray(item).reduce(
                        (obj, key) => {
                          obj[key] = isHidden ? grey[600] : unansweredUrgent ? red : blue;
                          return obj;
                        },
                        {},
                      );

                      const actionIconProps = {
                        width: 21,
                        height: 21,
                        className: `${classes.hideIcon} ${tabletView ? 'tablet' : ''}`,
                        fillColor: item.is_hidden ? fadedDarkerBlue : undefined,
                        onClick: (e) => {
                          e.stopPropagation();
                          if (item.is_favorite)
                            onToggleFavoriteQuestion({
                              id: item.id,
                              is_favorite: false,
                            });
                          else
                            onToggleHiddenQuestion({
                              id: item.id,
                              is_hidden: !item.is_hidden,
                            });
                        },
                      };

                      const ActionIcon = item.is_favorite ? GoldStarIcon : EyeIcon;

                      return tabletView ? (
                        <div
                          onClick={() => setSelectedQuestion(item)}
                          key={item.session_tracking_code}
                          className={`${classes.listItemContainer} tablet  ${
                            unansweredUrgent && !isHidden
                              ? 'thickLeft red'
                              : unansweredWithTime && !isHidden
                              ? 'thickLeft blue'
                              : (unansweredUrgent || unansweredWithTime) && isHidden
                              ? 'thickLeft grey'
                              : 'noBorder'
                          }`}
                        >
                          <Grid container justifyContent='space-between' alignItems='center'>
                            {/* clock-price / actions */}
                            <Grid item>
                              <Grid container spacing={2} alignItems='center'>
                                <Grid item className={classes.statusIconContainer}>
                                  <Tooltip
                                    overlayClassName={classes.tooltip}
                                    title={displayRemainingActiveTime(
                                      item,
                                      timeUnit,
                                      shortArchiveTimeEnabled,
                                    )}
                                  >
                                    <StatusComponent
                                      width={20}
                                      height={20}
                                      fillColor={
                                        isHidden
                                          ? grey[600]
                                          : unansweredUrgent
                                          ? red
                                          : fadedDarkerBlue
                                      }
                                    />
                                  </Tooltip>
                                </Grid>
                                <Grid item>
                                  <div
                                    className={`${classes.price} ${
                                      isHidden ? '' : unansweredUrgent ? 'red' : 'blue'
                                    }`}
                                  >
                                    {item.is_tip_enabled
                                      ? 'Tips'
                                      : item.is_no_charge
                                      ? '    '
                                      : item.display_price}
                                  </div>
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid item>
                              <Grid container spacing={2} alignItems='center'>
                                <Grid item className={`${classes.actionButtonContainer} tablet`}>
                                  <ActionIcon {...actionIconProps} />
                                </Grid>
                                <Grid item>
                                  <div className={classes.verticalHr} />
                                </Grid>
                                <Grid item>
                                  <div
                                    className={`${classes.remainingTime} ${
                                      unansweredUrgent && !isHidden ? 'red' : ''
                                    } tablet`}
                                  >
                                    {displayRemainingActiveTime(
                                      item,
                                      timeUnit,
                                      shortArchiveTimeEnabled,
                                    )}
                                  </div>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>

                          {/* customer name */}
                          <Grid container>
                            <Grid item>
                              <div className={classes.customerNameContainer}>
                                {displayCustomerName(item)}
                              </div>
                            </Grid>
                          </Grid>

                          {/* status / price / time left / actions */}
                          <Grid container>
                            {/* question title */}
                            <Grid item className={`${classes.questionTitleContainer} tablet`}>
                              <div className={classes.questionTitle}>{displayQnA(item)}</div>
                            </Grid>
                          </Grid>

                          {isHidden && <div className={classes.overlay} />}
                        </div>
                      ) : (
                        <div
                          onClick={() => setSelectedQuestion(item)}
                          key={item.session_tracking_code}
                          className={`${classes.listItemContainer}  ${
                            unansweredUrgent && !isHidden
                              ? 'thickLeft red'
                              : unansweredWithTime && !isHidden
                              ? 'thickLeft blue'
                              : (unansweredUrgent || unansweredWithTime) && isHidden
                              ? 'thickLeft grey'
                              : 'noBorder'
                          }`}
                        >
                          <Grid container justifyContent='space-between' alignItems='center'>
                            {/* clock / customer name */}
                            <Grid item>
                              <Grid container spacing={2} alignItems='center'>
                                <Grid item className={classes.statusIconContainer}>
                                  <Tooltip
                                    overlayClassName={classes.tooltip}
                                    title={displayRemainingActiveTime(
                                      item,
                                      timeUnit,
                                      shortArchiveTimeEnabled,
                                    )}
                                  >
                                    <StatusComponent
                                      width={20}
                                      height={20}
                                      fillColor={
                                        isHidden
                                          ? grey[600]
                                          : unansweredUrgent
                                          ? red
                                          : fadedDarkerBlue
                                      }
                                    />
                                  </Tooltip>
                                </Grid>
                                <Grid item>
                                  <div className={classes.customerNameContainer}>
                                    {displayCustomerName(item)}
                                  </div>
                                </Grid>
                              </Grid>
                            </Grid>
                            {/* question / answer display */}
                            <Grid item className={classes.questionTitleContainer}>
                              <div className={classes.questionTitle}>{displayQnA(item)}</div>
                            </Grid>
                            {/* status / price / time left / actions */}
                            <Grid item className={classes.lastInfoSectionContainer}>
                              <Grid container alignItems='center' spacing={3}>
                                <Grid item>
                                  <div>
                                    {getFollowupStatusTooltip(
                                      item,
                                      classes,
                                      progressBulletsProps,
                                      <ProgressStatusIcon {...progressBulletsProps} />,
                                    )}
                                  </div>
                                </Grid>
                                <Grid item>
                                  <div
                                    className={`${classes.price} ${
                                      isHidden ? '' : unansweredUrgent ? 'red' : 'blue'
                                    }`}
                                  >
                                    {item.is_tip_enabled
                                      ? 'Tips'
                                      : item.is_no_charge
                                      ? '    '
                                      : item.display_price}
                                  </div>
                                </Grid>
                                <Grid item>
                                  <div
                                    className={`${classes.remainingTime} ${
                                      unansweredUrgent && !isHidden ? 'red' : ''
                                    }`}
                                  >
                                    {displayRemainingActiveTime(
                                      item,
                                      timeUnit,
                                      shortArchiveTimeEnabled,
                                    )}
                                  </div>
                                </Grid>
                                <Grid item>
                                  <div className={classes.verticalHr} />
                                </Grid>
                                <Grid item className={classes.actionButtonContainer}>
                                  <ActionIcon {...actionIconProps} />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          {isHidden && <div className={classes.overlay} />}
                        </div>
                      );
                    })}
                    {/* archive text */}
                    {!!collectionsPerTab[displayOptions.activeTab].archive.length && (
                      <div
                        className={`${classes.archiveText} ${
                          !collectionsPerTab[displayOptions.activeTab].active.length
                            ? 'shrinkSpace'
                            : ''
                        }`}
                      >
                        Archive
                      </div>
                    )}
                    {/* archive list */}
                    {collectionsPerTab[displayOptions.activeTab].archive.map((item, idx, arr) => {
                      const isCompleted = item.is_completed;
                      const isHidden = item.is_hidden;
                      const isAnswered = item.last_question_answered;

                      const getStatusTooltip = (Component) =>
                        isCompleted ? (
                          <Tooltip
                            overlayClassName={classes.tooltip}
                            title={item.has_feedback ? 'Answer Rejected' : 'Answer Accepted'}
                          >
                            {Component}
                          </Tooltip>
                        ) : (
                          <>{Component}</>
                        );

                      const StatusComponent = !isCompleted
                        ? isAnswered
                          ? PaperPlaneIcon
                          : PaperFolderIcon
                        : item.has_feedback
                        ? CircleErrorIcon
                        : CircleSuccessIcon;

                      const progressBulletsProps = getProgressBulletsArray(item).reduce(
                        (obj, key) => {
                          obj[key] = grey[600];
                          return obj;
                        },
                        {},
                      );

                      const actionIconProps = {
                        width: 21,
                        height: 21,
                        className: `${classes.hideIcon} ${tabletView ? 'tablet' : ''}`,
                        fillColor: item.is_hidden ? fadedDarkerBlue : undefined,
                        onClick: (e) => {
                          e.stopPropagation();
                          if (item.is_hidden)
                            onToggleHiddenQuestion({ id: item.id, is_hidden: false });
                          else
                            onToggleFavoriteQuestion({
                              id: item.id,
                              is_favorite: !item.is_favorite,
                            });
                        },
                      };
                      const ActionIcon = item.is_hidden
                        ? EyeIcon
                        : item.is_favorite
                        ? GoldStarIcon
                        : StarIcon;

                      return tabletView ? (
                        <div
                          onClick={() => setSelectedQuestion(item)}
                          key={item.session_tracking_code}
                          className={`${classes.listItemContainer} tablet ${
                            !isCompleted && !isAnswered ? 'thickLeft grey' : 'noBorder'
                          }`}
                        >
                          <Grid container justifyContent='space-between' alignItems='center'>
                            {/* clock-price / actions */}
                            <Grid item>
                              <Grid container spacing={2} alignItems='center'>
                                <Grid item className={classes.statusIconContainer}>
                                  {getStatusTooltip(
                                    <StatusComponent
                                      width={20}
                                      height={20}
                                      fillColor={grey[500]}
                                    />,
                                  )}
                                </Grid>
                                <Grid item>
                                  <div className={`${classes.price} `}>
                                    {item.is_no_charge || item.has_feedback
                                      ? '   '
                                      : item.is_completed
                                      ? item.display_price == null
                                        ? '   '
                                        : item.display_price
                                      : 'Tips'}
                                  </div>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item>
                              <Grid container spacing={2} alignItems='center'>
                                <Grid item className={`${classes.actionButtonContainer} tablet`}>
                                  <ActionIcon {...actionIconProps} />
                                </Grid>
                                <Grid item>
                                  <div className={classes.verticalHr} />
                                </Grid>
                                <Grid item>
                                  <div className={`${classes.remainingTime} tablet`}>
                                    {DateTime.fromISO(item.last_submission_time).toFormat('MMM dd')}
                                  </div>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>

                          {/* customer name */}
                          <Grid container>
                            <Grid item>
                              <div className={classes.customerNameContainer}>
                                {displayCustomerName(item)}
                              </div>
                            </Grid>
                          </Grid>

                          {/* status / price / time left / actions */}
                          <Grid container>
                            {/* question title */}
                            <Grid item className={`${classes.questionTitleContainer} tablet`}>
                              <div className={classes.questionTitle}>{displayQnA(item)}</div>
                            </Grid>
                          </Grid>

                          {isHidden && <div className={classes.overlay} />}
                        </div>
                      ) : (
                        <div
                          onClick={() => setSelectedQuestion(item)}
                          key={item.session_tracking_code}
                          className={`${classes.listItemContainer}  ${
                            !isCompleted && !isAnswered ? 'thickLeft grey' : 'noBorder'
                          }`}
                        >
                          <Grid container justifyContent='space-between' alignItems='center'>
                            {/* clock / customer name */}
                            <Grid item>
                              <Grid container spacing={2} alignItems='center'>
                                <Grid item className={classes.statusIconContainer}>
                                  {getStatusTooltip(
                                    <StatusComponent
                                      width={20}
                                      height={20}
                                      fillColor={grey[500]}
                                    />,
                                  )}
                                </Grid>
                                <Grid item>
                                  <div className={classes.customerNameContainer}>
                                    {displayCustomerName(item)}
                                  </div>
                                </Grid>
                              </Grid>
                              <div className={classes.clockContainer}></div>
                            </Grid>
                            {/* question title */}
                            <Grid item className={classes.questionTitleContainer}>
                              <div className={classes.questionTitle}>{displayQnA(item)}</div>
                            </Grid>
                            {/* status / price / time left / actions */}
                            <Grid item className={classes.lastInfoSectionContainer}>
                              <Grid container alignItems='center' spacing={3}>
                                <Grid item>
                                  <div>
                                    {getFollowupStatusTooltip(
                                      item,
                                      classes,
                                      progressBulletsProps,
                                      <ProgressStatusIcon {...progressBulletsProps} />,
                                    )}
                                  </div>
                                </Grid>
                                <Grid item>
                                  <div className={`${classes.price} `}>
                                    {item.is_no_charge || item.has_feedback
                                      ? '   '
                                      : item.is_completed
                                      ? item.display_price === tipsValue
                                        ? '   '
                                        : item.display_price
                                      : 'Tips'}
                                  </div>
                                </Grid>
                                <Grid item>
                                  <div className={`${classes.remainingTime} `}>
                                    {DateTime.fromISO(item.last_submission_time).toFormat('MMM dd')}
                                  </div>
                                </Grid>
                                <Grid item>
                                  <div className={classes.verticalHr} />
                                </Grid>
                                <Grid item className={classes.actionButtonContainer}>
                                  <ActionIcon {...actionIconProps} />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          {isHidden && <div className={classes.overlay} />}
                        </div>
                      );
                    })}
                    {/* links/handle section */}
                    <Grid
                      container
                      direction='column'
                      alignItems='center'
                      className={classes.noQuestionContainer}
                    >
                      {/* first section */}
                      <Grid
                        item
                        container
                        direction='column'
                        alignItems='center'
                        className={`${classes.noQuestionFirstSection} ${
                          mobileView ? 'mobile' : ''
                        }`}
                      >
                        {!list.length ? (
                          <>
                            <Grid item>
                              <InboxIcon width={mobileView ? 25 : undefined} />
                            </Grid>
                            <Grid item>
                              <div
                                className={`${classes.noQuestionTitle} ${
                                  mobileView ? 'mobile' : ''
                                }`}
                              >
                                Looks like you don’t have any questions yet.
                              </div>
                            </Grid>
                            <Grid item>
                              <div
                                className={`${classes.moreQuestionsText} ${
                                  mobileView ? 'mobile' : ''
                                }`}
                              >
                                Get more questions by promoting your AsqMe link everywhere you share
                                content.
                              </div>
                            </Grid>
                          </>
                        ) : (
                          <Grid item>
                            <div
                              className={`${classes.moreQuestionsStandaloneText} ${
                                mobileView ? 'mobile' : ''
                              }`}
                            >
                              Get more questions by promoting your AsqMe link everywhere you share
                              content.
                            </div>
                          </Grid>
                        )}

                        {/* handle link */}
                        <Grid
                          item
                          container
                          justifyContent='space-between'
                          alignItems='center'
                          className={`${classes.inboxTagContainer} ${mobileView ? 'mobile' : ''}`}
                        >
                          <Grid item>
                            <div className={classes.asqmeUrl}>
                              AsqMe.com/<span>{user.asqMe_tag || ''}</span>
                            </div>
                          </Grid>
                          <Grid item>
                            <CopyToClipboard
                              text={`${process.env.REACT_APP_WEB_BASE_URL}/${user.asqMe_tag || ''}`}
                              onCopy={() => {
                                alert.success(null, {
                                  title: 'All set',
                                  body: 'Link copied to clipboard.',
                                  timeout: 1000 + slideoutTransitionDelay,
                                  offsetError: true,
                                });
                              }}
                            >
                              <img
                                src={chainlinkBlueImg}
                                className={classes.chainIcon}
                                alt='chain'
                              />
                            </CopyToClipboard>
                          </Grid>
                        </Grid>
                      </Grid>
                      {/* second section */}
                      <Grid item container>
                        <Grid item xs />
                        <Grid item xs={10}>
                          <Grid
                            container
                            spacing={2}
                            alignItems='stretch'
                            className={classes.linkSectionContainer}
                          >
                            {socialMediaLinks.map(
                              ({ icon: Icon, text, link, linkText, mobileEnabled }) =>
                                !mobileView || (mobileView && mobileEnabled) ? (
                                  <Grid item key={link} xs={12} sm={6} md={3}>
                                    <SocialCardItem
                                      classes={classes}
                                      mobileView={mobileView}
                                      icon={<Icon style={{ width: 40, height: 40 }} />}
                                      text={text}
                                      link={link}
                                      linkText={linkText}
                                    />
                                  </Grid>
                                ) : null,
                            )}
                          </Grid>
                        </Grid>
                        <Grid item xs />
                      </Grid>
                    </Grid>
                    <Grid item>
                      <div className={`${classes.moreTipsText} ${mobileView ? 'mobile' : ''}`}>
                        For more tips, please read our{' '}
                        <a
                          className={`${classes.contentLink} ${mobileView ? 'mobile' : ''}`}
                          target='_blank'
                          href='https://help.asqme.com/en/collections/7234856-creator-resources'
                        >
                          Content Creator Guide
                        </a>
                      </div>
                    </Grid>
                  </div>
                </Grid>
                {/* footer */}
                {!!list.length && (
                  <Grid
                    item
                    container
                    justifyContent='space-between'
                    alignItems='center'
                    className={classes.footerContainer}
                  >
                    <Grid item></Grid>
                    <Grid item>
                      <Grid container alignItems='center' spacing={2}>
                        <Grid item>
                          <EyeIcon fillColor={black} className={classes.footerShowHiddenIcon} />
                        </Grid>
                        <Grid item>
                          <div>Show Hidden</div>
                        </Grid>
                        <Grid item>
                          <Switch
                            classes={{
                              root: classes.switchRoot,
                              track: classes.switchTrack,
                              checked: classes.switchChecked,
                              switchBase: classes.switchBase,
                            }}
                            checked={displayOptions.showHidden}
                            onChange={onToggleHiddenQuestions}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </GridWrapper>
      </Grid>

      <QuestionSessionDrawer
        alert={alert}
        sendAnswerIsLoading={sendAnswerIsLoading}
        mobileType={mobileView}
        user={user}
        open={!!selectedQuestion}
        onClose={() => {
          if (drawerClosable) setSelectedQuestion(null);
        }}
        onToggleFavoriteQuestion={onToggleFavoriteQuestion}
        onToggleHiddenQuestion={onToggleHiddenQuestion}
        onSendAnswerToAudience={onSendAnswerToAudience}
        selectedQuestion={selectedQuestion}
      />
    </>
  );
};

export default Inbox;
