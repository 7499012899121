import { number, object, string } from 'prop-types';

const Clock = ({
  className = '',
  style = {},
  width = 35,
  height = 35,
  fillColor = '#495EFF',
  ...rest
}) => (
  <svg
    {...rest}
    className={className}
    style={style}
    width={width}
    height={height}
    viewBox='0 0 35 35'
    fill='none'
  >
    <path
      d='M17.4996 0C7.83474 0 0 7.83474 0 17.4996C0 27.1644 7.83474 34.9992 17.4996 34.9992C27.1644 34.9992 34.9992 27.1644 34.9992 17.4996C34.9885 7.83967 27.1603 0.010664 17.4996 0ZM23.9866 23.9874C23.3033 24.6707 22.1958 24.6707 21.5125 23.9874L16.2626 18.7366C15.9344 18.4085 15.7499 17.9631 15.7499 17.4996V6.99967C15.7499 6.03335 16.5333 5.24996 17.4996 5.24996C18.4659 5.24996 19.2493 6.03335 19.2493 6.99967V16.7753L23.9866 21.5125C24.6699 22.1958 24.6699 23.3033 23.9866 23.9866V23.9874Z'
      fill={fillColor}
    />
  </svg>
);

Clock.propTypes = {
  style: object,
  className: string,
  height: number,
  width: number,
  fillColor: string,
  strokeColor: string,
};

export default Clock;
