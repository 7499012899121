import { useMemo } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PaymentForm from '../../PendingPayment/PaymentForm';
import { CoffeIcon, PizzaSliceIcon, PizzaWholeIcon } from 'components/Icons';
import { formatPrice } from 'utils/currency-utils';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const TipPaymentForm = ({
  answerData,
  classes,
  clientSecret,
  error: propError,
  onResetClientSecret,
  redirectUrl,
  tip,
}) => {
  const {
    common: { white },
    blue,
  } = useTheme();

  const stripeProps = useMemo(
    () =>
      clientSecret
        ? {
            options: {
              clientSecret,
              appearance: {
                theme: 'stripe',
                labels: 'floating',
                rules: {
                  '.Input': {
                    padding: '7px',
                  },
                },
              },
            },
            stripe: stripePromise,
          }
        : null,
    [clientSecret],
  );

  const TipIconComponent =
    tip === '2' ? CoffeIcon : tip === '5' ? PizzaSliceIcon : tip === '10' ? PizzaWholeIcon : null;

  return (
    <>
      {/* price section */}
      <Grid
        item
        container
        direction='column'
        alignItems='center'
        justifyContent='center'
        className={classes.tipHeaderContainer}
      >
        <Grid item>
          <TipIconComponent fillColor={blue} strokeColor={white} />
        </Grid>
        <Grid item>
          <div className={classes.tipHeader}>Tip {formatPrice(tip, { appendCurrency: true, hideDecimals: true })}</div>
        </Grid>
      </Grid>

      {/* payment form */}
      <Grid item container className={classes.tipFormContainer}>
        <Grid item xs />
        <Grid item xs={10}>
          {stripeProps && (
            <Elements {...stripeProps}>
              <Grid container className={classes.formContainer}>
                <Grid item>
                  <PaymentForm
                    answerData={answerData}
                    classes={classes}
                    submitText={`Tip ${formatPrice(tip, { appendCurrency: true, hideDecimals: true })}`}
                    error={propError}
                    footerGrid={6}
                    footerButton={
                      <Grid item>
                        <div className={classes.goBackContainer} onClick={onResetClientSecret}>
                          Go Back
                        </div>
                      </Grid>
                    }
                    paymentInformationFooter={
                      <div className={classes.paymentInformationFooter}>
                        Your card will be charged {formatPrice(tip, { appendCurrency: true, hideDecimals: true })}
                      </div>
                    }
                    redirectUrl={redirectUrl}
                  />
                </Grid>
              </Grid>
            </Elements>
          )}
        </Grid>
        <Grid item xs />
      </Grid>
    </>
  );
};

export default TipPaymentForm;
