import { CircularProgress, Grid } from '@material-ui/core';
import { useEffect } from 'react';
import PageWrap from 'routes/Subscribe/page-wrap';
import { fetchApi } from 'utils/apiConfig';

export default function StripeLogin() {
  useEffect(() => {
    async function fetch() {
      const resp = await fetchApi('/creator/stripe-login');
      window.location.href = resp.url;
    }
    fetch();
  }, []);

  return (
    <>
      <PageWrap>
        <Grid
          container
          direction='column'
          justifyContent='center'
          alignItems='center'
          style={{ minHeight: '100vh' }}
        >
          <h1>Please wait...</h1>
          <CircularProgress style={{ marginBottom: 10 }} />
          <p>You will be redirected shortly</p>
        </Grid>
      </PageWrap>
    </>
  );
}
