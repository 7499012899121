import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  notFoundContainer: { height: '100%' },
  header: { fontSize: '2.5rem', fontFamily: 'Figtree-Bold' },
  description: { fontSize: '1.3rem' },
}));

const NotFound = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.notFoundContainer}>
      <Grid item sm xs />
      <Grid item sm={6} xs={12}>
        <Grid
          container
          direction='column'
          justifyContent='center'
          className={classes.notFoundContainer}
        >
          <Grid item container alignItems='center' direction='column'>
            <Grid item>
              <div className={classes.header}>404</div>
            </Grid>
            <Grid item>
              <div className={classes.description}>Error - Page not found</div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm xs />
    </Grid>
  );
};

export default NotFound;
