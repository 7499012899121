import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grow from '@material-ui/core/Grow';
import Button from '@material-ui/core/Button';
import { AppLogoIcon } from 'components/Icons';
import { commonBtnStyles } from 'assets/styles/common';
import { isProdEnv } from 'config/constants';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    backgroundColor: '#f1f1f1',
  },
  growContainer: {
    height: '100%',
  },
  mainContainer: {
    // minHeight: 600,
    backgroundColor: theme.common.white,
    borderRadius: 5,
    boxShadow: '0 12px 40px rgba(0,0,0,0.12)',
  },
  logo: {
    marginTop: theme.spacing(6),
  },
  header: {
    fontSize: '1.7rem',
    marginTop: theme.spacing(3),
    color: theme.grey[800],
    '&.shrink': {
      fontSize: '1.5rem',
      marginTop: theme.spacing(2),
    },
  },
  actionHeader: {
    fontSize: '1.9rem',
    fontFamily: 'Figtree-SemiBold',
    marginTop: theme.spacing(4),
    '&.shrink': {
      fontSize: '1.7rem',
      marginTop: theme.spacing(3),
    },
  },
  description: {
    fontSize: '1.3rem',
    textAlign: 'center',
    color: theme.grey[700],
    padding: `0 ${theme.spacing(3)}px`,
    marginTop: theme.spacing(5),
    '&.shrink': {
      fontSize: '1.1rem',
      marginTop: theme.spacing(4),
    },
  },
  closeBtn: {
    ...commonBtnStyles,
    margin: `${theme.spacing(5)}px 0`,
    backgroundColor: theme.blue,
    '&.Mui-disabled': {
      opacity: 0.7,
    },
    '& .MuiButton-label': {
      fontFamily: 'Figtree-Bold',
      color: theme.common.white,
      fontSize: '1.1rem',
    },
    '&:hover': {
      backgroundColor: theme.blue,
      opacity: 0.85,
      '&.Mui-disabled': {
        backgroundColor: theme.blue,
        opacity: 0.7,
      },
    },
  },
}));

const AccountNeedsVerification = () => {
  const classes = useStyles();
  const { logoPersonGrey, blue, breakpoints } = useTheme();
  const mobileView = useMediaQuery(breakpoints.down('xs'));

  const navigate = useNavigate();

  useEffect(() => {
    if (!sessionStorage.getItem('skip_authentication_process')) navigate('/auth/connect');
    sessionStorage.removeItem('skip_authentication_process');
  }, []);

  return (
    <Grid container className={classes.container}>
      <Grid item sm={3} xs />
      <Grid item sm={6} xs={10}>
        <Grid container className={classes.growContainer}>
          <Grid item xs={12}>
            <Grid
              container
              direction='column'
              justifyContent='center'
              className={classes.growContainer}
            >
              <Grid item>
                <Grow in timeout={1000}>
                  <Grid
                    container
                    direction='column'
                    alignItems='center'
                    className={classes.mainContainer}
                  >
                    <Grid item>
                      <AppLogoIcon
                        className={classes.logo}
                        strokeColor={blue}
                        fillColor={logoPersonGrey}
                      />
                    </Grid>
                    <Grid item>
                      <div className={`${classes.header} ${mobileView ? 'shrink' : ''}`}>
                        Welcome to AsqMe
                      </div>
                    </Grid>
                    <Grid item>
                      <div className={`${classes.actionHeader} ${mobileView ? 'shrink' : ''}`}>
                        Verify Your Account
                      </div>
                    </Grid>
                    <Grid item>
                      <div className={`${classes.description} ${mobileView ? 'shrink' : ''}`}>
                        Please check your email to verify your AsqMe account.
                      </div>
                    </Grid>
                    <Grid item container>
                      <Grid item xs />

                      <Grid item xs={10}>
                        <Button
                          className={classes.closeBtn}
                          type='submit'
                          variant='outlined'
                          fullWidth
                          size='large'
                          onClick={() =>
                            isProdEnv
                              ? window.location.replace(process.env.REACT_APP_WEB_BASE_URL)
                              : navigate('/auth/connect')
                          }
                        >
                          Close
                        </Button>
                      </Grid>
                      <Grid item xs />
                    </Grid>
                  </Grid>
                </Grow>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={3} xs />
    </Grid>
  );
};

export default AccountNeedsVerification;
