import React from 'react';
import ReactDOM from 'react-dom/client';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import TagManager from 'react-gtm-module';
import { ThemeProvider } from '@material-ui/core/styles';
import { Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import { AppDataProvider } from 'Context';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './App';
import queryClientInitialization from 'store/storeConfig';
import { initApiConfiguration } from 'utils/apiConfig';
import { intecomInitialization } from 'utils/intercomConfig';
import { alertTemplateOptions } from 'config/ui';
import translationDomFixConfig from 'utils/googleTranslationDomFixConfig';
import { isProdEnv } from 'config/constants';
import theme from 'assets/theme';
import { AnalyticsBrowser as Segment } from '@segment/analytics-next';
import { AnalyticsBrowser as Cio } from '@customerio/cdp-analytics-browser';

// reset/normalize styles
import 'assets/styles/normalize.css';
// antd styles
import 'antd/dist/antd.min.css';
// custom styles
import 'assets/styles/index.css';
import ErrorSlideout from 'components/ErrorSlideout';

// google translation dom fix (should be invoked before rendering))
translationDomFixConfig();

// server state initialization
const queryClient = queryClientInitialization();

//axios configuration setup
initApiConfiguration();

// cio initialization
export const cio =
  process.env.REACT_APP_CUSTOMERIO_WRITE_KEY && process.env.NODE_ENV !== 'development'
    ? Cio.load(
        { writeKey: process.env.REACT_APP_CUSTOMERIO_WRITE_KEY },
        {
          integrations: {
            'Customer.io In-App Plugin': {
              siteId: process.env.REACT_APP_CUSTOMERIO_SITE_ID,
            },
          },
        },
      )
    : null;

// segment initialization
export const segment =
  process.env.REACT_APP_SEGMENT_ID && process.env.NODE_ENV !== 'development'
    ? Segment.load({ writeKey: process.env.REACT_APP_SEGMENT_ID })
    : null;

// google tag manager initialization
if (process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID)
  TagManager.initialize({ gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID });

// intercom initialization
intecomInitialization();

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <ThemeProvider theme={theme}>
    <AlertProvider template={ErrorSlideout} {...alertTemplateOptions}>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
        authorizationParams={{
          redirect_uri: isProdEnv ? process.env.REACT_APP_WEB_BASE_URL : undefined,
        }}
      >
        <QueryClientProvider client={queryClient}>
          <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}>
            <AppDataProvider>
              <App />
            </AppDataProvider>
          </GoogleOAuthProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </Auth0Provider>
    </AlertProvider>
  </ThemeProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
