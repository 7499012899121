import Grid from '@material-ui/core/Grid';

const SocialCardItem = ({ classes, mobileView, icon, text, link, linkText }) => (
  <Grid
    container
    direction='column'
    justifyContent='space-between'
    className={classes.socialItemContainer}
  >
    <Grid item>{icon}</Grid>
    <Grid item>
      <div className={`${classes.socialMediaText} ${mobileView ? 'mobile' : ''}`}>{text}</div>
    </Grid>
    <Grid item>
      <a
        href={link}
        className={`${classes.socialMediaLink} ${mobileView ? 'mobile' : ''}`}
        target='_blank'
      >
        {linkText}
      </a>
    </Grid>
  </Grid>
);

export default SocialCardItem;
