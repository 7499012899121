const messengerEnabled =
  !!process.env.REACT_APP_INTERCOM_ID && process.env.NODE_ENV !== 'development';

const intecomInitialization = () => {
  if (!messengerEnabled) return;

  var w = window;
  var ic = w.Intercom;
  if (typeof ic === 'function') {
    ic('reattach_activator');
    ic('update', w.intercomSettings);
  } else {
    var d = document;
    var i = function () {
      i.c(arguments);
    };
    i.q = [];
    i.c = function (args) {
      i.q.push(args);
    };
    w.Intercom = i;
    var l = function () {
      var s = d.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = 'https://widget.intercom.io/widget/' + process.env.REACT_APP_INTERCOM_ID;
      var x = d.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
    };
    if (document.readyState === 'complete') {
      l();
    } else if (w.attachEvent) {
      w.attachEvent('onload', l);
    } else {
      w.addEventListener('load', l, false);
    }
  }
};

const loadIntercomMessenger = (userData) => {
  if (!messengerEnabled) return;
  window.Intercom('boot', {
    api_base: 'https://api-iam.intercom.io',
    app_id: process.env.REACT_APP_INTERCOM_ID,
    ...userData,
  });
};

// suppress messenger popup
const hideMessenger = () => {
  if (!messengerEnabled) return;
  window.Intercom('hide');
};

const updateMessenger = (userData) => {
  if (!messengerEnabled) return;
  const { hide_default_launcher } = userData || {};
  if (hide_default_launcher) hideMessenger();

  window.Intercom('update', { ...userData });
};

const clearMessengerSession = () => {
  if (!messengerEnabled) return;
  window.Intercom('shutdown');
};

export { clearMessengerSession, intecomInitialization, loadIntercomMessenger, updateMessenger };
