import getSymbolFromCurrency from 'currency-symbol-map'

/**
 * Returns a formatted price string for a given amount and currency code. The format of the
 * returned string is determined by the locale set in the user's browser.
 */
export function formatPrice(amount, options = {}) {
    const { currency = "USD", appendCurrency = false, hideDecimals = false, isThreeDecimal = false } = options;
    
    const decimalPlaces = hideDecimals ? 0 : isThreeDecimal ? 3 : 2;
    let formattedPrice = Number(amount).toFixed(decimalPlaces);

    const { symbol, position } = getCurrencySymbol(currency);

    if (position === 'start') {
        formattedPrice = `${symbol}${formattedPrice}`;
    } else {
        formattedPrice = `${formattedPrice} ${symbol}`;
    }

    if (appendCurrency) {
        formattedPrice += ` ${currency.toUpperCase()}`;
    }
    
    return formattedPrice;
}

/**
 * Returns an object containing the currency symbol and the position of the currency symbol
 * (either 'before' or 'after') for a given currency code.
 * The position of the symbol is determined by the locale set in the user's browser.
 */
export function getCurrencySymbol(currencyCode) {
    const locale = navigator.language;

    // Step 1: Format currency using Intl.NumberFormat based on the user's locale to
    // determine if the curency symbol is before or after the price
    const formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currencyCode,
        currencyDisplay: 'narrowSymbol'
    });

    const formatted = formatter.format(0);

    // Remove digits, decimal point, commas, and whitespace
    const symbolRaw = formatted.replace(/\d|\.|,|\s/g, '');

    // Determine position of the symbol
    const position = formatted.indexOf(symbolRaw) < formatted.indexOf('0') ? 'start' : 'end';

    // Step 2: Get the currency symbol from currency-symbol-map. Intl.NumberFormat has some inconsistencies
    // with the currency symbol it returns depending on the locale. For example, when using en-CA, it returns 'US$' for US dollars instead of '$'.
    const symbol = getSymbolFromCurrency(currencyCode);

    return {
        symbol,
        position
    };
}

export function convertCurrency(amount, fromCurrency, toCurrency, exchangeRates) {
    const conversionRate = exchangeRates.find((x) => x.base_currency === fromCurrency && x.target_currency === toCurrency);

    if (!conversionRate) {
        return amount;
    }

    return amount * conversionRate.rate;
}