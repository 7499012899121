import { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { commonBtnStyles } from 'assets/styles/common';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    paddingTop: theme.spacing(2),
  },
  textField: {
    '&.MuiFormControl-fullWidth': { height: '100%' },
    '& .MuiInputAdornment-positionEnd': {
      position: 'absolute',
      right: 9,
      top: 25,
    },
    '& .MuiFilledInput-root': {
      borderRadius: 0,
    },
    '& .MuiFilledInput-input': {
      height: '100% !important',
      paddingRight: theme.spacing(0.75),
      '&::-webkit-scrollbar': {
        width: 6,
        height: 8,
        display: 'block',
      },
      '&::-webkit-scrollbar-track': {
        background: 'inherit',
      },
      '&::-webkit-scrollbar-thumb': {
        visibility: 'hidden',
      },
      '&:hover::-webkit-scrollbar-thumb': {
        backgroundColor: theme.grey[500],
        borderRadius: 4,
        visibility: 'visible',
      },
    },
    '& .MuiFilledInput-multiline': {
      borderRadius: 0,
      padding: `${theme.spacing(1.5)}px ${theme.spacing(6.5)}px ${theme.spacing(
        1,
      )}px ${theme.spacing(1.5)}px`,
      height: '100%',
    },
    '& .MuiFormHelperText-root.Mui-error': {
      color: theme.error.dark,
      fontFamily: 'Figtree-Bold',
      fontSize: theme.spacing(1.6),
    },
    '& .MuiFormHelperText-contained': {
      marginLeft: 0,
    },
  },
  saveBtn: {
    ...commonBtnStyles,
    backgroundColor: theme.blue,
    '& .MuiButton-label': {
      fontFamily: 'Figtree-Bold',
      color: theme.common.white,
      fontSize: '1.1rem',
    },
    '&:hover': {
      backgroundColor: theme.blue,
      opacity: 0.8,
    },
  },
  feedbackBtnContainer: {
    marginBottom: theme.spacing(2),
  },
}));

const FeedbackField = ({ onSendFeedback, feedbackSubmissionIsLoading, sessionTrackingCode }) => {
  const classes = useStyles();

  const { getFieldProps, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: {
      customer_feedback: '',
    },
    validationSchema: Yup.object({
      customer_feedback: Yup.string()
        .required('Feedback is required')
        .min(3, 'Feedback must be at least 3 characters'),
    }),
    onSubmit: ({ customer_feedback }) => {
      if (onSendFeedback)
        onSendFeedback({
          customer_feedback,
          session_tracking_code: sessionTrackingCode,
        });
    },
  });

  const handleTrimOnBlur = useCallback(
    (e) => {
      e.target.value = e.target.value.trim();
      handleChange(e);
      handleBlur(e);
    },
    [handleChange, handleBlur],
  );

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction='column' spacing={3} className={classes.formContainer}>
        <Grid item>
          <TextField
            classes={{ root: classes.textField }}
            variant='filled'
            fullWidth
            placeholder='Enter your feedback here'
            multiline
            minRows={5}
            {...getFieldProps('customer_feedback')}
            onBlur={handleTrimOnBlur}
            error={!!touched.customer_feedback && !!errors.customer_feedback}
            helperText={touched.customer_feedback && errors.customer_feedback}
          />
        </Grid>
        <Grid item container className={classes.feedbackBtnContainer}>
          <Grid item xs />
          <Grid item xs={10}>
            <Button
              disabled={feedbackSubmissionIsLoading}
              type='submit'
              className={classes.saveBtn}
              fullWidth
              size='large'
            >
              {feedbackSubmissionIsLoading ? (
                <CircularProgress color='inherit' size={31} />
              ) : (
                <>Share Feedback</>
              )}
            </Button>
          </Grid>
          <Grid item xs />
        </Grid>
      </Grid>
    </form>
  );
};

export default FeedbackField;
