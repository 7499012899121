const Pencil = ({
  ...rest
}) => (
  <svg 
    width="18" 
    height="18" 
    viewBox="0 0 18 18" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <g clipPath="url(#clip0_6621_10274)">
      <path d="M13.9111 0.173072C13.5 0.170367 13.0971 0.316401 12.7861 0.627398L12.0938 1.34134L16.6587 5.92788L17.351 5.21394C17.9757 4.58924 17.9811 3.57512 17.351 2.9423L15.0577 0.649033C14.7413 0.332627 14.3221 0.175776 13.9111 0.173072ZM11.3149 1.96874L10.2332 3.00721L14.9928 7.76682L16.0962 6.74999L11.3149 1.96874ZM9.54088 3.76442L2.0553 11.1851C1.88223 11.2743 1.75783 11.4312 1.70915 11.6178L0.108188 17.0481C0.0351717 17.2861 0.100075 17.5484 0.275857 17.7242C0.451638 17.8999 0.713958 17.9648 0.951938 17.8918L6.38223 16.2909C6.63373 16.253 6.84196 16.0772 6.92309 15.8365L14.3005 8.52403L13.2837 7.50721L5.71155 15.101L2.66107 15.988L2.01203 15.3389L2.94232 12.1586L10.4495 4.67307L9.54088 3.76442ZM11.1851 5.43028L3.59136 13.0457L4.69473 13.2836L4.84617 14.3005L12.4616 6.70673L11.1851 5.43028Z" fill="black"/>
    </g>
    <defs>
      <clipPath id="clip0_6621_10274">
        <rect width="18" height="18" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);

export default Pencil;