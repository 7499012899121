const Paperclip = ({
  ...rest
}) => (
  <svg 
    width="18" 
    height="18" 
    viewBox="0 0 18 18" 
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path d="M13.6515 1.42788C13.381 1.436 13.1106 1.49279 12.851 1.57932C11.8125 1.92818 10.9228 2.72866 10.0385 3.61298L4.32693 9.32452C3.81852 9.83293 3.4291 10.4522 3.31011 11.1851C3.19112 11.918 3.41287 12.7563 4.04568 13.3918C4.69472 14.0409 5.56281 14.2356 6.29568 14.1058C7.02856 13.976 7.64244 13.5811 8.11299 13.1106L14.387 6.83654C14.5926 6.66887 14.6872 6.40114 14.6277 6.14152C14.5682 5.88461 14.3654 5.68179 14.1085 5.62229C13.8489 5.5628 13.5811 5.65745 13.4135 5.86298L7.13943 12.137C6.84196 12.4345 6.4309 12.6752 6.0577 12.7428C5.68451 12.8104 5.3681 12.7671 5.01924 12.4183C4.65686 12.0559 4.6163 11.7503 4.67309 11.4014C4.72988 11.0526 4.94893 10.6496 5.30049 10.2981L11.012 4.58654C11.8477 3.7509 12.6509 3.11268 13.2837 2.89904C13.9165 2.68539 14.3708 2.73137 15.101 3.46154C15.6472 4.00511 16.226 4.49729 16.3774 4.99759C16.4531 5.24639 16.4531 5.49519 16.2909 5.90625C16.1286 6.31731 15.7744 6.8771 15.101 7.55048C12.229 10.4225 10.025 12.6265 8.82693 13.8245C7.76413 14.8873 6.75812 15.5986 5.86299 15.7933C4.96786 15.988 4.1187 15.7797 3.00722 14.6683C1.89574 13.5568 1.50632 12.4778 1.62261 11.3582C1.73889 10.2386 2.42038 9.02434 3.6779 7.76682C5.19773 6.24699 8.37261 3.33173 8.37261 3.33173C8.58354 3.17488 8.68631 2.90985 8.63763 2.65024C8.58895 2.39062 8.39694 2.18239 8.14544 2.11208C7.89124 2.03906 7.6181 2.12019 7.44232 2.3149C7.44232 2.3149 4.26473 5.19231 2.6827 6.77163C1.28186 8.17247 0.400251 9.67608 0.237991 11.2284C0.0757314 12.7806 0.703135 14.3113 2.03366 15.6418C3.36149 16.9724 4.79749 17.4483 6.14424 17.1562C7.491 16.8642 8.65656 15.942 9.80049 14.7981C10.9985 13.6001 13.2025 11.396 16.0745 8.52404C16.8372 7.76142 17.3293 7.08263 17.589 6.42548C17.8486 5.76833 17.8594 5.12199 17.6971 4.58654C17.3753 3.51562 16.5072 2.91797 16.0745 2.48798C15.5796 1.99309 15.0307 1.67938 14.4736 1.53606C14.195 1.46304 13.9219 1.41977 13.6515 1.42788Z" fill="black"/>
  </svg>
);

export default Paperclip;