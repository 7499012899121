import { customValue } from 'utils/common/constants';

// checker for required profile data completion
const isProfileCompleted = ({
  asqMe_tag,
  display_name,
  price_per_question,
  price_per_question_custom,
}) =>
  !!asqMe_tag &&
  !!display_name &&
  ((!!price_per_question && price_per_question !== customValue) ||
    (price_per_question === customValue && !!price_per_question_custom));

export { isProfileCompleted };
