import { makeStyles } from '@material-ui/core/styles';
import { oneOf } from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';
import { featuresPerPriceTypes } from './constants';
import closeImg from 'assets/images/close.svg';
import goldLogoWithTitleImg from 'assets/images/gold-logo-with-title.svg';
import useAppData from 'hooks/context';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  menuIcon: {
    cursor: 'pointer',
  },
  drawer: {
    overflow: 'hidden',
  },
  paper: {
    overflowX: 'hidden',
    height: '100%',
    '&.maxWidth': {
      minWidth: 450,
      maxWidth: 600,
    },
  },
  drawerContainer: {
    height: '100%',
    backgroundColor: theme.modalDarkBackground,
    '@media (max-height: 650px)': {
      height: 'unset',
    },
  },
  headerContainer: {
    padding: `${theme.spacing(2)}px 0px`,
    '&.shrink': {
      padding: `${theme.spacing(1)}px 0px`,
    },
  },
  headerMainSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerLogo: {
    width: 110,
  },
  headerTitle: {
    paddingLeft: theme.spacing(1),
    color: theme.premiumYellow,
    fontSize: '1.5rem',
    '& span': {
      color: theme.common.white,
    },
  },
  hr: {
    margin: `0 auto`,
    backgroundColor: theme.grey[800],
    height: 2,
    border: 0,
    '&.shrink': {
      margin: `0 auto ${theme.spacing(1)}px`,
    },
  },
  contentContainer: { flexGrow: 1 },
  contentHeaderContainer: {
    marginTop: theme.spacing(2),
    '@media (max-height: 800px)': {
      marginTop: theme.spacing(0.5),
    },
    '@media (max-height: 630px)': {
      marginTop: theme.spacing(0),
    },
  },
  text: {
    color: theme.common.white,
    fontFamily: 'Figtree-SemiBold',
    fontSize: '1.5rem',
    textAlign: 'center',
    padding: `0 ${theme.spacing(2)}px`,
    lineHeight: 1.2,
    '& span': {
      fontFamily: 'Figtree-SemiBold',
      color: theme.premiumYellow,
    },
    '&.spaceTop': {
      '@media (max-height: 800px)': {
        fontSize: '1.4rem',
      },
      '@media (max-height: 630px)': {
        fontSize: '1.25rem',
      },
    },
    '&.shrink': {
      fontFamily: 'Figtree-SemiBold',
      fontSize: '1.35rem',
      '& span': {
        fontFamily: 'Figtree-SemiBold',
        color: theme.premiumYellow,
      },
    },
  },
  iconContainer: {
    marginTop: theme.spacing(4),
    '&.shrink': {
      marginTop: theme.spacing(1.2),
    },
    '@media (max-height: 800px)': {
      marginTop: theme.spacing(1.2),
    },
    '@media (max-height: 630px)': {
      marginTop: theme.spacing(1.2),
    },
  },
  icon: {},
  iconTitle: {
    color: theme.common.white,
    fontFamily: 'Figtree-SemiBold',
    fontSize: '1.2rem',
    '&.spaceTop': {
      '@media (max-height: 800px)': {
        fontSize: '1.1rem',
      },
      '@media (max-height: 630px)': {
        fontSize: '1rem',
      },
    },
    '&.shrink': {
      fontSize: '1.1rem',
    },
  },
  iconDescription: {
    color: theme.grey[500],
    fontSize: '1rem',
    lineHeight: 1.3,
    '&.shrink': {
      fontSize: '0.9rem',
    },
    '@media (max-height: 800px)': {
      fontSize: '0.9rem',
    },
    '@media (max-height: 630px)': {
      fontSize: '0.85rem',
    },
  },
  icons: {
    '&.shrink': {
      width: 35,
    },
    '@media (max-height: 800px)': {
      width: 35,
    },
    '@media (max-height: 630px)': {
      width: 30,
    },
  },
  footerContainer: {
    paddingBottom: theme.spacing(2.5),
    width: '90%',
    margin: 'auto',
    '&.shrinkSpacing': {
      paddingBottom: theme.spacing(2),
    },
  },
  footerInnerContainer: {
    padding: `${theme.spacing(1)}px ${theme.spacing(1.5)}px`,
    cursor: 'pointer',
    backgroundColor: theme.blue,
    borderRadius: 5,
  },
  footerText: {
    color: theme.grey[200],
    fontSize: '1.15rem',
    fontFamily: 'Figtree-SemiBold',
  },
  footerPriceContainer: {
    backgroundColor: theme.success.light,
    padding: theme.spacing(0.8),
    borderRadius: '50%',
    minWidth: 38
  },
  footerPriceText: {
    fontSize: '1rem',
    fontFamily: 'Figtree-Bold',
    color: theme.common.black,
  },
  footerPriceDescription: {
    fontSize: '1.1rem',
    color: theme.common.white,
  },
}));

const PremiumFeaturesDrawer = ({ mobileType, open, onClose }) => {
  const classes = useStyles();
  const { subscription, user } = useAppData();

  const { features = [] } = featuresPerPriceTypes;

  return (
    <Drawer
      classes={{
        root: classes.drawer,
        paper: `${classes.paper} ${!mobileType ? 'maxWidth' : ''}`,
        paperAnchorTop: classes.paper,
      }}
      transitionDuration={400}
      anchor={mobileType ? 'top' : 'right'}
      open={open}
      onClose={onClose}
    >
      <Grid container direction='column' className={classes.drawerContainer}>
        {/* header */}
        <Grid
          item
          container
          alignItems='center'
          className={`${classes.headerContainer} ${mobileType ? 'shrink' : ''}`}
        >
          <Grid item xs={11}>
            <div className={classes.headerMainSection}>
              <img
                src={goldLogoWithTitleImg}
                className={classes.headerLogo}
                alt='logo-with-title'
              />
              <div className={classes.headerTitle}>
                Pro <span>Plan</span>
              </div>
            </div>
          </Grid>
          <Grid item xs>
            <img className={classes.menuIcon} src={closeImg} onClick={onClose} />
          </Grid>
        </Grid>
        {/* hr */}
        <Grid item>
          <hr className={`${classes.hr} ${mobileType ? 'shrink' : ''}`} />
        </Grid>
        {/* content */}
        <Grid item container className={classes.contentContainer}>
          <Grid item xs />
          <Grid item xs={mobileType ? 10 : 8}>
            <Grid container direction='column'>
              {/* features */}
              {features.map(({ icon, title, description }) => (
                <Grid
                  key={title}
                  item
                  container
                  alignItems='center'
                  className={`${classes.iconContainer} ${mobileType ? 'shrink' : ''}`}
                  spacing={2}
                >
                  <Grid item xs={2}>
                    <img
                      src={icon}
                      alt='percent'
                      className={`${classes.icons} ${mobileType ? 'shrink' : ''}`}
                    />
                  </Grid>
                  <Grid item xs>
                    <Grid container direction='column'>
                      <Grid item>
                        <Grid container alignItems='center'>
                          <Grid item>
                            <div
                              className={`${classes.iconTitle} ${
                                mobileType ? 'shrink' : 'spaceTop'
                              }`}
                            >
                              {title}
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <div className={`${classes.iconDescription} ${mobileType ? 'shrink' : ''}`}>
                          {description}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs />
        </Grid>
        {/* footer */}
        {!user.is_subscribed && (
          <Grid item className={`${classes.footerContainer} ${mobileType ? 'shrinkSpacing' : ''}`}>
            <Link to='/subscribe' onClick={onClose}>
              <Grid
                container
                justifyContent='space-between'
                alignItems='center'
                className={classes.footerInnerContainer}
              >
                <Grid item>
                  <div className={classes.footerText}>{user.was_subscriber ? 'Subscribe' : 'Start your free trial'}</div>
                </Grid>
                <Grid item>
                  <Grid container spacing={1} alignItems='center'>
                    <Grid item>
                      <Grid
                        container
                        justifyContent='center'
                        alignItems='center'
                        className={classes.footerPriceContainer}
                      >
                        <Grid item>
                          <div className={classes.footerPriceText}>${subscription?.price}</div>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <div className={classes.footerPriceDescription}>per month</div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Link>
          </Grid>
        )}
      </Grid>
    </Drawer>
  );
};

export default PremiumFeaturesDrawer;
