import { number, object, string } from 'prop-types';
import common from '@material-ui/core/colors/common';

const CloseIcon = ({
  className = '',
  style = {},
  width = 18,
  height = 18,
  fillColor = common.white,
  ...restProps
}) => (
  <svg
    style={style}
    className={className}
    height={height}
    width={width}
    viewBox='0 0 18 18'
    fill='none'
    {...restProps}
  >
    <g clipPath='url(#clip0_33_1704)'>
      <path
        d='M11.9702 9.00061L17.5385 3.43228C18.1538 2.81702 18.1538 1.81932 17.5385 1.20504L16.7963 0.462794C16.181 -0.152464 15.1833 -0.152464 14.5691 0.462794L9.00073 6.03113L3.4324 0.46181C2.81714 -0.153448 1.81944 -0.153448 1.20516 0.46181L0.461932 1.20406C-0.153326 1.81932 -0.153326 2.81702 0.461932 3.43129L6.03125 9.00061L0.462916 14.5689C-0.152342 15.1842 -0.152342 16.1819 0.462916 16.7962L1.20516 17.5384C1.82042 18.1537 2.81813 18.1537 3.4324 17.5384L9.00073 11.9701L14.5691 17.5384C15.1843 18.1537 16.182 18.1537 16.7963 17.5384L17.5385 16.7962C18.1538 16.1809 18.1538 15.1832 17.5385 14.5689L11.9702 9.00061Z'
        fill={fillColor}
      />
    </g>
    <defs>
      <clipPath id='clip0_33_1704'>
        <rect height={height} width={width} fill='white' />
      </clipPath>
    </defs>
  </svg>
);

CloseIcon.propTypes = {
  style: object,
  className: string,
  height: number,
  width: number,
  fillColor: string,
};

export default CloseIcon;
