import { number, object, string } from 'prop-types';

const GoldStarIcon = ({
  className = '',
  style = {},
  width = 24,
  height = 24,
  fillColor = '#FFD058',
  strokeColor = '#000',
  ...rest
}) => (
  <svg
    {...rest}
    className={className}
    style={style}
    width={width}
    height={height}
    viewBox='0 0 24 24'
  >
    <path
      d='M23.7147 8.71733C23.537 8.14528 23.0055 7.75182 22.2571 7.63826L16.3216 6.73684L13.667 1.11535C13.332 0.406651 12.8104 0 12.2346 0C11.6589 0 11.1373 0.406651 10.8023 1.11535L8.14814 6.73637L2.21269 7.63779C1.46428 7.75135 0.932725 8.14481 0.75509 8.71686C0.577454 9.2889 0.785747 9.93351 1.32767 10.4853L5.62247 14.8609L4.60851 21.0394C4.47506 21.8522 4.70184 22.338 4.91509 22.6028C5.16486 22.9124 5.52915 23.083 5.94077 23.083C6.25186 23.083 6.58369 22.9878 6.92588 22.7993L12.2346 19.8821L17.5434 22.7993C17.8861 22.9874 18.2174 23.083 18.5285 23.083C18.9401 23.083 19.3044 22.9124 19.5542 22.6028C19.7675 22.3385 19.9942 21.8522 19.8608 21.0394L18.8468 14.8609L23.1416 10.4853C23.6831 9.93351 23.8923 9.28937 23.7147 8.71733Z'
      fill={fillColor}
    />
  </svg>
);

GoldStarIcon.propTypes = {
  style: object,
  className: string,
  height: number,
  width: number,
  fillColor: string,
  strokeColor: string,
};

export default GoldStarIcon;
