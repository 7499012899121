import { useEffect, useMemo } from 'react';
import { Button, CircularProgress, Grid, InputBase, makeStyles } from "@material-ui/core"
import useUpload from 'hooks/upload';
import { FileIcon, PaperclipIcon, TrashcanIcon } from 'components/Icons';

const useStyles = makeStyles((theme) => ({
  outerContainer: {
    marginBottom: 20,
  },
  questionInputContainer: {
    width: '100%',
    border: '1px solid #CCCCCC',
    borderRadius: 3,
    padding: 0,
    backgroundColor: 'white',
  },
  questionInputWithAttachemntContainer: {
    minHeight: 117,
  },
  root: {
    width: '100%',
    padding: 0,
  },
  labelContainer: {
    marginBottom: 10,
  },
  input: {
    padding: '10px 20px'
  },
  inputWithAttachment: {
    padding: 20,
  },
  inputError: {
    fontFamily: 'Figtree-Regular',
    fontSize: 12,
    lineHeight: '16.34px',
    color: '#D44444'
  },
  labelText: {
    fontFamily: 'Figtree-Bold',
    fontSize: 16,
    lineHeight: '19.2px',
  },
  uploadIcon: {
    cursor: 'pointer',
  },
  loadingIndicator: {
    color: theme.blue,
  },
  attachmentContainer: {
    backgroundColor: '#EEEEEE',
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 10,
    padding: '5px 10px',
  },
  attachmentLabelContainer: {
    '& svg': {
      marginRight: 8,
    },
    '& span': {
      fontFamily: 'Figtree-Regular',
      fontSize: 12,
      lineHeight: '16.34px',
    }
  },
  removeAttachmentButton: {
    minWidth: 0,
    padding: 0,
  }
}))

export const QuestionInputWithAttachment = (props) => {
  const {
    label,
    placeholder,
    errorMessage,
    handleFormImageChange, 
    handleFormRemoveAttachments, 
    imageUploadDisabled, 
    onAttachmentUploading,
    values,
    fieldProps,
    onBlur,
    disabled,
  } = props;

  const classes = useStyles();

  const isFileUploaded = useMemo(() => {
    return values.customer_attachment_image_encoded || values.customer_attachment_video_encoded;
  }, [values.customer_attachment_image_encoded, values.customer_attachment_video_encoded]);

  const {
    getRootProps: rootAttachmentProps,
    getInputProps: inputAttachmentProps,
    uploadIsLoading: attachmentUploadIsLoading,
    fileDisplayName: attachmentFileDisplayName,
  } = useUpload(
    handleFormImageChange({
      image_url: 'customer_attachment_image_url',
      image_file: 'customer_attachment_image_encoded',
      video_url: 'customer_attachment_video_url',
      video_file: 'customer_attachment_video_encoded',
    }),
    { disabled: imageUploadDisabled, videoAccept: true },
  );

  useEffect(() => {
    onAttachmentUploading(attachmentUploadIsLoading);
  }, [attachmentUploadIsLoading, onAttachmentUploading])

  return (
    <Grid item container className={classes.outerContainer}>
      <Grid item container xs={12} direction="row" justifyContent="space-between" className={classes.labelContainer}>
        <Grid item className={classes.labelText}>
          {label}
        </Grid>
        <Grid item>
          <div
            {...rootAttachmentProps()}
          >
            {attachmentUploadIsLoading ? ( 
              <CircularProgress thickness={4} size={18} classes={{ svg: classes.loadingIndicator }}/>
            ) : !isFileUploaded ? (
              <PaperclipIcon className={classes.uploadIcon} />
            ) : null}
            <input {...inputAttachmentProps()} />
          </div>
        </Grid>
      </Grid>
      <Grid container item xs={12} className={`${classes.questionInputContainer} ${classes.questionInputWithAttachemntContainer}`} alignContent="space-between">
        <Grid item xs={12}>
          <InputBase
            {...fieldProps}
            classes={{ root: classes.root, input: classes.inputWithAttachment }}
            placeholder={placeholder}
            multiline={true}
            onBlur={onBlur}
            disabled={disabled}
          />
        </Grid>
        {isFileUploaded && <Grid container item direction="row" justifyContent="space-between" alignContent="center" alignItems="center" xs={12} className={classes.attachmentContainer}>
          <Grid item>
            <Grid container item alignItems="center" className={classes.attachmentLabelContainer}>
              <FileIcon />
              <span>{attachmentFileDisplayName ?? "Attachment"}</span>
            </Grid>
          </Grid>
          <Button onClick={handleFormRemoveAttachments} classes={{ root: classes.removeAttachmentButton }}>
            <TrashcanIcon />
          </Button>
        </Grid>}
      </Grid>
      <Grid item xs={12}>
        {errorMessage && <span className={classes.inputError}>{errorMessage}</span>}
      </Grid>
    </Grid>
  )
}

export const QuestionInput = (props) => {
  const {
    label,
    placeholder,
    errorMessage,
    fieldProps,
    onBlur,
    disabled,
  } = props;

  const classes = useStyles();

  return (
    <Grid item container className={classes.outerContainer}>
      <Grid item container xs={12} direction="row" justifyContent="space-between" className={classes.labelContainer}>
        <Grid item className={classes.labelText}>
          {label}
        </Grid>
      </Grid>
      <Grid container item xs={12} className={classes.questionInputContainer} alignContent="space-between">
        <Grid item xs={12}>
          <InputBase
            {...fieldProps}
            classes={{ root: classes.root, input: classes.input }}
            placeholder={placeholder}
            multiline={true}
            error
            onBlur={onBlur}
            disabled={disabled}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {errorMessage && <span className={classes.inputError}>{errorMessage}</span>}
      </Grid>
    </Grid>
  )
}