import { number, object, string } from 'prop-types';
import common from '@material-ui/core/colors/common';

const AppLogo = ({
  className = '',
  style = {},
  width = 49,
  height = 50,
  strokeColor = '#495EFF',
  fillColor = common.white,
  ...restProps
}) => (
  <svg
    {...restProps}
    style={style}
    className={className}
    width={width}
    height={height}
    viewBox='0 0 49 50'
    fill='none'
  >
    <path
      d='M47.4661 41.6348C44.9739 41.6348 42.6833 40.7735 40.8719 39.3362C44.6819 35.1584 47.0064 29.603 47.0064 23.5024C47.0064 10.5236 36.4843 0 23.504 0C10.5236 0 0 10.5236 0 23.504C0 36.4843 10.5236 47.008 23.504 47.008C26.0559 47.008 28.5093 46.5966 30.8112 45.8433C32.7936 47.8532 35.5471 49.0985 38.591 49.0985C43.456 49.0985 47.5774 45.9191 48.9937 41.5252C48.4952 41.5977 47.9855 41.6365 47.4677 41.6365L47.4661 41.6348ZM27.6899 37.3893C26.3639 37.7893 24.959 38.0071 23.5024 38.0071C15.5016 38.0071 9.01543 31.5209 9.01543 23.5201C9.01543 15.5193 15.5016 9.03317 23.5024 9.03317C31.5032 9.03317 37.9893 15.5193 37.9893 23.5201C37.9893 24.8267 37.8135 26.0913 37.4893 27.2963C37.4989 27.2963 37.5086 27.2931 37.5183 27.2931C37.4667 27.4302 37.4183 27.5705 37.3731 27.7109C37.3538 27.777 37.3328 27.8447 37.3118 27.9109C35.8714 32.4484 32.2565 36.0149 27.6915 37.3909L27.6899 37.3893Z'
      fill={strokeColor}
    />
    <path
      d='M37.5184 27.2931C37.5087 27.2931 37.499 27.2963 37.4893 27.2963C37.4522 27.435 37.4135 27.5738 37.3716 27.7109C37.4184 27.5705 37.4668 27.4318 37.5168 27.2931H37.5184Z'
      fill={strokeColor}
    />
    <path
      d='M23.5038 34.876C27.0364 34.876 30.1932 33.2614 32.2756 30.7321C31.0465 28.5464 29.0285 26.8607 26.6025 26.0768C27.9962 25.0992 28.9092 23.4829 28.9092 21.6505C28.9092 18.6647 26.4896 16.2451 23.5038 16.2451C20.518 16.2451 18.0984 18.6647 18.0984 21.6505C18.0984 23.4829 19.0114 25.0976 20.4051 26.0768C17.979 26.8607 15.9611 28.5464 14.7319 30.7321C16.8144 33.263 19.9712 34.876 23.5038 34.876Z'
      fill={fillColor}
    />
  </svg>
);

AppLogo.propTypes = {
  style: object,
  className: string,
  height: number,
  width: number,
};

export default AppLogo;
