import { Tooltip } from 'antd';
import { DateTime } from 'luxon';
import { useMutation } from '@tanstack/react-query';
import { convertCurrency, getCurrencySymbol } from 'utils/currency-utils';
import {
  toggleFavoriteQuestion,
  toggleHiddenQuestion,
  sendAnswerToAudience,
  queryCreatorTypes,
} from 'store/creator';
import { useEffect } from 'react';
import { TranslateIcon } from 'components/Icons';
import { defaultAlertError } from 'utils/common/constants';

/**
 * @description Custom hook to handle toggling of favorite and hidden status of questions
 * @param {function} setList - function to update the list of questions
 * @param {function} setSelectedQuestion - function to update the selected question
 * @param {object} alert - react-alert instance
 * @returns {object} - object containing the mutation functions and loading status
 */
const useToggleActions = (list, setList, setSelectedQuestion, alert, queryClient) => {
  const { mutate: onToggleFavoriteQuestion, isLoading: favoriteIsLoading } = useMutation({
    mutationFn: toggleFavoriteQuestion,
    onSuccess: (updatedRecord) => {
      setList((prev) =>
        prev.map((item) =>
          item.id === updatedRecord.id ? { ...item, is_favorite: updatedRecord.is_favorite } : item,
        ),
      );
      setSelectedQuestion((prev) =>
        !prev || prev?.id !== updatedRecord.id
          ? prev
          : { ...prev, is_favorite: updatedRecord.is_favorite },
      );
    },
    onError: (err) => {
      alert.error(null, {...defaultAlertError, offsetError: true });
    },
  });

  const { mutate: onToggleHiddenQuestion, isLoading: toggleIsLoading } = useMutation({
    mutationFn: toggleHiddenQuestion,
    onSuccess: (updatedRecord) => {
      setList((prev) =>
        prev.map((item) =>
          item.id === updatedRecord.id ? { ...item, is_hidden: updatedRecord.is_hidden } : item,
        ),
      );
      setSelectedQuestion((prev) =>
        !prev || prev?.id !== updatedRecord.id
          ? prev
          : { ...prev, is_hidden: updatedRecord.is_hidden },
      );
    },
    onError: (err) => {
      alert.error(null, {...defaultAlertError, offsetError: true });
    },
  });

  // answer submission api call
  const {
    data: answerToAudience,
    mutate: onSendAnswerToAudience,
    reset: resetAnswerToAudience,
    isLoading: sendAnswerToAudienceIsLoading,
  } = useMutation({
    mutationFn: sendAnswerToAudience,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [queryCreatorTypes.inbox] });
      queryClient.invalidateQueries({ queryKey: [queryCreatorTypes.getQuestionDetails] });
    },
    onError: (err) => {
      alert.error(null, {...defaultAlertError, offsetError: true });
    },
  });

  useEffect(() => {
    if (answerToAudience) {
      setSelectedQuestion(null);
      resetAnswerToAudience();
    }
  }, [list]);

  return {
    onSendAnswerToAudience,
    onToggleFavoriteQuestion,
    onToggleHiddenQuestion,
    actionIsLoading: favoriteIsLoading || toggleIsLoading,
    sendAnswerIsLoading: sendAnswerToAudienceIsLoading,
    drawerClosable: !sendAnswerToAudienceIsLoading && !favoriteIsLoading && !toggleIsLoading,
  };
};

// Helper functions

const displayCustomerName = ({ customer_name = '' }) => {
  let [firstName, lastName] = customer_name.split(' ');
  firstName = (firstName || '').length > 7 ? `${firstName.slice(0, 7)}.` : firstName;
  lastName = (lastName || '').length ? `${lastName.slice(0, 1)}.` : '';

  return `${firstName} ${lastName}`;
};

const displayQnA = ({ original_question, last_answer, is_translated }) => {
  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {original_question}
        </div>
        {is_translated && (
          <TranslateIcon
            style={{
              width: '100% !important',
              maxWidth: '17px',
              height: '100% !important',
              maxHeight: '17px',
              marginLeft: 8,
            }}
            fillColor='#CCCCCC'
          />
        )}
      </div>
      {!!last_answer ? (
        <span
          style={{
            display: 'block',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {last_answer}
        </span>
      ) : null}
    </>
  );
};

const displayCustomerMessage = (message) => (
  <>
    {message.slice(0, 50)}
    {message.length > 50 ? '...' : ''}
  </>
);

const displayRemainingActiveTime = (
  { last_question_answered },
  timeRemainToArchive,
  shortArchiveTimeEnabled,
) => (
  <>
    {last_question_answered
      ? 'Answer Sent'
      : shortArchiveTimeEnabled
      ? `${parseInt(timeRemainToArchive) + 1} Minute${
          parseInt(timeRemainToArchive) + 1 === 1 ? '' : 's'
        } Left`
      : `${parseInt(timeRemainToArchive) + 1} Hour${
          parseInt(timeRemainToArchive) + 1 === 1 ? '' : 's'
        } Left`}
  </>
);

const displayLastActionTime = (time) => {
  const end = DateTime.now();
  const start = DateTime.fromISO(time);

  const { hours } = end.diff(start, ['hours', 'minutes', 'seconds']).toObject();
  return hours < 24
    ? `Today, ${start.toLocaleString(DateTime.TIME_SIMPLE)}`
    : hours < 48
    ? `Yesterday, ${start.toLocaleString(DateTime.TIME_SIMPLE)}`
    : start.toFormat('ccc. LLL L, yyyy');
};

const getProgressBulletsArray = ({ follow_up_no, last_question_answered }) =>
  (follow_up_no === 0 && last_question_answered) || (follow_up_no === 1 && !last_question_answered)
    ? ['firstBulletColor']
    : (follow_up_no === 1 && last_question_answered) ||
      (follow_up_no === 2 && !last_question_answered)
    ? ['firstBulletColor', 'secondBulletColor']
    : follow_up_no === 2 && last_question_answered
    ? ['firstBulletColor', 'secondBulletColor', 'thirdBulletColor']
    : [];

const getFollowupStatusTooltip = ({ is_completed }, classes, bulletProps, Component) => {
  const bulletsFilled = Object.keys(bulletProps).length;
  const remainingFollowUps = 3 - bulletsFilled;

  return is_completed || !bulletsFilled ? (
    <>{Component}</>
  ) : (
    <Tooltip
      overlayClassName={classes.tooltip}
      title={
        remainingFollowUps === 2
          ? '2 Follow-Ups Left'
          : remainingFollowUps === 1
          ? '1 Follow-Up Left'
          : 'No Follow-Ups Left'
      }
    >
      {Component}
    </Tooltip>
  );
};

export {
  displayCustomerName,
  displayCustomerMessage,
  displayLastActionTime,
  displayRemainingActiveTime,
  displayQnA,
  getProgressBulletsArray,
  getFollowupStatusTooltip,
  useToggleActions,
};
