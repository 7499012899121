export const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  headingText: {
    fontFamily: 'Figtree-Bold',
    fontSize: 16,
    lineHeight: '19px',
    letterSpacing: '0em',
    textAlign: 'left',
    marginBottom: 20,
  },
  radioGroup: {
    width: '100%',
    marginBottom: 40,
  },
  messageOption: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
    '& *': {
      fontFamily: 'Figtree-Regular',
    },
    fontSize: 16,
    lineHeight: '19px',
  },
  messageOptionBold: {
    '& *': {
      fontFamily: 'Figtree-Bold',
    }
  },
  messageOptionCustom: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '100%',
    '& span': {
      display: 'inline-flex',
      alignItems: 'center',
    },
  },
  radio: {
    '& .MuiTouchRipple-root': {
      color: '#495EFF',
    },
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
  radioOffset: { // For aligning the radio button to the top of the label text
    bottom: 11,
  },
  icon: {
    backgroundColor: 'rgba(217, 217, 217, 0.5)', // #D9D9D9 with 50% opacity
    width: 19,
    height: 19,
    borderRadius: '50%',
    border: '1px solid #D9D9D9',
  },
  checkedIcon: {
    position: 'absolute',
    backgroundColor: '#495EFF',
    width: 11,
    height: 11,
    borderRadius: '50%',
    top: 13,
    left: 13,
  },
  boldLabel: {},
});