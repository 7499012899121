import { makeStyles } from '@material-ui/core/styles';
import StarRating from './star-rating';
import { PlusIcon } from 'components/Icons';

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'start',
    flexDirection: 'column',
    padding: 20,
    backgroundColor: 'rgba(238, 238, 238, 0.5)',
    border: '2px solid #7E8DFF',
    color: theme.blue,
    marginBottom: 10,
    borderRadius: 5,
    maxWidth: 465,
    cursor: 'pointer',
    width: '100%',
    '& p': {
      fontFamily: 'Figtree-Regular',
      fontSize: 16,
      lineHeight: '19.2px',
      marginBottom: 0,
    },
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
    marginBottom: 10,
    '& h2': {
      fontFamily: 'Figtree-Bold',
      fontSize: 16,
      lineHeight: '19.2px',
      color: theme.blue,
      marginBottom: 0,
    },
  },
}));

const ReturnToThankBankCard = (props) => {
  const { title, body, onCardClick, showStars } = props; 

  const classes = useStyles();

  return (
    <div className={classes.cardContainer} onClick={onCardClick}>
      <div className={classes.headerContainer}>
        <h2>{title}</h2>
        <PlusIcon />
      </div>
      {showStars && <StarRating rating={0} />}
      <p>{body}</p>
    </div>
  )
}

export default ReturnToThankBankCard;