import { number, object, string } from 'prop-types';
import common from '@material-ui/core/colors/common';

const NewLogo = ({
  className = '',
  style = {},
  width = 34,
  height = 34,
  strokeColor = '#495EFF',
  fillColor = common.white,
  ...restProps
}) => (
  <svg
    {...restProps}
    style={style}
    className={className}
    width={width}
    height={height}
    viewBox='0 0 38 38'
    fill='none'
  >
    <path
      d='M35.8588 31.4535C33.976 31.4535 32.2456 30.8028 30.8771 29.717C33.7555 26.5608 35.5115 22.3639 35.5115 17.7551C35.5115 7.9502 27.5625 0 17.7564 0C7.95021 0 0 7.95021 0 17.7564C0 27.5625 7.95021 35.5127 17.7564 35.5127C19.6842 35.5127 21.5377 35.202 23.2767 34.6329C24.7743 36.1513 26.8545 37.092 29.154 37.092C32.8293 37.092 35.9429 34.6901 37.0128 31.3707C36.6363 31.4255 36.2512 31.4547 35.86 31.4547L35.8588 31.4535ZM20.9186 28.2461C19.9169 28.5484 18.8555 28.7129 17.7551 28.7129C11.7108 28.7129 6.81081 23.8128 6.81081 17.7685C6.81081 11.7242 11.7108 6.82421 17.7551 6.82421C23.7994 6.82421 28.6995 11.7242 28.6995 17.7685C28.6995 18.7556 28.5666 19.711 28.3217 20.6213C28.329 20.6213 28.3363 20.6189 28.3436 20.6189C28.3046 20.7224 28.2681 20.8285 28.234 20.9345C28.2193 20.9844 28.2035 21.0356 28.1876 21.0856C27.0994 24.5135 24.3685 27.2079 20.9199 28.2474L20.9186 28.2461Z'
      fill={strokeColor}
    />
    <path
      d='M28.3438 20.6189C28.3365 20.6189 28.3292 20.6213 28.3219 20.6213C28.2938 20.7261 28.2646 20.8309 28.2329 20.9345C28.2682 20.8285 28.3048 20.7237 28.3426 20.6189H28.3438Z'
      fill={strokeColor}
    />
    <path
      d='M17.7562 26.3476C20.4249 26.3476 22.8098 25.1278 24.383 23.217C23.4544 21.5658 21.9299 20.2924 20.0971 19.7001C21.15 18.9617 21.8397 17.7406 21.8397 16.3563C21.8397 14.1006 20.0118 12.2727 17.7562 12.2727C15.5005 12.2727 13.6726 14.1006 13.6726 16.3563C13.6726 17.7406 14.3624 18.9604 15.4152 19.7001C13.5825 20.2924 12.058 21.5658 11.1294 23.217C12.7026 25.129 15.0874 26.3476 17.7562 26.3476Z'
      fill={fillColor}
    />
  </svg>
);

NewLogo.propTypes = {
  style: object,
  className: string,
  height: number,
  width: number,
};

export default NewLogo;
