import { commonBtnStyles } from 'assets/styles/common';

const styles = (theme) => ({
  proceedToCheckoutContainer: {
    height: '100%',
  },
  proceedToCheckoutInnerContainer: {
    height: '100%',
  },
  form: {
    height: 'inherit',
  },
  header: {
    backgroundColor: theme.blue,
    paddingBottom: 0,
  },
  headerLogosContainer: {
    backgroundColor: theme.blue,
    width: '100%',
    height: 70,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  logoPlaceholderIcon: {
    transform: 'translateY(50%)',
    borderRadius: 50,
    width: 70,
    height: 70,
    backgroundColor: theme.common.black,
    border: `2px solid ${theme.common.white}`,
    position: 'relative',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  descriptionSection: {
    paddingTop: theme.spacing(4.5),
    '&.space': {
      paddingTop: theme.spacing(5.5),
    },
    '&.moreSpace': {
      paddingTop: theme.spacing(6.5),
    },
  },
  descriptionIntro: {
    textAlign: 'center',
    fontFamily: 'Figtree-Bold',
    fontSize: '1.3rem',
    '&.spaceTop': {
      paddingTop: theme.spacing(2),
    },
  },
  descriptionContent: {
    textAlign: 'center',
    fontSize: '1.1rem',
  },
  tipFormSection: {
    flexGrow: 1,
  },
  tipFormInnerSection: {
    height: '100%',
  },
  tipQuestion: {
    textAlign: 'center',
    fontSize: '1rem',
  },
  tipImg: {
    cursor: 'pointer',
    '&.blur': {
      opacity: 0.7,
    },
  },
  pendingOverlay: {
    position: 'relative',
  },
  progress: {
    position: 'absolute',
    left: 23,
    top: 17,
  },
  tipValue: {
    fontFamily: 'Figtree-Bold',
    fontSize: '1rem',
  },
  inputLabel: {
    fontFamily: 'Figtree-SemiBold',
    fontSize: '1rem',
  },
  inputFollowingText: {
    fontSize: '0.8rem',
    opacity: 0.7,
    textAlign: 'center',
  },
  textField: {
    '& .MuiFilledInput-root': {
      borderRadius: 0,
      height: theme.spacing(11),
      fontSize: theme.spacing(8),
    },
    '& .MuiInputAdornment-filled.MuiInputAdornment-positionStart': {
      marginTop: 0,
    },
    '& .MuiFilledInput-input': {
      textAlign: 'center',
      padding: `${theme.spacing(1.9)}px ${theme.spacing(1.5)}px ${theme.spacing(1.9)}px 0`,
    },
    '& .MuiFormHelperText-contained': {
      marginLeft: 0,
    },
  },
  dollarSign: {
    fontFamily: 'Figtree-SemiBold',
  },
  spacing: {
    paddingTop: theme.spacing(2),
  },
  hr: {
    border: `1px solid ${theme.greyHorizontalRule}`,
  },
  tipPaymentContainer: {
    padding: `${theme.spacing(2)}px 0`,
  },
  tipPaymentResetContainer: {
    flexGrow: 1,
  },
  emptySpace: {
    flexGrow: 1,
  },
  actionFooter: {
    paddingTop: theme.spacing(2),
  },
  submitBtn: {
    ...commonBtnStyles,
    border: `2px solid ${theme.blue}`,
    backgroundColor: theme.blue,
    '& .MuiButton-label': {
      fontFamily: 'Figtree-Bold',
      color: theme.common.white,
      fontSize: '1.1rem',
    },
    '&:hover': {
      backgroundColor: theme.blue,
      opacity: 0.8,
    },
  },
  submitBtnInfo: {
    paddingTop: theme.spacing(0.5),
    color: theme.grey[700],
    fontSize: '0.72rem',
    textAlign: 'center',
  },
  revealTextPrompt: {
    padding: `${theme.spacing(1.5)}px 0`,
    fontFamily: 'Figtree-Bold',
  },
  goBackContainer: {
    fontSize: '1rem',
    marginTop: theme.spacing(0.5),
    padding: `${theme.spacing(1.5)}px 0`,
    color: theme.common.black,
    textAlign: 'center',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  successImg: {
    width: 50,
    height: 50,
    '&.small': {
      width: 37,
      height: 37,
    },
  },
  successText: {
    fontSize: '1.3rem',
    fontFamily: 'Figtree-Bold',
  },
  successDescription: {
    fontSize: '1rem',
  },
  yesBtn: {
    ...commonBtnStyles,
    border: `2px solid ${theme.blue}`,
    backgroundColor: theme.blue,
    '&.Mui-disabled': {
      opacity: 0.7,
    },
    '& .MuiButton-label': {
      fontFamily: 'Figtree-Bold',
      color: theme.common.white,
      fontSize: '1.1rem',
    },
    '&:hover': {
      backgroundColor: theme.blue,
      opacity: 0.85,
      '&.Mui-disabled': {
        backgroundColor: theme.blue,
        opacity: 0.7,
      },
    },
    '&.spaceBottom': {
      marginBottom: theme.spacing(2),
    },
  },
  noBtn: {
    ...commonBtnStyles,
    border: `3px solid ${theme.blue}`,
    '& .MuiButton-label': {
      fontFamily: 'Figtree-Bold',
      fontSize: '1.1rem',
    },
    '&.MuiButton-outlined.Mui-disabled': {
      borderWidth: `3px`,
    },
  },
  form: {
    height: '100%',
  },
  formContainer: {
    height: '100%',
  },
  tipProcessingContainer: {
    paddingTop: theme.spacing(8),
    flex: 1,
  },
  tipHeaderContainer: {
    paddingTop: theme.spacing(5.5),
    flexGrow: 0.2,
  },
  paymentForm: {
    flexGrow: 1,
  },
  paymentInformationFooter: {
    fontSize: '0.9rem',
    textAlign: 'center',
    padding: `${theme.spacing(1)}px 0`,
    color: theme.grey[600],
  },
  tipHeader: {
    textAlign: 'center',
    fontFamily: 'Figtree-Bold',
    fontSize: '1.5rem',
    lineHeight: 1.2,
  },
  tipFormContainer: {
    paddingTop: theme.spacing(2),
    flexGrow: 0.9,
  },
  paymentSetupProcessing: {
    fontSize: '1rem',
  },
  hr: {
    backgroundColor: theme.fadedBlue,
    height: 1,
  },
  priceSectionContainer: {},
  priceContainer: {
    backgroundColor: theme.success.light,
    color: theme.common.black,
    borderRadius: 50,
    padding: theme.spacing(1),
  },
  price: {
    fontSize: '1.2rem',
    fontFamily: 'Figtree-Bold',
  },
  priceText: {
    lineHeight: 1.3,
    fontSize: '0.7rem',
  },
  priceDescription: {
    padding: `${theme.spacing(2)}px 0`,
    lineHeight: 1.3,
    fontSize: '0.9rem',
    textAlign: 'center',
    '& span': {
      color: theme.success.light,
    },
  },
  customerFooter: {
    padding: `${theme.spacing(1)}px 0`,
    position: 'relative',
  },
  saveBtn: {
    ...commonBtnStyles,
    backgroundColor: theme.blue,
    '&.Mui-disabled': {
      opacity: 0.7,
    },
    '& .MuiButton-label': {
      fontFamily: 'Figtree-Bold',
      color: theme.common.white,
      fontSize: '1.1rem',
    },
    '&:hover': {
      backgroundColor: theme.blue,
      opacity: 0.85,
      '&.Mui-disabled': {
        backgroundColor: theme.blue,
        opacity: 0.7,
      },
    },
  },
  errorContainer: {
    height: 130,
    backgroundColor: theme.common.black,
    position: 'absolute',
    left: -35,
    right: -35,
    bottom: -8,
  },
  closeIcon: {
    cursor: 'pointer',
    position: 'absolute',
    right: 13,
    top: 10,
  },
  errorInnerContainer: {
    height: 'inherit',
  },
  errorImg: {},
  errorHeader: {
    textAlign: 'center',
    color: theme.common.white,
    fontFamily: 'Figtree-Bold',
    fontSize: '1.1rem',
  },
  errorMessage: {
    lineHeight: 1,
    textAlign: 'center',
    color: theme.common.white,
    fontSize: '0.9rem',
  },
});

export default styles;
