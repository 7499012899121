import { Navigate } from 'react-router-dom';
import useAuth from 'hooks/auth';
import { useContext, useEffect } from 'react';
import { AppDataContext } from 'Context';

const inboxEnabled = process.env.REACT_APP_ENABLE_INBOX === 'true';

// Wrapper for generating admin route
const AdminRoute = ({ children }) => {
  const user = useAuth();

  return user?.isAdmin ? children : <Navigate to='/profile' replace />;
};

// Wrapper for generating authenticated route
const AuthRoute = ({ children }) => {
  const user = useAuth();

  return !!user ? children : <Navigate to='/auth/connect' replace />;
};

// Wrapper for generating public route
const PublicRoute = ({ children }) => {
  const {
    setAppData,
    appData: { auth, user },
  } = useContext(AppDataContext);
  const navigateTo = auth?.redirectUrl || '';

  useEffect(() => {
    if (auth?.redirectUrl && user) {
      setAppData((prev) => ({ ...prev, auth: { ...prev.auth, redirectUrl: null } }));
    }
  }, [auth, user]);

  const targetUrl =
    navigateTo || (inboxEnabled && user?.is_profile_completed ? '/inbox' : '/profile');

  return !!user ? <Navigate to={targetUrl} replace /> : children;
};

export { AdminRoute, AuthRoute, PublicRoute };
