import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';
import { updateMessenger } from 'utils/intercomConfig';
import { NewLogoIcon } from 'components/Icons';
import { useAlert } from 'react-alert';
import { ChainLinkIcon } from 'components/Icons';
import squareImg from 'assets/images/squares.svg';
import closeImg from 'assets/images/close.svg';
import copilotImg from 'assets/images/copilot-nav.svg';
import balanceImg from 'assets/images/balance-nav.svg';
import inboxImg from 'assets/images/inbox-nav.svg';
import profileImg from 'assets/images/profile-nav.svg';
import settingsImg from 'assets/images/settings-nav.svg';
import { slideoutTransitionDelay } from 'config/ui';
import useAppData from 'hooks/context';
import NavItem from 'components/NavItem';
import UpgradeToProButton from 'components/UpgradeToProButton';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  menuIcon: {
    cursor: 'pointer',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#181818',
    padding: 20,
    zIndex: 2,
  },
  logo: {
    width: 25,
    height: 25,
  },
  paper: {
    overflow: 'hidden',
    height: '100%',
  },
  drawerContainer: {
    height: '100%',
    backgroundColor: '#181818',
  },
  linksContainer: {
    marginTop: 20,
    padding: '0 20px',
  },
  link: {
    marginTop: theme.spacing(0.8),
    color: theme.common.white,
    fontSize: '1.3rem',
  },
  upgradeToProButtonContainer: {
    marginBottom: 20,
    padding: '0 20px',
    width: '100%',
  },
  chainlinkIcon: {
    cursor: 'pointer',
  },
  tag: {
    color: theme.blue,
  },
  footer: {
    padding: '0 20px',
    flexGrow: 0.1,
    backgroundColor: '#181818',
  },
  handleContainer: {
    paddingTop: 20,
    paddingBottom: 10,
  },
  handleDiv: {
    marginTop: 10,
  },
  footerLogoContainer: {
    padding: '0 !important',
    marginBottom: 20,
  },
  footerAppName: {
    fontFamily: 'Figtree-Bold',
    color: theme.common.white,
    fontSize: 32,
    '& span': {
      fontFamily: 'inherit',
      color: theme.blue,
    },
    '&.premium span': {
      fontFamily: 'inherit',
      color: theme.premiumYellow,
    },
  },
  hr: {
    margin: 'auto',
    backgroundColor: theme.grey[800],
    height: 2,
    border: 0,
  },
  logoutLink: {
    display: 'block',
    textAlign: 'center',
    fontSize: 14,
    textDecoration: 'underline',
    color: theme.grey[300],
    '&:hover': {
      color: theme.grey[400],
      textDecoration: 'underline',
    },
  },
  spacer: {
    flexGrow: 1,
  },
}));

const AppAuthHeader = () => {
  const classes = useStyles();

  const alert = useAlert();

  const { breakpoints, blue } = useTheme();

  const mobileView = useMediaQuery(breakpoints.down('xs'));

  const [drawerOpen, setDrawerOpen] = useState(false);

  // hide intercom launcher when drawer is open
  useEffect(() => {
    if (drawerOpen) updateMessenger({ hide_default_launcher: true });
    else updateMessenger({ hide_default_launcher: false });
  }, [drawerOpen]);

  const { user, balance } = useAppData();

  return (mobileView && 
    <>
      <Header
        rightIcon={
          <img
            src={squareImg}
            alt='square'
            className={classes.menuIcon}
            onClick={() => setDrawerOpen(true)}
          />
        } 
      />
      <Drawer
        classes={{ paperAnchorTop: classes.paper }}
        transitionDuration={400}
        anchor='top'
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <Grid
          container
          direction='column'
          className={classes.drawerContainer}
        >
          {/* header */}
          <Header
            rightIcon={
              <img
                src={closeImg}
                alt='close'
                className={classes.menuIcon}
                onClick={() => setDrawerOpen(false)}
              />
            } 
          />
          {/* links */}
          <Grid
            item
            container
            direction='column'
            alignItems='center'
            className={classes.linksContainer}
          >
            <NavItem 
              icon={inboxImg} 
              label='Inbox'
              path='/inbox'
            />
            <NavItem 
              icon={copilotImg} 
              label='FirstDraft'
              path='/FirstDraft' 
            />
            {(+balance?.balance > 0 || user.price_per_question !== '0') && (<NavItem 
              icon={balanceImg} 
              label='Balance'
              path='/balance'
            />)}
            <NavItem 
              icon={profileImg} 
              label='Profile'
              path='/profile'
            />
            <NavItem 
              icon={settingsImg} 
              label='Settings'
              path='/account'
            />
          </Grid>
          <div className={classes.spacer}></div>
          <Grid item className={classes.upgradeToProButtonContainer}>
            <UpgradeToProButton isInNav />
          </Grid>
          {/* footer */}
          <Grid item container className={classes.footer}>
            <Grid container direction='column' spacing={1}>
              {/* creator link */}
              <Grid item container direction='column'>
                <Grid item>
                  <hr className={classes.hr} />
                </Grid>
                <Grid item container justifyContent='space-between' className={classes.handleContainer}>
                  <Grid item>
                    <div className={classes.link}>
                      AsqMe.com/<span className={classes.tag}>{user.asqMe_tag}</span>
                    </div>
                  </Grid>
                  <Grid item>
                    <CopyToClipboard
                      text={`${process.env.REACT_APP_WEB_BASE_URL}/${user.asqMe_tag}`}
                      onCopy={() => {
                        alert.success(null, { title: "All set", body: "Link copied to clipboard.", timeout: 1000 + slideoutTransitionDelay, offsetError: true })
                      }}
                    >
                      <ChainLinkIcon fillColor={blue} className={classes.chainlinkIcon} />
                    </CopyToClipboard>
                  </Grid>
                </Grid>
              </Grid>
              {/* terms of use */}
              <Grid item container direction='column' className={classes.footerLogoContainer}>
                <Grid item>
                  <hr className={classes.hr} />
                </Grid>
                <Grid item container justifyContent='space-between' alignItems='center' className={classes.handleDiv}>
                  <Grid item>
                    <div
                      className={`${classes.footerAppName} ${
                        user.is_subscribed_and_profile_completed ? 'premium' : ''
                      }`}
                    >
                      Asq<span>Me</span>
                    </div>
                  </Grid>
                  <Grid item>
                    <Link to='/logout' className={classes.logoutLink}>
                      Log Out
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Drawer>
    </>
  )
};

const Header = (props) => {
  const { rightIcon } = props;

  const classes = useStyles();
  const {
    premiumYellow,
    common: { white },
  } = useTheme();

  const navigate = useNavigate();
  const { user } = useAppData();

  const newLogoColorProps = user?.is_subscribed_and_profile_completed
    ? { strokeColor: premiumYellow, fillColor: white }
    : {};

  const logoNavigationUrl = user?.is_profile_completed ? '/inbox' : '/profile';

  return (
    <div className={classes.headerContainer}>
      <NewLogoIcon
        {...newLogoColorProps}
        onClick={() => navigate(logoNavigationUrl)}
        className={classes.logo}
      />
      {rightIcon}
    </div>
  )
}

export default AppAuthHeader;
