import { number, object, string } from 'prop-types';
import common from '@material-ui/core/colors/common';

const ChainLinkIcon = ({
  className = '',
  style = {},
  width = 44,
  height = 44,
  fillColor = common.white,
  strokeColor = common.white,
  ...restProps
}) => (
  <svg
    {...restProps}
    style={style}
    className={className}
    height={height}
    width={width}
    viewBox='0 0 44 44'
    fill='none'
  >
    <g clipPath='url(#clip0_57_1820)'>
      <path
        d='M22 44C34.1503 44 44 34.1503 44 22C44 9.84974 34.1503 0 22 0C9.84974 0 0 9.84974 0 22C0 34.1503 9.84974 44 22 44Z'
        fill={fillColor}
      />
      <path
        d='M17.1865 32.5266C18.6499 32.5266 20.1133 31.9697 21.228 30.855L24.2363 27.8467C24.807 27.2761 24.807 26.3509 24.2363 25.7812C23.6667 25.2106 22.7405 25.2106 22.1709 25.7812L19.1626 28.7895C18.0734 29.8787 16.3006 29.8787 15.2114 28.7895C14.1222 27.7004 14.1222 25.9276 15.2114 24.8384L18.2197 21.8301C18.7903 21.2595 18.7903 20.3343 18.2197 19.7646C17.6501 19.194 16.7239 19.194 16.1543 19.7646L13.146 22.7729C10.9175 25.0014 10.9175 28.6275 13.146 30.856C14.2597 31.9687 15.7231 32.5266 17.1865 32.5266Z'
        fill={strokeColor}
      />
      <path
        d='M30.8548 13.145C28.6263 10.9175 25.0012 10.9165 22.7727 13.145L19.7644 16.1533C19.1938 16.7239 19.1938 17.6491 19.7644 18.2187C20.334 18.7894 21.2602 18.7894 21.8298 18.2187L24.8381 15.2104C25.9273 14.1212 27.7001 14.1212 28.7893 15.2104C29.8785 16.2996 29.8785 18.0724 28.7893 19.1616L25.781 22.1699C25.2104 22.7405 25.2104 23.6657 25.781 24.2353C26.0658 24.5212 26.44 24.6636 26.8142 24.6636C27.1884 24.6636 27.5626 24.5212 27.8474 24.2353L30.8557 21.227C33.0832 18.9995 33.0832 15.3735 30.8548 13.145Z'
        fill={strokeColor}
      />
      <path
        d='M26.0413 17.9585C25.4716 17.3879 24.5455 17.3879 23.9758 17.9585L17.9592 23.9751C17.3886 24.5457 17.3886 25.4709 17.9592 26.0405C18.244 26.3263 18.6182 26.4688 18.9924 26.4688C19.3666 26.4688 19.7408 26.3263 20.0256 26.0405L26.0423 20.0239C26.6119 19.4543 26.6119 18.5291 26.0413 17.9585Z'
        fill={strokeColor}
      />
    </g>
    <defs>
      <clipPath id='clip0_57_1820'>
        <rect height={height} width={width} fill='white' />
      </clipPath>
    </defs>
  </svg>
);

ChainLinkIcon.propTypes = {
  style: object,
  className: string,
  height: number,
  width: number,
  strokeColor: string,
  fillColor: string,
};

export default ChainLinkIcon;
