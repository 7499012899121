import { number, object, string } from 'prop-types';

const InboxLinkedin = ({
  className = '',
  style = {},
  width = 40,
  height = 40,
  fillColor = '#7E8DFF',
  ...rest
}) => (
  <svg
    {...rest}
    className={className}
    style={style}
    width={width}
    height={height}
    viewBox='0 0 40 40'
  >
    <path
      d='M35.2381 0H4.7619C2.13333 0 0 2.13333 0 4.7619V35.2381C0 37.8667 2.13333 40 4.7619 40H35.2381C37.8667 40 40 37.8667 40 35.2381V4.7619C40 2.13333 37.8667 0 35.2381 0ZM12.381 15.2381V33.3333H6.66667V15.2381H12.381ZM6.66667 9.97143C6.66667 8.6381 7.80952 7.61905 9.52381 7.61905C11.2381 7.61905 12.3143 8.6381 12.381 9.97143C12.381 11.3048 11.3143 12.381 9.52381 12.381C7.80952 12.381 6.66667 11.3048 6.66667 9.97143ZM33.3333 33.3333H27.619C27.619 33.3333 27.619 24.5143 27.619 23.8095C27.619 21.9048 26.6667 20 24.2857 19.9619H24.2095C21.9048 19.9619 20.9524 21.9238 20.9524 23.8095C20.9524 24.6762 20.9524 33.3333 20.9524 33.3333H15.2381V15.2381H20.9524V17.6762C20.9524 17.6762 22.7905 15.2381 26.4857 15.2381C30.2667 15.2381 33.3333 17.8381 33.3333 23.1048V33.3333Z'
      fill={fillColor}
    />
  </svg>
);

InboxLinkedin.propTypes = {
  style: object,
  className: string,
  height: number,
  width: number,
  fillColor: string,
  strokeColor: string,
};

export default InboxLinkedin;
