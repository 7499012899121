import { transitions, positions } from 'react-alert';

const slideoutTransitionDelay = 600; // Account for the 300ms slide in/out animation of the slide alert

const alertTemplateOptions = {
  position: positions.BOTTOM_CENTER,
  timeout: 3000 + slideoutTransitionDelay,
  containerStyle: {
    pointerEvents: 'all !important',
  }
};

export { alertTemplateOptions, slideoutTransitionDelay };
