import { useContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useAlert } from 'react-alert';
import { Radio } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Collapse from '@material-ui/core/Collapse';
import Drawer from '@material-ui/core/Drawer';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import Fade from '@material-ui/core/Fade';
import InputAdornment from '@material-ui/core/InputAdornment';
import Snackbar from '@material-ui/core/Snackbar';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ProfilePreview from 'components/ProfilePreview';
import { MaskedInput } from 'components/MaskedInput';
import DiscardChangesModal from 'components/DiscardChangesModal';
import GridWrapperWithBanner from '../../components/GridWrapper/GridWrapperWithBanner';
import Select from 'react-select';
import { updateMessenger } from 'utils/intercomConfig';
import { convertCurrency, getCurrencySymbol } from 'utils/currency-utils';
import { getSupportedCountries, queryCreatorTypes } from 'store/creator';
import {
  AmountChargeIcon,
  TipChargeIcon,
  ZeroChargeIcon,
  CircleErrorIcon,
  CircleSuccessIcon,
} from 'components/Icons';
import { AppDataContext } from 'Context';
import useUpload from 'hooks/upload';
import { checkIfTagExists, queryProfileTypes, saveProfile } from 'store/auth/profile';
import { pricePerQuestionOptions, initSupportedCountries } from './constants';
import { customValue, zeroValue, tipsValue, defaultAlertError } from 'utils/common/constants';
import chainlinkBlueBtn from 'assets/images/chainlink-blue.svg';
import infoIconImg from 'assets/images/info.svg';
import { errorStatuses } from 'utils/apiConfig';
import styles from './styles';
import PriceInfoDrawer from './PriceInfoDrawer';
import FeatureInfoDrawer from 'components/FeatureInfoDrawer';
import SaveProfileDrawer from './SaveProfileDrawer';
import PremiumFeaturesDrawer from 'components/PremiumFeaturesDrawer';
import ProfileFooter from './ProfileFooter';
import { trackTypes, useTrackActions } from './utils';
import { slideoutTransitionDelay } from 'config/ui';
import ClipsElement from 'components/Clips/ClipsElement';
import { Helmet } from 'react-helmet';
import { redditPixelScript } from 'utils/reddit-pixel';

const useStyles = makeStyles(styles);

const Profile = () => {
  const queryClient = useQueryClient();
  const alert = useAlert();
  const classes = useStyles();
  const {
    common: { white },
    error: { dark: red },
    success: { light: green },
    blue,
    breakpoints,
  } = useTheme();
  const mobileView = useMediaQuery(breakpoints.down('xs'));
  const smDimensionView = useMediaQuery(breakpoints.up('sm'));
  const mdDimensionView = useMediaQuery(breakpoints.up('md'));
  const lgDimensionView = useMediaQuery(breakpoints.up('lg'));

  const {
    appData: { tempProfileData, user, exchangeRates },
    setAppData,
  } = useContext(AppDataContext);

  const formRef = useRef({ valid: null, values: null, tempProfileData }); // ref used for temporary check and storing of profileData
  const checkTagTimeoutRef = useRef(null);
  const [previewProfileDrawer, setPreviewProfileDrawer] = useState(false);
  const [convertedPricePerQuestionOptions, setConvertedPricePerQuestionOptions] = useState(
    convertPricePerQuestionOptions(user.currency_code),
  );
  const [customPriceCurrencySymbol, setCustomPriceCurrencySymbol] = useState(
    getCurrencySymbol(user.currency_code ?? 'USD'),
  );
  const [supportedCountries, setSupportedCountries] = useState(initSupportedCountries);
  const [saveProfileDrawer, setSaveProfileDrawer] = useState(false);
  const [amountInfoDrawer, setAmountInfoDrawer] = useState(false);
  const [featureInfoDrawer, setFeatureInfoDrawer] = useState(null);
  const [premiumFeaturesDrawer, setPremiumFeaturesDrawer] = useState(false);
  const [discardChangesModal, setDiscardChangesModal] = useState(false);

  const drawerOpen = useMemo(
    () => saveProfileDrawer || amountInfoDrawer || !!featureInfoDrawer || premiumFeaturesDrawer,
    [saveProfileDrawer, amountInfoDrawer, featureInfoDrawer, premiumFeaturesDrawer],
  );

  // hide intercom launcher when a drawer is open
  useEffect(() => {
    if (drawerOpen) updateMessenger({ hide_default_launcher: true });
    else updateMessenger({ hide_default_launcher: false });
  }, [drawerOpen]);

  // track popup clicks and send metrics
  const onTrackAction = useTrackActions(user, exchangeRates);

  // asqMe tag field uniqueness call
  const {
    data: tagAlreadyExists,
    error: tagAlreadyExistsError,
    mutate: onCheckIfTagExists,
    reset: clearTagMutation,
  } = useMutation({
    mutationFn: checkIfTagExists,
    onSuccess: () => {
      checkTagTimeoutRef.current = null;
    },
    onError: (err) => {
      if (err.status !== errorStatuses.UNAUTHORIZED) {
        checkTagTimeoutRef.current = null;
        alert(null, { ...defaultAlertError, offsetError: true });
      }
    },
  });

  const { mutate: onSaveProfile, isLoading: saveProfileIsLoading } = useMutation({
    mutationFn: saveProfile,
    onSuccess: (_, data) => {
      queryClient.invalidateQueries({ queryKey: [queryProfileTypes.profile] });
      setSaveProfileDrawer(true);
      // alert.success(
      //   <div>
      //     <strong>Profile is successfully updated</strong>
      //   </div>,
      // );
    },
    onError: (err) => {
      if (err.status !== errorStatuses.UNAUTHORIZED) {
        alert.error(null, { ...defaultAlertError, offsetError: true });
      }
    },
  });

  // Delete from context any temporary stored profileData after mounting
  useEffect(() => {
    if (tempProfileData) setAppData((prev) => ({ ...prev, tempProfileData: null }));
  }, [tempProfileData]);

  const countriesOptions = supportedCountries
    .filter((x) => !x.noConnect)
    .map((c) => {
      return {
        label: `${c.country} - ${c.currency}`,
        value: `${c.country},${c.currency}`,
      };
    });

  useQuery({
    queryKey: [queryCreatorTypes.countries],
    queryFn: getSupportedCountries,
    onSuccess: (resp) => {
      setSupportedCountries(resp);
    },
    onError: (err) => {
      if (err.status !== errorStatuses.UNAUTHORIZED)
        alert.error(null, { ...defaultAlertError, offsetError: true });
    },
  });

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    initialValues,
    dirty,
    isValid,
    values,
    errors,
    touched,
    setFieldTouched,
    resetForm,
    submitForm,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      banner_image: user.banner_image,
      banner_file: '',
      logo_image: user.logo_image,
      logo_file: '',
      display_name: user.display_name || '',
      country: {
        label: `${user.country} - ${user.currency_code}`,
        value: `${user.country},${user.currency_code}`,
      },
      short_description: user.short_description || '',
      price_per_question: user.price_per_question || '10',
      price_per_question_custom: user.price_per_question_custom || '',
      asqMe_tag: user.asqMe_tag || '',
      youtube_tag: user.youtube_tag || '',
      patreon_tag: user.patreon_tag || '',
      ...formRef.current.tempProfileData,
    },
    validationSchema: Yup.object({
      display_name: Yup.string().required('Display name is required'),
      short_description: Yup.string(),
      country: Yup.object({
        label: Yup.string(),
        value: Yup.string(),
      }).nullable(false),
      price_per_question: Yup.string().required(),
      price_per_question_custom: Yup.string().when('price_per_question', {
        is: (value) => value === customValue,
        then: (schema) => schema.required('Custom price input is required'),
      }),
      asqMe_tag: Yup.string()
        .required('Handle is required')
        .min(4, 'Handle too short')
        .max(16, 'Handle too big'),
      youtube_tag: Yup.string(),
      patreon_tag: Yup.string(),
    }),
    onSubmit: (data, { setFieldValue }) => {
      if (
        tagAlreadyExists === true ||
        tagAlreadyExistsError ||
        !!checkTagTimeoutRef.current ||
        (typeof tagAlreadyExists !== 'boolean' && data.asqMe_tag !== user.asqMe_tag)
      ) {
        setAppData((prev) => ({ ...prev, tempProfileData: data }));
        return;
      }

      const {
        banner_file,
        banner_image,
        logo_file,
        logo_image,
        display_name,
        country,
        currency_code,
        short_description,
        ...dataToSend
      } = data;

      const truncatedDescription =
        short_description.length > 80 ? short_description.slice(0, 80) : short_description;

      const truncatedDisplayName =
        display_name.length > 30 ? display_name.slice(0, 30) : display_name;

      const [countryName, currencyCode] = country.value.split(',');

      onSaveProfile(
        {
          ...dataToSend,
          display_name: truncatedDisplayName,
          short_description: truncatedDescription,
          banner_file,
          country: countryName,
          currency_code: currencyCode,
          banner_image: banner_file ? banner_image : undefined,
          logo_file,
          logo_image: logo_file ? logo_image : undefined,
        },
        {
          onSuccess: () => {
            setFieldValue('display_name', truncatedDisplayName);
            setFieldValue('short_description', truncatedDescription);
          },
        },
      );
    },
  });

  // store every profileData change in ref
  useEffect(() => {
    formRef.current.valid = isValid;
    formRef.current.values = values;
  }, [isValid, values]);

  // while page unmounts, if form data are valid, try to submit and save them
  // else store them as tempProfileData in context to be available on next visit
  useEffect(() => {
    return () => {
      if (formRef.current.valid) submitForm();
      else setAppData((prev) => ({ ...prev, tempProfileData: formRef.current.values }));
    };
  }, []);

  const tag = useMemo(
    () =>
      touched.asqMe_tag &&
      !errors.asqMe_tag &&
      values.asqMe_tag &&
      values.asqMe_tag !== user.asqMe_tag
        ? values.asqMe_tag
        : null,
    [touched.asqMe_tag, errors.asqMe_tag, values.asqMe_tag, user],
  );

  useEffect(() => {
    clearTimeout(checkTagTimeoutRef.current);
    checkTagTimeoutRef.current = null;
    clearTagMutation();

    if (tag)
      checkTagTimeoutRef.current = setTimeout(() => {
        onCheckIfTagExists({ asqtag: tag });
      }, [500]);
  }, [tag]);

  const handleFormImageChange = useCallback(
    (formNames) => (formData) => {
      handleChange({
        target: {
          name: formNames.file,
          value: formData.encodedFile,
        },
      });
      handleChange({
        target: {
          name: formNames.image,
          value: formData.fileName,
        },
      });
    },
    [],
  );

  function convertPricePerQuestionOptions(currencyCode) {
    if (!currencyCode || currencyCode === 'USD') {
      return pricePerQuestionOptions;
    }

    let convertedOptions = [];

    for (const option of pricePerQuestionOptions) {
      const label = option.label;

      if (label === 'Custom') {
        convertedOptions.push(option);
        continue;
      }

      const value = Number(option.value);

      // Base rate will always be USD because pre defined price options are in USD. Therefore, we only convert the value for display purposes
      const convertedValue = Math.ceil(convertCurrency(value, 'USD', currencyCode, exchangeRates));
      const currencySymbol = getCurrencySymbol(currencyCode);
      const convertedLabel =
        currencySymbol.position === 'start'
          ? `${currencySymbol.symbol}${convertedValue}`
          : `${convertedValue} ${currencySymbol.symbol}`;

      convertedOptions.push({
        label: convertedLabel,
        value: `${value}`,
      });
    }

    return convertedOptions;
  }

  function convertCustomPricePerQuestion(currencyCode, oldCurrencyCode) {
    const customPrice = Number(values.price_per_question_custom);

    if (customPrice) {
      const convertFromCurrency = oldCurrencyCode ?? 'USD';
      const convertedCustomPrice = Math.ceil(
        convertCurrency(customPrice, convertFromCurrency, currencyCode, exchangeRates),
      );

      setFieldValue('price_per_question_custom', `${convertedCustomPrice}`);
    }
  }

  function handleCurrencyChange(newCurrencyCode, oldCurrencyCode) {
    setConvertedPricePerQuestionOptions(convertPricePerQuestionOptions(newCurrencyCode));
    convertCustomPricePerQuestion(newCurrencyCode, oldCurrencyCode);
    setCustomPriceCurrencySymbol(getCurrencySymbol(newCurrencyCode));
  }

  const {
    getRootProps: rootLogoProps,
    getInputProps: inputLogoProps,
    uploadIsLoading: logoUploadIsLoading,
  } = useUpload(handleFormImageChange({ image: 'logo_image', file: 'logo_file' }), {
    offsetError: true,
  });

  const handleTrimOnBlur = useCallback(
    (e) => {
      e.target.value = e.target.value.trim();
      handleChange(e);
      handleBlur(e);
    },
    [handleChange, handleBlur],
  );

  // unmount cleanup
  useEffect(
    () => () => {
      queryClient.invalidateQueries({ queryKey: [queryCreatorTypes.countries] });
    },
    [],
  );

  const [country, currencyCode] = values.country.value.split(',');

  // at initial profile visit, when display name is typed and blured
  // it's value is transformed and copied to asqme tag field
  const initializeTagFromDisplayName = useCallback(
    (e) => {
      handleTrimOnBlur(e);
      if (user.first_time_submission || !!user.asqMe_tag) return;

      if (!e.target.value) return;

      const asqmeTagField = {
        target: { name: 'asqMe_tag', value: `@${e.target.value.replace(' ', '').slice(0, 30)}` },
      };

      handleChange(asqmeTagField);
      setFieldTouched('asqMe_tag', true);
    },
    [handleTrimOnBlur, user.first_time_submission, user.asqMe_tag, user.display_name],
  );
  const zeroValueSelected = values.price_per_question === zeroValue;
  const tipValueSelected = values.price_per_question === tipsValue;
  const amountValueSelected =
    !!values.price_per_question && ![zeroValue, tipsValue].includes(values.price_per_question);

  return (
    <>
      <Helmet>
        <script>{redditPixelScript}</script>
      </Helmet>
      <Grid container className={classes.homeContainer}>
        <GridWrapperWithBanner
          bannerImageUrl={values.logo_file || values.logo_image}
          bannerShowLogo={true}
          rootLogoProps={rootLogoProps()}
          inputLogoProps={inputLogoProps()}
          isPhotoUploadLoading={logoUploadIsLoading}
        >
          {saveProfileIsLoading && (
            <Backdrop open className={classes.backdrop}>
              <CircularProgress color='inherit' thickness={3} size={60} />
            </Backdrop>
          )}
          {/* form */}
          <form onSubmit={handleSubmit} className={classes.form}>
            <Grid
              container
              direction='column'
              justifyContent='space-between'
              className={classes.homeInnerContainer}
            >
              {/* we need this for correct grid layout */}
              <Grid
                item
                container
                direction='column'
                justifyContent='space-between'
                className={classes.dataInputSection}
                // spacing={2}
              >
                {/* data input section */}
                {/* display name */}
                <Grid item container className={classes.spacing}>
                  <Grid item md sm xs />
                  <Grid item md={12} xs={10}>
                    <Grid container direction='column' className={classes.displayNameContainer}>
                      <Grid item>
                        <div className={classes.inputLabel}>
                          Display Name<span className={classes.requiredMark}>*</span>
                        </div>
                      </Grid>
                      <Grid item>
                        <TextField
                          classes={{ root: classes.textField }}
                          size='medium'
                          variant='filled'
                          fullWidth
                          placeholder='i.e. John Smith'
                          {...getFieldProps('display_name')}
                          onBlur={initializeTagFromDisplayName}
                          error={
                            !!touched.display_name &&
                            (!!errors.display_name || values.display_name.length > 30)
                          }
                          helperText={
                            touched.display_name &&
                            (errors.display_name ||
                              (values.display_name.length > 30
                                ? `Display name can only be 30 characters. You’re ${
                                    values.display_name.length - 30
                                  } over.`
                                : null))
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md sm xs />
                </Grid>
                {/* short description */}
                <Grid item container className={classes.spacing}>
                  <Grid item md sm xs />
                  <Grid item md={12} xs={10}>
                    <Grid container direction='column'>
                      <Grid item>
                        <div className={classes.inputLabel}>Short Description</div>
                      </Grid>
                      <Grid item>
                        <TextField
                          classes={{ root: classes.textField }}
                          size='medium'
                          variant='filled'
                          fullWidth
                          placeholder='i.e. Ask me about DIY projects!'
                          {...getFieldProps('short_description')}
                          onBlur={handleTrimOnBlur}
                          error={
                            !!touched.short_description && values.short_description.length > 80
                          }
                          helperText={
                            touched.short_description &&
                            values.short_description.length > 80 &&
                            `Short description can only be 80 characters. You’re ${
                              values.short_description.length - 80
                            } over.`
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md sm xs />
                </Grid>
                {/* asqMe link */}
                <Grid item container className={classes.spacing}>
                  <Grid item md sm xs />
                  <Grid item md={12} xs={10}>
                    <Grid container direction='column'>
                      <Grid item>
                        <div className={classes.inputLabel}>
                          AsqMe Handle<span className={classes.requiredMark}>*</span>
                        </div>
                      </Grid>
                      <Grid item container justifyContent='space-between' alignItems='center'>
                        {/* label */}
                        <Grid item>
                          <Grid
                            className={classes.prefixContainer}
                            container
                            direction='column'
                            justifyContent='center'
                          >
                            <Grid item>
                              <div className={classes.prefixLinkUrl}>AsqMe.com/</div>
                            </Grid>
                          </Grid>
                        </Grid>
                        {/* text input */}
                        <Grid item className={classes.inputContainer}>
                          <TextField
                            classes={{
                              root: `${classes.textField} space`,
                            }}
                            fullWidth
                            size='medium'
                            variant='filled'
                            placeholder='@YourHandleHere*'
                            InputProps={{
                              inputProps: {
                                mask: '@###############',
                                definitions: {
                                  '#': /[A-Za-z0-9]/,
                                },
                              },
                              inputComponent: MaskedInput,
                            }}
                            {...getFieldProps('asqMe_tag')}
                            onBlur={handleTrimOnBlur}
                            error={!!touched.asqMe_tag && !!errors.asqMe_tag}
                            helperText={
                              touched.asqMe_tag && errors.asqMe_tag ? (
                                <span className={classes.tagHelperText}>{errors.asqMe_tag}</span>
                              ) : typeof tagAlreadyExists === 'boolean' ? (
                                tagAlreadyExists ? (
                                  <span className={classes.tagHelperText}>
                                    <CircleErrorIcon
                                      width={18}
                                      height={18}
                                      fillColor={red}
                                      strokeColor={white}
                                    />
                                    <span>Handle not available</span>
                                  </span>
                                ) : (
                                  <span className={`${classes.tagHelperText} success`}>
                                    <CircleSuccessIcon
                                      width={18}
                                      height={18}
                                      fillColor={green}
                                      strokeColor={white}
                                    />
                                    <span>Handle available</span>
                                  </span>
                                )
                              ) : !!tagAlreadyExistsError ? (
                                <span className={classes.tagHelperText}>Service Error</span>
                              ) : null
                            }
                          />
                        </Grid>
                        {/* chain */}
                        {((initialValues.asqMe_tag === values.asqMe_tag && !!values.asqMe_tag) ||
                          tagAlreadyExists === false) && (
                          <Grid item>
                            <Fade in>
                              <div>
                                <CopyToClipboard
                                  text={`${process.env.REACT_APP_WEB_BASE_URL}/${
                                    values.asqMe_tag || ''
                                  }`}
                                  onCopy={() => {
                                    alert.success(null, {
                                      title: 'All set',
                                      body: 'Link copied to clipboard.',
                                      timeout: 1000 + slideoutTransitionDelay,
                                      offsetError: true,
                                    });
                                  }}
                                >
                                  <img
                                    src={chainlinkBlueBtn}
                                    className={classes.chainIcon}
                                    alt=''
                                  />
                                </CopyToClipboard>
                              </div>
                            </Fade>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md sm xs />
                </Grid>
                {process.env.REACT_APP_ENABLE_CLIPS === 'true' && (
                  <Grid item container className={classes.spacing}>
                    <Grid item md sm xs />
                    <Grid item md={12} xs={10}>
                      <Grid item>
                        <div className={classes.inputLabel}>
                          {user.clips_enabled
                            ? 'YouTube Channel (connected)'
                            : 'Connect YouTube Channel'}
                        </div>
                      </Grid>
                      <ClipsElement showEnabledClips />
                    </Grid>
                    <Grid item md sm xs />
                  </Grid>
                )}
                {/* price options section */}
                <Grid item container className={classes.spacing}>
                  <Grid item md sm xs />
                  <Grid item md={12} xs={10}>
                    <Grid container direction='column'>
                      {/* label */}
                      <Grid
                        item
                        container
                        justifyContent='flex-start'
                        spacing={1}
                        // alignItems='center'
                      >
                        <Grid item>
                          <div className={classes.inputLabel}>
                            How do you want to charge?
                            <span className={classes.requiredMark}>*</span>
                          </div>
                        </Grid>
                        <Grid
                          item
                          className={classes.infoImgContainer}
                          onClick={() => {
                            onTrackAction(trackTypes.tooltip);
                            setAmountInfoDrawer(true);
                          }}
                        >
                          <img className={classes.infoIconImg} src={infoIconImg} alt='' />
                        </Grid>
                      </Grid>
                      {/* price options */}
                      <Grid
                        item
                        container
                        spacing={
                          lgDimensionView ? 8 : mdDimensionView ? 4 : smDimensionView ? 2 : 1
                        }
                      >
                        {/* $0 */}
                        <Grid item className={classes.priceOptionOuterContainer}>
                          <Grid
                            container
                            direction='column'
                            justifyContent='center'
                            alignItems='center'
                            className={`${classes.priceOptionContainer} ${
                              zeroValueSelected ? 'selected' : ''
                            }`}
                            onClick={() => {
                              if (user.is_subscribed_and_profile_completed)
                                handleChange({
                                  target: { name: 'price_per_question', value: zeroValue },
                                });
                              else {
                                onTrackAction(trackTypes.free);
                                setFeatureInfoDrawer('free');
                              }
                            }}
                          >
                            <Grid item>
                              <ZeroChargeIcon fillColor={zeroValueSelected ? blue : undefined} />
                            </Grid>
                            <Grid item>
                              <div className={classes.priceOptionText}>
                                Don't <br />
                                Charge
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                        {/* tip */}
                        <Grid item className={classes.priceOptionOuterContainer}>
                          <Grid
                            container
                            direction='column'
                            justifyContent='center'
                            alignItems='center'
                            className={`${classes.priceOptionContainer} ${
                              tipValueSelected ? 'selected' : ''
                            }`}
                            onClick={() => {
                              if (user.is_subscribed_and_profile_completed)
                                handleChange({
                                  target: { name: 'price_per_question', value: tipsValue },
                                });
                              else {
                                onTrackAction(trackTypes.tipjar);
                                setFeatureInfoDrawer('tip');
                              }
                            }}
                          >
                            <Grid item>
                              <TipChargeIcon fillColor={tipValueSelected ? blue : undefined} />
                            </Grid>
                            <Grid item>
                              <div className={classes.priceOptionText}>
                                Tips <br />
                                Only
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                        {/* amount */}
                        <Grid item className={classes.priceOptionOuterContainer}>
                          <Grid
                            container
                            direction='column'
                            justifyContent='center'
                            alignItems='center'
                            className={`${classes.priceOptionContainer} ${
                              amountValueSelected ? 'selected' : ''
                            }`}
                            onClick={() =>
                              handleChange({
                                target: { name: 'price_per_question', value: '10' },
                              })
                            }
                          >
                            <Grid item>
                              <AmountChargeIcon
                                fillColor={amountValueSelected ? blue : undefined}
                              />
                            </Grid>
                            <Grid item>
                              <div className={classes.priceOptionText}>
                                Set <br />
                                Price
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      {touched.price_per_question && errors.price_per_question && (
                        <Grid item>
                          <div className={classes.radioGroupErrorText}>An option is required</div>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item md sm xs />
                </Grid>
                {/* price amount radio group */}
                <Grid item container className={classes.spacing}>
                  <Grid item md sm xs />
                  <Grid item md={12} xs={10}>
                    <Collapse in={amountValueSelected}>
                      {/* price radiogroup */}
                      <Grid container direction='column'>
                        <Grid item container direction='column'>
                          <Grid item container>
                            <Grid item>
                              <div className={classes.inputLabel}>
                                Price per Question {`(${currencyCode})`}{' '}
                                <span className={classes.requiredMark}>*</span>
                              </div>
                            </Grid>
                          </Grid>
                          <Grid item>
                            <Radio.Group
                              size='large'
                              className={classes.radioGroup}
                              options={convertedPricePerQuestionOptions}
                              optionType='button'
                              {...getFieldProps('price_per_question')}
                              onChange={(e) => {
                                handleChange(e);

                                if (
                                  e.target.value !== customValue &&
                                  !!values.price_per_question_custom
                                ) {
                                  handleChange({
                                    target: { name: 'price_per_question_custom', value: '' },
                                  });
                                }
                              }}
                            />
                            {touched.price_per_question && errors.price_per_question && (
                              <div className={classes.radioGroupErrorText}>
                                Price per Question is required
                              </div>
                            )}
                          </Grid>
                        </Grid>
                        {/* custom price input */}
                        <Grid item container direction='column' className={classes.spacing}>
                          <Collapse in={values.price_per_question === customValue}>
                            <Grid item>
                              <div className={classes.inputLabel}>
                                Custom Price Input<span className={classes.requiredMark}>*</span>
                              </div>
                            </Grid>
                            <Grid item>
                              <TextField
                                classes={{ root: classes.textField }}
                                size='medium'
                                variant='filled'
                                fullWidth
                                placeholder='i.e. 25'
                                InputProps={{
                                  startAdornment: customPriceCurrencySymbol.position ===
                                    'start' && (
                                    <InputAdornment position='end'>
                                      <div className={classes.dollarSign}>
                                        {customPriceCurrencySymbol.symbol}
                                      </div>
                                    </InputAdornment>
                                  ),
                                  endAdornment: customPriceCurrencySymbol.position === 'end' && (
                                    <InputAdornment position='start'>
                                      <div className={classes.dollarSign}>
                                        {customPriceCurrencySymbol.symbol}
                                      </div>
                                    </InputAdornment>
                                  ),
                                  inputProps: {
                                    mask: '#0000000000',
                                    definitions: {
                                      '#': /[1-9]/,
                                    },
                                  },
                                  inputComponent: MaskedInput,
                                }}
                                {...getFieldProps('price_per_question_custom')}
                                onBlur={handleTrimOnBlur}
                                error={
                                  !!touched.price_per_question_custom &&
                                  !!errors.price_per_question_custom
                                }
                                helperText={
                                  touched.price_per_question_custom &&
                                  errors.price_per_question_custom
                                }
                              />
                            </Grid>
                          </Collapse>
                        </Grid>
                      </Grid>
                    </Collapse>
                  </Grid>
                  <Grid item md sm xs />
                </Grid>
                {/* country drop down */}
                {!user.connected_account_enabled &&
                  values.price_per_question !== zeroValue &&
                  values.price_per_question !== tipsValue &&
                  process.env.REACT_APP_ENABLE_COUNTRY_DROPDOWN === 'true' && (
                    <Grid item container className={classes.spacing}>
                      <Grid item md sm xs />
                      <Grid item md={12} xs={10}>
                        <Grid container direction='column'>
                          {/* label */}
                          <Grid item>
                            <div className={classes.inputLabel}>Select Country</div>
                          </Grid>
                          {/* drop down */}
                          <Grid item>
                            <Select
                              className={classes.select}
                              classNamePrefix='select'
                              fullWidth
                              menuPlacement='top'
                              value={values.country}
                              isClearable={false}
                              placeholder='i.e. United States - USD'
                              {...getFieldProps('country')}
                              onChange={(option) => {
                                const newCurrencyCode = option.value.split(',')[1];
                                const oldCurrencyCode = values.country.value.split(',')[1];

                                handleCurrencyChange(newCurrencyCode, oldCurrencyCode);

                                handleChange({
                                  target: { name: 'country', value: option },
                                });
                              }}
                              options={countriesOptions}
                              error={!!touched.country && !values.country}
                            />
                          </Grid>
                          <Grid item>
                            <FormHelperText className={classes.errorText}>
                              {!!touched.country ? errors.country : ''}
                            </FormHelperText>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item md sm xs />
                    </Grid>
                  )}
                {/* footer - buttons section */}
                <ProfileFooter
                  classes={classes}
                  dirty={dirty}
                  logoUploadIsLoading={logoUploadIsLoading}
                  saveProfileIsLoading={saveProfileIsLoading}
                  setDiscardChangesModal={setDiscardChangesModal}
                  setPreviewProfileDrawer={setPreviewProfileDrawer}
                />
              </Grid>
            </Grid>
          </form>
        </GridWrapperWithBanner>
      </Grid>
      {/* informational popups section */}
      <PriceInfoDrawer
        open={amountInfoDrawer}
        mobileType={mobileView}
        onClose={() => setAmountInfoDrawer(false)}
        onLinkSelect={() => {
          setPremiumFeaturesDrawer(true);
          setAmountInfoDrawer(false);
        }}
      />
      <PremiumFeaturesDrawer
        open={premiumFeaturesDrawer}
        mobileType={mobileView}
        onClose={() => setPremiumFeaturesDrawer(false)}
      />
      <FeatureInfoDrawer
        open={!!featureInfoDrawer}
        type={featureInfoDrawer}
        mobileType={mobileView}
        onClose={() => setFeatureInfoDrawer(null)}
      />
      <SaveProfileDrawer
        asqMeTag={user.asqMe_tag}
        open={saveProfileDrawer}
        mobileType={mobileView}
        onClose={() => setSaveProfileDrawer(false)}
      />
      {/* profile preview drawer */}
      <Drawer
        classes={{ root: classes.drawer, paperAnchorTop: classes.paper }}
        transitionDuration={0}
        anchor='top'
        open={previewProfileDrawer}
        onClose={() => setPreviewProfileDrawer(false)}
      >
        <ProfilePreview
          inPreviewMode
          imageUploadDisabled
          amountOptionEnabled={![tipsValue, zeroValue].includes(values.price_per_question)}
          tipOptionEnabled={values.price_per_question === tipsValue}
          onPreviewClose={() => setPreviewProfileDrawer(false)}
          creatorData={{ ...values, currency_code: values.country?.value?.split(',')[1] ?? 'USD' }}
        />
      </Drawer>
      {/* discard changes modal */}
      <DiscardChangesModal
        open={discardChangesModal}
        onOk={() => {
          resetForm();
          setDiscardChangesModal(false);
        }}
        onCancel={() => {
          setDiscardChangesModal(false);
        }}
      />
    </>
  );
};

export default Profile;
