const Success = ({ className = '' }) => (
  <svg
    className={className}
    width='60'
    height='60'
    viewBox='0 0 80 80'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_3716_5401)'>
      <path
        d='M40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80Z'
        fill='#AEDC20'
      />
      <path
        d='M36.7922 61.2766L17.8797 46.534L23.2578 39.6279L34.8719 48.6808L54.0391 21.0046L61.2313 25.9891L36.7922 61.2766Z'
        fill='white'
      />
    </g>
    <defs>
      <clipPath id='clip0_3716_5401'>
        <rect width='80' height='80' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
export default Success;
