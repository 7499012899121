import { PatreonIcon, YoutubeIcon } from 'components/Icons';
import { customValue } from 'utils/common/constants';

const initSupportedCountries = [{ country: 'United States', currency: 'USD' }];

// Prices are in USD
const pricePerQuestionOptions = [
  // { label: 'None', value: zeroValue },
  // { label: 'Tips', value: tipsValue },
  { label: '$5', value: '5' },
  { label: '$10', value: '10' },
  { label: '$20', value: '20' },
  { label: '$40', value: '40' },
  { label: 'Custom', value: customValue },
];

const socialMediaOptions = [
  {
    name: 'youtube_tag',
    header: 'Youtube URL',
    icon: YoutubeIcon,
    prependLabel: 'youtube.com/',
  },
  {
    name: 'patreon_tag',
    header: 'Patreon URL',
    icon: PatreonIcon,
    prependLabel: 'patreon.com/',
  },
];

export { initSupportedCountries, pricePerQuestionOptions };
