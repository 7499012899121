import { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppDataContext } from 'Context';

const useAuthNavigation = () => {
  const navigate = useNavigate();

  const {
    appData: { user },
  } = useContext(AppDataContext);

  const login = useCallback(
    () =>
      !!user
        ? navigate('/profile', { replace: true })
        : window.location.replace(process.env.REACT_APP_WEB_BASE_URL),
    [user],
  );

  return { login };
};

export default useAuthNavigation;
