import { Grid } from '@material-ui/core';
import GridWrapper from 'components/GridWrapper';
import { makeStyles } from '@material-ui/core/styles';
import { AppAuthHeader } from 'components/Header';

const useStyles = makeStyles(() => ({
  pageWrap: {
    height: '100%',
  },
  innerWrap: {
    padding: 20,
  },
  innerWithoutPaddingWrap: {
    padding: 0,
  },
}));

export default function PageWrap({ children, isFullWidthChild }) {
  const classes = useStyles();

  return (
    <Grid container className={classes.pageWrap}>
      <GridWrapper isFullWidthChild>
        <Grid container direction='column'>
          <AppAuthHeader />
          <Grid
            item
            container
            className={isFullWidthChild ? classes.innerWithoutPaddingWrap : classes.innerWrap}
          >
            {children}
          </Grid>
        </Grid>
      </GridWrapper>
    </Grid>
  );
}
