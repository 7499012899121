import { useMemo, useEffect } from 'react';
import { Route, Navigate, useLocation } from 'react-router-dom';
import { AuthRoute, PublicRoute } from 'components/AuthRoutes';
import { segment } from 'index';

const resolveRouteSchema = (routeSchema) =>
  routeSchema.map(
    ({
      path,
      element: Element,
      authRequired,
      fallback,
      redirectPath,
      routes: subroutes,
    }) => {
      let Component = fallback ? (
        <Navigate to={redirectPath} replace />
      ) : (
        <Element />
      );

      const AuthWrapper =
        authRequired !== undefined
          ? authRequired
            ? AuthRoute
            : PublicRoute
          : null;

      if (AuthWrapper) Component = <AuthWrapper>{Component}</AuthWrapper>;

      Component = <><RouteChangeTracker />{Component}</>

      return subroutes ? (
        <Route key={path} path={path} element={Component}>
          {resolveRouteSchema(subroutes)}
        </Route>
      ) : (
        <Route key={path} path={path} element={Component} />
      );
    },
  );

// hook that resolves given route schema and generates route components
const useRouteSchema = (routeSchema) => {
  const routes = useMemo(() => resolveRouteSchema(routeSchema), []);

  return routes;
};

// Tracks route changes - used for analytics
const RouteChangeTracker = () => {
  const location = useLocation();

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      if (segment) {
        segment.page();
      } else {
        console.error('Segment is not loaded!')
      }
    }
  }, [location]);

  return null;
}

export default useRouteSchema;
