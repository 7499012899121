export const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  headingContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    width: '100%',
    gap: 10,
    '& button': {
      minWidth: 'auto',
      minHeight: 'auto',
      padding: 0,
      alignItems: 'center',
    },
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
    '& span': {
      display: 'inline-flex',
      alignItems: 'center',
      fontFamily: 'Figtree-SemiBold',
      fontSize: 16,
    },
  },
  headingText: {
    fontFamily: 'Figtree-Bold',
    fontSize: 16,
    lineHeight: '19px',
    letterSpacing: '0em',
    textAlign: 'left',
    marginBottom: 20,
  },
  tipButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    boxShadow: '0px 2px 2px 0px #0000001A',
    width: '100%',
    height: 44,
    padding: 12,
    marginBottom: 10,
    backgroundColor: '#FFFFFF',
    '& .MuiButton-label': {
      height: '100%',
    },
    '& .MuiButton-label img': {
      maxHeight: '100%',
    },
    '& .MuiButton-label span': {
      fontFamily: 'Figtree-SemiBold',
      fontSize: 10,
      lineHeight: '12px',
      textTransform: 'none',
      marginLeft: 8,
    },
  },
  sendTipButton: {
    height: 44,
    '& .MuiButton-label': {
      fontFamily: 'Figtree-Bold',
      fontSize: 16,
      lineHeight: '19.2px',
    },
  },
  sentTipButton: {
    backgroundColor: '#2B8F59',
    '&.Mui-disabled': {
      backgroundColor: '#2B8F59',
      opacity: 1,
    },
  },
  creditCardIcon: {
    width: 24,
    height: 24,
  },
  tipInputGroup: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 30,
    marginBottom: 80,
    width: '100%',
  },
  tipInputLabel: {
    fontFamily: 'Figtree-Bold',
    fontSize: 16,
    lineHeight: '21.79px',
    marginBottom: 6,
  },
  tipInput: {
    width: '100%',
    fontFamily: 'Figtree-Bold',
    fontSize: 32,
    lineHeight: '42.67px',
    padding: '10px 20px',
    '& input': {
      textAlign: 'center',
      color: '#454545',
      borderBottom: '1px solid #EEEEEE',
    }
  },
  totalPrice: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: 40,
    '& span': {
      textAlign: 'center',
      fontFamily: 'Figtree-Bold',
      fontSize: 14,
      lineHeight: '16.8px',
      color: '#666666'
    },
    '& span:nth-child(2)': {
      fontFamily: 'Figtree-Regular',
      size: 12,
      lineHeight: '16.34px',
    }
  },
  // Dialog styles
  dialog: {
    '& .MuiDialog-paper': {
      borderRadius: 20,
    }
  },
  dialogContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    padding: 20,
    '& p': {
      fontFamily: 'Figtree-Regular',
      fontSize: 14,
      lineHeight: '19.07px',
      marginBottom: 40,
    }
  },
  dialogHeaderContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: 20,
    '& h2': {
      fontFamily: 'Figtree-Bold',
      fontSize: 16,
      lineHeight: '19px',
      letterSpacing: '0em',
      textAlign: 'left',
      display: 'inline-flex',
      alignItems: 'center',
      marginBottom: 0,
    },
    '& button': {
      minWidth: 'auto',
      minHeight: 'auto',
      padding: 0,
      alignItems: 'start',
    },
  },
  redirectMessageHeaderMargin: {
    marginBottom: 10,
  },
  redirectMessageParagraphMargin: {
    marginBottom: '20px !important',
  },
  saveBtn: {
    marginTop: 40,
    padding: '7px 14px',
    textTransform: 'none',
    transition: 'all 0.3s ease-in-out',
    borderRadius: 5,
    backgroundColor: theme.blue,
    '& *': {
      fontFamily: 'FigTree-Regular',
    },
    '&.Mui-disabled': {
      opacity: 0.7,
    },
    '& .MuiButton-label': {
      fontFamily: 'Figtree-Regular',
      color: theme.common.white,
      fontSize: '1.1rem',
    },
    '&:hover': {
      backgroundColor: theme.blue,
      opacity: 0.85,
      '&.Mui-disabled': {
        backgroundColor: theme.blue,
        opacity: 0.7,
      },
    },
  },
});