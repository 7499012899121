import { Helmet } from 'react-helmet';
import useAuth from 'hooks/auth';
import fabicon from 'assets/fabicon/favicon.ico';
import appleTouchIcon from 'assets/fabicon/apple-touch-icon.png';
import fabicon32 from 'assets/fabicon/favicon-32x32.png';
import fabicon16 from 'assets/fabicon/favicon-16x16.png';
import manifest from 'assets/fabicon/site.webmanifest';

const PremiumFabicon = () => {
  const user = useAuth();

  return user?.is_subscribed_and_profile_completed ? (
    <Helmet>
      <link rel='apple-touch-icon' sizes='180x180' href={appleTouchIcon} />
      <link rel='icon' type='image/png' sizes='32x32' href={fabicon32} />
      <link rel='icon' type='image/png' sizes='16x16' href={fabicon16} />
      <link rel='manifest' href={manifest} />
      <link id='favicon' rel='icon' href={fabicon} type='image/x-icon' data-react-helmet='true' />
    </Helmet>
  ) : null;
};

export default PremiumFabicon;
