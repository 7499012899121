import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';
import closeImg from 'assets/images/close.svg';
import handImg from 'assets/images/hand.svg';
import messageQImg from 'assets/images/messageQuestion.svg';
import logoImg from 'assets/images/logo-2.svg';
import thunderImg from 'assets/images/thunder-big.png';

const useStyles = makeStyles((theme) => ({
  menuIcon: {
    cursor: 'pointer',
  },
  drawer: {
    overflow: 'hidden',
  },
  paper: {
    overflowX: 'hidden',
    height: '100%',
    '&.maxWidth': {
      maxWidth: 600,
    },
  },
  drawerSkeletonContainer: {
    height: '100%',
  },
  drawerContainer: {
    height: '100%',
    backgroundColor: theme.common.black,
    '@media (max-height: 500px)': {
      height: 'unset',
    },
  },
  header: {
    backgroundColor: theme.common.black,
    padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px ${theme.spacing(1)}px`,
  },
  earningsContainer: {
    marginTop: theme.spacing(4),
    '&.spaceTop': {
      '@media (max-height: 780px)': {
        marginTop: theme.spacing(3),
      },
      '@media (max-height: 630px)': {
        marginTop: theme.spacing(1),
      },
    },
  },
  text: {
    color: theme.common.white,
    fontFamily: 'Figtree-SemiBold',
    fontSize: '1.55rem',
    textAlign: 'center',
    padding: `0 ${theme.spacing(1)}px`,
    lineHeight: 1.2,
    '&.spaceTop': {
      '@media (max-height: 780px)': {
        fontSize: '1.4rem',
      },
      '@media (max-height: 630px)': {
        fontSize: '1.25rem',
      },
    },
  },
  iText: {
    color: theme.common.white,
    fontFamily: 'Figtree-SemiBold',
    fontSize: '1.45rem',
    textAlign: 'center',
    padding: `0 ${theme.spacing(1)}px`,
    lineHeight: 1.2,
  },
  iconContainer: {
    marginTop: theme.spacing(3),
    '&.spaceTop': {
      marginTop: theme.spacing(8),
      '@media (max-height: 780px)': {
        marginTop: theme.spacing(5),
      },
      '@media (max-height: 630px)': {
        marginTop: theme.spacing(2),
      },
    },
  },
  icon: {},
  iconTitle: {
    color: theme.common.white,
    fontFamily: 'Figtree-SemiBold',
    fontSize: '1.2rem',
    textAlign: 'center',
    padding: `0 ${theme.spacing(1)}px`,
    '&.spaceTop': {
      '@media (max-height: 780px)': {
        fontSize: '1.1rem',
      },
      '@media (max-height: 630px)': {
        fontSize: '1rem',
      },
    },
  },
  iconDescription: {
    color: theme.common.white,
    opacity: 0.8,
    fontSize: '1rem',
    textAlign: 'center',
    lineHeight: 1.2,
    padding: `0 ${theme.spacing(4)}px`,
    '&.shrink': {
      padding: `0 ${theme.spacing(15)}px`,
      margin: 'auto',
      '@media (max-height: 780px)': {
        fontSize: '0.9rem',
      },
      '@media (max-height: 630px)': {
        fontSize: '0.85rem',
      },
    },
  },
  iIconDescription: {
    color: theme.common.white,
    opacity: 0.8,
    fontSize: '1.1rem',
    textAlign: 'center',
    lineHeight: 1.3,
    padding: `0 ${theme.spacing(4)}px`,
    '& a': {
      color: theme.blue,
      textDecoration: 'underline',
    },
    '&.shrink': {
      width: '70%',
      margin: 'auto',
    },
  },
  iIconSecondDescription: {
    color: theme.common.white,
    fontSize: '1rem',
    textAlign: 'center',
    lineHeight: 1.3,
    padding: `${theme.spacing(6)}px ${theme.spacing(5)}px 0`,
  },
  thunderContainer: {
    marginTop: theme.spacing(8),
    '&.spaceTop': { marginTop: theme.spacing(20) },
  },
}));

const InfoDrawer = ({ mobileType, open, onClose, textType }) => {
  const classes = useStyles();

  return (
    <Drawer
      classes={{
        root: classes.drawer,
        paper: `${classes.paper} ${!mobileType ? 'maxWidth' : ''}`,
        paperAnchorTop: classes.paper,
      }}
      transitionDuration={400}
      anchor={mobileType ? 'top' : 'right'}
      open={open}
      onClose={onClose}
    >
      <Grid container direction='column' className={classes.drawerContainer}>
        {/* header */}
        <Grid
          item
          container
          justifyContent='flex-end'
          alignItems='center'
          className={classes.header}
        >
          <Grid item>
            <img src={closeImg} onClick={onClose} />
          </Grid>
        </Grid>
        {textType === 'earnings' ? (
          <>
            {/* text */}
            <Grid
              item
              container
              justifyContent='center'
              className={`${classes.earningsContainer} ${!mobileType ? 'spaceTop' : ''}`}
            >
              <Grid item>
                <div className={`${classes.text} ${!mobileType ? 'spaceTop' : ''}`}>
                  How is your balance calculated?
                </div>
              </Grid>
            </Grid>
            {/* 1st icon */}
            <Grid
              item
              container
              direction='column'
              alignItems='center'
              className={`${classes.iconContainer} ${!mobileType ? 'spaceTop' : ''}`}
              spacing={2}
            >
              <Grid item>
                <div className={classes.icon}>
                  <img src={messageQImg} alt='' />
                </div>
              </Grid>
              <Grid item>
                <div className={`${classes.iconTitle} ${!mobileType ? 'spaceTop' : ''}`}>
                  New Earnings
                </div>
                <div className={`${classes.iconDescription} ${!mobileType ? 'shrink' : ''}`}>
                  Recent cleared and pending earnings.
                </div>
              </Grid>
            </Grid>
            {/* 2nd icon */}
            <Grid
              item
              container
              direction='column'
              alignItems='center'
              className={`${classes.iconContainer} ${!mobileType ? 'spaceTop' : ''}`}
              spacing={2}
            >
              <Grid item>
                <div className={classes.icon}>
                  <img src={logoImg} alt='' />
                </div>
              </Grid>
              <Grid item>
                <div className={`${classes.iconTitle} ${!mobileType ? 'spaceTop' : ''}`}>
                  AsqMe Fee
                </div>
                <div className={`${classes.iconDescription} ${!mobileType ? 'shrink' : ''}`}>
                  Service cost and transaction fees.
                </div>
              </Grid>
            </Grid>
            {/* 3rd icon */}
            <Grid
              item
              container
              direction='column'
              alignItems='center'
              className={`${classes.iconContainer} ${!mobileType ? 'spaceTop' : ''}`}
              spacing={2}
            >
              <Grid item>
                <div className={classes.icon}>
                  <img src={handImg} alt='' />
                </div>
              </Grid>
              <Grid item>
                <div className={`${classes.iconTitle} ${!mobileType ? 'spaceTop' : ''}`}>
                  Available Funds
                </div>
                <div className={`${classes.iconDescription} ${!mobileType ? 'shrink' : ''}`}>
                  Cleared earnings available for withdrawal. New earnings clear in 2-3 business
                  days.
                </div>
              </Grid>
            </Grid>
          </>
        ) : textType === 'instant' ? (
          <>
            {/* thunder icon */}
            <Grid
              item
              container
              justifyContent='center'
              className={`${classes.thunderContainer} ${!mobileType ? 'spaceTop' : ''}`}
            >
              <Grid item>
                <div>
                  <img src={thunderImg} alt='' />
                </div>
              </Grid>
            </Grid>
            {/* 1st text */}
            <Grid item container justifyContent='center' className={classes.earningsContainer}>
              <Grid item>
                <div className={classes.iText}>
                  How does
                  <br /> Instant Withdrawal work?
                </div>
              </Grid>
            </Grid>
            {/* 2nd text */}
            <Grid item container justifyContent='center' className={classes.thunderContainer}>
              <Grid item>
                <div className={`${classes.iIconDescription} ${!mobileType ? 'shrink' : ''}`}>
                  AsqMe offers instant transfer wherever available. Availability is dependent on
                  your bank or debit card that is connected.
                </div>
                <div className={classes.iIconSecondDescription}>
                  The amount available instantly can vary. The remaining funds not available
                  instantly typically arrive within 2-3 business days.
                </div>
              </Grid>
            </Grid>
          </>
        ) : null}
      </Grid>
    </Drawer>
  );
};

export default InfoDrawer;
