import { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import BaseHeader from 'components/Header/BaseHeader';
import { WhiteLogoWhiteContentIcon, SuccessIcon } from 'components/Icons';
import asqmeLogoImg from 'assets/images/asqme-logo-new.png';
import { commonBtnStyles } from '../../assets/styles/common';
import { queryCustomerTypes } from 'store/customer';
import { formatPrice } from 'utils/currency-utils';

const useStyles = makeStyles((theme) => ({
  proceedToCheckoutContainer: {
    height: '100%',
  },
  proceedToCheckoutInnerContainer: {
    height: '100%',
  },
  header: {
    backgroundColor: theme.blue,
    paddingBottom: 0,
  },
  headerLogosContainer: {
    backgroundColor: theme.blue,
    width: '100%',
    height: 70,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  logoPlaceholderIcon: {
    transform: 'translateY(50%)',
    borderRadius: 50,
    width: 70,
    height: 70,
    backgroundColor: theme.common.black,
    border: `2px solid ${theme.common.white}`,
    position: 'relative',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  descriptionSection: {
    paddingTop: theme.spacing(5.5),
  },
  descriptionIntro: {
    textAlign: 'center',
    fontFamily: 'Figtree-Bold',
    fontSize: '1.3rem',
  },
  descriptionContent: {
    textAlign: 'center',
    fontSize: '1.1rem',
  },
  priceSectionContainer: {
    flexGrow: 1,
    // paddingTop: theme.spacing(2.5),
  },
  amountTransferedContainer: { position: 'relative' },
  absoluteCheckIcon: {
    position: 'absolute',
    left: -10,
    top: -10,
    width: 35,
    height: 35,
  },
  amountTransfered: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.blue,
    width: 74,
    height: 74,
    color: theme.common.white,
    fontSize: '1.4rem',
    fontFamily: 'Figtree-SemiBold',
    borderRadius: '50%',
  },
  successMessage: {
    textAlign: 'center',
    fontSize: '1rem',
    color: theme.grey[600],
  },
  footer: {
    paddingBottom: theme.spacing(2),
  },
  loginPromptText: {
    textAlign: 'center',
    fontSize: '0.75rem',
  },
  footerImageLogo: {
    width: 25,
    height: 35,
  },
  footertextLogo: {
    width: 80,
    height: 25,
  },
  saveBtn: {
    ...commonBtnStyles,
    backgroundColor: theme.blue,
    '&.Mui-disabled': {
      backgroundColor: theme.grey[500],
      color: theme.common.white,
    },
    '& .MuiButton-label': {
      fontFamily: 'Figtree-Bold',
      color: theme.common.white,
      fontSize: '1.1rem',
    },
    '&:hover': {
      backgroundColor: theme.blue,
      opacity: 0.85,
      '&.Mui-disabled': {
        backgroundColor: theme.blue,
        opacity: 0.7,
      },
    },
  },
  asqmeLogo: {
    width: '180px',
  },
}));

const AnswerAcceptedSuccess = ({ answerData, successState }) => {
  const classes = useStyles();

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  useEffect(
    () => () => {
      queryClient.removeQueries({ queryKey: [queryCustomerTypes.answerStepData] });
      queryClient.removeQueries({ queryKey: [queryCustomerTypes.wholeAnswerSet] });
    },
    [],
  );

  const {
    creator: { banner_image, logo_image } = {},
    creator_asqMe_tag,
    subscriptionTipFlow, // subscription tip flow
  } = answerData || {};

  return (
    <Grid container className={classes.proceedToCheckoutContainer}>
      <Grid item sm xs />
      <Grid item sm={6} xs={12}>
        <Grid
          container
          direction='column'
          justifyContent='space-between'
          className={classes.proceedToCheckoutInnerContainer}
        >
          {/* header */}
          <BaseHeader
            className={classes.header}
            leftIcon={<WhiteLogoWhiteContentIcon />}
            hideRightIcon
          />
          {/* header logos */}
          <Grid
            item
            container
            className={classes.headerLogosContainer}
            style={{ backgroundImage: `url(${banner_image})` }}
          >
            <Grid item xs={3} />
            <Grid item xs={6}>
              <Grid container justifyContent='center'>
                <Grid item>
                  {/* profile logo */}
                  <div
                    className={classes.logoPlaceholderIcon}
                    style={{
                      backgroundImage: `url(${logo_image})`,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs />
          </Grid>
          {/* thank u section */}
          <Grid item container className={classes.descriptionSection}>
            <Grid item xs />
            <Grid item xs={10}>
              <Grid container direction='column'>
                <Grid item>
                  <div className={classes.descriptionIntro}>Thanks!</div>
                </Grid>
                <Grid item>
                  <div className={classes.descriptionContent}>
                    {subscriptionTipFlow
                      ? `I’m glad you enjoyed my content.`
                      : `I’m glad you got the answer you wanted.`}
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs />
          </Grid>
          {/* price section */}
          <Grid item container className={classes.priceSectionContainer}>
            {successState?.amount && (
              <Grid
                item
                container
                direction='column'
                justifyContent='center'
                alignItems='center'
                spacing={1}
              >
                <Grid item className={classes.amountTransferedContainer}>
                  <SuccessIcon className={classes.absoluteCheckIcon} />
                  <div className={classes.amountTransfered}>
                    {formatPrice(successState.amount, {
                      currency: successState.currencyCode,
                      appendCurrency: false,
                      hideDecimals: true,
                    })}
                  </div>
                </Grid>
                <Grid item>
                  <div className={classes.successMessage}>
                    {successState?.isTip
                      ? 'Tip sent. Thanks for your support!'
                      : 'Your card has been charged.'}
                  </div>
                </Grid>
              </Grid>
            )}
          </Grid>

          {/* asmqe logo */}
          <Grid item container className={classes.footer}>
            <Grid item xs />
            <Grid item xs={10}>
              <Grid container direction='column' spacing={2}>
                {!subscriptionTipFlow && (
                  <Grid item container>
                    <Grid item xs />
                    <Grid item xs={10}>
                      <Button
                        type='submit'
                        className={classes.saveBtn}
                        fullWidth
                        size='large'
                        onClick={() => {
                          navigate(`/${creator_asqMe_tag}`, { replace: true });
                        }}
                      >
                        Ask Me Another Question
                      </Button>
                    </Grid>
                    <Grid item xs />
                  </Grid>
                )}

                <Grid item container direction='column' alignItems='center' spacing={1}>
                  <Grid item container>
                    <Grid item xs={12}>
                      <hr />
                    </Grid>
                  </Grid>
                  <Grid item container justifyContent='center' alignItems='center'>
                    <Grid item>
                      <img src={asqmeLogoImg} className={classes.asqmeLogo} alt='' />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs />
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm xs />
    </Grid>
  );
};

export default AnswerAcceptedSuccess;
