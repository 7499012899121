import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { PaperPlaneIconAlt } from 'components/Icons';
import { styles } from "./get-summary.styles";

const useStyles = makeStyles(styles);

const GetSummary = (props) => {
  const { onGetSummary } = props;
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.icon}>
        <span>🎉</span>
      </div>
      <h2 className={classes.headerText}>Thanks for supporting my work!</h2>
      <Button fullWidth={true} className={classes.summaryButton} onClick={onGetSummary}>
        <PaperPlaneIconAlt fillColor="#495EFF" />
        Send Thanks
      </Button>
    </div>
  )
}

export default GetSummary;