const StarFilled = ({
  ...rest
}) => (
  <svg
    {...rest}
    width={34}
    height={33}
    viewBox="2 1 34 33" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_6311_945)">
      <path d="M29.5033 32.9945L19 26.6969L8.49675 33L11.2415 21.0579L2 13.0117L14.2021 11.9382L19 0.660156L23.7979 11.9382L36 13.0117L26.7585 21.0579L29.5033 32.9945Z" fill="#FFD058"/>
      <path d="M29.5033 32.9945L19 26.6969L8.49675 33L11.2415 21.0579L2 13.0117L14.2021 11.9382L19 0.660156L23.7979 11.9382L36 13.0117L26.7585 21.0579L29.5033 32.9945Z" fill="url(#paint0_linear_6311_945)" fillOpacity="0.2"/>
      <path d="M26.2712 21.17L28.7527 31.9614L19.2571 26.2681L18.9999 26.1139L18.7427 26.2682L9.24734 31.9664L11.7288 21.1699L11.796 20.8777L11.5699 20.6808L3.21515 13.4067L14.246 12.4362L14.5448 12.4099L14.6622 12.1339L19 1.93741L23.3378 12.1339L23.4552 12.4099L23.754 12.4362L34.7849 13.4067L26.4301 20.6808L26.204 20.8778L26.2712 21.17Z" stroke="url(#paint1_linear_6311_945)"/>
    </g>
    <defs>
      <filter id="filter0_d_6311_945" x="0" y="0.660156" width="38" height="36.3398" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="2"/>
        <feGaussianBlur stdDeviation="1"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6311_945"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6311_945" result="shape"/>
      </filter>
      <linearGradient id="paint0_linear_6311_945" x1="19" y1="0.660156" x2="19" y2="33" gradientUnits="userSpaceOnUse">
      <stop offset="0.52" stopOpacity="0"/>
      <stop offset="1" stopOpacity="0.3"/>
      </linearGradient>
      <linearGradient id="paint1_linear_6311_945" x1="19" y1="0.660156" x2="19" y2="33" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FFEFC4"/>
        <stop offset="1" stopColor="#FFD058" stopOpacity="0"/>
      </linearGradient>
    </defs>
  </svg>
);

export default StarFilled;