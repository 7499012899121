import { useCallback } from 'react';
import { useMutation } from '@tanstack/react-query';
import { trackDontCharge, trackTipJar, trackTooltip } from 'store/creator';
import { convertCurrency, getCurrencySymbol } from 'utils/currency-utils';

const trackTypes = {
  free: 'free',
  tipjar: 'tipjar',
  tooltip: 'tooltip',
};

const formatAmount = (amount, isCustomAmount, toCurrency, exchangeRates) => {
  if (!amount) return '';

  const value = Number(amount);
  const convertedValue = isCustomAmount
    ? value
    : Math.ceil(convertCurrency(value, 'USD', toCurrency, exchangeRates));
  const currencySymbol = getCurrencySymbol(toCurrency);
  const convertedLabel =
    currencySymbol.position === 'start'
      ? `${currencySymbol.symbol}${convertedValue}`
      : `${convertedValue} ${currencySymbol.symbol}`;

  return convertedLabel;
};

const useTrackActions = (user, exchangeRates) => {
  const { mutate: onTrackDontCharge } = useMutation({
    mutationFn: trackDontCharge,
  });

  const { mutate: onTrackTipJar } = useMutation({
    mutationFn: trackTipJar,
  });

  const { mutate: onTrackTooltip } = useMutation({
    mutationFn: trackTooltip,
  });

  const onTrackAction = useCallback(
    (action) => {
      const handler =
        action === trackTypes.free
          ? onTrackDontCharge
          : action === trackTypes.tipjar
          ? onTrackTipJar
          : onTrackTooltip;

      handler({
        asqme_tag: user.asqMe_tag || '',
        display_name: user.display_name || '',
        email: user.email,
        first_name: user.gmail_user_data?.given_name || '',
        last_name: user.gmail_user_data?.family_name || '',
        name: user.gmail_user_data?.name || '',
        creator_question_price: formatAmount(
          user.price_per_question_custom || user.price_per_question || null,
          !!user.price_per_question_custom,
          user.currency_code,
          exchangeRates,
        ),
        creator_currency_code: user.currency_code,
      });
    },
    [user],
  );

  return onTrackAction;
};

export { trackTypes, useTrackActions };
