import { Slide } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { CloseIcon, WarningIcon } from 'components/Icons';
import AsqButton from 'routes/Subscribe/asq-button';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  confirmContainer: {
    height: 180,
    backgroundColor: theme.common.black,
    position: 'fixed',
    left: '33.333%',
    right: '16.666%',
    bottom: 0,
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    '&.offset': {
      left: '33.333%',
      right: '16.666%',
    },
    [theme.breakpoints.down('xs')]: {
      left: "0 !important",
      right: "0 !important",
    },
  },
  confirmHeader: {
    textAlign: 'center',
    color: theme.common.white,
    fontFamily: 'Figtree-Bold',
    fontSize: '1.1rem',
    paddingBottom: 10,
  },
  closeIcon: {
    cursor: 'pointer',
    position: 'absolute',
    right: 13,
    top: 10,
    width: 16,
    height: 16,
  },
  errorInnerContainer: {
    height: 'inherit',
  },
  errorImg: {},
}));

const ConfirmSubscriptionSlideout = ({ showSlideout, onConfirm, onClose }) => {
  const classes = useStyles();

  return (
    <Slide direction='up' in={showSlideout} mountOnEnter unmountOnExit>
      <div className={classes.confirmContainer}>
        <CloseIcon className={classes.closeIcon} onClick={onClose} />
        <Grid
          container
          direction='column'
          alignItems='center'
          justifyContent='center'
          className={classes.errorInnerContainer}
        >
          <Grid item>
            <WarningIcon className={classes.errorImg} />
          </Grid>
          <Grid item>
            <div className={classes.confirmHeader}>Your payment method on file will be charged.</div>
          </Grid>
          <Grid item>
            <AsqButton
              fullWidth={true}
              onClick={onConfirm}
            >
              Subscribe
            </AsqButton>
          </Grid>
        </Grid>
      </div>
    </Slide>
  );
};

export default ConfirmSubscriptionSlideout;