import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  outerContainer: {
    display: 'flex',
    position: 'relative',
    margin: '10px 0',
    '& svg': {
      position: 'absolute',
      alignSelf: 'center',
    }
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    borderRadius: 10,
    padding: '20px 0',
    paddingLeft: 50,
    width: '100%',
    marginLeft: 20,
    '& span:nth-child(1)': {
      fontFamily: 'Figtree-Bold',
      fontSize: 16,
      lineHeight: '16.8px',
      color: '#161616',
      marginBottom: 5,
    },
    '& span:nth-child(2)': {
      fontFamily: 'Figtree-Regular',
      fontSize: 12,
      lineHeight: '16.34px',
      color: '#666666',
    },
  },
}));

const SuccessInfoCard = (props) => {
  const { Icon, title, subtitle } = props;

  const classes = useStyles();

  return (
    <div className={classes.outerContainer}>
      <Icon />
      <div className={classes.innerContainer}>
        <span>{title}</span>
        <span>{subtitle}</span>
      </div>
    </div>
  )
}

export default SuccessInfoCard;