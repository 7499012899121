export const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  icon: {
    width: 106,
    height: 106,
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '20px 0',
    '& span': {
      fontSize: 65,
    },
  },
  headerText: {
    fontFamily: 'Figtree-Bold',
    fontSize: 20,
    lineHeight: '24px',
    color: '#FFFFFF',
    marginBottom: 50,
    textAlign: 'center',
  },
  summaryButton: {
    backgroundColor: '#FFFFFF',
    height: 44,
    '&:hover': {
      backgroundColor: '#FFFFFF',
    },
    '& .MuiButton-label': {
      fontFamily: 'Figtree-Bold',
      fontSize: 16,
      lineHeight: '19.2px',
      color: theme.blue,
      textTransform: 'none',
      '& svg': {
        width: 18,
        height: 18,
        marginRight: 10,
      }
    },
  }
});