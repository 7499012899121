import { number, object, string } from 'prop-types';
import common from '@material-ui/core/colors/common';

const DoublePaperIcon = ({
  className = '',
  style = {},
  width = 36,
  height = 36,
  fillColor = '#495EFF',
  strokeColor = common.white,
  ...rest
}) => (
  <svg
    {...rest}
    style={style}
    className={className}
    height={height}
    width={width}
    viewBox='0 0 36 36'
  >
    <g filter='url(#filter0_d_6201_15670)'>
      <rect x='2' y='1' width='32' height='32' rx='16' fill='white' />
      <g clipPath='url(#clip0_6201_15670)'>
        <path
          d='M20.3329 10.8447H11.7176C10.698 10.8447 9.8714 11.6713 9.8714 12.6909V24.9986C9.8714 26.0182 10.698 26.8447 11.7176 26.8447H20.3329C21.3525 26.8447 22.1791 26.0182 22.1791 24.9986V12.6909C22.1791 11.6713 21.3525 10.8447 20.3329 10.8447Z'
          fill={fillColor}
        />
        <path
          d='M25.8714 10.2295V22.5371C25.8683 23.5556 25.0437 24.3802 24.0253 24.3833H23.4099V12.691C23.4099 10.9918 22.0322 9.61407 20.333 9.61407H13.6745C13.9345 8.8783 14.6295 8.38561 15.4099 8.3833H24.0253C25.0437 8.38638 25.8683 9.21099 25.8714 10.2295Z'
          fill={fillColor}
        />
      </g>
    </g>
    <defs>
      <filter
        id='filter0_d_6201_15670'
        x='0'
        y='0'
        width='36'
        height='36'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset dy='1' />
        <feGaussianBlur stdDeviation='1' />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
        <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_6201_15670' />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect1_dropShadow_6201_15670'
          result='shape'
        />
      </filter>
      <clipPath id='clip0_6201_15670'>
        <rect width='16' height='18.4615' fill='white' transform='translate(9.8714 8.3833)' />
      </clipPath>
    </defs>
  </svg>
);

DoublePaperIcon.propTypes = {
  style: object,
  className: string,
  height: number,
  width: number,
  fillColor: string,
  strokeColor: string,
};

export default DoublePaperIcon;
