const WarningIcon = () => (
  <svg width='36' height='36' viewBox='0 0 40 36' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_2620_3932)'>
      <path
        d='M39.2005 27.076L25.1868 2.98525C22.8978 -0.994594 17.1096 -0.994594 14.8288 2.98525L0.806901 27.076C-1.48206 31.0558 1.36679 36 5.98588 36H33.9721C38.5912 36 41.4895 31.0068 39.2005 27.076ZM19.9996 30.6554C18.781 30.6554 17.7683 29.6502 17.7683 28.4407C17.7683 27.2312 18.781 26.2261 19.9996 26.2261C21.2182 26.2261 22.2309 27.2312 22.1815 28.4979C22.2391 29.6502 21.1688 30.6554 19.9996 30.6554ZM22.0333 16.3377C21.9345 18.0539 21.8275 19.7619 21.7287 21.478C21.6793 22.0337 21.6793 22.5404 21.6793 23.0879C21.6299 23.9951 20.9135 24.6979 19.9996 24.6979C19.0857 24.6979 18.3776 24.0441 18.3199 23.137C18.1717 20.4647 18.0153 17.8414 17.8671 15.1691C17.8177 14.4663 17.7683 13.7553 17.7106 13.0525C17.7106 11.8921 18.3693 10.9359 19.4397 10.6336C20.5101 10.3802 21.5722 10.8869 22.0333 11.8921C22.1897 12.2435 22.2391 12.5949 22.2391 13.0035C22.1897 14.1231 22.0827 15.2345 22.0333 16.3377Z'
        fill='#F2A42D'
      />
    </g>
    <defs>
      <clipPath id='clip0_2620_3932'>
        <rect width='40' height='36' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default WarningIcon;
