import { makeStyles, useTheme } from '@material-ui/core';
import PremiumFeaturesDrawer from 'components/PremiumFeaturesDrawer';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import logoImg from 'assets/images/logo-black.svg';
import logoQ from 'assets/images/logo-q.svg';
import iconInfo from 'assets/images/icon-info.svg';
import useAppData from 'hooks/context';
import { useMemo, useState, useEffect } from 'react';
import AsqButton from 'routes/Subscribe/asq-button';
import { updateMessenger } from 'utils/intercomConfig';
import CancelSubscriptionDialog from './CancelSubscriptionDialog';
import { formatDate, formatMonth } from 'utils/date-utils';
import ConfirmSubscriptionSlideout from 'components/ConfirmSubscriptionSlideout';
import { fetchApi, methods } from 'utils/apiConfig';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  subBox: {
    border: '1px solid #ffd058',
    borderRadius: 4,
    borderLeftWidth: 5,
    padding: 16,
    position: 'relative',
  },
  title: {
    '& span': { color: theme.premiumYellow },
  },
  subTitle: {
    color: theme.blue,
    fontWeight: 'bold',
  },
  subText: {
    color: '#666666',
  },
  subBtn: {
    marginTop: 10,
    '@media (max-width: 400px)': {
      '& .MuiButton-label': {
        fontSize: '0.8rem',
      },
    },
  },
  btnInner: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  btnInnerRight: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'initial',
    fontFamily: 'FigTree-Regular',
  },
  priceBubble: {
    background: '#FFF',
    borderRadius: '50%',
    minWidth: 40,
    color: '#000',
    padding: 5,
    marginRight: 3,
    fontWeight: 'bold',
    display: "flex",
    justifyContent: "center"
  },
  cancelLink: {
    position: 'absolute',
    bottom: 10,
    right: 10,
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    color: theme.blue,
    textDecoration: 'underline',
  },
  planTitle: {
    fontSize: 20,
    display: 'flex',
    alignItems: 'center',
    '& img': {
      width: 20,
      marginRight: 5,
    },
  },
  infoIcon: {
    position: 'absolute',
    top: 10,
    right: 10,
    cursor: 'pointer',
  },
  discountLine: {
    color: theme.blue,
  },
}));

function Unsubscribed() {
  const navigate = useNavigate();

  const { subscription, user } = useAppData();
  const classes = useStyles();
  const [showConfirmSlideout, setShowConfirmSlideout] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // hide intercom launcher when a drawer is open
  useEffect(() => {
    if (showConfirmSlideout) updateMessenger({ hide_default_launcher: true });
    else updateMessenger({ hide_default_launcher: false });
  }, [showConfirmSlideout]);

  async function subscribe() {
    setShowConfirmSlideout(false);
    setIsLoading(true);
    try {
      const result = await fetchApi('/creator/subscribe', methods.POST, {
        data: { chargeAndStartSubscription: true },
      });

      navigate(`/subscribe/confirm?payment_intent_client_secret=${result.paymentSecret}`);
    } catch {
      navigate('/subscribe?paymentDeclined=true');
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <InfoIcon />
      <img src={logoImg} alt='AsqMe logo' />
      <div className={classes.title}>
        <span>Pro</span> Plan
      </div>

      <div className={classes.subText}>
        Turn questions into opportunities with TipJar, No-Charge Questions, zero fees and more
      </div>

      <AsqButton
        fullWidth={true}
        extraClassName={classes.subBtn}
        isLoading={isLoading}
        to={user?.stripe_has_payment_method ? null : '/subscribe'}
        onClick={user?.stripe_has_payment_method ? () => setShowConfirmSlideout(true) : null}
      >
        <div className={classes.btnInner}>
          <div>
            {user?.was_subscriber
              ? 'Subscribe'
              : `Try free for ${subscription?.defaultTrialLengthDays} days`}
          </div>
          <div className={classes.btnInnerRight}>
            <div className={classes.priceBubble}>${subscription?.price}</div>/mo
          </div>
        </div>
      </AsqButton>
      <ConfirmSubscriptionSlideout
        showSlideout={showConfirmSlideout}
        onConfirm={subscribe}
        onClose={() => setShowConfirmSlideout(false)}
      />
    </>
  );
}

function CancelLink() {
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles();

  return (
    <>
      <CancelSubscriptionDialog isOpen={isOpen} setIsOpen={setIsOpen} />
      <button className={classes.cancelLink} onClick={() => setIsOpen(true)}>
        Cancel
      </button>
    </>
  );
}

function ResubscribeLink() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();

  async function resubscribe() {
    setIsLoading(true);
    const result = await fetchApi('/creator/resubscribe', methods.POST);
    navigate('/subscribe/confirm?resubscribed=true');
    setIsLoading(false);
  }

  return (
    <>
      <button className={classes.cancelLink} onClick={resubscribe} disabled={isLoading}>
        Resubscribe
      </button>
    </>
  );
}

function InfoIcon() {
  const classes = useStyles();
  const { breakpoints } = useTheme();
  const mobileView = useMediaQuery(breakpoints.down('xs'));

  const [premiumFeaturesDrawer, setPremiumFeaturesDrawer] = useState(false);

  // hide intercom launcher when a drawer is open
  useEffect(() => {
    if (premiumFeaturesDrawer) updateMessenger({ hide_default_launcher: true });
    else updateMessenger({ hide_default_launcher: false });
  }, [premiumFeaturesDrawer]);

  return (
    <>
      <img
        src={iconInfo}
        alt='info'
        onClick={() => setPremiumFeaturesDrawer(true)}
        className={classes.infoIcon}
      />
      <PremiumFeaturesDrawer
        open={premiumFeaturesDrawer}
        mobileType={mobileView}
        onClose={() => setPremiumFeaturesDrawer(false)}
      />
    </>
  );
}

function Subscribed() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { user } = useAppData();
  const info = user.stripe_subscription_info;

  const status = user.stripe_subscription_status;
  const planName = (
    <div className={classes.title}>
      <span>Pro</span> Plan
    </div>
  );

  const canCancel = ['active', 'past_due'].includes(status) || (status === 'trialing' && user.stripe_has_payment_method)
  const canResubscribe = status === 'pending_cancellation' && user.stripe_has_payment_method;
  const canSubscribe =
    !user.stripe_has_payment_method && (status == 'trialing' || status == 'pending_cancellation');

  function endPrefix() {
    if (canSubscribe) {
      return "Free trial ends on"
    } else if (status === 'pending_cancellation') {
      return "Ends on"
    } else {
      return "Renews on"
    }
  }

  let discountLine = null;

  if (info?.discount && info.discount?.monthCount) {
    const monthCount = info.discount.monthCount;
    const monthLabel = monthCount === 1 ? 'month' : 'months';
    discountLine = `Your subscription includes ${monthCount} free ${monthLabel}`;
  }

  return (
    <>
      <InfoIcon />
      <div className={classes.planTitle}>
        <img src={logoQ} alt='logo' /> {planName}
      </div>
      <div className={classes.subTitle}>
        {endPrefix()} {formatDate(info?.nextChargeDate)}
      </div>
      {discountLine && <div className={classes.discountLine}>{discountLine}</div>}
      <div className={classes.subText}>${info?.priceAmount / 100} Monthly</div>
      {canCancel && <CancelLink />}
      {canResubscribe && <ResubscribeLink />}
      {canSubscribe && (
        <button className={classes.cancelLink} onClick={() => navigate('/subscribe')}>
          Subscribe
        </button>
      )}
    </>
  );
}

export default function SubscriptionBox() {
  const { user } = useAppData();
  const classes = useStyles();

  const component = useMemo(
    () =>
      user.is_subscribed ? (
        <Subscribed />
      ) : ['canceled', 'unsubscribed'].includes(user.stripe_subscription_status) ? (
        <Unsubscribed />
      ) : (
        <p>Unhandled state</p>
      ),
    [user],
  );

  return <div className={classes.subBox}>{component}</div>;
}
