import { commonBtnStyles } from 'assets/styles/common';

const styles = (theme) => ({
  balanceContainer: {
    height: '100%',
  },
  innerContainer: {
    height: '100%',
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: theme.common.white },
  header: {
    paddingTop: theme.spacing(1),
    fontSize: '2.1rem',
    textAlign: 'center',
    '&.spaceY': {
      padding: `${theme.spacing(3)}px 0px`,
    },
  },
  hr: {
    border: `1px solid ${theme.fadedBlue}`,
  },
  priceSectionContainer: {
    flexGrow: 1,
    paddingTop: theme.spacing(2.5),
  },
  amountTransferedContainer: { position: 'relative' },
  absoluteCheckIcon: {
    position: 'absolute',
    left: -10,
    top: -10,
    width: 35,
    height: 35,
  },
  amountTransfered: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.blue,
    minWidth: 74,
    aspectRatio: ' 1 / 1',
    color: theme.common.white,
    fontSize: '1.4rem',
    fontFamily: 'Figtree-SemiBold',
    borderRadius: '50%',
    padding: theme.spacing(1),
  },
  successMessage: {
    textAlign: 'center',
    fontFamily: 'Figtree-Bold',
    fontSize: '1.1rem',
  },
  successHelperText: {
    textAlign: 'center',
    fontSize: '0.78rem',
    color: theme.grey[500],
  },
  priceSectionInnerContainer: {
    flexGrow: 1,
  },
  priceIntroText: {
    fontSize: '1.1rem',
  },
  infoIcon: {
    width: 17,
    height: 17,
    marginLeft: theme.spacing(1),
    cursor: 'pointer',
  },
  priceContainer: {
    padding: `${theme.spacing(1.3)}px ${theme.spacing(12)}px`,
    backgroundColor: theme.blue,
    color: theme.common.white,
    borderRadius: 50,
    fontFamily: 'Figtree-Bold',
    fontSize: '1.8rem',
    position: 'relative',
  },
  infoAbsoluteIcon: {
    cursor: 'pointer',
    position: 'absolute',
    right: -20,
    bottom: 0,
    width: 18,
    height: 18,
  },
  earningsContainer: {
    marginTop: theme.spacing(3),
  },
  row: {
    fontSize: '1.1rem',
    '&.b-dark': {
      backgroundColor: theme.grey[200],
    },
    '&.t-bold div': {
      fontFamily: 'Figtree-SemiBold',
    },
    '&.t-faded div': {
      color: theme.grey[600],
    },
    '&.t-blue div': {
      fontFamily: 'Figtree-SemiBold',
      color: theme.blue,
    },
  },
  fourthContainer: { display: 'flex', alignItems: 'center' },
  bottomInfoContainer: {
    backgroundColor: theme.grey[200],
    padding: `${theme.spacing(1)}px 0`,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
  thunderImg: {
    width: 21,
    height: 21,
  },
  stripeImg: {
    width: 75,
    height: 32,
  },
  instantAvailableText: {
    marginLeft: theme.spacing(1),
    padding: `${theme.spacing(0.5)}px 0`,
    color: theme.grey[600],
    '& span': {
      color: theme.blue,
      fontFamily: 'Figtree-Bold',
    },
  },
  notEnoughFunds: {
    padding: `${theme.spacing(0.5)}px 0`,
    color: theme.grey[600],
    '& span': {
      color: theme.blue,
      fontFamily: 'Figtree-Bold',
    },
  },
  href: {
    marginLeft: theme.spacing(1),
    color: theme.blue,
    textDecoration: 'underline',
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      color: theme.blue,
      textDecoration: 'underline',
      opacity: 0.8,
    },
  },
  btnContainer: {
    padding: `${theme.spacing(2)}px 0`,
  },
  saveBtn: {
    ...commonBtnStyles,
    backgroundColor: theme.blue,
    '&.Mui-disabled': {
      backgroundColor: theme.grey[500],
      color: theme.common.white,
    },
    '& .MuiButton-label': {
      fontFamily: 'Figtree-Bold',
      color: theme.common.white,
      fontSize: '1.1rem',
    },
    '&:hover': {
      backgroundColor: theme.blue,
      opacity: 0.85,
      '&.Mui-disabled': {
        backgroundColor: theme.blue,
        opacity: 0.7,
      },
    },
  },
  redirectText: {
    fontSize: '1rem',
    fontFamily: 'Figtree-Bold',
    textAlign: 'center',
  },
});

export default styles;
