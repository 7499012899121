import { makeStyles } from '@material-ui/core/styles';
import { createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import useRouteSchema from 'hooks/routes';
import useAppData from 'hooks/context';
import routeSchema from 'config/routes';
import PremiumFabicon from 'components/PremiumFabicon';

const useStyles = makeStyles((theme) => ({
  appContainer: {
    height: 'inherit',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.common.white,
    color: theme.common.black,
  },
}));

const App = () => {
  const classes = useStyles();
  const { initialLoad } = useAppData();

  const routes = useRouteSchema(routeSchema);

  const router = createBrowserRouter(createRoutesFromElements(routes));

  return (
    <>
      <PremiumFabicon />
      <div className={classes.appContainer}>
        {initialLoad ? (
          <Backdrop open className={classes.backdrop}>
            <CircularProgress color='inherit' thickness={3} size={70} />
          </Backdrop>
        ) : (
          <RouterProvider router={router} />
        )}
      </div>
    </>
  );
};

export default App;
