import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CustomMessageDialog from './custom-message-dialog';
import { RadioGroup, Radio, } from '@material-ui/core';
import ThankBankButton from "./thank-bank-button";
import { EyeAltIcon, PaperPlaneIconAlt } from 'components/Icons';
import Button from '@material-ui/core/Button';
import { styles } from './thank-creator.styles';

const useStyles = makeStyles(styles);

const ThankCreator = (props) => {
  const { onThankYouSubmit, onChange, comment, isSubmitted, displayName, onModalClosedAfterSubmit } = props;

  const classes = useStyles();

  const [selectedOption, setSelectedOption] = useState('');
  const [customMessage, setCustomMessage] = useState('');
  const [openMessageDialog, setOpenMessageDialog] = useState(false);
  const [showReadonlyCustomMessage, setShowReadonlyCustomMessage] = useState(false);

  useEffect(() => {
    if (!comment) return;

    let option = options.indexOf(comment)

    if (option === -1) {
      option = 'custom';
    }

    setSelectedOption(`${option}`);

    if (option === 'custom') {
      setCustomMessage(comment);
    }
  }, [])

  const closeDialog = () => {
    if (!isSubmitted) {
      setSelectedOption('');
      setCustomMessage('');
    }

    setOpenMessageDialog(false);
  }

  const showReadonlyDialog = () => {
    setShowReadonlyCustomMessage(true);
    setOpenMessageDialog(true);
  }

  const options = [
    'Your work is amazing! I appreciate you.',
    'Thanks for your insights! Learned a lot.',
    'You\'re a true inspiration.',
    'Your expertise is invaluable!'
  ];

  const optionTitles = [
    'Your work is amazing!',
    'Thanks for your insights',
    'True Inspiration',
    'Invaluable Expertise'
  ]

  const handleOptionChange = (event) => {
    if (!isSubmitted) {
      if (event.target.value === 'custom') {
        setOpenMessageDialog(true);
      } else {
        onChange(options[event.target.value], optionTitles[selectedOption])
      }

      setSelectedOption(event.target.value);
    }
  };

  const handleMessageChange = (newCustomMessage) => {
    setCustomMessage(newCustomMessage);
  }

  const onSubmit = () => {
    if (selectedOption === 'custom') {
      setOpenMessageDialog(true);
    } else {
      onThankYouSubmit(true, optionTitles[selectedOption]);
    }
  }

  const onCustomSubmit = () => {
    onThankYouSubmit(false, 'Custom', customMessage);

    // Wait for send animation to finish, then close the modal
    setTimeout(() => {
      setOpenMessageDialog(false);

      // Wait for modal to close, then go to next card
      setTimeout(() => {
        onModalClosedAfterSubmit();
      }, 500);
    }, 500)
  }


  return (
    <div className={classes.container}>
      <h2 className={classes.headingText}>Gratitude is the secret sauce. Pour it on thick! 🍔❤️</h2>
        <RadioGroup 
          name='message' 
          value={selectedOption} 
          onChange={handleOptionChange}
          className={classes.radioGroup}
        >
          <div className={`${classes.messageOption} ${selectedOption === '0' ? classes.messageOptionBold : ""}`}>
            <CustomRadio value='0' className={classes.radioOffset} />
            <span>Your work is amazing! <br /> I appreciate you.</span>
          </div>
          <div className={`${classes.messageOption} ${selectedOption === '1' ? classes.messageOptionBold : ""}`}>
            <CustomRadio value='1' className={classes.radioOffset} />
            <span>Thanks for your insights! <br /> Learned a lot.</span>
          </div>
          <div className={`${classes.messageOption} ${selectedOption === '2' ? classes.messageOptionBold : ""}`}>
            <CustomRadio value='2' />
            <span>You're a true inspiration.</span>
          </div>
          <div className={`${classes.messageOption} ${selectedOption === '3' ? classes.messageOptionBold : ""}`}>
            <CustomRadio value='3' />
            <span>Your expertise is invaluable!</span>
          </div>
          <div className={`${classes.messageOption} ${selectedOption === 'custom' ? classes.messageOptionBold : ""}`}>
            <CustomRadio value='custom' />
            <div className={classes.messageOptionCustom}>
              <span>Custom</span>
              {(isSubmitted && selectedOption === 'custom') && <Button onClick={showReadonlyDialog}><EyeAltIcon /></Button>}
            </div>
          </div>
        </RadioGroup>
      <ThankBankButton 
        onClick={onSubmit}
        isSubmitted={isSubmitted}
        isDisabled={!selectedOption}
        Icon={PaperPlaneIconAlt}
      />
      <CustomMessageDialog 
        open={openMessageDialog}
        displayName={displayName}
        isSubmitted={isSubmitted}
        message={customMessage}
        onMessageChange={handleMessageChange}
        handleClose={closeDialog}
        handleSubmit={onCustomSubmit}
        readOnly={showReadonlyCustomMessage}
      />
    </div>
  )
}

const CustomRadio = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();

  return (
    <Radio
      {...rest}
      className={`${classes.radio} ${className}`}
      checkedIcon={<span className={classes.icon}><span className={classes.checkedIcon}></span></span>}
      icon={<span className={classes.icon} />}
    />
  )
}

export default ThankCreator;