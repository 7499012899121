import { StarAltIcon, StarFilledIcon } from 'components/Icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  starRating: {
    display: 'flex',
    justifyContent: 'start',
    gap: 10,
    marginBottom: 10,
    '& svg': {
      width: 17,
      height: 16,
    },
  },
}));

const StarRating = (props) => {
  const { rating } = props;

  const classes = useStyles();

  const numBlankStars = 5 - rating;

  return (
    <div className={classes.starRating}>
      {[...Array(rating)].map((_, i) => (
        <StarFilledIcon key={`star-${i}`} />
      ))}
      {[...Array(numBlankStars)].map((_, i) => (
        <StarAltIcon key={`blank-star-${i}`} />
      ))}
    </div>
  )
}

export default StarRating;