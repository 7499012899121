const CreditCard = ({
  ...rest
}) => (
  <svg
    {...rest}
    width="24" 
    height="24" 
    viewBox="0 0 24 24" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M21.5625 4.5H2.4375C1.6435 4.5 1 5.1435 1 5.9375V18.0625C1 18.8565 1.6435 19.5 2.4375 19.5H21.5625C22.3565 19.5 23 18.8565 23 18.0625V5.9375C23 5.1435 22.3565 4.5 21.5625 4.5Z" fill="url(#paint0_linear_6532_1528)"/>
    <path d="M8.5265 17.053C11.3026 17.053 13.553 14.8026 13.553 12.0265C13.553 9.25044 11.3026 7 8.5265 7C5.75044 7 3.5 9.25044 3.5 12.0265C3.5 14.8026 5.75044 17.053 8.5265 17.053Z" fill="#CF1928"/>
    <path d="M15.0265 17.053C17.8026 17.053 20.053 14.8026 20.053 12.0265C20.053 9.25044 17.8026 7 15.0265 7C12.2504 7 10 9.25044 10 12.0265C10 14.8026 12.2504 17.053 15.0265 17.053Z" fill="url(#paint1_linear_6532_1528)"/>
    <path d="M10 12.0265C10 13.5625 10.691 14.9355 11.7765 15.8575C12.8625 14.9355 13.553 13.5625 13.553 12.0265C13.553 10.4905 12.862 9.1175 11.7765 8.1955C10.691 9.1175 10 10.4905 10 12.0265Z" fill="#D97218"/>
    <defs>
      <linearGradient id="paint0_linear_6532_1528" x1="10.1875" y1="5.5305" x2="14.374" y2="20.473" gradientUnits="userSpaceOnUse">
        <stop stopColor="#00B3EE"/>
        <stop offset="1" stopColor="#0082D8"/>
      </linearGradient>
      <linearGradient id="paint1_linear_6532_1528" x1="10" y1="12.0265" x2="20.0535" y2="12.0265" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FEDE00"/>
        <stop offset="1" stopColor="#FFD000"/>
      </linearGradient>
    </defs>
  </svg>
);

export default CreditCard; 