import { transform } from 'framer-motion';

const inboxStyles = (theme) => ({
  inboxOuterContainer: {
    height: '100%',
    // overflow: 'hidden',
  },
  backdrop: { position: 'absolute', zIndex: theme.zIndex.drawer + 1, color: theme.common.white },
  inboxInnerContainer: {
    height: '100% !important',
    backgroundColor: theme.grey[50],
  },
  headerContainer: {
    height: 75,
    padding: theme.spacing(2),
    backgroundColor: theme.blue,
  },
  logoPlaceholder: {
    width: 38,
    height: 38,
    backgroundColor: theme.common.white,
    borderRadius: '50%',
    border: `1px solid ${theme.common.white}`,
    '& img': {
      width: '100%',
      height: '100%',
      borderRadius: '50%',
    },
  },
  headerText: {
    marginLeft: theme.spacing(1),
    color: theme.common.white,
    fontSize: '1.25rem',
    '& span': {
      fontFamily: 'Figtree-SemiBold',
    },
  },
  clipsElement: {
    padding: '0 30px',
  },
  tabsContainer: {
    [theme.breakpoints.down('xs')]: {
      height: 47,
      zIndex: 2,
    },
    backgroundColor: theme.grey[50],
  },
  tabsContainerPlaceholder: {
    height: 47,
  },
  asqmeUrl: {
    fontFamily: 'Figtree-Bold',
    fontSize: '0.9rem',
    '& span': {
      fontFamily: 'Figtree-Bold',
      fontSize: '0.9rem',
      color: theme.blue,
    },
    '&.disabled': {
      opacity: 0.5,
    },
  },
  chainIcon: {
    marginLeft: theme.spacing(1),
    width: theme.spacing(3.5),
    cursor: 'pointer',
    '&.disabled': {
      cursor: 'not-allowed',
    },
  },
  itemsPerPageTabs: {
    '&.ant-tabs-top>.ant-tabs-nav': {
      margin: 0,
    },
    '& .ant-tabs-tab': {
      padding: 0,
    },
  },
  tabs: {
    padding: `0 ${theme.spacing(2)}px`,
    '& .ant-tabs-ink-bar': {
      backgroundColor: theme.blue,
    },
    '&.ant-tabs>.ant-tabs-nav': {
      // padding: `0 ${theme.spacing(2)}px`,
      borderBottom: `1px solid ${theme.grey[400]}`,
      '& .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right:after': {
        opacity: 0,
      },
      '& .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left:before': {
        opacity: 0,
      },
    },
    '&.ant-tabs-top>.ant-tabs-nav': {
      margin: 0,
    },
    [theme.breakpoints.down('xs')]: {
      '& .ant-tabs-nav-operations': {
        display: 'none !important',
      },
      '&.ant-tabs>.ant-tabs-nav div.ant-tabs-nav-operations': {
        display: 'none !important',
      },
      '& .ant-tabs-tab': {
        margin: 0,
        marginRight: 16,
      },
    },
  },
  tabTitle: {
    fontSize: '0.8rem',
    fontFamily: 'Figtree-Bold',
    color: theme.common.black,
    textShadow: 'none',
    '& span': {
      color: theme.grey[500],
    },
  },
  listOuterContainer: {
    flexGrow: 1,
    padding: theme.spacing(2),
    position: 'relative',
    overflow: 'hidden',
    [theme.breakpoints.down('xs')]: {
      overflow: 'initial',
    },
  },
  listInnerContainer: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    overflow: 'auto',
    paddingRight: theme.spacing(3),
    '&::-webkit-scrollbar': {
      width: 7,
      height: 9,
      display: 'block',
    },
    '&::-webkit-scrollbar-track': {
      background: 'inherit',
    },
    '&::-webkit-scrollbar-thumb': {
      visibility: 'hidden',
    },
    '&:hover::-webkit-scrollbar-thumb': {
      background: theme.grey[500],
      borderRadius: 4,
      visibility: 'visible',
    },
    [theme.breakpoints.down('xs')]: {
      overflow: 'initial',
    },
  },
  listHeaderTextContainer: {
    maxHeight: 33,
  },
  listItemContainer: {
    backgroundColor: theme.common.white,
    cursor: 'pointer',
    position: 'relative',
    border: `1px solid ${theme.grey[400]}`,
    borderRadius: 5,
    padding: `${theme.spacing(2.5)}px ${theme.spacing(1)}px`,
    height: 76,
    marginTop: theme.spacing(1),
    '&.tablet': {
      height: 120,
      padding: theme.spacing(1),
    },
    '&.thickLeft': {
      borderLeft: `6px solid ${theme.grey[400]}`,
      '&.blue': {
        borderColor: theme.blue,
      },
      '&.red': {
        borderColor: theme.secondary.main,
      },
      '&.grey': {
        borderLeftColor: theme.grey[600],
      },
    },
    '&.spaceBottom': {
      marginBottom: theme.spacing(3),
    },
    '&.noBorder': {
      border: `1px solid ${theme.grey[300]}`,
      paddingLeft: theme.spacing(1.7),
    },
  },
  tooltip: {
    '&.ant-tooltip-placement-top': {
      paddingBottom: theme.spacing(1),
    },
    '& .ant-tooltip-arrow': {
      display: 'none',
    },
    '& .ant-tooltip-inner': {
      borderRadius: 20,
      backgroundColor: theme.common.black,
    },
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: theme.grey[300],
    opacity: 0.5,
  },
  statusIconContainer: {
    marginTop: theme.spacing(1),
  },
  customerNameContainer: {
    minWidth: 85,
    fontSize: '1rem',
    fontFamily: 'Figtree-Bold',
  },
  questionTitleContainer: {
    paddingLeft: theme.spacing(2),
    flexGrow: 1,
    lineHeight: 1.3,
    width: '50%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&.tablet': {
      padding: 'unset',
      paddingTop: theme.spacing(1),
    },
  },
  customerMessage: {
    wordBreak: 'break-word',
    whiteSpace: 'pre-wrap',
  },
  questionTitle: {
    width: '95%',
    // marginBottom: theme.spacing(1),
    textDecoration: 'underline',
    '& span': {
      opacity: 0.5,
    },
  },
  lastInfoSectionContainer: {
    // flexGrow: 0.,
  },
  price: {
    minWidth: 32,
    fontSize: '1rem',
    fontFamily: 'Figtree-Bold',
    color: theme.grey[600],
    '&.red': {
      color: theme.secondary.main,
    },
    '&.blue': {
      color: theme.blue,
    },
  },
  remainingTime: {
    textAlign: 'end',
    minWidth: 83,
    fontSize: '0.8rem',
    color: theme.grey[500],
    '&.red': {
      color: theme.secondary.main,
    },
    '&.tablet': {
      minWidth: 40,
      textAlign: 'center',
    },
  },
  verticalHr: {
    borderLeft: `1px solid ${theme.grey[400]}`,
    height: 15,
  },
  actionButtonContainer: {
    position: 'relative',
    minWidth: 50,
    '&.tablet': {
      minWidth: 40,
    },
  },
  hideIcon: {
    position: 'absolute',
    zIndex: 1,
    cursor: 'pointer',
    left: '10%',
    top: 0,
    '&.tablet': {
      minWidth: 40,
      left: '20%',
      top: -5,
    },
    // marginRight: theme.spacing(2),
    // marginTop: theme.spacing(0.5),
  },
  activeText: {
    fontSize: '1.1rem',
    fontFamily: 'Figtree-SemiBold',
    paddingBottom: theme.spacing(1),
  },
  archiveText: {
    fontSize: '1.1rem',
    fontFamily: 'Figtree-SemiBold',
    padding: `${theme.spacing(2)}px 0 ${theme.spacing(1)}px`,
    '&.shrinkSpace': {
      padding: `0 0 ${theme.spacing(1)}px 0`,
    },
  },
  footerContainer: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    minHeight: 60,
    border: `1px solid ${theme.grey[400]}`,
    boxShadow: '3px 3px 5px grey',
    zIndex: 2,
    backgroundColor: theme.common.white,
    [theme.breakpoints.down('xs')]: {
      position: 'fixed',
      bottom: 0,
    },
  },
  footerShowHiddenIcon: {
    marginTop: theme.spacing(0.5),
  },
  switchRoot: {
    width: 50,
    padding: `7px 0 7px 7px`,
    '&.notAllowed': {
      // cursor: 'not-allowed',
    },
  },
  switchTrack: {
    borderRadius: 12,
    '&.disabled': {
      backgroundColor: theme.grey[400],
    },
  },
  switchChecked: {
    '&.MuiSwitch-colorSecondary': {
      color: theme.common.white,
      '& + .MuiSwitch-track': { backgroundColor: theme.blue, opacity: 1 },
    },
  },
  switchBase: {
    '&.disabled': {
      color: theme.grey[400],
    },
    '&:hover': { backgroundColor: 'transparent' },
  },
  noQuestionContainer: {
    // height: '95%',
  },
  noQuestionFirstSection: {
    paddingTop: theme.spacing(5),
    '@media (max-height: 870px)': {
      paddingTop: theme.spacing(4),
    },
    '&.mobile': {
      paddingTop: theme.spacing(1),
    },
  },
  sectionSpacing: {
    paddingTop: theme.spacing(2),
  },
  inboxTagContainer: {
    marginTop: theme.spacing(3),
    width: '100%',
    maxWidth: 300,
    backgroundColor: theme.common.white,
    padding: `${theme.spacing(2.3)}px ${theme.spacing(2)}px`,
    borderRadius: 10,
    '@media (max-height: 870px)': {
      marginTop: theme.spacing(2),
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    },
    '&.mobile': {
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      maxWidth: 250,
      marginTop: theme.spacing(2),
    },
  },
  linkSectionContainer: {
    marginTop: theme.spacing(3),
    '&.mobile': {
      marginTop: theme.spacing(1),
    },
  },
  socialItemContainer: {
    height: '100%',
    backgroundColor: theme.common.white,
    padding: theme.spacing(2),
    borderRadius: 10,
  },
  socialMediaText: {
    flexGrow: 1,
    fontSize: '0.9rem',
    marginTop: theme.spacing(1),
    '&.mobile': {
      fontSize: '0.9rem',
    },
  },
  socialMediaLink: {
    display: 'inline-block',
    fontSize: '0.95rem',
    marginTop: theme.spacing(2),
    fontFamily: 'Figtree-SemiBold',
    border: `3px solid ${theme.blue}`,
    padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
    borderRadius: 7,
    color: theme.blue,
    textDecoration: 'none',
    transition: 'opacity 0.3s ease-in-out',
    '&:hover': {
      opacity: 0.8,
    },
    '&.mobile': {
      fontSize: '0.9rem',
    },
  },
  linkInnerContainer: {
    marginTop: theme.spacing(3),
    textAlign: 'center',
    '@media (max-height: 870px)': {
      marginTop: theme.spacing(1),
    },
    '&.mobile': {
      marginTop: theme.spacing(1),
    },
  },
  noQuestionTitle: {
    textAlign: 'center',
    fontFamily: 'Figtree-Bold',
    fontSize: '1.2rem',
    '&.mobile': {
      fontSize: '.9rem',
    },
  },
  moreQuestionsText: {
    textAlign: 'center',
    color: '#121f3c',
    opacity: 0.9,
    fontSize: '1.2rem',
    padding: `0 ${theme.spacing(2)}px`,
    '&.mobile': {
      fontSize: '.9rem',
    },
  },
  moreQuestionsStandaloneText: {
    marginTop: theme.spacing(2),
    fontFamily: 'Figtree-Bold',
    textAlign: 'center',
    color: '#121f3c',
    opacity: 0.9,
    fontSize: '1rem',
    padding: `0 ${theme.spacing(2)}px`,
    '&.mobile': {
      fontSize: '.9rem',
    },
  },
  helperText: {
    textAlign: 'center',
    fontSize: '1rem',
    '&.mobile': {
      fontSize: '.85rem',
    },
  },
  inboxLink: {
    color: theme.common.black,
    fontFamily: 'Figtree-SemiBold',
    '&:hover': {
      textDecoration: 'underline',
      fontFamily: 'Figtree-SemiBold',
    },
    '&.mobile': {
      fontSize: '0.8rem',
    },
  },
  moreTipsText: {
    padding: `${theme.spacing(3)}px 0 `,
    textAlign: 'center',
    fontSize: '0.9rem',
    fontFamily: 'Figtree-SemiBold',
    color: theme.grey[400],
    '&.mobile': {
      fontSize: '0.8rem',
    },
  },
  contentLink: {
    textDecoration: 'underline',
    fontSize: '0.9rem',
    color: theme.grey[500],
    '&:hover': {
      textDecoration: 'underline',
      color: theme.grey[700],
    },
    '&.mobile': {
      fontSize: '0.8rem',
    },
  },
});

const drawerStyles = (theme) => ({
  drawer: {
    overflow: 'hidden',
    marginLeft: 176, // Don't cover the sidebar on desktop
    '& .MuiBackdrop-root': {
      marginLeft: 176,
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      '& .MuiBackdrop-root': {
        marginLeft: 0,
      },
    },
  },
  paper: {
    // overflowX: 'hidden',
    overflow: 'hidden',
    height: '100%',
    // maxWidth: 450,
    width: '50%',
    '&.shrink': {
      width: '100%',
    },
  },
  backdrop: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: theme.zIndex.drawer + 1,
    color: theme.common.white,
  },
  menuIcon: {
    cursor: 'pointer',
  },
  drawerContainer: {
    position: 'relative',
    height: '100%',
    backgroundColor: theme.common.white,
    '@media (max-height: 500px)': {
      height: 'unset',
    },
  },
  headerContainer: {
    minHeight: 63,
    padding: `0 ${theme.spacing(2)}px`,
  },
  customerQuestion: {
    width: '80%',
    color: theme.grey[700],
    wordBreak: 'break-word',
  },
  actionHeaderIcon: {
    marginTop: theme.spacing(0.5),
    cursor: 'pointer',
    '&.notAllowed': {
      cursor: 'not-allowed',
    },
  },
  translationContainer: {
    padding: `${theme.spacing(1.5)}px  ${theme.spacing(1)}px ${theme.spacing(1)}px`,
    backgroundColor: theme.grey[700],
  },
  tranlationIcon: { width: 20, height: 20, marginLeft: theme.spacing(1) },
  tranlationText: {
    marginLeft: theme.spacing(1),
    color: theme.grey[200],
  },
  contentOuterContainer: {
    backgroundColor: theme.grey[200],
    flexGrow: 1,
    position: 'relative',
    overflow: 'hidden',
    padding: theme.spacing(2),
    boxShadow: `inset 0px 11px 8px -10px grey`,
  },
  contentInnerContainer: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    overflow: 'auto',
    paddingRight: theme.spacing(4),
    '&::-webkit-scrollbar': {
      width: 7,
      height: 9,
      display: 'block',
    },
    '&::-webkit-scrollbar-track': {
      background: 'inherit',
    },
    '&::-webkit-scrollbar-thumb': {
      visibility: 'hidden',
    },
    '&:hover::-webkit-scrollbar-thumb': {
      background: theme.grey[500],
      borderRadius: 4,
      visibility: 'visible',
    },
  },
  questionContainer: {
    maxWidth: '75%',
    marginBottom: theme.spacing(3),
  },
  questionInnerContainer: {
    minWidth: 180,
  },
  customerName: {
    fontFamily: 'Figtree-SemiBold',
    color: theme.grey[750],
  },
  questionDate: {
    fontSize: '0.75rem',
    color: theme.grey[700],
  },
  questionTitleContainer: {
    backgroundColor: theme.common.white,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    borderRadius: 5,
  },
  customerMessage: {
    wordBreak: 'break-word',
    whiteSpace: 'pre-wrap',
  },
  customerAttachmentContainer: {
    marginTop: theme.spacing(1),
  },
  customerAttachment: {
    cursor: 'pointer',
    display: 'block',
    width: 44,
    height: 44,
    backgroundColor: theme.common.white,
    borderRadius: 10,
    border: `1px solid ${theme.grey[300]}`,
    '&.large': {
      width: 88,
      height: 88,
    },
    '& img': {
      width: '100%',
      height: '100%',
      borderRadius: 10,
    },
  },
  asqMeAssistantContainer: {
    width: 357,
  },
  asqMeAssistantHeader: {
    '& img': {
      position: 'relative',
      bottom: 2,
    },
    '& span': {
      fontSize: 14,
      lineHeight: '19.07px',
      color: '#666666',
    },
    '& span.text1': {
      fontFamily: 'Figtree-Bold',
    },
    '& span.text2': {
      fontFamily: 'Figtree-Regular',
    },
  },
  asqMeAssistantMessage: {
    padding: '4px 20px !important',
    '& p': {
      fontSize: 16,
      lineHeight: '21.79px',
    },
  },
  answerContainer: {
    marginBottom: theme.spacing(1),
  },
  logoPlaceholder: {
    width: 40,
    height: 40,
    backgroundColor: theme.common.white,
    borderRadius: '50%',
    border: `2px solid ${theme.common.white}`,
    marginTop: theme.spacing(3),
    marginRight: theme.spacing(1.8),
    '& img': {
      width: 38,
      height: 38,
      borderRadius: '50%',
    },
  },
  answerTitleContainer: {
    backgroundColor: theme.blue,
    color: theme.common.white,
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(
      2,
    )}px`,
    borderRadius: 5,
  },
  answerMessage: {
    wordBreak: 'break-word',
    whiteSpace: 'pre-wrap',
  },
  clipsAnswerContainer: {
    maxWidth: 310,
    borderRadius: 10,
    backgroundColor: '#495EFF',
    overflow: 'hidden',
  },
  clipsAnswerThumbnailContainer: {
    position: 'relative',
  },
  clipsAnswerThumbnail: {
    width: '100%',
  },
  clipsAnswerThumbnailTitle: {
    padding: '10px 20px',
    '& span': {
      fontFamilyt: 'Figtree-Regular',
      fontSize: 16,
      lineHeight: '21.79px',
      color: 'white',
      textDecoration: 'underline',
    },
  },
  textareaSectionOuterContainer: {
    backgroundColor: theme.grey[200],
  },
  textareaSectionInnerContainer: {
    padding: theme.spacing(2),
    backgroundColor: theme.common.white,
    '&.withBorder': {
      borderRadius: '7px 7px 0px 0px',
      boxShadow: '4px 15px 25px grey',
    },
    '&.withoutBorder': {
      boxShadow: 'none',
      border: '0',
    },
  },
  form: {
    width: '100%',
  },
  attachmentContainer: {
    padding: `${theme.spacing(1.5)}px ${theme.spacing(1.5)}px`,
  },
  attachmentInputContainer: {
    position: 'relative',
  },
  clipsVideoThumbnail: {
    position: 'relative',
    height: 88,
    borderRadius: 10,
    overflow: 'hidden',
    '& img': {
      maxHeight: '100%',
    },
  },
  clipsPlayButtonContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  clipsCloseButtonContainer: {
    position: 'absolute',
    top: 2,
    right: 2,
  },
  actionLoader: {
    position: 'absolute',
    zIndex: 1,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textareaSectionContainer: {
    position: 'relative',
    border: `2px solid ${theme.grey[600]}`,
    borderRadius: 10,
  },
  firstDraftIcon: {
    marginTop: theme.spacing(0.8),
    marginLeft: theme.spacing(0.5),
  },
  answerSentContainer: {
    padding: `${theme.spacing(4)}px 0`,
    backgroundColor: theme.grey[200],
  },
  answerSentText: {
    fontSize: '.9rem',
    color: theme.grey[500],
  },
  divider: {
    height: 1,
    background: theme.grey[400],
  },
  firstDraftAnswer: {
    // Extra space to avoid the close button. Repeated class to override MUI (specificity)
    '& .MuiInputBase-root.MuiInputBase-root': {
      paddingRight: 20,
    }
  },
  textField: {
    '&.spaceBottom': { paddingBottom: theme.spacing(2) },
    '&.MuiFormControl-fullWidth': { height: '100%' },

    '&.textArea .MuiFilledInput-input': {
      // height: '100% !important',
      // minHeight: '10px !important',
      // maxHeight: '150px !important',
      '&.minHeightEnabled': {
        // height: '10px !important',
      },
      paddingRight: theme.spacing(1.2),
      fontSize: '1rem',
      '&::-webkit-scrollbar': {
        width: 6,
        height: 8,
        display: 'block',
      },
      '&::-webkit-scrollbar-track': {
        background: 'inherit',
      },
      '&::-webkit-scrollbar-thumb': {
        visibility: 'hidden',
      },
      '&:hover::-webkit-scrollbar-thumb': {
        backgroundColor: theme.grey[500],
        borderRadius: 4,
        visibility: 'visible',
      },
    },
    '&.textArea.firstDraft .MuiFilledInput-input': {
      paddingRight: theme.spacing(1),
    },
    '&.textArea.firstDraft.initHeight .MuiFilledInput-input': {
      height: '100% !important',
    },
    '& .MuiFilledInput-multiline': {
      backgroundColor: 'transparent',
      // border: `2px solid ${theme.grey[600]}`,
      // borderRadius: 10,
      padding: `${theme.spacing(1.5)}px ${theme.spacing(1.5)}px ${theme.spacing(1.5)}px`,
      height: '100%',
      '&.Mui-disabled': {
        color: 'unset !important',
      },
    },

    '& .MuiFormHelperText-root.Mui-error': {
      color: theme.error.dark,
      fontFamily: 'Figtree-Bold',
      fontSize: theme.spacing(1.6),
    },
    '& .MuiFormHelperText-contained': {
      marginLeft: 0,
    },
  },
  textAreaActionContainer: {
    padding: `0 ${theme.spacing(1)}px`,
  },
  actionButton: {
    cursor: 'pointer',
    transition: 'opacity 0.3s ease-in-out',
    '&.disabled': {
      cursor: 'unset',
      opacity: 0.5,
    },
    '&.spaceTop': {
      marginTop: theme.spacing(1),
    },
    '&.spaceHalfTop': {
      marginTop: theme.spacing(0.5),
    },
    '&.absolute': {
      position: 'absolute',
      top: -6,
      right: -7,
      backgroundColor: theme.common.white,
      borderRadius: '50%',
    },
  },
  clearMsgBtn: {
    position: 'absolute',
    right: 10,
    transform: 'translateY(5px)',
  },
  unclicked: {
    opacity: 0.5,
  },
});

export { inboxStyles, drawerStyles };
