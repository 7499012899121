import { useContext, useEffect, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useAuth0 } from '@auth0/auth0-react';
import Grid from '@material-ui/core/Grid';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { useAlert } from 'react-alert';
import { useMutation } from '@tanstack/react-query';
import { AppDataContext } from 'Context';
import { commonBtnStyles } from 'assets/styles/common';
import { oktaAuth } from 'store/auth/okta';
import { isProdEnv } from 'config/constants';
import { authRouteUrlNames } from 'config/routes';
import logoImg from 'assets/images/logo.svg';
import titleImg from 'assets/images/title.svg';
import youtubeImg from 'assets/images/youtube.svg';
import facebookImg from 'assets/images/facebook.svg';
import tiktokImg from 'assets/images/tiktok.svg';
import twitterImg from 'assets/images/twitter.svg';
import { defaultAlertError } from 'utils/common/constants';

const useStyles = makeStyles((theme) => ({
  connectContainer: {
    height: 'inherit',
    overflow: 'hidden',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.common.white,
    color: theme.common.black,
  },
  mainView: {
    height: '100%',
  },
  topHr: {
    height: theme.spacing(1),
    backgroundColor: theme.blue,
  },
  body: {
    flexGrow: 0.7,
  },
  icon: {
    width: '100%',
  },
  bodyHr: {
    backgroundColor: theme.greyHorizontalRule,
  },
  bodyMainText: {
    textAlign: 'center',
    fontSize: '2rem',
    lineHeight: `${theme.spacing(5)}px`,
    '& span:first-child': {
      fontFamily: 'Figtree-Bold',
    },
    '& span:last-child': {
      color: theme.blue,
    },
  },
  bodyhelperText: {
    fontFamily: 'Figtree-SemiBold',
    textAlign: 'center',
    fontSize: '1.1rem',
    lineHeight: `${theme.spacing(3)}px`,
  },
  footer: {
    paddingBottom: theme.spacing(2),
  },
  googleBtn: {
    ...commonBtnStyles,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& div': {
      marginLeft: theme.spacing(1),
      fontSize: '1rem',
      color: theme.common.black,
      fontFamily: 'Figtree-SemiBold',
    },
  },
  signInText: {
    color: theme.blue,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const Connect = () => {
  const classes = useStyles();
  const alert = useAlert();
  const { onSetAuth, onLogout, redirectUrlAfterAuth } = useContext(AppDataContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const { loginWithRedirect, getIdTokenClaims, user: oktaUser, logout } = useAuth0();

  const { mutate: onOktaAuth } = useMutation({
    mutationFn: oktaAuth,
    onSuccess: ({ jwt, email_needs_verification }) => {
      const redirectUrl =
        searchParams.get('redirectTo') &&
        Object.values(authRouteUrlNames).includes(searchParams.get('redirectTo').split('?')[0])
          ? searchParams.get('redirectTo')
          : null;

      if (email_needs_verification) {
        sessionStorage.setItem('skip_authentication_process', 'true');
        logout({ logoutParams: { returnTo: `${window.location.origin}/auth/account/unverified` } });
      } else onSetAuth(jwt, { fromLogin: true, redirectUrl });
    },
    onError: (err) => {
      setSearchParams(undefined);
      alert.error(null, defaultAlertError);

      onLogout();
    },
  });

  const getOktaAuthData = useCallback(async () => {
    try {
      const claims = await getIdTokenClaims();

      const { __raw: token } = claims || {};
      if (token) onOktaAuth({ token });
    } catch (error) {
      console.log(error);
    }
  }, [oktaUser]);

  useEffect(() => {
    if (oktaUser) getOktaAuthData();
  }, [oktaUser]);

  useEffect(() => {
    // navigated from landing page with an okta id_token, proceed to login
    if (searchParams.get('id_token')) {
      const requestBody = { token: searchParams.get('id_token') };

      if (searchParams.get('handle')) requestBody.handle = searchParams.get('handle');
      if (searchParams.get('price') && ['yes', 'no', 'tip'].includes(searchParams.get('price')))
        requestBody.price = searchParams.get('price');

      onOktaAuth(requestBody);

      // in prod app we need to "hide" that login page
      // and redirect user to asqme.com in order to connect from there.
      // So if that page is visited and the following condition is satisfied,
      // redirect back to asqme.com
    } else if (isProdEnv) {
      const redirectUrl = redirectUrlAfterAuth
        ? `${process.env.REACT_APP_WEB_BASE_URL}?redirectTo=${redirectUrlAfterAuth}`
        : process.env.REACT_APP_WEB_BASE_URL;
      window.location.replace(redirectUrl);
    }
  }, []);

  return (
    <Grid container className={classes.connectContainer}>
      {searchParams.get('id_token') || isProdEnv ? (
        <Backdrop open className={classes.backdrop}>
          <CircularProgress color='inherit' thickness={3} size={70} />
        </Backdrop>
      ) : (
        <>
          <Grid item sm xs />
          <Grid item sm={6} xs={12}>
            <Grid
              container
              direction='column'
              justifyContent='space-between'
              className={classes.mainView}
            >
              <Grid item container direction='column' spacing={4}>
                <Grid item>
                  <hr className={classes.topHr} />
                </Grid>
                <Grid item container justifyContent='center' alignItems='center' spacing={2}>
                  <Grid item>
                    <img src={logoImg} alt='logo' />
                  </Grid>
                  <Grid item>
                    <img src={titleImg} alt='title' />
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                container
                direction='column'
                justifyContent='space-between'
                className={classes.body}
              >
                <Grid item container>
                  <Grid item xs />
                  <Grid item xs={10}>
                    <hr className={classes.bodyHr} />
                  </Grid>
                  <Grid item xs />
                </Grid>

                <Grid item container justifyContent='center'>
                  <Grid item>
                    <div className={classes.bodyMainText}>
                      <span>Supercharge</span> <br />
                      <span>
                        your audience and
                        <br /> your revenue
                      </span>
                    </div>
                  </Grid>
                </Grid>

                <Grid item container justifyContent='space-evenly' alignItems='center'>
                  <Grid item>
                    <img src={youtubeImg} alt='youtube' className={classes.icon} />
                  </Grid>
                  <Grid item>
                    <img src={tiktokImg} alt='tiktok' className={classes.icon} />
                  </Grid>
                  <Grid item>
                    <img src={twitterImg} alt='twitter' className={classes.icon} />
                  </Grid>
                  <Grid item>
                    <img src={facebookImg} alt='facebook' className={classes.icon} />
                  </Grid>
                </Grid>

                <Grid item container justifyContent='center'>
                  <Grid item>
                    <div className={classes.bodyhelperText}>
                      Generate cash by answering
                      <br /> viewers questions
                    </div>
                  </Grid>
                </Grid>

                <Grid item container>
                  <Grid item xs />
                  <Grid item xs={10}>
                    <hr className={classes.bodyHr} />
                  </Grid>
                  <Grid item xs />
                </Grid>
              </Grid>

              <Grid item container className={classes.footer}>
                <Grid item xs />
                <Grid item xs={10}>
                  <Grid container direction='column' spacing={1}>
                    <Grid item>
                      <Button
                        onClick={() => {
                          const redirect_uri = `${window.location.origin}/auth/connect${
                            redirectUrlAfterAuth ? `?redirectTo=${redirectUrlAfterAuth}` : ''
                          }`;
                          loginWithRedirect({
                            authorizationParams: {
                              redirect_uri,
                            },
                          });
                        }}
                        variant='outlined'
                        className={classes.googleBtn}
                      >
                        <div>Sign in</div>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs />
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm xs />
        </>
      )}
    </Grid>
  );
};

export default Connect;
