import { useCallback, useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMutation } from '@tanstack/react-query';
import { useAlert } from 'react-alert';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import BaseHeader from 'components/Header/BaseHeader';
import {
  CoffeIcon,
  PizzaSliceIcon,
  PizzaWholeIcon,
  WhiteLogoWhiteContentIcon,
} from 'components/Icons';
import TipPaymentForm from './TipPaymentForm';
import { acceptAnswer } from 'store/customer';
import {
  createTipPaymentIntent,
  setupTipPaymentIntent,
  setupSubscriptionTipPaymentIntent,
} from 'store/payment';
import styles from './styles';
import { formatPrice } from 'utils/currency-utils';
import { defaultAlertError } from 'utils/common/constants';

const useStyles = makeStyles(styles);

const ProceedToTipCheckout = ({
  answerData,
  onAnswerAcceptanceSuccess,
  onResetStatus,
  questionTrackingCode,
  previousTip, // erroneous case, set previously selected tip
}) => {
  const alert = useAlert();
  const classes = useStyles();
  const {
    common: { white },
    blue,
  } = useTheme();

  const {
    creator: { banner_image, logo_image, display_name, asqMe_tag } = {},
    questionDowngradedToStandard,
    session_tracking_code,
    subscriptionTipFlow, // from tipjar subscription flow
  } = answerData || {};

  const [selectedTip, setSelectedTip] = useState(subscriptionTipFlow ? '5' : null);

  const {
    data: subscriptionTipPaymentIntentData,
    mutate: onSetupSubscriptionTipPaymentIntent,
    isLoading: setupSubscriptionPaymentIsLoading,
    reset: clearSetupSubscriptionTipPaymentIntent,
  } = useMutation({
    mutationFn: setupSubscriptionTipPaymentIntent,
    onError: (err) => {
      alert.error(null, defaultAlertError);
    },
  });

  const {
    data: tipPaymentIntent,
    mutate: onSetupTipPaymentIntent,
    isLoading: setupTipPaymentIsLoading,
    reset: clearSetupTipPaymentIntent,
  } = useMutation({
    mutationFn: setupTipPaymentIntent,
    onError: (err) => {
      setSelectedTip(null);
      alert.error(null, defaultAlertError);
    },
  });

  const { mutate: onCreateTipPaymentIntent, isLoading: createTipPaymentIntentIsLoading } =
    useMutation({
      mutationFn: createTipPaymentIntent,
      onSuccess: ({ amount }) => {
        onAnswerAcceptanceSuccess(amount, 'USD', true);
      },
      onError: (err) => {
        setSelectedTip(null);
        alert.error(null, defaultAlertError);
      },
    });

  const { mutate: onAcceptAnswer, isLoading: acceptAnswerIsLoading } = useMutation({
    mutationFn: acceptAnswer,
    onSuccess: () => {
      onAnswerAcceptanceSuccess(null, null, false);
    },
    onError: (err) => {
      alert.error(null, defaultAlertError);
    },
  });

  const onSelectTip = useCallback(
    (tip) => {
      setSelectedTip((prev) => (prev === tip && !subscriptionTipFlow ? null : tip));
    },
    [subscriptionTipFlow],
  );

  const handleProceed = useCallback(() => {
    if (selectedTip) {
      if (questionDowngradedToStandard)
        onCreateTipPaymentIntent({
          session_tracking_code,
          tip_price: selectedTip,
        });
      else if (subscriptionTipFlow)
        onSetupSubscriptionTipPaymentIntent({
          tip_price: selectedTip,
          asqtag: asqMe_tag,
        });
      else
        onSetupTipPaymentIntent({
          question_tracking_code: questionTrackingCode,
          tip_price: selectedTip,
        });
    } else onAcceptAnswer({ session_tracking_code });
  }, [selectedTip]);

  useEffect(() => {
    if (previousTip) {
      setSelectedTip(previousTip);
      onSetupTipPaymentIntent({
        question_tracking_code: questionTrackingCode,
        tip_price: previousTip,
      });
    }
  }, [previousTip]);

  const serviceClientSecret = tipPaymentIntent || subscriptionTipPaymentIntentData?.intent;

  const clearMutationDataOnReturn = subscriptionTipFlow
    ? clearSetupSubscriptionTipPaymentIntent
    : clearSetupTipPaymentIntent;

  const actionIsLoading =
    setupTipPaymentIsLoading ||
    setupSubscriptionPaymentIsLoading ||
    createTipPaymentIntentIsLoading ||
    acceptAnswerIsLoading;

  return (
    <Grid container className={classes.proceedToCheckoutContainer}>
      <Grid item sm xs />
      <Grid item sm={6} xs={12}>
        <Grid
          container
          direction='column'
          justifyContent='space-between'
          className={classes.proceedToCheckoutInnerContainer}
        >
          <Grid container direction='column'>
            {/* header */}
            <BaseHeader
              className={classes.header}
              leftIcon={<WhiteLogoWhiteContentIcon />}
              hideRightIcon
            />
            {/* header logos */}
            <Grid
              item
              container
              className={classes.headerLogosContainer}
              style={{ backgroundImage: `url(${banner_image})` }}
            >
              <Grid item xs={3} />
              <Grid item xs={6}>
                <Grid container justifyContent='center'>
                  <Grid item>
                    {/* profile logo */}
                    <div
                      className={classes.logoPlaceholderIcon}
                      style={{
                        backgroundImage: `url(${logo_image})`,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs />
            </Grid>
          </Grid>
          {serviceClientSecret ? (
            <TipPaymentForm
              answerData={answerData}
              classes={classes}
              clientSecret={serviceClientSecret}
              onResetClientSecret={() => {
                setSelectedTip(subscriptionTipFlow ? selectedTip : null);
                clearMutationDataOnReturn();
              }}
              redirectUrl={
                subscriptionTipFlow
                  ? `${window.location.href}?session_tracking_code=${subscriptionTipPaymentIntentData?.session_tracking_code}`
                  : undefined
              }
              tip={selectedTip}
              error={!!previousTip}
            />
          ) : (
            <>
              {/* description section */}
              <Grid item container className={`${classes.descriptionSection} space`}>
                <Grid item xs />
                <Grid item xs={10}>
                  <Grid container direction='column'>
                    {subscriptionTipFlow ? (
                      <Grid item>
                        <div className={`${classes.descriptionIntro} spaceTop`}>
                          Thank {display_name} with a tip
                        </div>
                      </Grid>
                    ) : (
                      <>
                        <Grid item>
                          <div className={classes.descriptionIntro}>Thanks!</div>
                        </Grid>
                        <Grid item>
                          <div className={classes.descriptionContent}>
                            I’m glad you got the answer you wanted.
                          </div>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs />
              </Grid>

              {/* price section */}
              <Grid item container className={classes.tipFormSection}>
                <Grid item xs />
                <Grid item xs={10}>
                  <Grid
                    container
                    direction='column'
                    justifyContent='center'
                    className={classes.tipFormInnerSection}
                    spacing={3}
                  >
                    {!subscriptionTipFlow && (
                      <>
                        <Grid item>
                          <hr />
                        </Grid>
                        <Grid item>
                          <div className={classes.tipQuestion}>
                            Would you like to thank <br /> {display_name} with a tip?
                          </div>
                        </Grid>
                      </>
                    )}
                    <Grid item container alignItems='center' justifyContent='space-evenly'>
                      <Grid item>
                        <Grid container direction='column' alignItems='center'>
                          <Grid item>
                            <div className={classes.pendingOverlay}>
                              <CoffeIcon
                                fillColor={selectedTip === '2' ? blue : white}
                                strokeColor={selectedTip === '2' ? white : blue}
                                className={classes.tipImg}
                                onClick={() => onSelectTip('2')}
                              />
                            </div>
                          </Grid>
                          <Grid item>
                            <div className={classes.tipValue}>{formatPrice(2, { appendCurrency: true, hideDecimals: true })}</div>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Grid container direction='column' alignItems='center'>
                          <Grid item>
                            <div className={classes.pendingOverlay}>
                              <PizzaSliceIcon
                                fillColor={selectedTip === '5' ? blue : white}
                                strokeColor={selectedTip === '5' ? white : blue}
                                className={classes.tipImg}
                                onClick={() => onSelectTip('5')}
                              />
                            </div>
                          </Grid>
                          <Grid item>
                            <div className={classes.tipValue}>{formatPrice(5, { appendCurrency: true, hideDecimals: true })}</div>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Grid container direction='column' alignItems='center'>
                          <Grid item>
                            <div className={classes.pendingOverlay}>
                              <PizzaWholeIcon
                                fillColor={selectedTip === '10' ? blue : white}
                                strokeColor={selectedTip === '10' ? white : blue}
                                className={classes.tipImg}
                                onClick={() => onSelectTip('10')}
                              />
                            </div>
                          </Grid>
                          <Grid item>
                            <div className={classes.tipValue}>{formatPrice(10, { appendCurrency: true, hideDecimals: true })}</div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs />
              </Grid>

              {/* action section */}
              <Grid item container className={classes.actionFooter}>
                <Grid item xs />
                <Grid item xs={6}>
                  <Grid container direction='column'>
                    <Grid item>
                      <Button
                        disabled={actionIsLoading}
                        variant='outlined'
                        className={`${classes.yesBtn} ${subscriptionTipFlow ? 'spaceBottom' : ''}`}
                        fullWidth
                        size='large'
                        onClick={handleProceed}
                      >
                        {actionIsLoading ? (
                          <CircularProgress color='inherit' size={31} />
                        ) : !!selectedTip ? (
                          `Tip ${formatPrice(selectedTip, { appendCurrency: true, hideDecimals: true })}`
                        ) : (
                          'No Tip'
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                  {!subscriptionTipFlow && (
                    <Grid item>
                      <div
                        className={classes.goBackContainer}
                        onClick={() => {
                          if (actionIsLoading) return;
                          onResetStatus();
                        }}
                      >
                        Go Back
                      </div>
                    </Grid>
                  )}
                </Grid>
                <Grid item xs />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <Grid item sm xs />
    </Grid>
  );
};

export default ProceedToTipCheckout;
