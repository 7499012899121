import { commonBtnStyles } from 'assets/styles/common';

const styles = (theme) => ({
  mainContainer: {
    height: '100%',
    position: 'relative',
  },
  revealAnswerContainer: {
    height: '100%',
    paddingTop: 120,
  },
  revealAnswerInnerContainer: {
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
  },
  header: {
    backgroundColor: theme.blue,
  },
  headerLogosContainer: {
    backgroundColor: theme.blue,
    width: '100%',
    height: 70,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  logoPlaceholderIcon: {
    transform: 'translateY(50%)',
    borderRadius: 50,
    width: 70,
    height: 70,
    backgroundColor: theme.common.black,
    border: `2px solid ${theme.common.white}`,
    position: 'relative',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  qnaContainer: {
    // flexGrow: 0.5,
  },
  customerQuestionSection: {
    paddingTop: theme.spacing(5.5),
    '&.noSpace': {
      paddingTop: theme.spacing(2),
    },
  },
  questionContainer: {
    padding: `${theme.spacing(1)}px 0`,
    backgroundColor: theme.grey[300],
  },
  questionIntro: {
    fontFamily: 'Figtree-Bold',
    fontSize: '1.1rem',
    '&.spaceTop': {
      paddingTop: theme.spacing(1),
    },
  },
  questionText: {
    fontSize: '0.85rem',
    lineHeight: 1.35,
    wordBreak: 'break-word',
    whiteSpace: 'pre-wrap',
  },
  attachment: {
    width: '100%',
    height: '100%',
    // maxHeight: 175, // Disabled because of AM-228
  },
  creatorAnswerSection: {
    paddingTop: theme.spacing(1),
  },
  answerIntro: {
    fontFamily: 'Figtree-Bold',
    fontSize: '1.2rem',
    lineHeight: 1.35,
  },
  answerText: {
    fontSize: '1rem',
    wordBreak: 'break-word',
    whiteSpace: 'pre-wrap',
  },
  clipsContainer: {
    width: '100%',
  },
  clipsAnswerThumbnailContainer: {
    position: 'relative',
    overflow: 'hidden',
  },
  clipsAnswerThumbnail: {
    width: '100%',
  },
  clipsPlayButtonContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  midSectionContainer: {
    flexGrow: 1,
    padding: `${theme.spacing(1)}px 0`,
  },
  downgradedQuestionContainer: {
    backgroundColor: theme.blue,
    padding: theme.spacing(1.3),
  },
  downgradedQuestionText: {
    fontSize: '0.84rem',
    color: theme.common.white,
    paddingLeft: theme.spacing(2),
    '& span': {
      fontFamily: 'Figtree-Bold',
    },
  },
  verifiedImg: {
    width: 30,
    height: 30,
    cursor: 'pointer',
  },
  expertText: {
    fontFamily: 'Figtree-Bold',
  },
  spacing: {
    paddingTop: theme.spacing(2),
    '&.pointer': {
      cursor: 'pointer',
    },
  },
  hr: {
    border: `1px solid ${theme.greyHorizontalRule}`,
    '&.marginTop': {
      marginTop: theme.spacing(1.5),
    },
  },
  replySoonFooter: {
    padding: `${theme.spacing(1)}px 0`,
  },
  actionFooter: {
    // paddingTop: theme.spacing(2),
    '&.bottomPadding': {
      paddingBottom: theme.spacing(2),
    },
  },
  goBackContainer: {
    fontSize: '1rem',
    color: theme.common.black,
    textAlign: 'center',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  replySoonText: {
    fontFamily: 'Figtree-Bold',
    fontSize: '1rem',
    lineHeight: 1.35,
    textAlign: 'center',
  },
  followUpHeader: {
    fontFamily: 'Figtree-Bold',
    fontSize: '1.2rem',
    lineHeight: 1.35,
    textAlign: 'center',
    '&.space': {
      paddingTop: theme.spacing(1.5),
    },
  },
  successSmallImg: {
    width: 14,
    height: 14,
    marginBottom: theme.spacing(0.2),
  },
  followUpDescription: {
    textAlign: 'center',
    '& span': {
      fontFamily: 'Figtree-Bold',
    },
  },
  mismatchContainer: {
    marginTop: theme.spacing(2),
    padding: `0 ${theme.spacing(2)}px`,
    minHeight: theme.spacing(6),
    backgroundColor: theme.grey[700],
  },
  translationImg: {
    width: 23,
  },
  translationIntro: {
    color: theme.common.white,
    paddingLeft: theme.spacing(2),
    fontSize: '0.8rem',
    '& span': {
      fontFamily: 'Figtree-SemiBold',
      fontSize: '1.2rem',
    },
  },
  toggleMessage: {
    paddingTop: theme.spacing(1),
    color: theme.grey[600],
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  conclusion: {
    textAlign: 'center',
    fontFamily: 'Figtree-Bold',
    fontSize: '1rem',
  },
  conclusionContainer: {
    paddingBottom: theme.spacing(2),
  },
  verticalLine: {
    width: 2,
    height: '100%',
    backgroundColor: theme.greyHorizontalRule,
    borderRadius: 50,
  },
  actions: {
    opacity: 1,
    width: 65,
    height: 65,
    cursor: 'pointer',
    transition: 'all 0.3s ease-in-out',
    '&.blur': {
      opacity: 0.6,
    },
  },
  actionText: {
    paddingTop: theme.spacing(0.7),
    textAlign: 'center',
    lineHeight: 1.2,
    color: theme.grey[800],
    '& span': {
      color: theme.grey[500],
    },
  },
  pendingOverlay: {
    position: 'relative',
  },
  progress: {
    position: 'absolute',
    left: 15,
    top: 15,
  },
  successImg: {
    width: 25,
    height: 25,
  },
  completedPaymentText: {
    opacity: 0.8,
    textAlign: 'center',
  },
  yesBtn: {
    ...commonBtnStyles,
    border: `2px solid ${theme.blue}`,
    backgroundColor: theme.blue,
    '&.Mui-disabled': {
      opacity: 0.7,
    },
    '& .MuiButton-label': {
      fontFamily: 'Figtree-Bold',
      color: theme.common.white,
      fontSize: '1.1rem',
    },
    '&:hover': {
      backgroundColor: theme.blue,
      opacity: 0.85,
      '&.Mui-disabled': {
        backgroundColor: theme.blue,
        opacity: 0.7,
      },
    },
  },
  noBtn: {
    ...commonBtnStyles,
    border: `3px solid ${theme.blue}`,
    '& .MuiButton-label': {
      fontFamily: 'Figtree-Bold',
      fontSize: '1.1rem',
    },
  },
  sendAnswerPrompt: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: '#00000073',
  },
  promptCard: {
    backgroundColor: theme.common.black,
    color: theme.common.white,
    width: '80%',
  },
  promptCloseIcon: {
    textAlign: 'end',
  },
  promptCopilotIcon: {
    textAlign: 'center',
    '& img': {
      width: 100,
      height: 100,
    },
  },
  promptHeader: {
    textAlign: 'center',
    fontSize: '1.4rem',
    fontFamily: 'Figtree-Bold',
    '& sup': {
      fontSize: '0.8rem',
    },
  },
  promptBody: {
    padding: `0 ${theme.spacing(3)}px`,
    textAlign: 'center',
    fontSize: '1rem',
    lineHeight: 1.3,
    color: theme.grey[400],
    '& span': {
      color: theme.common.white,
      fontFamily: 'Figtree-Bold',
    },
  },
  checkbox: {
    color: theme.common.white,
    '&.Mui-checked': {
      color: theme.common.white,
    },
    '& .MuiSvgIcon-root': {
      width: '1.4em',
      height: '1.4em',
    },
  },

  verificationText: {
    color: theme.grey[400],
    fontSize: '0.8rem',
    lineHeight: 1.3,
    '& span': {
      color: theme.common.white,
      fontFamily: 'Figtree-Bold',
    },
  },
  promptLastText: {
    textAlign: 'center',
    fontSize: '0.9rem',
  },
  promptFooter: {
    padding: `${theme.spacing(3)}px ${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(
      2,
    )}px  !important`,
  },
  promptEditBtnContainer: {
    paddingRight: theme.spacing(1),
  },
  promptSendBtnContainer: {
    paddingLeft: theme.spacing(1),
  },
  promptBtn: {
    ...commonBtnStyles,
    '&.Mui-disabled': {
      opacity: 0.7,
    },
    '&.send': {
      padding: `${theme.spacing(0.5)}px ${theme.spacing(4)}px`,
      backgroundColor: theme.common.white,
      '& .MuiButton-label': {
        color: theme.blue,
      },
    },
    '& .MuiButton-label': {
      fontFamily: 'Figtree-Bold',
      fontSize: '1rem',
    },
    '&:hover': {
      opacity: 0.85,
    },
  },
  answeredActionButtonContainer: {
    marginTop: 60,
  },
  answeredActionButton: {
    maxHeight: 44,
    '& svg': {
      marginRight: 10,
    },
    '& .MuiButton-label': {
      fontFamily: 'Figtree-Bold',
    },
  },
  outlinedButton: {
    border: '2px solid #495EFF',
    backgroundColor: 'white !important',
    '& .MuiButton-label': {
      color: '#495EFF'
    },

  }
});

export default styles;
