import { useState, useMemo, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useAlert } from 'react-alert';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import SubscriptionBox from './SubscriptionBox';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Switch from '@material-ui/core/Switch';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Dropdown } from 'antd';
import GridWrapper from 'components/GridWrapper';
import { AppAuthHeader } from 'components/Header';
import PremiumFeaturesDrawer from 'components/PremiumFeaturesDrawer';
import FeatureInfoDrawer from 'components/FeatureInfoDrawer';
import UnderDevFeatureDrawer from 'components/UnderDevFeatureDrawer';
import LinkInfoDrawer from './LinkInfoDrawer';
import { EditPencilIcon } from 'components/Icons';
import Header from './Header';
import Card from './Card';
import useAuth from 'hooks/auth';
import { resetPassword } from 'store/auth/okta';
import { updateMessenger } from 'utils/intercomConfig';
import { defaultLanguage, languagesLookup } from 'utils/lookups/supportedTranslationLanguages';
import { useTranslation, useTipJar } from './utils';
import useAppData from 'hooks/context';
import { defaultAlertError, originTypes } from 'utils/common/constants';
import infoImg from 'assets/images/info-white.svg';
import blueLogoImg from 'assets/images/blue-logo-black-content.svg';
import settingsImg from 'assets/images/settings.svg';
import chainlinkWhiteImg from 'assets/images/chainlink-white.svg';
import chainlinkBlueImg from 'assets/images/chainlink-blue.svg';
import goldLogoImg from 'assets/images/gold-logo-black-content.svg';
import whiteLogoImg from 'assets/images/white-logo-white-content.svg';
import translateBlackImg from 'assets/images/translate-black.svg';
import thankBankLogo from 'assets/images/thank-bank-logo.svg';
import qnaImg from 'assets/images/qna.svg';
import stripeImg from 'assets/images/stripe.svg';
import googleImg from 'assets/images/google-black.svg';
import { styles } from './styles';
import ThankBankSettings from './ThankBankSettings';
import ThankBankPreview from './ThankBankPreview';
import { slideoutTransitionDelay } from 'config/ui';
import ClipsElement from 'components/Clips/ClipsElement';
import clipsImg from 'assets/images/clips-sub-icon.svg';

const inboxEnabled = process.env.REACT_APP_ENABLE_INBOX === 'true';

const CopyToClipboardWrapper = ({ children, is_subscribed_and_profile_completed, ...rest }) =>
  is_subscribed_and_profile_completed ? (
    <CopyToClipboard {...rest}>{children}</CopyToClipboard>
  ) : (
    children
  );

const useStyles = makeStyles(styles);

const Account = () => {
  const classes = useStyles();
  const alert = useAlert();
  const { breakpoints, modalDarkBackground } = useTheme();
  const mobileView = useMediaQuery(breakpoints.down('xs'));
  const smallView = useMediaQuery(breakpoints.down('sm'));

  const queryClient = useQueryClient();
  const { user } = useAppData();

  const [linkInfoDrawer, setLinkInfoDrawer] = useState(false);
  const [premiumFeaturesDrawer, setPremiumFeaturesDrawer] = useState(false);
  const [featureInfoDrawer, setFeatureInfoDrawer] = useState(null);
  const [showThankBankPreview, setShowThankBankPreview] = useState(false);
  const [underDevFeatureDrawer, setUnderDevFeatureDrawer] = useState(false);

  const drawerOpen = useMemo(
    () => linkInfoDrawer || underDevFeatureDrawer || !!featureInfoDrawer || premiumFeaturesDrawer,
    [linkInfoDrawer, underDevFeatureDrawer, featureInfoDrawer, premiumFeaturesDrawer],
  );

  const { mutate: onResetPassword, isLoading: resetPasswordIsLoading } = useMutation({
    mutationFn: resetPassword,
    onError: (error) => {
      alert.error(null, { ...defaultAlertError, offsetError: true });
    },
  });

  // hide intercom launcher when a drawer is open
  useEffect(() => {
    if (drawerOpen) updateMessenger({ hide_default_launcher: true });
    else updateMessenger({ hide_default_launcher: false });
  }, [drawerOpen]);

  const {
    asqMe_tag,
    email,
    is_subscribed,
    is_subscribed_and_profile_completed,
    tip_jar_enabled,
    translationSettings: { languageTranslationEnabled, preferred_language },
  } = useAuth();

  // translation settings
  const {
    isLoading: updateTranslationSettingsIsLoading,
    handleChangeTranslationSettings,
    selectedLanguageOption,
  } = useTranslation({ preferred_language, queryClient, alert });

  // tip jar feature
  const { isLoading: updateTipJarFeatureIsLoading, handleTipJarChange } = useTipJar({
    is_subscribed_and_profile_completed,
    setFeatureInfoDrawer,
    queryClient,
    alert,
  });

  const isLoading =
    updateTranslationSettingsIsLoading || updateTipJarFeatureIsLoading || resetPasswordIsLoading;

  return (
    <>
      <Grid container className={classes.container}>
        <GridWrapper>
          {isLoading && (
            <Backdrop open className={classes.backdrop}>
              <CircularProgress color='inherit' thickness={3} size={60} />
            </Backdrop>
          )}
          <Grid container direction='column' className={classes.innerContainer}>
            {/* header */}
            <AppAuthHeader />
            {/* page content */}
            <Grid item container direction='column'>
              {/* link section */}
              {!inboxEnabled && (
                <Grid item container direction='column'>
                  {/* link header */}
                  <Header
                    leftSection={{ icon: chainlinkWhiteImg, text: 'Your Links' }}
                    rightSection={
                      <img
                        onClick={() => setLinkInfoDrawer(true)}
                        src={infoImg}
                        className={classes.infoLink}
                        alt='info'
                      />
                    }
                  />
                  {/* 1st row */}
                  <Grid item container className={classes.firstSectionContainer}>
                    <Grid item sm xs />
                    <Grid item sm={12} xs={10}>
                      {/* qna catalog */}
                      <Card
                        isPremium
                        topLeftSection={{ icon: qnaImg, text: 'Q&A Catalog' }}
                        topRightSection={
                          <Grid container alignItems='center'>
                            <Grid item>
                              <img
                                className={classes.tipjarLinkInfo}
                                src={goldLogoImg}
                                alt='logo'
                                onClick={() => setUnderDevFeatureDrawer(true)}
                              />
                            </Grid>
                            <Grid item>
                              <Switch
                                classes={{
                                  root: classes.switchRoot,
                                  track: `${classes.switchTrack} disabled`,
                                  checked: classes.switchChecked,
                                  switchBase: `${classes.switchBase} disabled`,
                                }}
                                checked={false}
                                onChange={() => setUnderDevFeatureDrawer(true)}
                              />
                            </Grid>
                          </Grid>
                        }
                        bottomLeftSection={
                          <div className={`${classes.asqmeUrl} disabled`}>
                            AsqMe.com/ask/<span>{asqMe_tag || ''}</span>
                          </div>
                        }
                        bottomRightSection={
                          <CopyToClipboardWrapper
                            is_subscribed_and_profile_completed={false}
                            text={`${process.env.REACT_APP_WEB_BASE_URL}/ask/${asqMe_tag || ''}`}
                            onCopy={() => {
                              alert.success(null, {
                                title: 'All set',
                                body: 'Link copied to clipboard.',
                                timeout: 1000 + slideoutTransitionDelay,
                                offsetError: true,
                              });
                            }}
                          >
                            <img
                              src={chainlinkWhiteImg}
                              className={`${classes.chainIcon} disabled`}
                              alt='chain'
                            />
                          </CopyToClipboardWrapper>
                        }
                      />
                    </Grid>
                    <Grid item sm xs />
                  </Grid>
                </Grid>
              )}
              {/* First Draft section */}
              {process.env.REACT_APP_ENABLE_CLIPS === 'true' && (
                <Grid item container direction='column'>
                  <Header
                    leftSection={{ icon: clipsImg, text: 'First Draft' }}
                    extraClassName={classes.firstDraftHeader}
                  />
                  <Grid item container className={classes.firstDraftContainer}>
                    <Grid item sm xs />
                    <Grid item sm={12} xs={10}>
                      <ClipsElement showEnabledClips hideBoxShadow noPadding hideLogo />
                    </Grid>
                    <Grid item sm xs />
                  </Grid>
                </Grid>
              )}
              <Grid item container direction='column' className={classes.sectionContainer}>
                {/* settings header */}
                <Header leftSection={{ icon: settingsImg, text: 'Settings' }} />

                {/* settings content */}
                {/* 1st row */}
                <Grid item container className={classes.firstSectionContainer} spacing={2}>
                  <Grid item xs={12}>
                    <Grid container direction='column' spacing={2}>
                      {/* asqme handle */}
                      <Grid item container>
                        <Grid item sm xs />
                        <Grid item sm={12} xs={10}>
                          <Card
                            topLeftSection={{ icon: blueLogoImg, text: 'AsqMe' }}
                            topRightSection={
                              <Link to='/profile' className={classes.changeHandleLink}>
                                Change Handle
                              </Link>
                            }
                            bottomLeftSection={
                              <div className={classes.asqmeUrl}>
                                AsqMe.com/<span>{asqMe_tag || ''}</span>
                              </div>
                            }
                            bottomRightSection={
                              <CopyToClipboard
                                text={`${process.env.REACT_APP_WEB_BASE_URL}/${asqMe_tag || ''}`}
                                onCopy={() => {
                                  alert.success(null, {
                                    title: 'All set',
                                    body: 'Link copied to clipboard.',
                                    timeout: 1000 + slideoutTransitionDelay,
                                    offsetError: true,
                                  });
                                }}
                              >
                                <img
                                  src={chainlinkBlueImg}
                                  className={classes.chainIcon}
                                  alt='chain'
                                />
                              </CopyToClipboard>
                            }
                          />
                        </Grid>
                        <Grid item sm xs />
                      </Grid>
                      {/* translation section */}
                      <Grid item container>
                        <Grid item sm xs />
                        <Grid item sm={12} xs={10}>
                          {/* toggle translation feature */}
                          <Card
                            isPremium
                            secondSectionClassName={classes.secondSectionClassName}
                            topLeftSection={{ icon: translateBlackImg, text: 'AutoTranslate' }}
                            topRightSection={
                              <Grid container alignItems='center'>
                                <Grid item>
                                  <img
                                    src={goldLogoImg}
                                    onClick={() => setFeatureInfoDrawer('tip')}
                                    className={classes.tipjarLinkInfo}
                                    alt='logo'
                                  />
                                </Grid>

                                <Grid item>
                                  <Switch
                                    classes={{
                                      root: classes.switchRoot,
                                      track: `${classes.switchTrack} ${
                                        !is_subscribed_and_profile_completed ? 'disabled' : ''
                                      }`,
                                      checked: classes.switchChecked,
                                      switchBase: `${classes.switchBase} ${
                                        !is_subscribed_and_profile_completed ? 'disabled' : ''
                                      }`,
                                    }}
                                    disabled={!is_subscribed}
                                    checked={languageTranslationEnabled}
                                    onChange={(e) => {
                                      if (!is_subscribed_and_profile_completed) {
                                        setFeatureInfoDrawer('tip');
                                        return;
                                      }

                                      if (e.target.checked !== languageTranslationEnabled)
                                        handleChangeTranslationSettings(
                                          e.target.checked,
                                          e.target.checked ? defaultLanguage : '',
                                        );
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            }
                            bottomLeftSection={
                              <Grid container direction='column'>
                                {/* language selection */}
                                <Grid item xs={12}>
                                  <div style={{ fontFamily: 'Figtree-SemiBold' }}>
                                    {user.is_subscribed
                                      ? 'Unlimited Translations with Pro Plan'
                                      : 'Starter Plan Gets 5 per Month | Get Unlimited with Pro'}
                                  </div>
                                  <div>My preferred language:</div>
                                  <Select
                                    className={classes.select}
                                    classNamePrefix='select'
                                    value={selectedLanguageOption}
                                    isClearable={false}
                                    isDisabled={
                                      !(
                                        (languageTranslationEnabled &&
                                          is_subscribed_and_profile_completed) ||
                                        !is_subscribed
                                      )
                                    }
                                    onChange={(option) => {
                                      if (option && option.value !== preferred_language)
                                        handleChangeTranslationSettings(
                                          !is_subscribed ? false : true,
                                          option.value,
                                        );
                                    }}
                                    isMulti={false}
                                    isSearchable
                                    options={languagesLookup}
                                  />
                                </Grid>
                              </Grid>
                            }
                          />
                        </Grid>
                        <Grid item sm xs />
                      </Grid>
                      {/* email section */}
                      <Grid item container className={smallView ? classes.emailContainer : ''}>
                        <Grid item md xs />
                        <Grid item sm={12} xs={10}>
                          <Grid container direction='column' spacing={1}>
                            <Grid item>
                              <div className={classes.connectionsText}>Email Address</div>
                            </Grid>
                            {/* language selection */}
                            <Grid item container alignItems='center' spacing={2}>
                              {(user.last_origin || '').includes(originTypes.google) && (
                                <Grid item>
                                  <img src={googleImg} alt='google' />
                                </Grid>
                              )}
                              <Grid item>
                                <div className={classes.email}>{email}</div>
                              </Grid>
                              {process.env.REACT_APP_ENABLE_CHANGE_PASSWORD === 'true' &&
                                (user.last_origin || '').includes(originTypes.credentials) && (
                                  <Grid item>
                                    <Dropdown
                                      menu={{
                                        items: [
                                          {
                                            key: 'change_password',
                                            label: (
                                              <div
                                                onClick={() =>
                                                  onResetPassword({
                                                    email: user.email,
                                                  })
                                                }
                                              >
                                                Reset Password
                                              </div>
                                            ),
                                          },
                                        ],
                                      }}
                                    >
                                      <EditPencilIcon
                                        className={classes.editEmailIcon}
                                        width={30}
                                        height={30}
                                        strokeColor={modalDarkBackground}
                                        fillColor='transparent'
                                      />
                                    </Dropdown>
                                  </Grid>
                                )}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item md xs />
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* stripe connections section */}
                  {user.connected_account_enabled && (
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs />
                        <Grid item sm={12} xs={10}>
                          <Grid
                            container
                            direction='column'
                            className={!smallView ? classes.stripeConnectionContainer : ''}
                            spacing={smallView ? 1 : 4}
                          >
                            {/* header */}
                            <Grid item className={classes.connectionsTextContainer}>
                              <div className={classes.connectionsText}>Connections</div>
                            </Grid>
                            {/* language selection */}
                            <Grid item xs={12}>
                              <Grid
                                container
                                justifyContent='space-between'
                                alignItems='center'
                                className={classes.stripeSettingsContainer}
                              >
                                <Grid item>
                                  <img className={classes.stripeImg} src={stripeImg} alt='stripe' />
                                </Grid>
                                <Grid item>
                                  <Link to='/stripe-login' target='_blank'>
                                    Settings
                                  </Link>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              {/* Thank Bank */}
              <Grid item container direction='column' className={classes.sectionContainer}>
                {/* Thank Bank header */}
                <Header
                  leftSection={{ icon: thankBankLogo, text: 'ThankBank', superscript: 'TM' }}
                  rightSection={
                    <Button
                      className={classes.thankBankPreviewButton}
                      onClick={() => setShowThankBankPreview(true)}
                    >
                      Preview
                    </Button>
                  }
                  rightAlignRightSection={true}
                />
                {/* Thank Bank content */}
                <Grid item container className={classes.firstSectionContainer}>
                  <Grid item sm xs />
                  <Grid item sm={12} xs={10}>
                    <ThankBankSettings
                      user={user}
                      showPremiumFeaturesDrawer={() => setPremiumFeaturesDrawer(true)}
                    />
                  </Grid>
                  <Grid item sm xs />
                </Grid>
              </Grid>
              {/* subscription section */}
              <Grid item container direction='column' className={classes.sectionContainer}>
                {/* subscription header */}
                <Header leftSection={{ icon: whiteLogoImg, text: 'Subscription Center' }} />
                {/* subscription content */}
                <Grid item container className={classes.firstSectionContainer}>
                  <Grid item sm xs />
                  <Grid item sm={12} xs={10}>
                    <SubscriptionBox />
                  </Grid>
                  <Grid item sm xs />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </GridWrapper>
      </Grid>
      {/* informational popups section */}
      <LinkInfoDrawer
        open={linkInfoDrawer}
        mobileType={mobileView}
        onClose={() => setLinkInfoDrawer(false)}
        onLinkSelect={() => {
          setPremiumFeaturesDrawer(true);
          setLinkInfoDrawer(false);
        }}
      />
      {/* Preview Thank Bank Drawer */}
      <ThankBankPreview
        user={user}
        open={showThankBankPreview}
        onClose={() => setShowThankBankPreview(false)}
      />
      <FeatureInfoDrawer
        open={!!featureInfoDrawer}
        mobileType={mobileView}
        type={featureInfoDrawer}
        onClose={() => setFeatureInfoDrawer(null)}
      />
      <PremiumFeaturesDrawer
        open={premiumFeaturesDrawer}
        mobileType={mobileView}
        onClose={() => setPremiumFeaturesDrawer(false)}
      />
      <UnderDevFeatureDrawer
        open={underDevFeatureDrawer}
        mobileType={mobileView}
        onClose={() => setUnderDevFeatureDrawer(false)}
      />
    </>
  );
};

export default Account;
