import { methods, fetchApi } from 'utils/apiConfig';
const { POST, PUT } = methods;

const generateUploadUrl = (data) =>
  fetchApi(`/amazon-s3-service/getPreSignedUrl`, POST, {
    headers: {
      chinawall: process.env.REACT_APP_CHINA_WALL,
    },
    data,
  });

const uploadFile = ({ url, fileData }) =>
  fetchApi(url, PUT, {
    timeout: 0,
    headers: {
      'Content-Type': fileData.fileType,
      Authorization: undefined,
    },
    data: fileData.file,
  });

export { generateUploadUrl, uploadFile };
