import { number, object, string } from 'prop-types';

const EyeIcon = ({
  className = '',
  style = {},
  width = 26,
  height = 22,
  fillColor = '#C1C1C1',
  ...rest
}) => (
  <svg
    {...rest}
    className={className}
    style={style}
    width={width}
    height={height}
    viewBox='0 0 26 22'
    fill='none'
  >
    <g clipPath='url(#clip0_5876_2910)'>
      <path
        d='M12.8022 6.96476L16.5241 10.6122L16.5419 10.4211C16.5419 8.50459 14.9529 6.94739 12.9972 6.94739L12.8022 6.96476Z'
        fill={fillColor}
      />
      <path
        d='M12.9972 4.6314C16.2583 4.6314 18.9052 7.22528 18.9052 10.4211C18.9052 11.1679 18.7517 11.8801 18.4859 12.5341L21.9419 15.921C23.7259 14.462 25.1321 12.5748 26.0006 10.4211C23.9507 5.33808 18.9113 1.73682 12.9978 1.73682C11.3434 1.73682 9.76052 2.02611 8.28949 2.54717L10.8416 5.04227C11.509 4.78771 12.2357 4.6314 12.9978 4.6314H12.9972Z'
        fill={fillColor}
      />
      <path
        d='M1.18137 1.47633L3.8753 4.11635L4.41309 4.64338C2.46353 6.13707 0.921609 8.12252 0 10.4211C2.04427 15.5042 7.0893 19.1054 12.9972 19.1054C14.8288 19.1054 16.5773 18.758 18.1785 18.1268L18.6809 18.6191L22.1253 22L23.6317 20.5296L2.68784 0L1.18137 1.47633ZM7.71515 7.87393L9.54064 9.66289C9.48747 9.91202 9.45203 10.1606 9.45203 10.4211C9.45203 12.3377 11.041 13.8949 12.9967 13.8949C13.2625 13.8949 13.5167 13.8601 13.7649 13.808L15.5904 15.597C14.8044 15.9791 13.9305 16.2108 12.9967 16.2108C9.7356 16.2108 7.08874 13.617 7.08874 10.4211C7.08874 9.50658 7.32524 8.64955 7.71515 7.87393Z'
        fill={fillColor}
      />
    </g>
    <defs>
      <clipPath id='clip0_5876_2910'>
        <rect width='26' height='22' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

EyeIcon.propTypes = {
  style: object,
  className: string,
  height: number,
  width: number,
  fillColor: string,
  strokeColor: string,
};

export default EyeIcon;
