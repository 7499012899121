import { number, object, string } from 'prop-types';
import common from '@material-ui/core/colors/common';

const PaperFolderIcon = ({
  className = '',
  style = {},
  width = 25,
  height = 25,
  fillColor = common.black,
}) => (
  <svg
    className={className}
    style={style}
    width={width}
    height={height}
    viewBox='0 0 23 17'
    fill='none'
  >
    <g clipPath='url(#clip0_5876_5770)'>
      <path
        d='M12.1656 11.415C11.9482 11.5513 11.7042 11.6055 11.4867 11.6055C11.2693 11.6055 11.0253 11.5513 10.8079 11.415L0 4.79468V13.5945C0 15.4744 1.52057 16.9999 3.39436 16.9999H19.6056C21.4794 16.9999 23 15.4744 23 13.5945V4.79468L12.1651 11.415H12.1656Z'
        fill={fillColor}
      />
      <path
        d='M19.6056 0H3.39437C1.79213 0 0.434381 1.14401 0.108734 2.67007L11.5138 9.64428L22.8918 2.67007C22.5662 1.14454 21.2084 0 19.6062 0H19.6056Z'
        fill={fillColor}
      />
    </g>
    <defs>
      <clipPath id='clip0_5876_5770'>
        <rect width='23' height='17' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

PaperFolderIcon.propTypes = {
  style: object,
  className: string,
  height: number,
  width: number,
  fillColor: string,
};

export default PaperFolderIcon;
