import { number, object, string } from 'prop-types';

const InboxInstagramIcon = ({
  className = '',
  style = {},
  width = 40,
  height = 40,
  fillColor = '#7E8DFF',
  ...rest
}) => (
  <svg
    {...rest}
    className={className}
    style={style}
    width={width}
    height={height}
    viewBox='0 0 40 40'
  >
    <path
      d='M11.8182 0C5.3 0 0 5.3 0 11.8182V28.1818C0 34.7 5.3 40 11.8182 40H28.1818C34.7 40 40 34.7 40 28.1818V11.8182C40 5.3 34.7 0 28.1818 0H11.8182ZM30.9091 7.27273C31.9091 7.27273 32.7273 8.09091 32.7273 9.09091C32.7273 10.0909 31.9091 10.9091 30.9091 10.9091C29.9091 10.9091 29.0909 10.0909 29.0909 9.09091C29.0909 8.09091 29.9091 7.27273 30.9091 7.27273ZM20 10C25.5182 10 30 14.4818 30 20C30 25.5182 25.5182 30 20 30C14.4818 30 10 25.5182 10 20C10 14.4818 14.4818 10 20 10ZM20 11.8182C15.4909 11.8182 11.8182 15.4909 11.8182 20C11.8182 24.5091 15.4909 28.1818 20 28.1818C24.5091 28.1818 28.1818 24.5091 28.1818 20C28.1818 15.4909 24.5091 11.8182 20 11.8182Z'
      fill={fillColor}
    />
  </svg>
);

InboxInstagramIcon.propTypes = {
  style: object,
  className: string,
  height: number,
  width: number,
  fillColor: string,
  strokeColor: string,
};

export default InboxInstagramIcon;
