import { number, object, string } from 'prop-types';

const InboxPatreonIcon = ({
  className = '',
  style = {},
  width = 40,
  height = 40,
  fillColor = '#7E8DFF',
  ...rest
}) => (
  <svg
    {...rest}
    className={className}
    style={style}
    width={width}
    height={height}
    viewBox='0 0 40 40'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M35.2381 0H4.7619C2.13333 0 0 2.13333 0 4.7619V35.2381C0 37.8667 2.13333 40 4.7619 40H35.2381C37.8667 40 40 37.8667 40 35.2381V4.7619C40 2.13333 37.8667 0 35.2381 0ZM26.3671 7.65373C29.4339 8.64624 32.0479 11.3969 32.0525 14.7532C32.0578 18.6193 29.5374 21.5379 25.8392 22.4533C23.6874 22.9858 21.9395 23.5001 20.5819 25.3208C20.0104 26.0873 19.5549 27.101 19.0865 28.1435C17.9911 30.5817 16.8245 33.1781 13.9359 33.1414C8.38579 33.0713 8.01293 23.2316 8.05528 19.4957C8.10687 14.9518 9.49147 10.3957 13.8991 8.31573C17.5357 6.59989 22.5586 6.42124 26.3671 7.65373Z'
      fill={fillColor}
    />
  </svg>
);

InboxPatreonIcon.propTypes = {
  style: object,
  className: string,
  height: number,
  width: number,
  fillColor: string,
  strokeColor: string,
};

export default InboxPatreonIcon;
