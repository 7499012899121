/**
 * If auto translation is performed from google chrome (and possibly from other browsers)
 * it breaks the underlying DOM structure and causes the app to crash.
 * The following code is provided as a workaround to this issue until is resolved from google.
 *
 * It should be bundled and invoked within the app and not to be served statically as a separate file inside html
 * since some web app routes are accessible from landing page (wp) and there are some path relolution issues.
 */
const translationDomFixConfig = () => {
  if (typeof Node === 'function' && Node.prototype) {
    const originalRemoveChild = Node.prototype.removeChild;
    Node.prototype.removeChild = function (child) {
      if (child.parentNode !== this) {
        if (console) {
          console.error('Cannot remove a child from a different parent', child, this);
        }
        return child;
      }
      return originalRemoveChild.apply(this, arguments);
    };

    const originalInsertBefore = Node.prototype.insertBefore;
    Node.prototype.insertBefore = function (newNode, referenceNode) {
      if (referenceNode && referenceNode.parentNode !== this) {
        if (console) {
          console.error(
            'Cannot insert before a reference node from a different parent',
            referenceNode,
            this,
          );
        }
        return newNode;
      }
      return originalInsertBefore.apply(this, arguments);
    };
  }
};

export default translationDomFixConfig;
