import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useAlert } from 'react-alert';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams, } from 'react-router-dom';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import AnswerStep from './AnswerStep';
import QuestionStep from './QuestionStep';
import NotFound from 'components/NotFound';
import { queryCustomerTypes, fetchQuestionStepData, fetchAnswerStepData } from 'store/customer';
import { defaultAlertError } from 'utils/common/constants';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.common.white,
    color: theme.common.black,
  },
}));

const PublicProfile = () => {
  const { tag } = useParams();

  const asqMeTagFound = tag.startsWith('@');

  const navigate = useNavigate();

  const alert = useAlert();
  const classes = useStyles();

  const [initialLoad, setInitialLoad] = useState(true);

  // tracking code found - search for answer record and payment method existance
  const {
    data: answerStepData,
    isFetching: answerStepDataLoading,
    error: answerStepDataError,
  } = useQuery({
    queryKey: [queryCustomerTypes.answerStepData, tag],
    queryFn: fetchAnswerStepData,
    enabled: !asqMeTagFound,
    retry: 1,
    onSuccess: () => {
      setInitialLoad(false);
    },
    onError: (err) => {
      if (err.status === 404) {
        setInitialLoad(false);
      } else
        alert.error(null, defaultAlertError);
    },
  });

  // asqMe tag found - get inital question data
  const {
    data: questionStepData,
    isFetching: questionStepDataLoading,
    error: questionStepDataError,
  } = useQuery({
    queryKey: [queryCustomerTypes.questionStepData, tag],
    queryFn: fetchQuestionStepData,
    enabled: asqMeTagFound,
    retry: 1,
    onSuccess: () => {
      setInitialLoad(false);
    },
    onError: (err) => {
      if (err.status === 404) {
        // Redirect to homepage if asqMe tag not found
        window.location.replace(process.env.REACT_APP_WEB_BASE_URL)
      } else
        alert.error(null, defaultAlertError);
    },
  });

  const loading = initialLoad || answerStepDataLoading || questionStepDataLoading;

  return (
    <>
      {loading ? (
        <Backdrop open className={classes.backdrop}>
          <CircularProgress color='inherit' thickness={3} size={70} />
        </Backdrop>
      ) : answerStepData ? (
        <AnswerStep answerStepData={answerStepData} questionTrackingCode={tag} />
      ) : questionStepData ? (
        <QuestionStep creatorData={questionStepData} tag={tag} />
      ) : questionStepDataError?.status === 404 || answerStepDataError?.status === 404 ? (
        <NotFound />
      ) : null}
    </>
  );
};

export default PublicProfile;
