import { useEffect, useState, useMemo } from 'react';
import { ButtonBase, Grid, makeStyles } from "@material-ui/core";
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { fetchClipsInfo, queryClipsTypes } from "store/clips";
import { DateTime } from 'luxon';
import { trackAppCtaClick } from 'store/creator';
import { getPathAndPageName } from 'utils/url-utils';
import { ClipsDisconnectDialog } from './ClipsDisconnectDialog';

const useStyles = makeStyles((theme) => ({
  thumbnail: {
    width: 75,
    height: 75,
    borderRadius: '50%',
    marginRight: 10,
  },
  infoContainer: {
    flexGrow: 100,
    minWidth: 150,
  },
  handle: {
    marginBottom: 10,
    '& span': {
      fontFamily: 'Figtree-Regular',
      fontSize: 16,
      lineHeight: '21.79px',
    },
  },
  subtitle: {
    fontFamily: 'Figtree-Regular',
    fontSize: 12,
    lineHeight: '16.34px',
  },
  buttonContainer: {
    flexGrow: 1,
    marginTop: 20,
  },
  outlinedButton: {
    height: 44,
    width: '100%',
    borderRadius: 5,
    padding: '0 20px',
    border: '2px solid #495EFF',
    fontFamily: 'Figtree-Bold',
    fontSize: 16,
    lineHeight: '19.2px',
    color: '#495EFF',
  },
  lastUpdated: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    '& p': {
      lineHeight: 1,
      fontFamily: 'Figtree-Regular',
      fontSize: 12,
      color: '#454545',
      marginTop: 10,
      marginBottom: 0,
      whiteSpace: 'nowrap',
      maxWidth: 124,
      overflow: 'visible',
      direction: 'rtl',
    }
  }
}));

const ClipsEnabled = () => {
  const classes = useStyles();

  const queryClient = useQueryClient();

  const [clipsInfo, setClipsInfo] = useState(null);
  const [showDisconnectDialog, setShowDisconnectDialog] = useState(false);

  // unmount cleanup
  useEffect(() => {
    queryClient.invalidateQueries(queryClipsTypes.clipsInfo);
  }, [])

  useQuery({
    queryKey: [queryClipsTypes.clipsInfo],
    queryFn: fetchClipsInfo,
    onSuccess: (data) => {
      setClipsInfo(data);
    },
  });

  const updatedAt = useMemo(() => {
    if (!clipsInfo?.clips_last_updated) return "Today";

    const inputDate = DateTime.fromISO(clipsInfo.clips_last_updated).startOf('day');
    const today = DateTime.now().startOf('day');

    if (inputDate.equals(today)) {
        return "Today";
    } else {
        return inputDate.toFormat('MM/dd/yyyy');
    }

  }, [clipsInfo]);

  const proceessingStatus = useMemo(() => {
    if (clipsInfo?.clips_total_items == null || clipsInfo.clips_total_items === 0) return 'processing videos...';

    return `${clipsInfo.clips_total_items_processed}/${clipsInfo.clips_total_items} videos analyzed`;
  }, [clipsInfo]);

  return (
    <Grid container item direction="row" alignItems="center">
      <Grid item>
        <img 
          src={clipsInfo?.clips_profile_image} 
          alt="Channel thumbnail"
          className={classes.thumbnail}
        />
      </Grid>
      <Grid container item xs direction="column" className={classes.infoContainer}>
        <Grid item className={classes.handle}>
          <span>{clipsInfo?.clips_handle}</span>
        </Grid>
        <Grid item>
          {clipsInfo && <span className={classes.subtitle}>{`${proceessingStatus}`}</span>}
        </Grid>
      </Grid>
      <Grid item className={classes.buttonContainer}>
        <ButtonBase 
          className={classes.outlinedButton}
          onClick={() => {
            setShowDisconnectDialog(true);
            trackAppCtaClick({
              ...getPathAndPageName(),
              cta_verbiage: 'Disconnect Account'
            })
          }}
        >
          Disconnect
        </ButtonBase>
        <div className={classes.lastUpdated}>
          <p>Last Updated: {updatedAt}</p>
        </div>
      </Grid>
      <ClipsDisconnectDialog 
        open={showDisconnectDialog} 
        onClose={() => setShowDisconnectDialog(false)}
      />
    </Grid>
  )
}

export default ClipsEnabled;