import { useTheme, makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import { headerStyles } from './styles';

const useStyles = makeStyles(headerStyles);

const Header = ({ leftSection: { icon, text, superscript }, rightSection, rightAlignRightSection, extraClassName }) => {
  const classes = useStyles();
  const { breakpoints } = useTheme();
  const smallView = useMediaQuery(breakpoints.down('sm'));

  return (
    <Grid
      item
      container
      justifyContent={smallView ? 'space-between' : 'flex-start'}
      className={`${classes.itemHeader} ${extraClassName}`}
      alignItems='center'
    >
      <Grid item>
        <Grid container alignItems='center' spacing={1}>
          <Grid item>
            <img src={icon} alt='icon' />
          </Grid>
          <Grid item>
            <div className={classes.leftHeaderText}>{text}<span>{superscript}</span></div>
          </Grid>
        </Grid>
      </Grid>
      {rightSection && (
        <Grid item className={`${classes.rightItem} ${rightAlignRightSection ? classes.rightItemRightAlign : ''}`}>
          {rightSection}
        </Grid>
      )}
    </Grid>
  );
};
export default Header;
