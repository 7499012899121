import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Link } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { ButtonBase, Grid, makeStyles, TextField } from "@material-ui/core";
import { ClipsPlayButton } from 'components/Clips/ClipsButtons';
import { ClipsVideoPlayerDialog } from 'components/Clips/ClipsVideoPlayerDialog';
import { AppDataContext } from 'Context';
import { useAlert } from 'react-alert';
import { slideoutTransitionDelay } from 'config/ui';
import { defaultAlertError } from 'utils/common/constants';
import { firstDraftChatInit, firstDraftChatQuestion } from 'store/creator';
import styles from './styles';
import GridWrapperWithBanner from "components/GridWrapper/GridWrapperWithBanner";
import { PaperPlaneIcon } from "components/Icons";
import { TimeSpan } from 'utils/time-span';
import { trackAppCtaClick } from 'store/creator';
import { getPathAndPageName } from 'utils/url-utils';
import { updateMessenger } from 'utils/intercomConfig';
import asqmeAssistantProfileImg from 'assets/images/asqme-assistant-profile.png';
import chatGptLogoImg from 'assets/images/chat-gpt-logo.png';
import firstDraftIcon from 'assets/images/copilot-happy-blue.svg';
import doublePaperIcon from 'assets/images/double-paper-icon.svg';
import dotSpinner from 'assets/images/dot-spinner.svg';

const useStyles = makeStyles(styles);

const FirstDraft = () => {
  const classes = useStyles();

  const alert = useAlert();

  const {
    appData: { firstDraftChat, doNotShowClipsPrompt, user },
    initFirstDraftChat,
    addNewMessageToFirstDraftChat,
    setFirstDraftChatGptFollowUp,
    setFirstDraftChatExpiry,
    setDoNotShowClipsPrompt
  } = useContext(AppDataContext);

  const [initChatExpiry, setInitChatExpiry] = useState(null);
  const [clipsVideo, setClipsVideo] = useState(null);
  const [showClipsElement, setShowClipsElement] = useState(true);

  const messagesEndRef = useRef(null);

  useEffect(() => {
    if (firstDraftChat == null) {
      onFirstDraftChatInit();
    }

    // Hide the intercom launcher (blue chat bubble) on the FirstDraft page
    // because it blocks the chat send button
    updateMessenger({ hide_default_launcher: true });

    return () => {
      updateMessenger({ hide_default_launcher: false });
    }
  }, [])

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'instant' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [firstDraftChat?.messages]);


  // Expiry (if set) needs to be scheduled after the chat is initialized
  useEffect(() => {
    if (initChatExpiry != null && initChatExpiry != null) {
      setFirstDraftChatExpiry(initChatExpiry);
      setInitChatExpiry(null);
    }
  }, [firstDraftChat, initChatExpiry, setFirstDraftChatExpiry])

  const getAssistantMsg = (message) => {
    // No answer means we have nothing to say
    if (!message.first_draft_answer) return null;
  
    // No video
    if (!message.clipsCreatorItem)
      return `I didn’t find an answer in your YouTube channel but got one from ChatGPT.`;
  
    // Video but no answer
    if (!message.clips_is_content_driven_answer)
      return `I didn’t find an exact answer in your YouTube channel but did find a related video. If it helps, here is an answer from ChatGPT.`;
  
    // Video with timestamp
    if (message.clips_timestamp)
      return `I found an answer and video clip in your YouTube channel. I cued up the video to the answer.`;
  
    // Video with no timestamp
    return `I found an answer and video in your YouTube channel.`;
  }

  const { mutate: onFirstDraftChatInit } = useMutation({
    mutationFn: firstDraftChatInit,
    onSuccess: (data) => {
      let firstMessage = '';
      if (data.chats_used >= data.chat_limit) {
        firstMessage = `Hi ${user.display_name ?? ''}! You have used all ${data.chat_limit} AsqMe Assistant conversations included in your plan. Please upgrade to the Pro Plan for unlimited AsqMe Assistant conversations and FirstDrafts.\n\nWould you like to upgrade now?`;
      } else if (user.clips_enabled) {
        firstMessage = `Hi ${user.display_name ?? ''}! Please ask your question below.`;
      } else {
        firstMessage = 'no-clips'
      }

      initFirstDraftChat(data.chats_used, data.chat_limit, {
        text: firstMessage,
        type: 'assistant',
      })

      // Note about Beta status
      addNewMessageToFirstDraftChat({ 
        text: <>Don't forget that <strong>I'm in beta</strong> so I will probably make some mistakes.</>, 
        type: 'assistant' 
      });

      if (data.chat_expiry_timestamp) {
        setInitChatExpiry(data.chat_expiry_timestamp);
      }
    },
    onError: (err) => {
      console.error(err.response?.data);
      alert.error(null, {...defaultAlertError, offsetError: true });
    },
  });

  const { mutate: onSendFirstDraftQuestion, isLoading: isSendingQuestion } = useMutation({
    mutationFn: firstDraftChatQuestion,
    onSuccess: (data) => {
      if (data.chat_expiry_timestamp) {
        setFirstDraftChatExpiry(data.chat_expiry_timestamp);
      }

      if (firstDraftChat.ask_chat_gpt_follow_up) {
        setFirstDraftChatGptFollowUp(false);
      } else if (user.clips_enabled) {
        const assistantMessage = getAssistantMsg(data);
        addNewMessageToFirstDraftChat({ text: assistantMessage, type: 'assistant' });
      }

      addNewMessageToFirstDraftChat({ ...data, type: 'assistant' });

      if (data.clips_is_content_driven_answer) {
        addNewMessageToFirstDraftChat({ text: 'Would you like to see an answer from ChatGPT too?', type: 'assistant' });
        setFirstDraftChatGptFollowUp(true);
      }
    },
    onError: (err) => {
      console.error(err.response?.data);
      alert.error(null, {...defaultAlertError, offsetError: true });
    },
  });

  const sendMessage = (message) => {
    const newMessage = {
      text: message,
      type: 'user'
    }

    let useGptOnly = false;
    if (firstDraftChat.ask_chat_gpt_follow_up) {
      if (message.toLowerCase() === 'yes') {
        useGptOnly = true;

        // Use the last message sent by the creator
        for (let i = firstDraftChat.messages.length - 1; i >= 0; i--) {
          if (firstDraftChat.messages[i].type === 'user') {
            message = firstDraftChat.messages[i].text;
            break;
          }
        }
      } else {
        setFirstDraftChatGptFollowUp(false);

        if (message.toLowerCase() === 'no') {
          addNewMessageToFirstDraftChat(newMessage);
          addNewMessageToFirstDraftChat({ text: 'OK! I’m ready for another question when you’ve got one.', type: 'assistant' });
          return; // Wait for the next question from creator
        }
      }
    }

    addNewMessageToFirstDraftChat(newMessage);

    // Clear the chat expiry so the user doesn't reccieve a message about it expiring while
    // they're waiting for the response
    setFirstDraftChatExpiry(null);

    onSendFirstDraftQuestion({ question: message, use_gpt_only: useGptOnly });
  };

  const showClipsVideo = (message) => {
    let timestamp = null;

    if (message.clips_timestamp) {
      timestamp = new TimeSpan(message.clips_timestamp);
    }

    const video = {
      id: message.clipsCreatorItem?.external_id,
      title: message.clipsCreatorItem?.title,
      timestamp,
    };

    setClipsVideo(video);
  };

  return (
    <>
      <Grid container className={classes.outerContainer}>
        <GridWrapperWithBanner
          bannerTitle='AsqMe Assistant'
          bannerUseLargeTitle={true}
          bannerImageUrl={asqmeAssistantProfileImg}
          bannerShowBetaIcon={true}
          showClipsElement={showClipsElement}
          onDismissClipsElement={() => setShowClipsElement(false)}
        >
          <Grid container direction='column' className={classes.chatContainer}>
            <Grid container item alignContent="flex-start">
              {firstDraftChat?.messages.map((message, index) => {
                if (message.type === 'user') {
                  return (
                    <CreatorMessage 
                      key={`creator-message-${index}`}
                      user={user}
                      message={message.text}
                    />
                  )
                } else {
                  if (message.text === 'no-clips') {
                    return (
                      <AssistantMessageNoClips
                        key={`assistant-message-${index}`}
                        user={user}
                        showClipsElement={showClipsElement}
                        onShowClipsElement={() => setShowClipsElement(true)}
                        doNotShowClipsPrompt={doNotShowClipsPrompt}
                        setDoNotShowClipsPrompt={setDoNotShowClipsPrompt}
                      />
                    )
                  } else {
                    return (
                      <AssistantMessage 
                        key={`assistant-message-${index}`}
                        user={user}
                        
                        message={message}
                        showClipsVideo={showClipsVideo}
                      />
                    )
                  }
                }
              })}
              {isSendingQuestion && <LoadingMessage showChatGptLogo={!user.clips_enabled || firstDraftChat?.ask_chat_gpt_follow_up} />}
            </Grid>
            {/* Spacer to push the chat input to the bottom of the screen */}
            {/* Also used as an anchor for auto scrolling to latest message */}
            <Grid item ref={messagesEndRef} style={{ flexGrow: 1 }} />
            <Grid container item justifyContent='center' className={classes.helpText}>
              <span>For examples of the kinds of questions you can ask or to provide feedback, <a href='https://asq.how/assistant' target='_blank' rel='noreferrer'>click here</a>.</span>
            </Grid>
            {/* Fixed to the bottom of the screen via position 'sticky'*/}
            <MessageInputBox
              chatsUsed={firstDraftChat?.chats_used}
              chatLimit={firstDraftChat?.chat_limit}
              onSend={sendMessage}
              isSending={isSendingQuestion}
              user={user}
            />
          </Grid>
        </GridWrapperWithBanner>
      </Grid>
      <ClipsVideoPlayerDialog
        video={clipsVideo}
        offsetOnDesktop
        onClose={() => setClipsVideo(null)}
      />
    </>
  )
}

const LoadingMessage = (props) => {
  const { showChatGptLogo } = props;

  const classes = useStyles();

  return (
    <Grid container item xs={12} justifyContent='flex-start' wrap='nowrap' className={classes.messageOuterContainer}>
      <Grid item className={classes.asqMeAssistantProfileImg}>
        <img src={showChatGptLogo ? chatGptLogoImg : asqmeAssistantProfileImg} alt='Creator Profile' className={classes.messageProfileImage} />
      </Grid>
      <Grid item className={classes.assistantMessageContainer}>
        <img src={dotSpinner} alt='Spinner'></img>
      </Grid>
    </Grid>
  )
}

// Contains a link that enables the user to re enable the clips prompt
// Handles the case where the user has clicked 'do not show again' and when the user closes the element via the close button
const AssistantMessageNoClips = (props) => {
  const { user, showClipsElement, onShowClipsElement, doNotShowClipsPrompt, setDoNotShowClipsPrompt } = props;

  const classes = useStyles();

  const reShowClipsElement = () => {
    setDoNotShowClipsPrompt(false);
    onShowClipsElement();
  }

  return (
    <Grid container item xs={12} justifyContent='flex-start' wrap='nowrap' className={classes.messageOuterContainer}>
      <Grid item className={classes.asqMeAssistantProfileImg}>
        <img src={asqmeAssistantProfileImg} alt='Creator Profile' className={classes.messageProfileImage} />
      </Grid>
      <Grid item className={classes.assistantMessageContainer}>
        <p>
          Hi {user.display_name ?? ''}. Please ask your question below and I will get an answer from ChatGPT.<br /><br />
          If you want to use your own content to answer,
          {doNotShowClipsPrompt || !showClipsElement ? <a href='# ' onClick={() => reShowClipsElement(false)} className={classes.connectClipsLink}> just connect your YouTube channel. </a> : ' just connect your YouTube channel. '}
          I will even find your relevant video clips and cue them up to just the right spot so you can attach them to your answer and get more views.
        </p>
      </Grid>
    </Grid>
  )
}

const AssistantMessage = (props) => {
  const { message, showClipsVideo, user } = props;

  const classes = useStyles();

  const alert = useAlert();

  const profileImage = () => {
    if (message.clips_is_content_driven_answer) {
      return user.logo_image
    } else if (message.first_draft_answer) {
      return chatGptLogoImg;
    } else {
      return asqmeAssistantProfileImg;
    }
  }

  return (
    <Grid container item xs={12} justifyContent='flex-start' wrap='nowrap' className={classes.messageOuterContainer}>
      <Grid item className={classes.asqMeAssistantProfileImg}>
        <img src={profileImage()} alt='Creator Profile' className={classes.messageProfileImage} />
      </Grid>
      <Grid item container direction='column' alignItems='flex-start'>
        <Grid item className={classes.assistantMessageContainer}>
          <p>{message.text || message.first_draft_answer}</p>
        </Grid>
        {message.clipsCreatorItem != null && <Grid
          container
          item
          direction='column'
          className={classes.clipsAnswerContainer}
        >
          <Grid item>
            <div className={classes.clipsAnswerThumbnailContainer}>
              <img
                src={message.clipsCreatorItem?.thumbnail}
                alt='thumbnail'
                className={classes.clipsAnswerThumbnail}
              />
              <div className={classes.clipsPlayButtonContainer}>
                <ClipsPlayButton onClick={() => showClipsVideo(message)} isLarge />
              </div>
            </div>
          </Grid>
          <Grid item className={classes.clipsAnswerThumbnailTitle}>
            <span>{message.clipsCreatorItem.title}</span>
          </Grid>
        </Grid>}
        {message.first_draft_answer && <Grid item>
          <CopyToClipboard
            text={`${message.first_draft_answer}${
              message.clips_url != null
                ? `\n\nHere’s a video: ${message.clips_url}`
                : ''
            }`}
            onCopy={() => {
              alert.success(null, {
                title: 'All set',
                body: 'Answer copied to clipboard.',
                timeout: 1000 + slideoutTransitionDelay,
                offsetError: true,
              });
            }}
          >
            <div className={classes.copyLink}>
              <img src={doublePaperIcon} alt='Double Paper Icon' />
              <span>{message.clipsCreatorItem != null ? `Copy answer and ${message.clips_timestamp != null ? 'clip' : 'video'}` : 'Copy answer'}</span>
            </div>
          </CopyToClipboard>
        </Grid>}
      </Grid>
    </Grid>
  )
}

const CreatorMessage = (props) => {
  const { message, user } = props; 

  const classes = useStyles();

  return (
    <Grid container item xs={12} justifyContent='flex-end' wrap='nowrap' className={classes.messageOuterContainer}>
      <Grid item className={classes.creatorMessageContainer}>
        <p>{message}</p>
      </Grid>
      <Grid item>
        <img src={user.logo_image} alt='Creator Profile' className={classes.messageProfileImage} />
      </Grid>
    </Grid>
  )
}

const MessageInputBox = (props) => {
  const { onSend, isSending, chatsUsed, chatLimit, user } = props;

  const classes = useStyles();

  const [message, setMessage] = useState('');

  const sendMessage = () => {
    trackAppCtaClick({
      ...getPathAndPageName(),
      cta_verbiage: 'Submit a question',
    });
    onSend(message);
    setMessage('');
  }

  const isChatLimitReached = useMemo(() => {
    return chatsUsed != null && chatLimit != null && chatsUsed >= chatLimit;
  }, [chatsUsed, chatLimit])

  const isSendDisabled = useMemo(() => {
    return isSending || message.length === 0;
  }, [isSending, message])

  return (
    <Grid container item className={classes.messageInputOuterContainer}>
      <Grid container className={classes.messageInputContainer}>
        <Grid item xs={12} className={classes.textFieldContainer}>
          <TextField
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            fullWidth
            multiline
            placeholder='What do you want to ask?'
            InputProps={{
              disableUnderline: true,
            }}
            className={classes.textField}
          />
        </Grid>
        <Grid container item justifyContent='flex-end' className={classes.controlsContainer}>
          {(!user.is_subscribed && chatsUsed != null && chatLimit != null) && 
          <>
            <Grid item className={classes.firstDraftIcon}>
              <img src={firstDraftIcon} alt='FirstDraft Icon' />
            </Grid>
            <Grid item className={classes.firstDraftQuotaText}>
              <span>{chatLimit - chatsUsed}/{chatLimit} Conversations Left</span>
            </Grid>
          </>}
          <Grid item className={classes.sendButtonContainer}>
            {isChatLimitReached && !user.is_subscribed ? (
              <ButtonBase
                to='/upgrade'
                component={Link}
                className={classes.upgradeButton}
              >
                Go Pro!
              </ButtonBase>
            ) : (
            <ButtonBase
              onClick={sendMessage}
              disabled={isSendDisabled}
            >
              <PaperPlaneIcon
                width={20}
                height={20}
                fillColor={!isSendDisabled ? '#495EFF' : '#666666'}
              />
            </ButtonBase>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default FirstDraft;