import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
import { string, element, bool } from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { NewLogoIcon } from 'components/Icons';
import useAppData from 'hooks/context';
import squareImg from 'assets/images/squares.svg';

const useStyles = makeStyles((theme) => ({
  header: {
    zIndex: 2,
    backgroundColor: theme.common.black,
    padding: theme.spacing(1),
  },
  newLogo: {},
}));

const inboxEnabled = process.env.REACT_APP_ENABLE_INBOX === 'true';

const BaseHeader = ({ className, leftIcon, rightIcon, hideRightIcon, centerElement }) => {
  const classes = useStyles();
  const {
    premiumYellow,
    common: { white },
  } = useTheme();

  const navigate = useNavigate();
  const { user } = useAppData();

  const newLogoColorProps = user?.is_subscribed_and_profile_completed
    ? { strokeColor: premiumYellow, fillColor: white }
    : {};

  const logoNavigationUrl = inboxEnabled && user?.is_profile_completed ? '/inbox' : '/profile';

  return (
    <Grid
      item
      container
      justifyContent='space-between'
      alignItems='center'
      className={`${classes.header} ${className || ''}`}
    >
      <Grid item>
        {leftIcon || (
          <NewLogoIcon
            {...newLogoColorProps}
            onClick={() => navigate(logoNavigationUrl)}
            className={classes.newLogo}
          />
        )}
      </Grid>
      {centerElement || null}
      <Grid item>{hideRightIcon ? null : rightIcon || <img src={squareImg} alt='square' />}</Grid>
    </Grid>
  );
};

BaseHeader.propTypes = {
  className: string,
  leftIcon: element,
  rightIcon: element,
  centerElement: element,
  hideRightIcon: bool,
};

export default BaseHeader;
