const File = ({
  ...rest
}) => (
  <svg 
    width="14" 
    height="18" 
    viewBox="0 0 14 18" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path d="M2.84615 0C1.32091 0 0.0769196 1.24399 0.0769196 2.76923V15.2308C0.0769196 16.756 1.32091 18 2.84615 18H11.1538C12.6791 18 13.9231 16.756 13.9231 15.2308V5.53846C13.9231 4.80288 13.2497 4.10517 12.0409 2.92067C11.8732 2.75571 11.692 2.57182 11.5216 2.40144C11.3513 2.23107 11.1674 2.07151 11.0024 1.90385C9.81791 0.695012 9.12019 0 8.38461 0H2.84615ZM2.84615 1.38462H7.88702C8.38732 1.51172 8.38461 2.11208 8.38461 2.72596V4.84615C8.38461 5.22746 8.69561 5.53846 9.07692 5.53846H11.1538C11.8434 5.53846 12.5385 5.54117 12.5385 6.23077V15.2308C12.5385 15.9961 11.9192 16.6154 11.1538 16.6154H2.84615C2.08083 16.6154 1.46153 15.9961 1.46153 15.2308V2.76923C1.46153 2.00391 2.08083 1.38462 2.84615 1.38462ZM4.92307 6.23077C4.15775 6.23077 3.53846 6.85006 3.53846 7.61539C3.53846 8.38071 4.15775 9 4.92307 9C5.6884 9 6.30769 8.38071 6.30769 7.61539C6.30769 6.85006 5.6884 6.23077 4.92307 6.23077ZM9.05529 8.41587C8.23317 8.41587 7.94381 11.8125 6.80528 11.8125C5.96153 11.8125 5.63431 10.4279 4.92307 10.4279C4.21184 10.4279 2.93269 13.3269 2.93269 13.3269C2.67037 13.619 3.0625 13.8462 3.36538 13.8462H10.6779C10.924 13.8462 11.2404 13.5325 11.0457 13.1106C11.0457 13.1106 9.8774 8.41587 9.05529 8.41587Z" fill="#454545"/>
  </svg>
);

export default File;