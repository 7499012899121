import { methods, fetchApi } from 'utils/apiConfig';
const { GET, PATCH } = methods;

const queryProfileTypes = {
  profile: 'profile',
  initBalance: 'initBalance',
};

const fetchProfile = ({ signal } = {}) => fetchApi('/auth/profile', GET, signal ? { signal } : {});

const saveProfile = (data) => fetchApi('/auth/profile', PATCH, { data });

const checkIfTagExists = (params) => fetchApi('/auth/check_if_AsqMeTag_exists', GET, { params });

const fetchTranslationLanguages = ({ signal }) =>
  fetchApi('/google-translate-service/get_supported_languages', GET, {
    headers: {
      chinawall: process.env.REACT_APP_CHINA_WALL,
    },
    signal,
  });

const updateTranslatedLanguage = (data) =>
  fetchApi('/creator/updateLanguageSettings', PATCH, { data });

export {
  queryProfileTypes,
  fetchProfile,
  saveProfile,
  checkIfTagExists,
  fetchTranslationLanguages,
  updateTranslatedLanguage,
};
