import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { AppDataContext } from 'Context';
import { useContext, useEffect, useState } from 'react';
import { fetchApi } from 'utils/apiConfig';

const states = {
  default: 0,
  cancelling: 1,
  error: 2,
  cancelled: 3,
};
export default function CancelSubscriptionDialog({ isOpen, setIsOpen }) {
  const [state, setState] = useState(states.default);
  const { reloadProfile } = useContext(AppDataContext);

  // We don't immediately reload the profile after the cancellation is complete because that will stop rendering the component
  // that includes CancelSubscriptionDialog, hiding the "cancelled" state message
  useEffect(() => {
    if (state === states.cancelled && !isOpen) {
      reloadProfile();
    }
  }, [reloadProfile, state, isOpen]);

  function closeDialog() {
    setIsOpen(false);
    // Delay to avoid changing text while visible
    setTimeout(() => {
      setState(states.default);
    });
  }

  function dialogTitle() {
    switch (state) {
      case states.default:
        return 'Cancel Subscription';
      case states.cancelling:
        return 'Cancelling...';
      case states.error:
        return 'Error Occurred';
      case states.cancelled:
        return 'Cancellation Confirmed';
      default:
        return 'Unhandled state';
    }
  }

  async function handleEndSub() {
    setState(states.cancelling);
    try {
      await fetchApi('/creator/subscribe/cancel', 'POST');
      setState(states.cancelled);
    } catch (ex) {
      setState(states.error);
    }
  }

  function dialogContent() {
    switch (state) {
      case states.default:
        return (
          <>
            Are you sure you want to cancel?
            <br />
            <br />
            You will lose access to TipJar, offering complimentary answers, keeping 100% of your
            earnings, zero transaction fees, and more.
          </>
        );
      case states.cancelling:
        return <CircularProgress />;
      case states.error:
        return <>Failed to cancel subscription. Please try again later</>;
      case states.cancelled:
        return (
          <>
            We are sorry to see you go. Your account will be downgraded to the AsqMe Starter Plan at
            the end of your current subscription.
            <br />
            <br />
            You may re-subscribe to Pro at any time.
          </>
        );
      default:
        return <>Unhandled state</>;
    }
  }

  return (
    <Dialog open={isOpen}>
      <DialogTitle>{dialogTitle()}</DialogTitle>
      <DialogContent>{dialogContent()}</DialogContent>
      <DialogActions>
        {(state === states.default || state === states.error) && (
          <>
            <Button onClick={handleEndSub}>End subscription</Button>
            <Button onClick={closeDialog}>Stay subscribed</Button>
          </>
        )}
        {state === states.cancelled && <Button onClick={closeDialog}>OK</Button>}
      </DialogActions>
    </Dialog>
  );
}
