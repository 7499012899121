import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import BaseHeader from 'components/Header/BaseHeader';
import { WhiteLogoWhiteContentIcon } from 'components/Icons';
import closeImg from 'assets/images/close-circle.svg';
import ThankBank from 'routes/Public/ThankBank';

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: theme.blue,
    paddingBottom: 0,
  },
  previewHeaderText: {
    backgroundColor: theme.common.black,
    color: theme.common.white,
    fontFamily: 'Figtree-Bold',
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1.5)}px`,
    borderRadius: 10,
  },
  drawer: {
    overflow: 'hidden',
  },
  paper: {
    overflowX: 'hidden',
    height: '100%',
  },
}));

const ThankBankPreview = (props) => {
  const { user, open, onClose } = props;
  
  const classes = useStyles();

  return (
    <Drawer
      classes={{ root: classes.drawer, paperAnchorTop: classes.paper }}
      transitionDuration={0}
      anchor='top'
      open={open}
      onClose={onClose}
    >
      <BaseHeader
        className={classes.header}
        leftIcon={<WhiteLogoWhiteContentIcon />}
        centerElement={
          <div className={classes.previewHeaderText}>PREVIEWING THANKBANK</div>
        }
        rightIcon={<img src={closeImg} onClick={onClose} alt='' />}
      />
      <ThankBank previewMode={true} user={user} />
    </Drawer>
  );
}

export default ThankBankPreview;