import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import NavigationMenu from 'components/NavigationMenu';
import AsqMeCreatorHeader from 'components/Header/AsqMeCreatorHeader';
import { AppAuthHeader } from 'components/Header';
import ClipsElement from 'components/Clips/ClipsElement';


const useStyles = makeStyles((theme) => ({
  navContainer: {
    maxWidth: 176,
  },
  contentContainer: {
    position: 'relative',
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
      overflowY: 'initial',
      overflowX: 'clip',
    }
  },
  clipsContainer: {
    padding: '0 30px',
    marginBottom: 20,
    '&:empty': {
      padding: 0,
      margin: 0,
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0 20px',
    }
  },
  innerContent: {
    paddingTop: 160,
    flexGrow: 1,
  },
  innerContainer: {
    flexGrow: 1,
  }
}));

const GridWrapperWithBanner = (props) => {
  const { 
    children, 
    bannerTitle,
    bannerUseLargeTitle,
    bannerImageUrl, 
    bannerShowLogo, 
    bannerShowBetaIcon,
    rootLogoProps, 
    inputLogoProps, 
    isPhotoUploadLoading,
    showClipsElement,
    onDismissClipsElement,
  } = props;

  const { breakpoints } = useTheme();
  const classes = useStyles();
  const mobileView = useMediaQuery(breakpoints.down('xs'));

  return (
    <>
      {!mobileView && (
        <Grid item xs className={classes.navContainer}>
          <NavigationMenu />
        </Grid>
      )}
      <Grid container item sm xs={12} className={classes.contentContainer} direction='column'>
        {/* black header - navigation menu toggler */}
        <AppAuthHeader />
        <Grid item>
          <AsqMeCreatorHeader
            title={bannerTitle}
            useLargeTitle={bannerUseLargeTitle}
            iconUrl={bannerImageUrl}
            showLogo={bannerShowLogo}
            showBetaIcon={bannerShowBetaIcon}
            rootLogoProps={rootLogoProps}
            inputLogoProps={inputLogoProps}
            isPhotoUploadLoading={isPhotoUploadLoading}
          />
        </Grid>
        <Grid container item justifyContent='center' className={classes.innerContent} direction='column'>
          {showClipsElement && <Grid item className={classes.clipsContainer}>
            <ClipsElement
                showCloseButton
                showDoNotShowAgainButton
                showElementIn={showClipsElement}
                onDismiss={() => onDismissClipsElement && onDismissClipsElement()}
            />
          </Grid>}
          <Grid container item justifyContent='center' className={classes.innerContainer}>
            <Grid item md={7} sm={8} xs={12}>
              {children}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
};

export default GridWrapperWithBanner;