import { number, object, string } from 'prop-types';
import common from '@material-ui/core/colors/common';

const AsqMeTextLogo = ({
  className = '',
  style = {},
  width = 97,
  height = 28,
  strokeColor = common.white,
  fillColor = '#495EFF',
  ...restProps
}) => (
  <svg
    {...restProps}
    style={style}
    className={className}
    width={width}
    height={height}
    viewBox='0 0 97 28'
    fill='none'
  >
    <g clipPath='url(#clip0_3884_5127)'>
      <path
        d='M0.123124 19.5453L6.96785 1.15184C7.23088 0.394309 7.78654 0 8.60608 0H10.6833C11.502 0 12.0289 0.394309 12.3215 1.15184L19.2542 19.5461C19.6052 20.5458 19.1958 21.2125 18.1428 21.2125H16.2416C15.4516 21.2125 14.896 20.8485 14.6034 20.0607L13.58 17.2727H5.68229L4.68768 20.0304C4.39505 20.8182 3.86899 21.2125 3.07905 21.2125H1.2353C0.182328 21.2125 -0.227019 20.5458 0.12397 19.5461L0.123124 19.5453ZM12.642 13.485L9.6582 4.93949L6.58725 13.485H12.642Z'
        fill={strokeColor}
      />
      <path
        d='M20.8316 18.4245C20.3927 17.5157 20.8612 16.8489 21.7679 16.606L22.6745 16.3941C23.4645 16.1822 23.8155 16.5757 24.4295 17.2122C24.9564 17.8183 25.7455 18.121 26.6817 18.121C27.8227 18.121 28.6126 17.5754 28.6126 16.7573C28.6126 16.0906 28.1737 15.7576 27.2086 15.3936L25.3657 14.7269C23.8155 14.2115 21.1243 13.0302 21.1243 10.212C21.1243 7.39372 23.4645 5.30273 26.7113 5.30273C28.6422 5.30273 30.3972 5.93919 31.5085 7.66613C32.0937 8.54474 31.6548 9.33254 30.6898 9.5755L29.8711 9.78738C29.1108 9.99926 28.7014 9.72684 28.1745 9.21146C27.7652 8.78688 27.2382 8.63554 26.7122 8.63554C25.7759 8.63554 25.1915 9.24173 25.1915 9.96899C25.1915 10.6357 25.7768 10.9687 26.5659 11.2419L28.4375 11.9692C31.5381 13.0302 32.6494 14.7571 32.7078 16.6665C32.7078 19.9396 29.8998 21.5455 26.653 21.5455C23.9914 21.5455 21.8271 20.5155 20.8325 18.4245H20.8316Z'
        fill={strokeColor}
      />
      <path
        d='M46.5133 26.5455V18.9997C45.4307 20.7569 43.6757 21.5455 41.6874 21.5455C37.4755 21.5455 34.4629 18.0303 34.4629 13.4245C34.4629 8.81883 37.4755 5.30359 41.6874 5.30359C43.6766 5.30359 45.4315 6.09139 46.5133 7.81833V7.09107C46.5133 6.15193 47.0106 5.63654 47.9172 5.63654H49.6426C50.5492 5.63654 51.0465 6.15193 51.0465 7.09107V26.5455C51.0465 27.4847 50.5492 28.0001 49.6426 28.0001H47.9172C47.0106 28.0001 46.5133 27.4847 46.5133 26.5455ZM46.5716 13.4245C46.5716 10.9401 45.0509 9.06097 42.7395 9.06097C40.428 9.06097 38.9953 11.0309 38.9953 13.4245C38.9953 15.8182 40.3697 17.7881 42.7395 17.7881C45.1093 17.7881 46.5716 15.909 46.5716 13.4245Z'
        fill={strokeColor}
      />
      <path
        d='M54.352 19.6663L55.9902 1.39399C56.0486 0.485114 56.5755 0 57.4526 0H59.5585C60.3772 0 60.8745 0.363222 61.1967 1.15184L66.4032 14.3334L71.6393 1.15184C71.9319 0.36404 72.458 0 73.2479 0H75.3835C76.2614 0 76.7578 0.485114 76.8458 1.39399L78.484 19.6663C78.572 20.666 78.0747 21.2117 77.1384 21.2117H75.3539C74.4472 21.2117 73.9499 20.7266 73.862 19.8177L72.9257 8.24203L68.4212 19.8177C68.1286 20.6055 67.6025 20.9695 66.8126 20.9695H66.0226C65.2327 20.9695 64.7066 20.6063 64.414 19.8177L59.9095 8.24203L58.9445 19.8177C58.8861 20.7266 58.3592 21.2117 57.4822 21.2117H55.6976C54.7614 21.2117 54.2353 20.666 54.352 19.6663ZM80.765 13.4842C80.765 9.09037 83.9535 5.30271 89.0137 5.30271C93.2839 5.30271 96.7947 7.96961 96.9994 12.9394C97.029 13.818 96.4437 14.2123 95.5658 14.2123H85.0945C85.0361 16.0611 86.8198 17.8486 89.7157 17.8486C90.7983 17.8486 91.9096 17.5762 93.0505 16.9095C93.8405 16.4546 94.4841 16.5151 95.0397 17.2121L95.3611 17.6367C95.9464 18.364 95.888 19.1821 95.0981 19.7882C93.3728 21.0914 91.3539 21.5454 89.4239 21.5454C84.0415 21.5454 80.7659 17.9394 80.7659 13.485L80.765 13.4842ZM92.7579 11.6051C92.3485 9.51413 90.6223 8.8474 89.0721 8.8474C87.5218 8.8474 85.7085 9.57466 85.2112 11.6051H92.7579Z'
        fill={fillColor}
      />
    </g>
    <defs>
      <clipPath id='clip0_3884_5127'>
        <rect width={width} height={height} fill={strokeColor} />
      </clipPath>
    </defs>
  </svg>
);

AsqMeTextLogo.propTypes = {
  style: object,
  className: string,
  height: number,
  width: number,
};

export default AsqMeTextLogo;
