import { makeStyles } from "@material-ui/core"
import { ThankBankLogoNoBg } from "components/Icons";

const useStyles = makeStyles((theme) => ({
  outer: {
    height: 74,
    minWidth: 74,
    padding: 5,
    borderRadius: 100,
    backgroundColor: 'rgba(73, 94, 255, 0.1)'
  },
  inner: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: 64,
    borderRadius: 100,
    backgroundColor: 'rgba(73, 94, 255, 0.1)',
  },
  fixedWidth: {
    width: 64,
  },
  price: {
    display: 'inline-flex',
    justifyContent: 'center',
    fontFamily: 'Figtree-Bold',
    fontSize: 32,
    lineHeight: '38.4px',
    padding: '0 10px',
    letterSpacing: -2,
  },
  logo: {
    width: "100%",
  }
}));

export const QuestionPriceDisplay = (props) => {
  const { formattedPrice } = props;

  const classes = useStyles();

  return (
    <div className={classes.outer}>
      <div className={classes.inner}>
        <span className={classes.price}>{formattedPrice ?? '$_'}</span>
      </div>
    </div>
  )
};

export const QuestionTipPriceDisplay = () => {
  const classes = useStyles();

  return (
    <div className={classes.outer}>
      <div className={`${classes.inner} ${classes.fixedWidth}`}>
        <ThankBankLogoNoBg className={classes.logo} />
      </div>
    </div>
  )
}