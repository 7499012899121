import { useCallback, useEffect, useMemo, useState, useContext } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useAlert } from 'react-alert';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import Grid from '@material-ui/core/Grid';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import GridWrapper from 'components/GridWrapper';
import { AppAuthHeader } from 'components/Header';
import InfoDrawer from './InfoDrawer';
import infoImg from 'assets/images/info.svg';
import thunderImg from 'assets/images/thunder.svg';
import successImg from 'assets/images/success.svg';
import stripeImg from 'assets/images/stripe.svg';
import { texts } from './constants';
import { getBalance, withdraw, queryCreatorTypes } from 'store/creator';
import { errorStatuses } from 'utils/apiConfig';
import styles from './styles';
import { AppDataContext } from 'Context';
import useAppData from 'hooks/context';
import { updateMessenger } from 'utils/intercomConfig';
import { formatPrice } from 'utils/currency-utils';
import { defaultAlertError } from 'utils/common/constants';

const useStyles = makeStyles(styles);

const Balance = () => {
  const alert = useAlert();
  const classes = useStyles();

  const { breakpoints } = useTheme();
  const mobileView = useMediaQuery(breakpoints.down('xs'));

  const queryClient = useQueryClient();
  const { user } = useAppData();

  const { reloadProfile, updateBalance } = useContext(AppDataContext);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerType, setDrawerType] = useState('');

  // hide intercom launcher when a drawer is open
  useEffect(() => {
    if (drawerOpen) updateMessenger({ hide_default_launcher: true });
    else updateMessenger({ hide_default_launcher: false });
  }, [drawerOpen]);

  const {
    data,
    isFetching: balanceIsLoading,
    error: balanceError,
  } = useQuery({
    queryKey: [queryCreatorTypes.balance],
    queryFn: getBalance,
    onSuccess: (resp) => {
      updateBalance(resp);
    },
    onError: (err) => {
      if (err.status !== errorStatuses.UNAUTHORIZED)
        alert.error(null, {...defaultAlertError, offsetError: true });
    },
  });

  // transforms every key-value pair from api response (amount related):
  // 1. Transforms existing key-value pairs for display purposes (float: amount < $1, integer: amount >= $1)
  // 2. For every existing key-value pair,
  // it creates a clone with appended key name (int) and with float value (used only for checks)
  const balance = useMemo(() => {
    if (!data) return null;

    const { url, is_withdraw_amount_instant, ...restData } = data;

    // Refresh the user's data after successfully activating the stripe account
    if (url == null && !user.connected_account_enabled) {
      reloadProfile();
    }

    const transformedData = Object.keys(restData).reduce((obj, key) => {
      obj[`${key}_number`] = parseFloat(restData[key]);
      obj[key] = restData[key];
      return obj;
    }, {});

    return { url, is_withdraw_amount_instant, ...transformedData };
  }, [data]);

  
  const currencyInfo = balance?.currency_info;

  const {
    mutate: onWithdraw,
    reset,
    data: withdrawalSuccess,
    isLoading: withdrawIsLoading,
  } = useMutation({
    mutationFn: withdraw,
    onError: (err) => {
      if (err.status !== errorStatuses.UNAUTHORIZED) 
        alert.error(null, {...defaultAlertError, offsetError: true });
    },
  });

  // unmounting clean up
  useEffect(
    () => () => {
      queryClient.removeQueries({ queryKey: [queryCreatorTypes.balance] });
    },
    [],
  );

  const proceedAmountTransferFlow = useCallback(() => {
    if (!!withdrawalSuccess) {
      queryClient.removeQueries({ queryKey: [queryCreatorTypes.balance] });
      reset();
    } else onWithdraw();
  }, [withdrawalSuccess]);

  const { incufficientFunds, showHelperDisclaimer, disableProceedButton } = useMemo(
    () => ({
      incufficientFunds: balance ? balance.available_for_withdraw_number < balance.minimum_payout : false,
      showHelperDisclaimer: balance
        ? !!balance.url ||
          !!balance.available_for_withdraw_number ||
          balance.available_for_withdraw_number < 1
        : false,
      disableProceedButton: balance
        ? !!balance.url || balance.available_for_withdraw_number < balance.minimum_payout
        : false,
    }),
    [balance],
  );

  return (
    <Grid container className={classes.balanceContainer}>
      <InfoDrawer
        open={drawerOpen}
        textType={drawerType}
        mobileType={mobileView}
        onClose={() => setDrawerOpen(false)}
      />
      <GridWrapper>
        {balanceIsLoading ? (
          <Backdrop open className={classes.backdrop}>
            <CircularProgress color='inherit' thickness={3} size={70} />
          </Backdrop>
        ) : null}
        <Grid
          container
          direction='column'
          justifyContent='space-between'
          className={classes.innerContainer}
        >
          {/* black header - navigation menu toggler */}
          <AppAuthHeader />
          {/* success screen */}
          {!!withdrawalSuccess ? (
            <Fade in>
              <Grid
                item
                container
                direction='column'
                justifyContent='center'
                alignItems='center'
                className={classes.priceSectionContainer}
                spacing={1}
              >
                <Grid item className={classes.amountTransferedContainer}>
                  <img src={successImg} alt='' className={classes.absoluteCheckIcon} />
                  <div className={classes.amountTransfered}>
                    {formatPrice(balance?.available_for_withdraw_number, { currency: currencyInfo?.code, hideDecimals: currencyInfo?.isZeroDecimal, isThreeDecimal: currencyInfo?.isThreeDecimal })}
                  </div>
                </Grid>
                <Grid item>
                  <div className={classes.successMessage}>Withdrawal in process...</div>
                  <div className={classes.successHelperText}>
                    Funds will be on their way shortly.
                  </div>
                </Grid>
              </Grid>
            </Fade>
          ) : (
            <>
              {/* title section */}
              <Grid item container>
                <Grid item xs />
                <Grid item xs={10}>
                  <Grid container direction='column'>
                    {/* title */}
                    <Grid item container direction='column' spacing={1}>
                      <Grid item>
                        <div className={`${classes.header} ${!mobileView ? 'spaceY' : ''}`}>
                          {texts.title}
                        </div>
                      </Grid>
                      <Grid item>
                        <hr className={classes.hr} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs />
              </Grid>
              {/* price section */}
              <Grid item className={classes.priceSectionContainer}>
                {!!balance ? (
                  <Fade in>
                    <Grid
                      container
                      direction='column'
                      alignItems='center'
                      spacing={1}
                      className={classes.priceSectionInnerContainer}
                    >
                      {/* money */}
                      <Grid item>
                        <div className={classes.priceContainer}>
                          {formatPrice(balance.net_earnings, { currency: currencyInfo?.code, hideDecimals: currencyInfo?.isZeroDecimal, isThreeDecimal: currencyInfo?.isThreeDecimal, appendCurrency: true })}
                        </div>
                      </Grid>
                      {!user.connected_account_enabled && user.currency_code !== 'USD' && (
                        <Grid item>
                          <div>{texts.balanceDisclaimer}</div>
                        </Grid>
                      )}
                      {/* earnings */}
                      <Grid item container className={classes.earningsContainer}>
                        <Grid item xs />
                        <Grid item xs={10}>
                          <Grid container direction='column' spacing={2}>
                            <Grid
                              item
                              container
                              justifyContent='space-between'
                              alignItems='center'
                              className={`${classes.row} ${texts.firstRowClassName}`}
                            >
                              <Grid item>
                                <div>{texts.firstRowText}</div>
                              </Grid>
                              <Grid item>
                                <div>{formatPrice(balance.total_earnings, { currency: currencyInfo?.code, hideDecimals: currencyInfo?.isZeroDecimal, isThreeDecimal: currencyInfo?.isThreeDecimal })}</div>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              container
                              justifyContent='space-between'
                              alignItems='center'
                              className={`${classes.row} ${texts.secondRowClassName}`}
                            >
                              <Grid item>
                                <div>{texts.secondRowText}</div>
                              </Grid>
                              <Grid item>
                                <div>-{formatPrice(balance.asqme_fees, { currency: currencyInfo?.code, hideDecimals: currencyInfo?.isZeroDecimal, isThreeDecimal: currencyInfo?.isThreeDecimal })}</div>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              container
                              justifyContent='space-between'
                              alignItems='center'
                              className={`${classes.row} ${texts.thirdRowClassName}`}
                            >
                              <Grid item>
                                <div>{texts.thirdRowText}</div>
                              </Grid>
                              <Grid item>
                                <div>{formatPrice(balance.net_earnings, { currency: currencyInfo?.code, hideDecimals: currencyInfo?.isZeroDecimal, isThreeDecimal: currencyInfo?.isThreeDecimal })}</div>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              container
                              justifyContent='space-between'
                              alignItems='center'
                              className={`${classes.row} ${texts.fourthRowClassName}`}
                            >
                              <Grid item>
                                <div className={classes.fourthContainer}>
                                  {texts.fourthRowText}{' '}
                                  <img
                                    src={infoImg}
                                    onClick={() => {
                                      setDrawerType('earnings');
                                      setDrawerOpen(true);
                                    }}
                                    className={classes.infoIcon}
                                    alt='info'
                                  />
                                </div>
                              </Grid>
                              <Grid item>
                                <div>
                                  {formatPrice(balance.available_for_withdraw, { currency: currencyInfo?.code, hideDecimals: currencyInfo?.isZeroDecimal, isThreeDecimal: currencyInfo?.isThreeDecimal })}
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs />
                      </Grid>
                    </Grid>
                  </Fade>
                ) : null}
              </Grid>
            </>
          )}
          {/* withdraw section */}
          <Grid item container>
            <Grid item xs />
            <Grid item xs={10} className={classes.btnContainer}>
              {/* disclaimer text */}
              {showHelperDisclaimer && !withdrawalSuccess ? (
                <Grid
                  container
                  justifyContent='center'
                  alignItems='center'
                  className={classes.bottomInfoContainer}
                >
                  {!!balance.url ? (
                    <>
                      <Grid item>
                        <img src={stripeImg} alt='stripe' className={classes.stripeImg} />
                      </Grid>
                      <Grid item>
                        <a href={balance.url} className={classes.href}>
                          Activate to Withdraw Funds
                        </a>
                      </Grid>
                    </>
                  ) : incufficientFunds ? (
                    <>
                      <Grid item>
                        <div className={classes.notEnoughFunds}>
                          <span>{formatPrice(balance.minimum_payout, { currency: currencyInfo?.code, hideDecimals: currencyInfo?.isZeroDecimal, isThreeDecimal: currencyInfo?.isThreeDecimal })}</span> minimum required to
                          withdraw.
                        </div>
                      </Grid>
                    </>
                  ) : balance.is_withdraw_amount_instant ? (
                    <>
                      <Grid item>
                        <img src={thunderImg} alt='thunder' className={classes.thunderImg} />
                      </Grid>
                      <Grid item>
                        <div className={classes.instantAvailableText}>
                          Funds available instantly.
                        </div>
                      </Grid>
                    </>
                  ) : !balance.is_withdraw_amount_instant ? (
                    <>
                      <Grid item>
                        <div className={classes.instantAvailableText}>
                          Funds available in 2-3 business days.
                        </div>
                      </Grid>
                    </>
                  ) : null}
                </Grid>
              ) : null}
              <Button
                className={classes.saveBtn}
                fullWidth
                size='large'
                onClick={proceedAmountTransferFlow}
                disabled={
                  balanceIsLoading || !!balanceError || disableProceedButton || withdrawIsLoading
                }
              >
                {withdrawIsLoading ? (
                  <CircularProgress color='inherit' size={31} />
                ) : !!withdrawalSuccess ? (
                  'Back to balance'
                ) : (
                  texts.button(
                    balanceIsLoading || !!balanceError
                      ? 'loading'
                      : disableProceedButton
                      ? 'disable'
                      : formatPrice(balance?.available_for_withdraw_number, { currency: currencyInfo?.code, hideDecimals: currencyInfo?.isZeroDecimal, isThreeDecimal: currencyInfo?.isThreeDecimal }),
                  )
                )}
              </Button>
            </Grid>
            <Grid item xs />
          </Grid>
        </Grid>
      </GridWrapper>
    </Grid>
  );
};

export default Balance;
