import { useContext, useMemo } from 'react';
import { AppDataContext } from 'Context';
import { ButtonBase, CircularProgress, Dialog, makeStyles } from "@material-ui/core"
import { useMutation } from '@tanstack/react-query';
import AsqButton from "routes/Subscribe/asq-button";
import { CloseIconAlt } from "components/Icons";
import { trackAppCtaClick } from 'store/creator';
import { disconnectClips } from "store/creator";
import { googleDeauthenticate } from "store/auth/google";
import { useAlert } from 'react-alert';
import { defaultAlertError } from 'utils/common/constants';

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      borderRadius: 20,
    }
  },
  dialogContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    padding: 20,
    '& p': {
      fontFamily: 'Figtree-Regular',
      fontSize: 14,
      lineHeight: '19.07px',
      marginBottom: 20,
    }
  },
  dialogHeaderContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: 10,
    '& h2': {
      fontFamily: 'Figtree-Bold',
      fontSize: 16,
      lineHeight: '19px',
      textAlign: 'left',
      display: 'inline-flex',
      alignItems: 'center',
      marginBottom: 0,
    },
    '& button': {
      minWidth: 'auto',
      minHeight: 'auto',
      padding: 0,
      alignItems: 'start',
      '& svg': {
        width: 18,
        height: 18,
      }
    },
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    gap: 10,
  },
  disconnectButton: {
    fontFamily: 'Figtree-Bold',
    fontSize: 16,
    lineHeight: '19.2px',
    textDecoration: 'underline',
    padding: '0 20px',
  },
  spinner: {
    margin: '0 26px',
  }
}));

export const ClipsDisconnectDialog = (props) => {
  const { open, onClose } = props;

  const classes = useStyles();

  const alert = useAlert();

  const { reloadProfile } = useContext(AppDataContext);

  const { mutate: onDisconnectClips, isClipsDisconnectLoading } = useMutation({
    mutationFn: disconnectClips,
    onSuccess: () => {
      onDeauthenticateGoogle();
    },
    onError: (err) => {
      console.error(err.response?.data);

      alert.error(null, {...defaultAlertError, offsetError: true });
    },
  });

  const { mutate: onDeauthenticateGoogle, isGoogleDeauthLoading } = useMutation({
    mutationFn: googleDeauthenticate,
    onSuccess: () => {
      reloadProfile();
      onClose();
    },
    onError: (err) => {
      console.error(err.response?.data);

      alert.error(null, {...defaultAlertError, offsetError: true });
    },
  });

  const isLoading = useMemo(() => isClipsDisconnectLoading || isGoogleDeauthLoading, 
    [isClipsDisconnectLoading, isGoogleDeauthLoading]);

  return (
    <Dialog
      open={open}
      className={classes.dialog}
      fullWidth={true}
      maxWidth="xs"
    >
      <div className={classes.dialogContainer}>
        <div className={`${classes.dialogHeaderContainer} ${classes.redirectMessageHeaderMargin}`}>
          <h2>You’re about to disconnect from your Youtube Account</h2>
          <ButtonBase onClick={onClose} disabled={isLoading}><CloseIconAlt /></ButtonBase>
        </div>
        <p className={classes.redirectMessageParagraphMargin}>
          By continuing you’ll lose access to personalized First Draft answers and relevant clips in your responses. Reminder: we never sell or share your data.
        </p>
        <div className={classes.buttonContainer}>
          <ButtonBase
            className={classes.disconnectButton}
            disabled={isLoading}
            onClick={() => {
              onDisconnectClips();
              trackAppCtaClick({
                url: window.location.pathname,
                page_name: 'Disconnect Pop-up',
                cta_verbiage: 'Disconnect'
              })
            }}
          >
            { isLoading ? <CircularProgress size={31} className={classes.spinner} /> : 'Disconnect' }
          </ButtonBase>
          <AsqButton 
            fullWidth={true}
            disabled={isLoading}
            onClick={() => {
              onClose();
              trackAppCtaClick({
                url: window.location.pathname,
                page_name: 'Disconnect Pop-up',
                cta_verbiage: 'Cancel'
              })
            }}
          >
            Cancel
          </AsqButton>
        </div>
      </div>
    </Dialog>
  )
}