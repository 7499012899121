import { number, object, string } from 'prop-types';

const InboxTiktokIcon = ({
  className = '',
  style = {},
  width = 42,
  height = 42,
  fillColor = '#7E8DFF',
  ...rest
}) => (
  <svg
    {...rest}
    className={className}
    style={style}
    width={width}
    height={height}
    viewBox='0 0 42 42'
  >
    <path
      d='M37 0H5C2.243 0 0 2.243 0 5V37C0 39.757 2.243 42 5 42H37C39.757 42 42 39.757 42 37V5C42 2.243 39.757 0 37 0ZM33.006 18.323C32.779 18.344 32.549 18.358 32.316 18.358C29.693 18.358 27.388 17.009 26.047 14.97C26.047 20.319 26.047 26.405 26.047 26.507C26.047 31.216 22.229 35.034 17.52 35.034C12.811 35.034 8.993 31.216 8.993 26.507C8.993 21.798 12.811 17.98 17.52 17.98C17.698 17.98 17.872 17.996 18.047 18.007V22.209C17.872 22.188 17.7 22.156 17.52 22.156C15.116 22.156 13.168 24.104 13.168 26.508C13.168 28.912 15.116 30.86 17.52 30.86C19.924 30.86 22.047 28.966 22.047 26.562C22.047 26.467 22.089 6.968 22.089 6.968H26.105C26.483 10.559 29.382 13.393 33.006 13.653V18.323Z'
      fill={fillColor}
    />
  </svg>
);

InboxTiktokIcon.propTypes = {
  style: object,
  className: string,
  height: number,
  width: number,
  fillColor: string,
  strokeColor: string,
};

export default InboxTiktokIcon;
