import { useContext } from 'react';
import { AppDataContext } from 'Context';

// hook that returns app Data from provider
const useAppData = () => {
  const { appData } = useContext(AppDataContext);

  return appData;
};

export default useAppData;
