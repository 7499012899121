// Features per price selection at informational popups
import { popUpFeatures } from 'utils/common/constants';

const featuresPerPriceTypes = {
  free: {
    features: [
      popUpFeatures.complimentaryAnswers,
      popUpFeatures.tipJar,
      popUpFeatures.noRevshare,
      popUpFeatures.zeroFees,
      popUpFeatures.translation,
      popUpFeatures.clips,
    ],
  },
  tip: {
    features: [
      popUpFeatures.tipJar,
      popUpFeatures.complimentaryAnswers,
      popUpFeatures.noRevshare,
      popUpFeatures.zeroFees,
      popUpFeatures.translation,
      popUpFeatures.clips,
    ],
  },
};

export { featuresPerPriceTypes };
