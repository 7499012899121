import { Grid, makeStyles } from '@material-ui/core';
import yotuubeLogo from 'assets/images/youtube-logo-red.svg';
import arrows from 'assets/images/right-left-arrows.svg';
import logo from 'assets/images/logo-blue-white.svg';

const useStyles = makeStyles((theme) => ({
  iconsContainer: {
    marginTop: 40,
    marginBottom: 20,
  },
  iconItem: {
    alignContent: 'center',
  },
  title: {
    fontFamily: 'Figtree-Bold',
    fontSize: 20,
    lineHeight: '24px',
    color: 'white',
    textAlign: 'center'
  },
  body: {
    fontFamily: 'Figtree-Regular',
    fontSize: 16,
    lineHeight: '21.79px',
    color: 'white',
    textAlign: 'center'
  }
}));

const ClipsConnectSuccess = () => {
  const classes = useStyles();

  return (
    <Grid container direction="column" justifyContent='center'>
      <Grid container item direction='row' justifyContent='center' spacing={1} className={classes.iconsContainer}>
        <Grid item className={classes.iconItem}>
          <img src={logo} alt="logo" />
        </Grid>
        <Grid item className={classes.iconItem}>
          <img src={arrows} alt="arrows" />
        </Grid>
        <Grid item className={classes.iconItem}>
          <img src={yotuubeLogo} alt="youtube" />
        </Grid>
      </Grid>
        <span className={classes.title}>Youtube Connected</span>
        <span className={classes.body}>AsqMe can now pull relevant clips in to your answers from your YouTube channel.</span>
    </Grid>
  )
}

export default ClipsConnectSuccess;