import { useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { AppDataContext } from 'Context';

const useStyles = makeStyles((theme) => ({
  logoutContainer: { height: '100%' },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.common.white,
    color: theme.common.black,
  },
}));

const Logout = () => {
  const classes = useStyles();
  const { onLogout } = useContext(AppDataContext);

  useEffect(() => {
    onLogout();
  }, []);

  return (
    <Grid container justifyContent='center' alignItems='center' className={classes.logoutContainer}>
      <Grid item>
        <Backdrop open className={classes.backdrop}>
          <CircularProgress color='inherit' size={70} thickness={3} />
        </Backdrop>
      </Grid>
    </Grid>
  );
};

export default Logout;
