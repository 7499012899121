import { useTheme, makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import { cardStyles } from './styles';

const useStyles = makeStyles(cardStyles);

const CardOption = ({
  isPremium,
  topLeftSection: { icon, text },
  topRightSection,
  bottomLeftSection,
  bottomRightSection,
  secondSectionClassName,
}) => {
  const classes = useStyles();
  const { breakpoints } = useTheme();
  const mediumView = useMediaQuery(breakpoints.up('md'));

  return (
    <Grid container direction='column' className={`${classes.card} ${isPremium ? 'premium' : ''}`}>
      {/* 1st row */}
      <Grid item container justifyContent='space-between' alignItems='center'>
        <Grid item>
          <Grid container alignItems='center' spacing={1}>
            <Grid item>
              <img src={icon} alt='icon' />
            </Grid>
            <Grid item>
              <div className={classes.leftTopHeaderText}>{text}</div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.rightItem}>
          {topRightSection}
        </Grid>
      </Grid>
      {/* 2nd row */}
      <Grid
        item
        container
        justifyContent={
          !bottomRightSection ? undefined : mediumView ? 'flex-start' : 'space-between'
        }
        alignItems='center'
        className={`${classes.secondSection} ${secondSectionClassName || ''}`}
      >
        <Grid item xs={bottomRightSection ? undefined : 12}>
          {bottomLeftSection}
        </Grid>
        {bottomRightSection && <Grid item>{bottomRightSection}</Grid>}
      </Grid>
    </Grid>
  );
};
export default CardOption;
