import { useContext } from 'react';
import { AppDataContext } from 'Context';

// hook that returns user from provider
const useAuth = () => {
  const {
    appData: { user },
  } = useContext(AppDataContext);

  return user;
};

export default useAuth;
