const CalendarPriority = ({
  ...rest
}) => (
  <svg 
    width="26" 
    height="26" 
    viewBox="0 0 26 26" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <g clipPath="url(#clip0_6621_10278)">
      <path d="M7 0C6.39844 0 6 0.398438 6 1V4C6 4.60156 6.39844 5 7 5C7.60156 5 8 4.60156 8 4V1C8 0.398438 7.60156 0 7 0ZM19 0C18.3984 0 18 0.398438 18 1V4C18 4.60156 18.3984 5 19 5C19.6016 5 20 4.60156 20 4V1C20 0.398438 19.6016 0 19 0ZM3 2C1.30078 2 0 3.30078 0 5V23C0 24.6992 1.30078 26 3 26H23C24.6992 26 26 24.6992 26 23V5C26 3.30078 24.6992 2 23 2H21V4C21 5.10156 20.1016 6 19 6C17.8984 6 17 5.10156 17 4V2H9V4C9 5.10156 8.10156 6 7 6C5.89844 6 5 5.10156 5 4V2H3ZM2 8H24V23C24 23.6016 23.6016 24 23 24H3C2.39844 24 2 23.6016 2 23V8ZM13 10C12.5 10 12.1133 10.1992 11.8125 10.5C11.5117 10.8008 11.4062 11.1875 11.4062 11.6875C11.4062 12.1875 12.1133 14.6992 12.3125 17H13.8125C14.1133 14.6992 14.6875 12.0859 14.6875 11.6875C14.6875 11.1875 14.4883 10.8008 14.1875 10.5C13.8867 10.1992 13.5 10 13 10ZM13 19C12.6016 19 12.207 19.1055 11.9062 19.4062C11.6055 19.707 11.5 20.1016 11.5 20.5C11.5 21 11.6055 21.293 11.9062 21.5938C12.207 21.8945 12.6016 22 13 22C13.3984 22 13.793 21.8945 14.0938 21.5938C14.3945 21.293 14.5 20.8984 14.5 20.5C14.5 20.1016 14.3945 19.707 14.0938 19.4062C13.793 19.1055 13.3984 19 13 19Z" fill="#454545"/>
    </g>
    <defs>
      <clipPath id="clip0_6621_10278">
        <rect width="26" height="26" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);

export default CalendarPriority;