const CloseAlt = ({
  ...rest
}) => (
  <svg
    {...rest}
    width="26" 
    height="26" 
    viewBox="0 0 26 26" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M21.7344 19.6406L19.6367 21.7344C19.2539 22.1211 18.6289 22.1211 18.2422 21.7344L13 16.4961L7.76172 21.7344C7.375 22.1211 6.74609 22.1211 6.36328 21.7344L4.26563 19.6406C3.87891 19.2539 3.87891 18.6289 4.26563 18.2422L9.50391 13L4.26563 7.76172C3.88281 7.37109 3.88281 6.74219 4.26563 6.36328L6.36328 4.26563C6.74609 3.87891 7.375 3.87891 7.76172 4.26563L13 9.50781L18.2422 4.26563C18.6289 3.87891 19.2578 3.87891 19.6367 4.26563L21.7344 6.35938C22.1211 6.74609 22.1211 7.375 21.7383 7.76172L16.4961 13L21.7344 18.2422C22.1211 18.6289 22.1211 19.2539 21.7344 19.6406Z" fill="black"/>
  </svg>
);

export default CloseAlt; 