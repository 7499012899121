export const starterPlanTableItems = [
  {
    title: '80/20 Revenue Share',
    description: 'Keep 80% of your earnings. We pay you.'
  },
  {
    title: 'Low 3.9% Transaction Fees',
    description: 'We just pass through the transaction processing and balance transfer fees from our payment partner Stripe.'
  },
  {
    title: 'Universal Inbox',
    description: 'Seamlessly manage Q&A across all your platforms with a single, unified inbox. Use your inbox to answer your current questions, archive old answers, and more!'
  },
  {
    title: 'Set a Price per Answer',
    description: '$5, $10, $20 or a custom amount…you set the price for your expertise. Change it any time.'
  },
  {
    title: 'Answer By Email',
    description: 'You receive an email notification when you get a new question. Simply reply to that email to send your answer.'
  },
  {
    title: 'Attachments',
    description: 'Questions are content too. You and your audience members can attach videos, pictures and other files for rich questions and thorough answers. Turn them into Shorts!'
  },
  {
    title: '10 FirstDrafts',
    description: 'When connected to YouTube, FirstDraft uses AI to write answers for you based on your existing content. Review the draft answers in your AsqMe Inbox before replying.',
    description2: 'When audience questions arrive, FirstDraft crafts answers that sound just like you — because they are. And when detected, answers come with a video clip from your channel cued up to just the right spot.If you are not connected to YouTube or an answer cannot be found in your content, ChatGPT will provide an answer.'
  },
  {
    title: '10 AutoTranslated Q&As',
    description: 'AutoTranslate seamlessly detects and translates foreign language questions into your preferred language and translates answers back into the question’s original language.'
  },
  {
    title: 'ThankBank',
    description: 'ThankBank lets your audience members say thank you for answering their questions. Get stars, thank you notes, and (optionally) tips!'
  },
]

export const proPlanTableItems = [
  {
    title: 'Zero Revenue Share',
    description: 'Keep 100% of what you earn. No commissions or revenue splits.'
  },
  {
    title: 'Low 3.9% Transaction Fees',
    description: 'We just pass through the transaction processing and balance transfer fees from our payment partner Stripe.'
  },
  {
    title: 'Universal Inbox',
    description: 'Seamlessly manage Q&A across all your platforms with a single, unified inbox. Use your inbox to answer your current questions, archive old answers, and more!'
  },
  {
    title: 'Set a Price per Answer',
    description: '$5, $10, $20 or a custom amount…you set the price for your expertise. Change it any time.',
    description2: 'If you don’t want to charge for answers, no problem! With Pro you can accept tips only or not charge at all!',
  },
  {
    title: 'Answer By Email',
    description: 'You receive an email notification when you get a new question. Simply reply to that email to send your answer.'
  },
  {
    title: 'Attachments',
    description: 'Questions are content too. You and your audience members can attach videos, pictures and other files for rich questions and thorough answers. Turn them into Shorts!'
  },
  {
    title: 'Unlimited FirstDrafts',
    description: 'When connected to YouTube, FirstDraft uses AI to write answers for you based on your existing content. Review the draft answers in your AsqMe Inbox before replying.',
    description2: 'When audience questions arrive, FirstDraft crafts answers that sound just like you — because they are. And when detected, answers come with a video clip from your channel cued up to just the right spot.If you are not connected to YouTube or an answer cannot be found in your content, ChatGPT will provide an answer.',
  },
  {
    title: 'Unlimited AutoTranslated Q&As',
    description: 'ThankBank lets your audience members say thank you for answering their questions. Get stars, thank you notes, and (optionally) tips! '
  },
  {
    title: 'ThankBank',
    description: 'ThankBank lets your audience members say thank you for answering their questions. Get stars, thank you notes, and (optionally) tips! '
  },
  {
    title: 'Connect Your Own Tip Jars',
    description: 'Add your favorite tip jars to ThankBank…or use ours. You can connect Cash App, PayPal, Venmo and Buy Me a Coffee.'
  },
  {
    title: 'Accept Tips Only',
    description: 'Want to let your audience decide what your expertise is worth? After you answer a question, we’ll suggest they say thanks with a tip.'
  },
  {
    title: 'Don’t Charge for Answers',
    description: 'Don’t want to charge for answers? No problem! Perfect if you already monetize questions through a membership.'
  },
]