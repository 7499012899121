import { number, object, string } from 'prop-types';

const InboxYoutubeIcon = ({
  className = '',
  style = {},
  width = 44,
  height = 32,
  fillColor = '#7E8DFF',
  ...rest
}) => (
  <svg
    {...rest}
    className={className}
    style={style}
    width={width}
    height={height}
    viewBox='0 0 44 32'
  >
    <path
      d='M41.8984 5.5C41.5 3.30078 39.6016 1.69922 37.3984 1.19922C34.1016 0.5 28 0 21.3984 0C14.8008 0 8.60156 0.5 5.30078 1.19922C3.10156 1.69922 1.19922 3.19922 0.800781 5.5C0.398438 8 0 11.5 0 16C0 20.5 0.398438 24 0.898438 26.5C1.30078 28.6992 3.19922 30.3008 5.39844 30.8008C8.89844 31.5 14.8984 32 21.5 32C28.1016 32 34.1016 31.5 37.6016 30.8008C39.8008 30.3008 41.6992 28.8008 42.1016 26.5C42.5 24 43 20.3984 43.1016 16C42.8984 11.5 42.3984 8 41.8984 5.5ZM16 23V9L28.1992 16L16 23Z'
      fill={fillColor}
    />
  </svg>
);

InboxYoutubeIcon.propTypes = {
  style: object,
  className: string,
  height: number,
  width: number,
  fillColor: string,
  strokeColor: string,
};

export default InboxYoutubeIcon;
