const styles = (theme) => ({
  menuIcon: {
    cursor: 'pointer',
  },
  drawer: {
    overflow: 'hidden',
  },
  paper: {
    overflowX: 'hidden',
    height: '100%',
    '&.maxWidth': {
      minWidth: 450,
      maxWidth: 600,
    },
  },
  drawerContainer: {
    height: '100%',
    backgroundColor: theme.modalDarkBackground,
    '@media (max-height: 500px)': {
      height: 'unset',
    },
  },
  borderImg: {
    width: '100%',
  },
  headerContainer: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  contentContainer: {
    flexGrow: 1,
  },
  comingSoonText: {
    color: theme.premiumYellow,
    fontSize: '1.1rem',
  },
  notreadyText: {
    color: theme.common.white,
    fontSize: '2rem',
    textAlign: 'center',
    '@media (max-height: 780px)': {
      fontSize: '1.7rem',
    },
    '@media (max-height: 630px)': {
      fontSize: '1.6rem',
    },
  },
  underDevImg: {},
  waitText: {
    color: theme.common.white,
    fontSize: '1.1rem',
    textAlign: 'center',
    '@media (max-height: 780px)': {
      fontSize: '1rem',
      fontSize: '1rem',
    },
    '@media (max-height: 630px)': {
      fontSize: '1rem',
    },
  },
  asqmeLogo: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    '@media (max-height: 780px)': {
      marginBottom: theme.spacing(1),
    },
  },
});

export default styles;
