import { number, object, string } from 'prop-types';

const InboxVideoIcon = ({
  className = '',
  style = {},
  width = 40,
  height = 40,
  fillColor = '#7E8DFF',
  ...rest
}) => (
  <svg
    {...rest}
    className={className}
    style={style}
    width={width}
    height={height}
    viewBox='0 0 40 40'
  >
    <g clipPath='url(#clip0_7568_32442)'>
      <path
        d='M7.69234 3.36536C3.68994 3.36536 0.288492 6.61656 0.288492 10.7692C0.288492 13.4315 1.70075 15.7211 3.75003 17.0192C1.62864 17.3618 3.05176e-05 19.1767 3.05176e-05 21.3942V33.9904C3.05176e-05 36.4543 2.00724 38.4615 4.47118 38.4615H27.8366C30.3005 38.4615 32.3077 36.4543 32.3077 33.9904V32.3077H33.8462C35.3846 32.3077 36.9231 35.3846 38.4616 35.3846H40V20H38.4616C36.9231 20 35.3846 23.0769 33.8462 23.0769H32.3077V21.3942C32.3077 19.1767 30.6791 17.3618 28.5577 17.0192C30.607 15.7211 32.0193 13.4315 32.0193 10.7692C32.0193 6.61656 28.6178 3.36536 24.6154 3.36536C20.613 3.36536 17.2116 6.61656 17.2116 10.7692C17.2116 13.3654 18.5577 15.613 20.5289 16.923H11.7789C13.75 15.613 15.0962 13.3654 15.0962 10.7692C15.0962 6.61656 11.6947 3.36536 7.69234 3.36536Z'
        fill={fillColor}
      />
    </g>
    <defs>
      <clipPath id='clip0_7568_32442'>
        <rect width='40' height='40' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

InboxVideoIcon.propTypes = {
  style: object,
  className: string,
  height: number,
  width: number,
  fillColor: string,
  strokeColor: string,
};

export default InboxVideoIcon;
