const WhiteLogoWhiteContent = () => (
  <svg width='38' height='34' viewBox='0 0 38 38' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M35.8588 31.4535C33.976 31.4535 32.2456 30.8028 30.8771 29.717C33.7555 26.5608 35.5115 22.3639 35.5115 17.7551C35.5115 7.9502 27.5625 0 17.7564 0C7.95021 0 0 7.95021 0 17.7564C0 27.5625 7.95021 35.5127 17.7564 35.5127C19.6842 35.5127 21.5377 35.202 23.2767 34.6329C24.7743 36.1513 26.8545 37.092 29.154 37.092C32.8293 37.092 35.9429 34.6901 37.0128 31.3707C36.6363 31.4255 36.2512 31.4547 35.86 31.4547L35.8588 31.4535ZM20.9186 28.2461C19.9169 28.5484 18.8555 28.7129 17.7551 28.7129C11.7108 28.7129 6.81081 23.8128 6.81081 17.7685C6.81081 11.7242 11.7108 6.82421 17.7551 6.82421C23.7994 6.82421 28.6995 11.7242 28.6995 17.7685C28.6995 18.7556 28.5666 19.711 28.3217 20.6213C28.329 20.6213 28.3363 20.6189 28.3436 20.6189C28.3046 20.7224 28.2681 20.8285 28.234 20.9345C28.2193 20.9844 28.2035 21.0356 28.1876 21.0856C27.0994 24.5135 24.3685 27.2079 20.9199 28.2474L20.9186 28.2461Z'
      fill='white'
    />
    <path
      d='M28.3437 20.6189C28.3364 20.6189 28.3291 20.6213 28.3217 20.6213C28.2937 20.7261 28.2645 20.8309 28.2328 20.9345C28.2681 20.8285 28.3047 20.7237 28.3425 20.6189H28.3437Z'
      fill='white'
    />
    <path
      d='M17.7563 26.3475C20.4251 26.3475 22.8099 25.1277 24.3831 23.2169C23.4545 21.5657 21.93 20.2922 20.0973 19.7C21.1501 18.9615 21.8399 17.7405 21.8399 16.3561C21.8399 14.1005 20.012 12.2726 17.7563 12.2726C15.5007 12.2726 13.6728 14.1005 13.6728 16.3561C13.6728 17.7405 14.3625 18.9603 15.4154 19.7C13.5826 20.2922 12.0581 21.5657 11.1295 23.2169C12.7027 25.1289 15.0876 26.3475 17.7563 26.3475Z'
      fill='white'
    />
  </svg>
);

export default WhiteLogoWhiteContent;
