import { number, object, string } from 'prop-types';

const InboxFacebook = ({
  className = '',
  style = {},
  width = 44,
  height = 44,
  fillColor = '#7E8DFF',
  ...rest
}) => (
  <svg
    {...rest}
    className={className}
    style={style}
    width={width}
    height={height}
    viewBox='0 0 44 44'
  >
    <path
      d='M22 0C9.85 0 0 9.85 0 22C0 33.03 8.125 42.137 18.712 43.728V27.831H13.269V22.048H18.712V18.2C18.712 11.829 21.816 9.032 27.111 9.032C29.647 9.032 30.988 9.22 31.623 9.306V14.354H28.011C25.763 14.354 24.978 16.485 24.978 18.887V22.048H31.566L30.672 27.831H24.978V43.775C35.716 42.318 44 33.137 44 22C44 9.85 34.15 0 22 0Z'
      fill={fillColor}
    />
  </svg>
);

InboxFacebook.propTypes = {
  style: object,
  className: string,
  height: number,
  width: number,
  fillColor: string,
  strokeColor: string,
};

export default InboxFacebook;
