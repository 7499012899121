const AttachmentUploaded = ({ style }) => (
  <svg
    style={style}
    width='34'
    height='34'
    viewBox='0 0 47 47'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect width='47' height='47' rx='10' fill='#495EFF' />
    <g clipPath='url(#clip0_1008_4918)'>
      <path
        d='M24 37C31.1797 37 37 31.1797 37 24C37 16.8203 31.1797 11 24 11C16.8203 11 11 16.8203 11 24C11 31.1797 16.8203 37 24 37Z'
        fill='white'
      />
      <path
        d='M22.9575 30.9148L16.8109 26.1235L18.5588 23.879L22.3334 26.8212L28.5627 17.8264L30.9002 19.4464L22.9575 30.9148Z'
        fill='#495EFF'
      />
    </g>
    <defs>
      <clipPath id='clip0_1008_4918'>
        <rect width='26' height='26' fill='white' transform='translate(11 11)' />
      </clipPath>
    </defs>
  </svg>
);

export default AttachmentUploaded;
