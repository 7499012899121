import { number, object, string } from 'prop-types';

const AttachmentActionIcon = ({
  className = '',
  style = {},
  width = 24,
  height = 22,
  fillColor = '#666666',
  ...rest
}) => (
  <svg {...rest} className={className} style={style} width={width} height={height}>
    <g clipPath='url(#clip0_6642_8035)'>
      <path
        d='M6.2495 17.4999C4.64869 17.4999 3.2246 16.4748 2.70755 14.949L2.6724 14.8341C2.55052 14.4301 2.49943 14.0902 2.49943 13.7499V6.93188L0.0735971 15.0301C-0.238597 16.2212 0.472511 17.4559 1.6655 17.7849L17.1289 21.9259C17.3221 21.9761 17.5147 22 17.705 22C18.7012 22 19.611 21.339 19.866 20.365L20.767 17.4999H6.2495Z'
        fill={fillColor}
      />
      <path
        d='M8.99971 6.99985C10.1027 6.99985 10.9999 6.10266 10.9999 4.99968C10.9999 3.89671 10.1027 2.99951 8.99971 2.99951C7.89672 2.99951 6.99951 3.89671 6.99951 4.99968C6.99951 6.10266 7.89672 6.99985 8.99971 6.99985Z'
        fill={fillColor}
      />
      <path
        d='M21.4996 0H6.49934C5.12119 0 3.99945 1.1222 3.99945 2.49986V13.5001C3.99945 14.8782 5.12166 16 6.49934 16H21.4996C22.8778 16 23.9995 14.8778 23.9995 13.5001V2.49986C23.9995 1.12173 22.8773 0 21.4996 0ZM6.49934 2.00017H21.4996C21.7757 2.00017 21.9998 2.22423 21.9998 2.50033V9.5996L18.8408 5.91379C18.5057 5.52098 18.021 5.31098 17.4997 5.29879C16.9817 5.3016 16.4956 5.53176 16.1638 5.92973L12.4498 10.3876L11.2399 9.18053C10.556 8.49662 9.44268 8.49662 8.7597 9.18053L5.99965 11.9396V2.49986C5.99965 2.22377 6.22371 1.9997 6.49981 1.9997L6.49934 2.00017Z'
        fill={fillColor}
      />
    </g>
    <defs>
      <clipPath id='clip0_6642_8035'>
        <rect width='24' height='22' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

AttachmentActionIcon.propTypes = {
  style: object,
  className: string,
  height: number,
  width: number,
  fillColor: string,
};

export default AttachmentActionIcon;
