const Plus = ({
  ...rest
}) => (
<svg 
  width="18px" 
  height="18px" 
  viewBox="0 0 800 800"
  xmlns="http://www.w3.org/2000/svg"
  {...rest}
>
  <g transform="matrix(1,0,0,1,-0.15626,-0.0160169)">
    <g transform="matrix(1,0,0,1.05,-12304.6,-3219.98)">
      <path d="M13104.8,3379.52L13104.8,3515.72L12774.8,3515.72L12774.8,3828.57L12634.8,3828.57L12634.8,3515.72L12304.8,3515.72L12304.8,3379.52L12634.8,3379.52L12634.8,3066.67L12774.8,3066.67L12774.8,3379.52L13104.8,3379.52Z" fill="#495EFF"/>
    </g>
  </g>
</svg>
);

export default Plus ;