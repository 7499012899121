import { commonBtnStyles } from 'assets/styles/common';

const pencilAdornment = {
  bottom: 25,
};

const iconAdornment = {
  top: 25,
};

const styles = (theme) => ({
  container: { height: '100%', minHeight: 630 },
  innerContainer: {
    height: '100%',
    position: 'relative',
  },
  questionSectionContainer: {
    flexGrow: 0.6,
  },
  answerSectionOuterContainer: {
    flexGrow: 0.5,
  },
  answerSectionInnerContainer: {
    height: '100%',
  },
  answerContainer: {
    flexGrow: 1,
  },
  progress: {
    color: theme.common.white,
  },
  header: {
    backgroundColor: theme.blue,
  },
  headerLogosContainer: {
    backgroundColor: theme.blue,
    width: '100%',
    height: 70,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  logoPlaceholderIcon: {
    transform: 'translateY(50%)',
    borderRadius: 50,
    width: 70,
    height: 70,
    backgroundColor: theme.common.black,
    border: `2px solid ${theme.common.white}`,
    position: 'relative',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  introSectionContainer: {
    paddingTop: theme.spacing(4.5),
  },
  creatorDisplayName: {
    fontSize: '1.3rem',
  },
  creatorDescription: {
    fontSize: '1rem',
    lineHeight: 1,
    opacity: 0.5,
    textAlign: 'center',
  },
  askText: {
    color: theme.blue,
    fontSize: '1.2rem',
    fontFamily: 'Figtree-Bold',
    lineHeight: 1.15,
  },
  textareaSectionOutterContainer: {
    flexGrow: 1,
  },
  form: {
    height: '100%',
  },
  textareaSectionInnerContainer: {
    height: '100%',
    paddingTop: theme.spacing(1),
  },
  textAreaContainer: {
    flexGrow: 1,
  },
  textField: {
    '&.MuiFormControl-fullWidth': { height: '100%' },
    '& .MuiInputBase-root.Mui-disabled': {
      color: '#000000ad',
    },
    '& .MuiInputAdornment-positionEnd': {
      position: 'absolute',
      right: 9,
      ...pencilAdornment,
      '&.attach': iconAdornment,
    },
    '& .MuiFilledInput-input': {
      height: '100% !important',
      paddingRight: theme.spacing(0.75),
      '&::-webkit-scrollbar': {
        width: 6,
        height: 8,
        display: 'block',
      },
      '&::-webkit-scrollbar-track': {
        background: 'inherit',
      },
      '&::-webkit-scrollbar-thumb': {
        visibility: 'hidden',
      },
      '&:hover::-webkit-scrollbar-thumb': {
        backgroundColor: theme.grey[500],
        borderRadius: 4,
        visibility: 'visible',
      },
    },
    '& .MuiFilledInput-root': {
      borderRadius: 0,
    },
    '& .MuiFilledInput-multiline': {
      borderRadius: 0,
      padding: `${theme.spacing(1.5)}px ${theme.spacing(6.5)}px ${theme.spacing(
        1,
      )}px ${theme.spacing(1.5)}px`,
      height: '100%',
    },
    '& .MuiFormHelperText-root.Mui-error': {
      color: theme.error.dark,
      fontFamily: 'Figtree-Bold',
      fontSize: theme.spacing(1.6),
    },
    '& .MuiFormHelperText-contained': {
      marginLeft: 0,
    },
  },

  attachmentIcon: {
    width: 35,
    height: 35,
    cursor: 'pointer',
    '&.disabled': {
      cursor: 'auto',
      opacity: 0.7,
    },
  },
  imageBackDrop: {
    borderRadius: 5,
    position: 'absolute',
    zIndex: 5,
    color: theme.common.white,
    backgroundColor: theme.fadedBlack,
  },
  hr: {
    backgroundColor: theme.fadedBlue,
    height: 1,
  },
  saveBtn: {
    ...commonBtnStyles,
    backgroundColor: theme.blue,
    '&.Mui-disabled': {
      opacity: 0.7,
    },
    '& .MuiButton-label': {
      fontFamily: 'Figtree-Bold',
      color: theme.common.white,
      fontSize: '1.1rem',
    },
    '&:hover': {
      backgroundColor: theme.blue,
      opacity: 0.85,
      '&.Mui-disabled': {
        backgroundColor: theme.blue,
        opacity: 0.7,
      },
    },
  },
  answerIntro: {
    textAlign: 'center',
    color: theme.blue,
    fontSize: '1.2rem',
    fontFamily: 'Figtree-Bold',
    paddingTop: theme.spacing(1),
    lineHeight: 1.15,
  },
  footer: {
    padding: `${theme.spacing(1)}px 0`,
  },
  sendAnswerPrompt: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: theme.grey[900],
  },
  sendAnswerPromptContainer: {
    height: '100%',
  },
  promptCard: {
    backgroundColor: theme.common.black,
    color: theme.common.white,
  },
  promptCloseIcon: {
    padding: theme.spacing(1),
    textAlign: 'end',
  },
  promptContent: {
    flexGrow: 1,
  },
  promptIconContainer: {
    marginTop: theme.spacing(5),
  },
  verifiedBadgeImg: {
    width: 110,
  },
  promptShieldContainer: {
    position: 'relative',
  },
  promptRobot: {
    position: 'absolute',
    left: -30,
    top: -25,
  },
  promptHeader: {
    color: theme.common.white,
    paddingTop: theme.spacing(0.5),
    textAlign: 'center',
    fontSize: '1.5rem',
    fontFamily: 'Figtree-SemiBold',
  },
  promptVerificationContainer: {
    backgroundColor: theme.common.black,
    borderRadius: 15,
    padding: theme.spacing(3),
  },
  promptVerificationHeader: {
    // paddingTop: theme.spacing(2),
  },
  promptVerificationCheck: {
    paddingTop: theme.spacing(4),
  },
  promptVerificationEdit: {},
  promptBody: {
    // padding: `0 ${theme.spacing(2)}px`,
    textAlign: 'center',
    fontSize: '0.9rem',
    lineHeight: 1.3,
    color: theme.grey[400],
    '& span': {
      color: theme.common.white,
      fontFamily: 'Figtree-SemiBold',
    },
  },
  verificationText: {
    color: theme.grey[400],
    fontSize: '1.05rem',
    lineHeight: 1.3,
    '& span': {
      color: theme.common.white,
      fontFamily: 'Figtree-SemiBold',
    },
  },
  icon: {
    borderRadius: 3,
    width: theme.spacing(3.2),
    height: theme.spacing(3.2),
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: 'transparent',
    border: `2px solid ${theme.blue}`,
  },
  checkedIcon: {
    border: `none`,
    backgroundColor: theme.blue,
    '&:before': {
      display: 'block',
      width: 25,
      height: 25,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
  },
  promptVerificationEditText: {
    color: theme.grey[400],
    fontSize: '1rem',
    lineHeight: 1.3,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  promptLastText: {
    textAlign: 'center',
    fontSize: '0.9rem',
  },

  promptBtn: {
    ...commonBtnStyles,
    border: `2px solid ${theme.blue}`,
    backgroundColor: theme.blue,
    '&.Mui-disabled': {
      opacity: 0.7,
    },
    '& .MuiButton-label': {
      fontFamily: 'Figtree-Bold',
      color: theme.common.white,
      fontSize: '1.1rem',
    },
    '&:hover': {
      backgroundColor: theme.blue,
      opacity: 0.85,
      '&.Mui-disabled': {
        backgroundColor: theme.blue,
        opacity: 0.7,
      },
    },
    '&.grey': {
      border: `2px solid ${theme.grey[600]}`,
      backgroundColor: theme.grey[600],
      '&:hover': {
        backgroundColor: theme.grey[600],
        opacity: 0.85,
        '&.Mui-disabled': {
          backgroundColor: theme.grey[600],
          opacity: 0.7,
        },
      },
    },
  },
  promptProgress: {
    color: theme.common.black,
  },
  promptTranslationImg: {
    width: theme.spacing(5),
  },
  drawerTranslationIntro: {
    padding: `${theme.spacing(0.3)}px 0`,
    color: theme.grey[400],
    fontSize: '0.9rem',
    '& span': {
      fontSize: '1.3rem',
      color: theme.common.white,
      fontFamily: 'Figtree-SemiBold',
    },
  },
  drawerTranslationText: {
    color: theme.grey[400],
    fontSize: '0.9rem',
    textAlign: 'center',
    lineHeight: 1.2,
  },
});

export default styles;
