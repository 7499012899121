import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useAlert } from 'react-alert';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import QnAView from './QnAView';
import NotFound from 'components/NotFound';
import { queryCreatorTypes, getAudienceQuestion } from 'store/creator';
import { defaultAlertError } from 'utils/common/constants';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.common.white,
    color: theme.common.black,
  },
}));

const CopilotQnA = () => {
  const { code } = useParams();

  const alert = useAlert();
  const classes = useStyles();

  const [initialLoad, setInitialLoad] = useState(true);

  const { data: audienceQuestion, error: audienceQuestionError } = useQuery({
    queryKey: [queryCreatorTypes.audienceQuestion, code],
    queryFn: getAudienceQuestion,
    retry: 1,
    onSuccess: () => {
      setInitialLoad(false);
    },
    onError: (err) => {
      setInitialLoad(false);
      if (err.status !== 404)
        alert.error(null, defaultAlertError);
    },
  });

  return (
    <>
      {initialLoad ? (
        <Backdrop open className={classes.backdrop}>
          <CircularProgress color='inherit' thickness={3} size={70} />
        </Backdrop>
      ) : audienceQuestion ? (
        <QnAView questionData={audienceQuestion} questionTrackingCode={code} />
      ) : audienceQuestionError ? (
        <NotFound />
      ) : null}
    </>
  );
};

export default CopilotQnA;
