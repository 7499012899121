const CalendarStandard = ({
  ...rest
}) => (
  <svg 
    width="26" 
    height="26" 
    viewBox="0 0 26 26" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <g clipPath="url(#clip0_6621_10285)">
      <path d="M7 0C6.44922 0 6 0.449219 6 1V4C6 4.55078 6.44922 5 7 5C7.55078 5 8 4.55078 8 4V1C8 0.449219 7.55078 0 7 0ZM19 0C18.4492 0 18 0.449219 18 1V4C18 4.55078 18.4492 5 19 5C19.5508 5 20 4.55078 20 4V1C20 0.449219 19.5508 0 19 0ZM3 2C1.34375 2 0 3.34375 0 5V23C0 24.6562 1.34375 26 3 26H23C24.6562 26 26 24.6562 26 23V5C26 3.34375 24.6562 2 23 2H21V4C21 5.10156 20.1055 6 19 6C17.8945 6 17 5.10156 17 4V2H9V4C9 5.10156 8.10547 6 7 6C5.89453 6 5 5.10156 5 4V2H3ZM2 9H24V23C24 23.5508 23.5508 24 23 24H3C2.44922 24 2 23.5508 2 23V9ZM4.34375 11C4.12891 11.0703 3.98828 11.2734 4 11.5V14.2188C3.88672 14.3906 3.88672 14.6094 4 14.7812V18.2812C3.93359 18.418 3.93359 18.582 4 18.7188V21.5C4 21.7773 4.22266 22 4.5 22H21.5C21.7773 22 22 21.7773 22 21.5V18.625C22.0156 18.5547 22.0156 18.4766 22 18.4062V14.625C22.0156 14.5547 22.0156 14.4766 22 14.4062V11.5C22 11.2227 21.7773 11 21.5 11H4.5C4.46875 10.9961 4.4375 10.9961 4.40625 11C4.38672 11 4.36328 11 4.34375 11ZM5 12H8V13.0938C7.56641 13.2422 7.24219 13.5664 7.09375 14H5V12ZM9 12H13V13H9V12ZM14 12H17V14H14.9062C14.7578 13.5664 14.4336 13.2422 14 13.0938V12ZM18 12H21V14H18V12ZM5 15H7V18H5V15ZM9 15H13V18H9V15ZM15 15H17V18H15V15ZM18 15H21V18H18V15ZM5 19H7.09375C7.24219 19.4336 7.56641 19.7578 8 19.9062V21H5V19ZM14.9062 19H17V21H14V19.9062C14.4336 19.7578 14.7578 19.4336 14.9062 19ZM18 19H21V21H18V19ZM9 20H13V21H9V20Z" fill="#495EFF"/>
    </g>
    <defs>
      <clipPath id="clip0_6621_10285">
        <rect width="26" height="26" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);

export default CalendarStandard;