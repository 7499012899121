import Account from 'routes/Account';
import AccountNeedsVerification from 'routes/Auth/Unverified';
import Balance from 'routes/Balance';
import Connect from 'routes/Auth/Connect';
import CopilotQnA from 'routes/Public/Copilot/QnA';
import Inbox from 'routes/Inbox';
import Logout from 'routes/Auth/Logout';
import Profile from 'routes/Profile';
import PublicProfile from 'routes/Public/Profile';
import Subscribe from 'routes/Subscribe';
import SubscribeConfirm from 'routes/Subscribe/confirm';
import Tipjar from 'routes/Public/Tipjar';
import StripeLogin from 'routes/StripeLogin';
import ThankBank from 'routes/Public/ThankBank';
import Upgrade from 'routes/Upgrade';
import FirstDraft from 'routes/FirstDraft';

const inboxEnabled = process.env.REACT_APP_ENABLE_INBOX === 'true';

const authRouteUrlNames = {
  ACCOUNT: '/account',
  BALANCE: '/balance',
  FIRSTDRAFT: '/FirstDraft',
  INBOX: '/inbox',
  PROFILE: '/profile',
  SUBSCRIBE: '/subscribe',
  UPGRADE: '/upgrade',
};

// app routes schema
const routeSchema = [
  {
    path: '/auth/connect',
    element: Connect,
    authRequired: false,
  },
  {
    path: '/auth/account/unverified',
    element: AccountNeedsVerification,
    authRequired: false,
  },
  {
    path: authRouteUrlNames.ACCOUNT,
    element: Account,
    authRequired: true,
  },
  {
    path: authRouteUrlNames.BALANCE,
    element: Balance,
    authRequired: true,
  },
  inboxEnabled
    ? {
        path: authRouteUrlNames.INBOX,
        element: Inbox,
        authRequired: true,
      }
    : null,
  {
    path: authRouteUrlNames.FIRSTDRAFT,
    element: FirstDraft,
    authRequired: true,
  },
  {
    path: authRouteUrlNames.PROFILE,
    element: Profile,
    authRequired: true,
  },
  {
    path: '/stripe-login',
    element: StripeLogin,
    authRequired: true,
  },
  {
    path: authRouteUrlNames.SUBSCRIBE,
    element: Subscribe,
    authRequired: true,
  },
  {
    path: authRouteUrlNames.UPGRADE,
    element: Upgrade,
    authRequired: true,
  },
  {
    path: '/subscribe/confirm',
    element: SubscribeConfirm,
    authRequired: true,
  },
  {
    path: '/logout',
    element: Logout,
    authRequired: true,
  },
  {
    path: '/:code/answer/firstdraft',
    element: CopilotQnA,
  },
  {
    path: '/tips/:tag',
    element: Tipjar,
    authRequired: false,
  },
  {
    path: '/thx/:code',
    element: ThankBank,
    authRequired: false,
  },
  {
    path: '/:tag',
    element: PublicProfile,
    authRequired: false,
  },
  {
    path: '*',
    redirectPath: '/auth/connect',
    fallback: true,
  },
].filter((r) => !!r);

export { authRouteUrlNames, routeSchema as default };
