import { createTheme } from '@material-ui/core/styles';

const theme = {
  // custom colors to be added
  blue: '#495EFF',
  fadedBlue: '#495eff94',
  fadedDarkerBlue: '#7E8DFF',
  greyHorizontalRule: '#CCCCCC',
  darkHorizontalRule: '#999999',
  lightGreenLogo: '#C1FF00',
  modalDarkBackground: '#181818',
  premiumYellow: '#FFD058',
  logoPersonGrey: '#757579',
  iconGrey: '#C1C1C1',

  // common
  common: {
    black: '#000',
    white: '#fff',
    fadedBlack: '#00000080',
    fadedWhite: '#FFFFFFB2',
  },
  primary: {
    light: '#7986cb',
    main: '#00B0CA',
    dark: '#005577',
    contrastText: '#fff',
  },
  secondary: {
    light: '#ff4081',
    main: '#E60000',
    dark: '#990000',
    contrastText: '#fff',
  },
  error: {
    light: '#e57373',
    main: '#FF0000',
    dark: '#d32f2f',
    contrastText: '#fff',
  },
  success: {
    light: '#A9D817',
    main: '#009900',
    dark: '#388e3c',
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  info: {
    light: '#64b5f6',
    main: '#007C92',
    dark: '#1976d2',
    contrastText: '#fff',
  },
  warning: {
    light: '#ffb74d',
    main: '#eb9700',
    dark: '#f57c00',
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  grey: {
    50: '#fafafa',
    100: '#f4f4f4',
    200: '#eeeeee',
    300: '#e0e0e0',
    400: '#bdbdbd',
    500: '#9e9e9e',
    600: '#757575',
    700: '#616161',
    750: '#4A4A4A',
    800: '#424242',
    900: '#212121',
    A100: '#d5d5d5',
    A200: '#aaaaaa',
    A400: '#333333',
    A700: '#666666',
  },
};

export default createTheme(theme);
