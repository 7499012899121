import { methods, fetchApi } from 'utils/apiConfig';
const { POST, GET } = methods;

const setupPaymentIntent = (data) =>
  fetchApi('/customer/setup_payment_intent', POST, {
    data,
    headers: {
      chinawall: process.env.REACT_APP_CHINA_WALL,
    },
  });

const setupPriorityPaymentIntent = (data) =>
  fetchApi('/customer_priority/setup_payment_intent', POST, {
    data,
    headers: {
      chinawall: process.env.REACT_APP_CHINA_WALL,
    },
  });

const setupTipPaymentIntent = (data) =>
  fetchApi('/customer/setup_payment_intent_TIP', POST, {
    data,
    headers: {
      chinawall: process.env.REACT_APP_CHINA_WALL,
    },
  });

  const setupThankBankPaymentIntent = (data) =>
    fetchApi('/customer/setup_payment_intent_Thank_Bank', POST, {
      data,
      headers: {
        chinawall: process.env.REACT_APP_CHINA_WALL,
      },
    });

const setupSubscriptionTipPaymentIntent = (data) =>
  fetchApi('customer/setup_payment_intent_tip_jar_bonus', POST, {
    data,
    headers: {
      chinawall: process.env.REACT_APP_CHINA_WALL,
    },
  });

const createPaymentIntent = (data) =>
  fetchApi('/customer/create_payment_intent', POST, {
    data,
    headers: {
      chinawall: process.env.REACT_APP_CHINA_WALL,
    },
  });

const createSubscriptionTipPaymentIntent = (data) =>
  fetchApi('/customer/create_payment_intent_TIP_Jar_Bonus', POST, {
    data,
    headers: {
      chinawall: process.env.REACT_APP_CHINA_WALL,
    },
  });

const createTipPaymentIntent = (data) =>
  fetchApi('/customer/create_payment_intent_TIP', POST, {
    data,
    headers: {
      chinawall: process.env.REACT_APP_CHINA_WALL,
    },
  });

const createThankBankPaymentIntent = (data) =>
  fetchApi('/customer/create_payment_intent_Thank_Bank', POST, {
    data,
    headers: {
      chinawall: process.env.REACT_APP_CHINA_WALL,
    },
  });

const getPaymentMethodDetails = (payment_method_stripe_id) =>
  fetchApi(`/stripe/payment_method_details/${payment_method_stripe_id}`, GET, {
    headers: {
      chinawall: process.env.REACT_APP_CHINA_WALL,
    },
  });


const fetchSubscription = () => fetchApi('/stripe/subscription', GET);

export {
  setupPaymentIntent,
  setupPriorityPaymentIntent,
  setupTipPaymentIntent,
  setupThankBankPaymentIntent,
  setupSubscriptionTipPaymentIntent,
  createPaymentIntent,
  createTipPaymentIntent,
  createThankBankPaymentIntent,
  createSubscriptionTipPaymentIntent,
  fetchSubscription,
  getPaymentMethodDetails,
};
