const Translate = ({
  className = '',
  style = {},
  width = 39,
  height = 39,
  fillColor = 'white',
  ...restProps
}) => (
  <svg
    {...restProps}
    style={style}
    className={className}
    width={width}
    height={height}
    viewBox='0 0 39 39'
  >
    <path d='M29.3582 28.9182L27.767 25.7357L26.1758 28.9182H29.3582Z' fill={fillColor} />
    <path
      d='M35.5738 16.535H33.9551V13.1103C33.9551 12.7873 33.818 12.4788 33.5788 12.2625C33.3397 12.0462 33.019 11.9411 32.6975 11.9738C32.5155 11.9921 28.4403 12.4529 26.4118 16.5357H24.7513V19.041C24.7513 22.1907 22.1889 24.7524 19.04 24.7524H16.5347V35.5748C16.5347 37.4639 18.0718 39.001 19.9609 39.001H35.5738C37.4628 39.001 39 37.4639 39 35.5748V19.9619C39 18.0729 37.4628 16.5357 35.5738 16.5357V16.535ZM22.1584 31.8432L26.7447 22.6706C26.9382 22.2836 27.3335 22.0391 27.7669 22.0391C28.2004 22.0391 28.5957 22.2836 28.7892 22.6706L33.3755 31.8432C33.6573 32.4076 33.4288 33.0939 32.8643 33.3758C32.2999 33.6576 31.6136 33.4291 31.3318 32.8646L30.5007 31.2033H25.0331L24.2021 32.8646C24.0018 33.2653 23.5981 33.4969 23.1791 33.4969C23.0077 33.4969 22.8333 33.458 22.6688 33.3758C22.1043 33.0939 21.8758 32.4076 22.1577 31.8432H22.1584Z'
      fill={fillColor}
    />
    <path
      d='M12.3155 9.6239H10.1499C10.2916 10.7558 10.6534 11.708 11.2323 12.4742C11.8112 11.7072 12.1738 10.7558 12.3147 9.6239H12.3155Z'
      fill={fillColor}
    />
    <path
      d='M19.0391 22.4666C20.9282 22.4666 22.4653 20.9295 22.4653 19.0404V3.42675C22.4661 1.53769 20.9289 0.000549316 19.0391 0.000549316H3.4262C1.53714 0.000549316 0 1.53769 0 3.42675V19.0397C0 20.9287 1.53714 22.4659 3.4262 22.4659H5.04485V25.8905C5.04485 26.2135 5.18196 26.522 5.42114 26.7383C5.63213 26.9288 5.90559 27.0331 6.18742 27.0331C6.22551 27.0331 6.26436 27.0316 6.30244 27.027C6.48449 27.0087 10.5597 26.5479 12.5881 22.4651H19.0391V22.4666ZM11.8416 15.9113C11.6421 15.8046 11.4379 15.6843 11.233 15.5502C11.0281 15.6843 10.824 15.8046 10.6244 15.9113C8.67519 16.951 6.72901 16.9632 6.64674 16.9632C6.01604 16.9632 5.50416 16.4513 5.50416 15.8206C5.50416 15.1899 6.01604 14.6781 6.64674 14.6781C6.65664 14.6781 8.06658 14.6575 9.4689 13.9369C8.66605 12.9094 8.03383 11.5147 7.85101 9.62484H6.64598C6.01528 9.62484 5.5034 9.11296 5.5034 8.48226C5.5034 7.85156 6.01528 7.33969 6.64598 7.33969H10.0897V6.64805C10.0897 6.01735 10.6016 5.50548 11.2323 5.50548C11.863 5.50548 12.3748 6.01735 12.3748 6.64805V7.33969H15.8186C16.4493 7.33969 16.9611 7.85156 16.9611 8.48226C16.9611 9.11296 16.4493 9.62484 15.8186 9.62484H14.6143C14.4315 11.5147 13.7993 12.9094 12.9964 13.9369C14.3987 14.6575 15.8087 14.6781 15.8239 14.6781C16.4546 14.6781 16.9642 15.1899 16.9642 15.8206C16.9642 16.4513 16.45 16.9632 15.8193 16.9632C15.7378 16.9632 13.7916 16.951 11.8416 15.9113Z'
      fill={fillColor}
    />
    <path
      d='M27.8534 6.53743C28.0766 6.76062 28.3691 6.87183 28.6616 6.87183C28.9541 6.87183 29.2466 6.76062 29.4698 6.53743C29.9161 6.09107 29.9161 5.3682 29.4698 4.92183L29.1415 4.59353C33.3469 4.84261 36.6916 8.34194 36.6916 12.6091C36.6916 13.2398 37.2035 13.7517 37.8342 13.7517C38.4649 13.7517 38.9767 13.2398 38.9767 12.6091C38.9767 7.07368 34.5938 2.54299 29.1156 2.30381L29.469 1.95038C29.9154 1.50401 29.9154 0.78114 29.469 0.334774C29.0226 -0.111591 28.299 -0.111591 27.8534 0.334774L25.5599 2.6283C25.1135 3.07467 25.1135 3.79754 25.5599 4.2439L27.8534 6.53743Z'
      fill={fillColor}
    />
    <path
      d='M11.1233 32.4404C10.677 31.994 9.95411 31.994 9.50775 32.4404C9.06138 32.8867 9.06138 33.6104 9.50775 34.056L9.83605 34.3843C5.63061 34.1352 2.28591 30.6359 2.28591 26.3687C2.28591 25.738 1.77404 25.2262 1.14334 25.2262C0.512635 25.2262 0 25.738 0 26.3687C0 31.9041 4.38292 36.4348 9.86118 36.674L9.50775 37.0274C9.06138 37.4738 9.06138 38.1974 9.50775 38.643C9.73093 38.8662 10.0234 38.9774 10.3159 38.9774C10.6084 38.9774 10.9009 38.8662 11.1241 38.643L13.4176 36.3495C13.864 35.9031 13.864 35.1795 13.4176 34.7339L11.1249 32.4404H11.1233Z'
      fill={fillColor}
    />
  </svg>
);

export default Translate;
