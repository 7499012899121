import { number, object, string } from 'prop-types';

const BottomArrowIcon = ({
  className = '',
  style = {},
  width = 28,
  height = 28,
  fillColor = '#666666',
  ...rest
}) => (
  <svg
    {...rest}
    className={className}
    style={style}
    width={width}
    height={height}
    viewBox='0 0 37 37'
  >
    <rect
      x='18.3848'
      width='26'
      height='26'
      rx='13'
      transform='rotate(45 18.3848 0)'
      fill={fillColor}
    />
    <g clipPath='url(#clip0_7137_9858)'>
      <path
        d='M19.0137 24.9477L23.7888 19.8918C23.925 19.7475 24 19.5552 24 19.35C24 19.1448 23.925 18.9525 23.7888 18.8082L23.355 18.3492C23.219 18.205 23.0371 18.1256 22.8433 18.1256C22.6496 18.1256 22.4617 18.205 22.3257 18.3492L19.534 21.2989L19.534 11.9277C19.534 11.5052 19.2217 11.1714 18.8224 11.1714L18.2094 11.1714C17.8103 11.1714 17.4663 11.5052 17.4663 11.9277L17.4663 21.3322L14.659 18.3492C14.5227 18.205 14.3459 18.1256 14.1521 18.1256C13.9583 18.1256 13.7791 18.205 13.6428 18.3492L13.2107 18.8082C13.0744 18.9525 13 19.1448 13 19.35C13 19.5552 13.0752 19.7475 13.2115 19.8918L17.9866 24.9477C18.1234 25.0923 18.3058 25.172 18.4999 25.1714C18.6944 25.172 18.8772 25.0923 19.0137 24.9477Z'
        fill='white'
      />
    </g>
    <defs>
      <clipPath id='clip0_7137_9858'>
        <rect width='14' height='11' fill='white' transform='translate(24 11.1714) rotate(90)' />
      </clipPath>
    </defs>
  </svg>
);

BottomArrowIcon.propTypes = {
  style: object,
  className: string,
  height: number,
  width: number,
  fillColor: string,
};

export default BottomArrowIcon;
